import React from 'react';

import MUIBadge, { BadgeProps as MUIBadgeProps } from '@material-ui/core/Badge';

import { useStyles } from './Badge.styles';

type BadgeProps = MUIBadgeProps & {
  visible?: boolean;
};

/**
 * Пользовательский компонент значка, построенный с использованием Material-UI.
 *
 * @component
 * @param {BadgeProps} props - Свойства для компонента Badge.
 * @param {boolean} [props.visible=false] - Определяет, должен ли значок быть видимым.
 * @param {MUIBadgeProps} [props.rest] - Остальные свойства, которые передаются в компонент Material-UI Badge.
 * @returns {JSX.Element} Компонент Badge.
 */

export const Badge = (props: BadgeProps) => {
  const { visible = false, ...rest } = props;

  const classes = useStyles();

  return (
    <MUIBadge
      invisible={!visible}
      variant="dot"
      className={`aqa_badge ${classes.badge}`}
      data-testid="badge-test-id"
      {...rest}
    >
      {props.children}
    </MUIBadge>
  );
};
