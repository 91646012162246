import React from 'react';

import classNames from 'classnames';
import { SvgIcon } from '@material-ui/core';

import { IconProps } from '../Icon.types';
import { useStyles } from '../Icon.styles';

export const UserLinkIcon = ({ size, color }: IconProps) => {
  const classes = useStyles();

  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(
        size && classes[`icon_size_${size}` as const],
        color && classes[`icon_color_${color}` as const],
      )}
    >
      <path d="M14.8516 7.95078C14.8516 6.37596 13.5764 5.10078 12.0016 5.10078C10.4267 5.10078 9.15156 6.37596 9.15156 7.95078C9.15156 9.5256 10.4267 10.8008 12.0016 10.8008C13.5764 10.8008 14.8516 9.5256 14.8516 7.95078ZM16.6516 7.95078C16.6516 10.5197 14.5705 12.6008 12.0016 12.6008C9.43263 12.6008 7.35156 10.5197 7.35156 7.95078C7.35156 5.38185 9.43263 3.30078 12.0016 3.30078C14.5705 3.30078 16.6516 5.38185 16.6516 7.95078ZM5.90156 18.4293C5.90156 19.1982 5.8108 19.1008 6.23686 19.1008H17.7663C18.1923 19.1008 18.1016 19.1982 18.1016 18.4293C18.1016 16.5368 15.3234 15.4008 12.0016 15.4008C8.67976 15.4008 5.90156 16.5368 5.90156 18.4293ZM4.10156 18.4293C4.10156 15.1026 7.77435 13.6008 12.0016 13.6008C16.2288 13.6008 19.9016 15.1026 19.9016 18.4293C19.9016 20.165 19.216 20.9008 17.7663 20.9008H6.23686C4.7871 20.9008 4.10156 20.165 4.10156 18.4293Z" />
    </SvgIcon>
  );
};
