import { IsOptional, IsNotEmpty, IsUUID } from 'class-validator';

export class GetCanCreateBatchEventOrEventForAnotherEmployeeDto {
  @IsUUID()
  @IsNotEmpty()
  companyId: string;

  @IsUUID()
  @IsOptional()
  parentEventId?: string;
}
