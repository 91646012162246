import { Type, Transform } from 'class-transformer';
import {
  IsInt,
  IsNumber,
  IsIn,
  IsArray,
  IsString,
  IsDateString,
  IsUUID,
  IsNotEmpty,
  ValidateNested,
  IsOptional,
  IsBoolean,
} from 'class-validator';
import { injectable } from 'inversify';

import { HttpService } from '@vk-hr-tek/core/http';
import {
  FiltersResponse,
  Filter,
  FilterService,
  FilterType,
} from '@vk-hr-tek/core/filter';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { UnmarshallerService } from '@vk-hr-tek/core/unmarshaller';
import { QueryService } from '@vk-hr-tek/core/query';

export class UpdateFilterPresetParams {
  @IsUUID()
  @IsNotEmpty()
  filter_preset_id: string;
}

export class GetFilterPresetParams {
  @IsUUID()
  @IsNotEmpty()
  filter_preset_id: string;
}

export class ListFilterPresetsQuery {
  @IsIn([
    'events',
    'schedule_events',
    'absence_events',
    'employees',
    'company_policies',
  ])
  @IsString()
  @IsNotEmpty()
  list_code:
    | 'events'
    | 'schedule_events'
    | 'absence_events'
    | 'employees'
    | 'company_policies';
}

export class FileRequestDownloadQuery {
  @IsUUID()
  @IsNotEmpty()
  download_id: string;
}

export class GetFileRequestStateQuery {
  @IsUUID()
  @IsNotEmpty()
  download_id: string;
}

export type FilterPresetColor = string;

export class FilterPreset {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsIn(['purple', 'orange', 'red', 'green', 'blue'])
  @IsString()
  @IsNotEmpty()
  color: 'purple' | 'orange' | 'red' | 'green' | 'blue';

  @IsNotEmpty()
  filters: object;
}

export class ListFilterPresetsResponse {
  @ValidateNested({ each: true })
  @Type(() => FilterPreset)
  @IsArray()
  @IsNotEmpty()
  presets: FilterPreset[];
}

export class FileDownload {
  @IsUUID()
  @IsNotEmpty()
  download_id: string;

  @IsIn(['in_progress', 'done', 'expired', 'error'])
  @IsString()
  @IsNotEmpty()
  status: 'in_progress' | 'done' | 'expired' | 'error';

  @IsString()
  @IsOptional()
  error_message?: string;
}

export class GetFileRequestStateResponse {
  @ValidateNested()
  @Type(() => FileDownload)
  @IsNotEmpty()
  download: FileDownload;
}

export class RecognizedQrCodeMultipleAttribute {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsIn(['multi_attribute'])
  @IsString()
  @IsNotEmpty()
  type: 'multi_attribute';

  @IsString({ each: true })
  @IsArray()
  @IsNotEmpty()
  values: string[];
}

export class RecognizedQrCodeAttribute {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsIn(['attribute'])
  @IsString()
  @IsNotEmpty()
  type: 'attribute';

  @IsString()
  @IsNotEmpty()
  value: string;
}

export class RecognizeQrCodeResponse {
  @IsArray()
  @IsNotEmpty()
  attributes: (RecognizedQrCodeAttribute | RecognizedQrCodeMultipleAttribute)[];
}

export class RecognizedAttribute {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  value: string;
}

export class RecognizeDocumentResponse {
  @ValidateNested({ each: true })
  @Type(() => RecognizedAttribute)
  @IsArray()
  @IsNotEmpty()
  attributes: RecognizedAttribute[];
}

export class FilterValuesOneOf {}

export class CreateOrUpdateFilterPresetRequest {
  @IsString()
  @IsNotEmpty()
  name: string;

  @IsIn(['purple', 'orange', 'red', 'green', 'blue'])
  @IsString()
  @IsNotEmpty()
  color: 'purple' | 'orange' | 'red' | 'green' | 'blue';

  @ValidateNested()
  @Type(() => FilterValuesOneOf)
  @IsNotEmpty()
  filters: FilterValuesOneOf;
}

export class DeleteFilterPresetsRequest {
  @IsUUID(undefined, { each: true })
  @IsArray()
  @IsNotEmpty()
  ids: string[];
}

export class RecognizeQrCodeRequest {
  @IsUUID()
  @IsNotEmpty()
  cache_file_id: string;

  @IsUUID()
  @IsNotEmpty()
  event_id: string;

  @IsUUID()
  @IsNotEmpty()
  node_id: string;

  @IsUUID()
  @IsNotEmpty()
  attribute_id: string;
}

export class RecognizeDocumentRequest {
  @IsUUID()
  @IsNotEmpty()
  cache_file_id: string;

  @IsUUID()
  @IsNotEmpty()
  event_id: string;

  @IsUUID()
  @IsNotEmpty()
  node_id: string;

  @IsUUID()
  @IsNotEmpty()
  attribute_id: string;
}

export class ConvertImageRequest {
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @Type(() => String)
  @IsNotEmpty()
  image: File;
}

@injectable()
export class ToolsService {
  constructor(
    private validator: ValidationService,
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
    private query: QueryService,
  ) {}

  async updateFilterPreset({
    params,
    body,
  }: {
    params: UpdateFilterPresetParams;
    body: CreateOrUpdateFilterPresetRequest;
  }) {
    await Promise.all([
      this.validator.validateOrReject(params, UpdateFilterPresetParams),
      this.validator.validateOrReject(body, CreateOrUpdateFilterPresetRequest),
    ]);

    let requestPath = '/filter_preset/{filter_preset_id}';

    const paramsKeys = Object.keys(
      params,
    ) as (keyof UpdateFilterPresetParams)[];
    if (paramsKeys.length) {
      paramsKeys.forEach((param) => {
        requestPath = requestPath.replace(
          `{${param}}`,
          encodeURIComponent(String(params[param])),
        );
      });
    }

    const result = await this.http.put(requestPath, body, { withSide: true });

    return result;
  }

  async getFilterPreset({ params }: { params: GetFilterPresetParams }) {
    await Promise.all([
      this.validator.validateOrReject(params, GetFilterPresetParams),
    ]);

    let requestPath = '/filter_preset/{filter_preset_id}';

    const paramsKeys = Object.keys(params) as (keyof GetFilterPresetParams)[];
    if (paramsKeys.length) {
      paramsKeys.forEach((param) => {
        requestPath = requestPath.replace(
          `{${param}}`,
          encodeURIComponent(String(params[param])),
        );
      });
    }

    const result = await this.http.get(requestPath, {}, { withSide: true });

    const response = await this.unmarshaller.unmarshall(result, FilterPreset);

    return response;
  }

  async deleteFilterPresets({ body }: { body: DeleteFilterPresetsRequest }) {
    await Promise.all([
      this.validator.validateOrReject(body, DeleteFilterPresetsRequest),
    ]);

    let requestPath = '/filter_preset/delete';

    const result = await this.http.post(requestPath, body, { withSide: true });

    return result;
  }

  async listFilterPresets({ query }: { query: ListFilterPresetsQuery }) {
    await Promise.all([
      this.validator.validateOrReject(query, ListFilterPresetsQuery),
    ]);

    let requestPath = '/filter_preset';

    const result = await this.http.get(requestPath, query, { withSide: true });

    const response = await this.unmarshaller.unmarshall(
      result,
      ListFilterPresetsResponse,
    );

    return response;
  }

  async createFilterPreset({
    body,
  }: {
    body: CreateOrUpdateFilterPresetRequest;
  }) {
    await Promise.all([
      this.validator.validateOrReject(body, CreateOrUpdateFilterPresetRequest),
    ]);

    let requestPath = '/filter_preset';

    const result = await this.http.post(requestPath, body, { withSide: true });

    const response = await this.unmarshaller.unmarshall(result, FilterPreset);

    return response;
  }

  async fileRequestDownload({ query }: { query: FileRequestDownloadQuery }) {
    await Promise.all([
      this.validator.validateOrReject(query, FileRequestDownloadQuery),
    ]);

    let requestPath = '/file_request/download';

    const result = await this.http.getFile(
      requestPath,
      {
        withAuth: true,
        withSide: true,
        isJson: true,
      },
      query,
    );

    return result;
  }

  async getFileRequestState({ query }: { query: GetFileRequestStateQuery }) {
    await Promise.all([
      this.validator.validateOrReject(query, GetFileRequestStateQuery),
    ]);

    let requestPath = '/file_request';

    const result = await this.http.get(requestPath, query, {});

    const response = await this.unmarshaller.unmarshall(
      result,
      GetFileRequestStateResponse,
    );

    return response;
  }

  async recognizeQrCode({ body }: { body: RecognizeQrCodeRequest }) {
    await Promise.all([
      this.validator.validateOrReject(body, RecognizeQrCodeRequest),
    ]);

    let requestPath = '/tools/qr_code/recognize';

    const result = await this.http.post(requestPath, body, {});

    const response = await this.unmarshaller.unmarshall(
      result,
      RecognizeQrCodeResponse,
    );

    return response;
  }

  async recognizeDocument({ body }: { body: RecognizeDocumentRequest }) {
    await Promise.all([
      this.validator.validateOrReject(body, RecognizeDocumentRequest),
    ]);

    let requestPath = '/tools/document/recognize';

    const result = await this.http.post(requestPath, body, {});

    const response = await this.unmarshaller.unmarshall(
      result,
      RecognizeDocumentResponse,
    );

    return response;
  }

  async convertImage({ body }: { body: ConvertImageRequest }) {
    await Promise.all([
      this.validator.validateOrReject(body, ConvertImageRequest),
    ]);

    let requestPath = '/tools/image/convert';

    const result = await this.http.generateFile(requestPath, body, {
      isJson: false,
    });

    return result;
  }
}
