/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { useField } from 'react-final-form';

import { compose, ValidateType } from '@vk-hr-tek/core/validation';

import { FileInput as Input, FileInputValue } from '../../input';

/**
 * Интерфейс пропсов компонента FileInput.
 *
 * @typedef {Object} FileInputProps
 * @property {string} label - Подпись поля.
 * @property {React.ReactNode} [tooltip] - Всплывающая подсказка.
 * @property {string} name - Имя поля.
 * @property {'input' | 'preview'} [variant] - Вариант отображения поля.
 * @property {string} [placeholder] - Текст заполнителя.
 * @property {boolean} [required] - Обязательность поля.
 * @property {boolean} [disabled] - Недоступность поля.
 * @property {(value?: FileInputValue) => void} [onChange] - Обработчик изменения значения.
 * @property {boolean} [loading] - Состояние загрузки.
 * @property {boolean} [showErrorWithoutTouched] - Показывать ошибку без взаимодействия с полем.
 * @property {ValidateType[]} [validate] - Массив валидаторов.
 * @property {(file: File) => Promise<{ file_id: string; expired_at: string }>} [cache] - Функция для кэширования файла.
 * @property {boolean} [fullWidth] - Занимать всю ширину контейнера.
 * @property {string} [bannerText] - Текст баннера.
 * @property {string} [helperText] - Дополнительный текст помощи.
 * @property {string} [testId] - Идентификатор для тестирования.
 */

type FileInputProps = {
  label: string;
  tooltip?: React.ReactNode;
  name: string;
  variant?: 'input' | 'preview';
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: (value?: FileInputValue) => void;
  loading?: boolean;
  loadingText?: JSX.Element;
  showErrorWithoutTouched?: boolean;
  validate?: ValidateType[];
  cache?: (file: File) => Promise<{ file_id: string; expired_at: string }>;
  fullWidth?: boolean;
  bannerText?: string;
  autorecognitionQr?: boolean;
  helperText?: string;
  testId?: string;
};

/**
 * Компонент для загрузки файла с возможностью предварительного просмотра.
 *
 * @param {FileInputProps} props - Свойства компонента.
 * @param {string} props.label - Подпись поля.
 * @param {React.ReactNode} [props.tooltip] - Всплывающая подсказка.
 * @param {string} props.name - Имя поля.
 * @param {'input' | 'preview'} [props.variant='input'] - Вариант отображения поля.
 * @param {string} [props.placeholder] - Текст заполнителя.
 * @param {boolean} [props.required=false] - Обязательность поля.
 * @param {boolean} [props.disabled=false] - Недоступность поля.
 * @param {(value?: FileInputValue) => void} [props.onChange] - Обработчик изменения значения.
 * @param {boolean} [props.loading=false] - Состояние загрузки.
 * @param {boolean} [props.showErrorWithoutTouched=false] - Показывать ошибку без взаимодействия с полем.
 * @param {ValidateType[]} [props.validate] - Массив валидаторов.
 * @param {(file: File) => Promise<{ file_id: string; expired_at: string }>} [props.cache] - Функция для кэширования файла.
 * @param {boolean} [props.fullWidth] - Занимать всю ширину контейнера.
 * @param {string} [props.bannerText] - Текст баннера.
 * @param {string} [props.helperText] - Дополнительный текст помощи.
 * @param {string} [props.testId] - Идентификатор для тестирования.
 * @returns {React.ReactElement} - Элемент React.
 */
export const FileInput = ({
  label,
  tooltip,
  name,
  variant = 'input',
  placeholder,
  required = false,
  disabled = false,
  onChange,
  loading = false,
  loadingText = undefined,
  showErrorWithoutTouched = false,
  validate,
  cache,
  fullWidth,
  bannerText,
  autorecognitionQr = false,
  helperText,
  testId,
}: FileInputProps) => {
  const { input, meta } = useField(name, {
    validate: compose(validate || []),
  });

  const { onChange: onChangeInput, onBlur } = input;

  const error = showErrorWithoutTouched
    ? !!input.value && meta.error
    : meta.touched && meta.error;

  return (
    <Input
      {...input}
      label={label}
      value={input.value}
      onChange={(props) => {
        onChangeInput(props);
        onChange?.(props);
      }}
      variant={variant}
      tooltip={tooltip}
      required={required}
      error={error}
      errorText={meta.error}
      helperText={helperText}
      disabled={disabled}
      loading={loading}
      loadingText={loadingText}
      onBlur={onBlur}
      cache={cache}
      fullWidth={fullWidth}
      placeholder={placeholder}
      autorecognitionQr={autorecognitionQr}
      bannerText={bannerText}
      testId={testId}
    />
  );
};
