import React, { useContext } from 'react';

import ReactInfiniteScroll, { Props } from 'react-infinite-scroll-component';

import { useStyles } from './InfiniteScroll.styles';
import { InfiniteScrollContext } from './InfiniteScrollProvider';

interface InfiniteScrollProps extends Props {
  withOverflow?: boolean;
}

/**
 * Пользовательский компонент InfiniteScroll, который расширяет `react-infinite-scroll-component`.
 * Он позволяет при необходимости применять стили для переполнения и использует контекст для определения целевого элемента прокрутки.
 *
 * @param {Object} props - Свойства, которые можно передать в компонент InfiniteScroll.
 * @param {boolean} [props.withOverflow=false] - Если true, применяет стили для переполнения к компоненту.
 * @param {React.ReactNode} children - Содержимое, которое будет отображено внутри компонента InfiniteScroll.
 * @returns {JSX.Element} JSX-элемент, представляющий компонент InfiniteScroll.
 */

export const InfiniteScroll = ({ children, ...props }: InfiniteScrollProps) => {
  const classes = useStyles();
  const { withOverflow } = props;
  const scrollTarget = useContext(InfiniteScrollContext);

  return (
    <ReactInfiniteScroll
      {...(scrollTarget
        ? {
            scrollableTarget: scrollTarget,
          }
        : {})}
      {...props}
      {...(withOverflow && {
        className: classes.infiniteScroll,
      })}
    >
      {children}
    </ReactInfiniteScroll>
  );
};
