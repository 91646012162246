import React, { Suspense, useMemo } from 'react';

import { Switch } from 'react-router-dom';

import { SentryRoute as Route } from '@vk-hr-tek/core/monitoring/SentryRoute';

import { modules, ModulesNames } from '@app/modules';

import { PrivateRoute, PageLoading, AbilityProvider } from './layout';

const Auth = React.lazy(async () => import('./auth'));
const Candidates = React.lazy(async () => import('./candidates'));
const Employees = React.lazy(async () => import('./employees'));
const Events = React.lazy(async () => import('./events'));
const Organization = React.lazy(async () => import('./organization'));
const Settings = React.lazy(async () => import('./settings'));
const Vacations = React.lazy(async () => import('./vacations'));
const Policy = React.lazy(async () => import('./policy'));
const User = React.lazy(async () => import('./user'));
const Payslips = React.lazy(async () => import('./payslips'));
const Competencies = React.lazy(async () => import('./competencies'));
const Dashboard = React.lazy(async () => import('./dashboard'));
const Absences = React.lazy(async () => import('./absences'));
const Personal = React.lazy(async () => import('./personal'));

const resources: {
  module: ModulesNames;
  Component: React.ComponentType;
}[] = [
  {
    module: 'events',
    Component: Events,
  },
  {
    module: 'candidates',
    Component: Candidates,
  },
  {
    module: 'employees',
    Component: Employees,
  },
  {
    module: 'policy',
    Component: Policy,
  },
  {
    module: 'payslips',
    Component: Payslips,
  },
  {
    module: 'competencies',
    Component: Competencies,
  },

  {
    module: 'settings',
    Component: Settings,
  },
  {
    module: 'vacations',
    Component: Vacations,
  },
  {
    module: 'absences',
    Component: Absences,
  },
];

function App({ page = 'all' }: { page?: string }) {
  const routes = useMemo(() => {
    return page === 'all'
      ? resources.map((resource) => ({
          ...resource,
          path: modules[resource.module].basename,
          resource: modules[resource.module].resource,
        }))
      : resources
          .filter(({ module }) => {
            if (page === 'competencies') {
              return module === 'events' || module === page;
            }

            if (page === 'absences') {
              return (
                module === 'events' || module === 'vacations' || module === page
              );
            }

            return module === page;
          })
          .map((resource, index, arr) => ({
            ...resource,
            path:
              arr.length > 1 && resource.module !== page
                ? `/${modules[resource.module].basename}`
                : '',
            resource: modules[resource.module].resource,
          }));
  }, [page]);

  if (page === 'all') {
    return (
      <Suspense fallback={<PageLoading />}>
        <Switch>
          <Route path="/auth">
            <Auth />
          </Route>
          <PrivateRoute path="/create-certificate" resource="User">
            <User />
          </PrivateRoute>
          <PrivateRoute path="/profile" resource="User">
            <User />
          </PrivateRoute>
          <PrivateRoute path="/organization" resource="Events">
            <AbilityProvider role="general">
              <Organization />
            </AbilityProvider>
          </PrivateRoute>
          <PrivateRoute path="/personal" resource="Personal">
            <Personal />
          </PrivateRoute>
          <PrivateRoute path="/company" resource="Events">
            <AbilityProvider role="company">
              <Switch>
                {routes.map(({ path, resource, Component }) => (
                  <PrivateRoute
                    path={`/company/${path}`}
                    resource={resource}
                    key={path}
                  >
                    <Component />
                  </PrivateRoute>
                ))}
              </Switch>
            </AbilityProvider>
          </PrivateRoute>
          <PrivateRoute path="/employee" resource="Events">
            <AbilityProvider role="employee">
              <Switch>
                {routes.map(({ path, resource, Component }) => (
                  <PrivateRoute
                    path={`/employee/${path}`}
                    resource={resource}
                    key={path}
                  >
                    <Component />
                  </PrivateRoute>
                ))}
              </Switch>
            </AbilityProvider>
          </PrivateRoute>
          <PrivateRoute path="/" resource="Events">
            <Dashboard />
          </PrivateRoute>
        </Switch>
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<PageLoading />}>
      <Switch>
        <Route path="/auth">
          <Auth />
        </Route>
        <PrivateRoute path="/create-certificate" resource="User">
          <User />
        </PrivateRoute>
        <PrivateRoute path="/profile" resource="User">
          <User />
        </PrivateRoute>
        <Switch>
          {routes.map(({ path, resource, Component }) => (
            <PrivateRoute path={`${path}`} resource={resource} key={path}>
              <Component />
            </PrivateRoute>
          ))}
        </Switch>
        <PrivateRoute path="/" resource="Events">
          <Dashboard />
        </PrivateRoute>
      </Switch>
    </Suspense>
  );
}

export default App;
