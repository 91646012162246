import { IsNotEmpty, IsUUID, IsString } from 'class-validator';

export class BatchEventDto {
  @IsUUID(undefined, { each: true })
  @IsNotEmpty()
  event_id: string;

  @IsString()
  @IsNotEmpty()
  name: string;
}
