import React from 'react';

import { Form as FinalForm, FormProps } from 'react-final-form';

import { setFieldData } from './form.mutators';

/**
 * Компонент формы, обертка вокруг react-final-form с добавленным мутатором setFieldData.
 *
 * @template FormValues - Интерфейс значений формы, где ключи - это строки, а значения - любые типы данных.
 * @template InitialFormValues - Интерфейс начальных значений формы, который является подмножеством значений формы.
 * @param {FormProps<FormValues, InitialFormValues>} props - Свойства формы, передаваемые в react-final-form.
 * @returns {JSX.Element} Элемент формы.
 */
export const Form = <
  FormValues extends Record<string, any>,
  InitialFormValues = Partial<FormValues>,
>(
  props: FormProps<FormValues, InitialFormValues>,
) => <FinalForm {...props} mutators={{ ...props.mutators, setFieldData }} />;
