/**
 * Конфигурация темы для создания палитры Material-UI на основе предоставленных токенов дизайна.
 * Этот файл расширяет интерфейсы палитры и темы Material-UI, чтобы включить пользовательские свойства.
 *
 * @module createPalette
 * @requires Tokens из '../types'
 */

import { Tokens } from '../types';

/**
 * Расширяет интерфейс TypeText Material-UI для включения свойства цвета tertiary.
 *
 * @typedef {Object} TypeText
 * @property {string} primary - Основной цвет текста.
 * @property {string} secondary - Вторичный цвет текста.
 * @property {string} disabled - Цвет отключенного текста.
 * @property {string} hint - Цвет подсказки текста.
 * @property {string} tertiary - Третичный цвет текста.
 */
declare module '@material-ui/core/styles/createPalette' {
  interface TypeText {
    primary: string;
    secondary: string;
    disabled: string;
    hint: string;
    tertiary: string;
  }

  /**
   * Расширяет интерфейс TypeBackground Material-UI для включения дополнительных цветов фона.
   *
   * @typedef {Object} TypeBackground
   * @property {string} default - Основной цвет фона.
   * @property {string} paper - Цвет фона для бумаги.
   * @property {string} primary - Основной цвет фона.
   * @property {string} dark - Темный цвет фона.
   * @property {string} lightPrimary - Светлый основной цвет фона.
   * @property {string} lightSecondary - Светлый вторичный цвет фона.
   * @property {string} darkPrimary - Темный основной цвет фона.
   * @property {string} darkSecondary - Темный вторичный цвет фона.
   * @property {string} greyscalePrimary - Основной цвет фона в оттенках серого.
   * @property {string} greyscaleSecondary - Вторичный цвет фона в оттенках серого.
   * @property {string} greyscaleTertiary - Третичный цвет фона в оттенках серого.
   * @property {string} gradientPurple - Цвет фона с фиолетовым градиентом.
   */
  interface TypeBackground {
    default: string;
    paper: string;
    primary: string;
    dark: string;
    lightPrimary: string;
    lightSecondary: string;
    darkPrimary: string;
    darkSecondary: string;
    greyscalePrimary: string;
    greyscaleSecondary: string;
    greyscaleTertiary: string;
    gradientPurple: string;
  }

  /**
   * Расширяет интерфейс PaletteOptions Material-UI для включения пользовательских цветовых схем review и blue.
   *
   * @typedef {Object} PaletteOptions
   * @property {Object} review - Цветовая схема review.
   * @property {string} review.main - Основной цвет review.
   * @property {string} review.light - Светлый цвет review.
   * @property {string} review.stroke - Цвет обводки review.
   * @property {Object} blue - Цветовая схема blue.
   * @property {string} blue.main - Основной цвет blue.
   * @property {string} blue.light - Светлый цвет blue.
   * @property {string} blue.stroke - Цвет обводки blue.
   * @property {Object} stroke - Цветовая схема обводки.
   * @property {string} stroke.primary - Основной цвет обводки.
   * @property {string} stroke.secondary - Вторичный цвет обводки.
   * @property {string} stroke.tertiary - Третичный цвет обводки.
   */
  interface PaletteOptions {
    review: {
      main: string;
      light: string;
      stroke: string;
    };
    blue: {
      main: string;
      light: string;
      stroke: string;
    };
    stroke: {
      primary: string;
      secondary: string;
      tertiary: string;
    };
  }

  /**
   * Расширяет интерфейс Palette Material-UI для включения пользовательских цветовых схем review и blue.
   *
   * @typedef {Object} Palette
   * @property {Object} review - Цветовая схема review.
   * @property {string} review.main - Основной цвет review.
   * @property {string} review.light - Светлый цвет review.
   * @property {string} review.stroke - Цвет обводки review.
   * @property {Object} blue - Цветовая схема blue.
   * @property {string} blue.main - Основной цвет blue.
   * @property {string} blue.light - Светлый цвет blue.
   * @property {string} blue.stroke - Цвет обводки blue.
   * @property {Object} stroke - Цветовая схема обводки.
   * @property {string} stroke.primary - Основной цвет обводки.
   * @property {string} stroke.secondary - Вторичный цвет обводки.
   * @property {string} stroke.tertiary - Третичный цвет обводки.
   */
  interface Palette {
    review: {
      main: string;
      light: string;
      stroke: string;
    };
    blue: {
      main: string;
      light: string;
      stroke: string;
    };
    stroke: {
      primary: string;
      secondary: string;
      tertiary: string;
    };
  }

  /**
   * Расширяет интерфейс SimplePaletteColorOptions Material-UI для включения дополнительных цветовых свойств.
   *
   * @typedef {Object} SimplePaletteColorOptions
   * @property {string} [light] - Светлый цвет.
   * @property {string} main - Основной цвет.
   * @property {string} [dark] - Темный цвет.
   * @property {string} [contrastText] - Цвет контраста текста.
   * @property {string} [stroke] - Цвет обводки.
   * @property {string} [hover] - Цвет при наведении.
   * @property {string} [black] - Черный цвет.
   * @property {string} [quaternary] - Четвертичный цвет.
   */
  interface SimplePaletteColorOptions {
    light?: string;
    main: string;
    dark?: string;
    contrastText?: string;
    stroke?: string;
    hover?: string;
    black?: string;
    quaternary?: string;
  }

  /**
   * Расширяет интерфейс PaletteColor Material-UI для включения дополнительных цветовых свойств.
   *
   * @typedef {Object} PaletteColor
   * @property {string} light - Светлый цвет.
   * @property {string} main - Основной цвет.
   * @property {string} dark - Темный цвет.
   * @property {string} contrastText - Цвет контраста текста.
   * @property {string} stroke - Цвет обводки.
   * @property {string} hover - Цвет при наведении.
   * @property {string} black - Черный цвет.
   * @property {string} quaternary - Четвертичный цвет.
   */
  interface PaletteColor {
    light: string;
    main: string;
    dark: string;
    contrastText: string;
    stroke: string;
    hover: string;
    black: string;
    quaternary: string;
  }
}

/**
 * Расширяет интерфейс ThemeOptions Material-UI для включения токенов дизайна и функции px.
 *
 * @typedef {Object} ThemeOptions
 * @property {Tokens} tokens - Токены дизайна.
 * @property {(px: number) => number} px - Функция для преобразования пикселей в специфические для темы единицы измерения.
 */
declare module '@material-ui/core/styles/createTheme' {
  export interface ThemeOptions {
    tokens: Tokens;
    px: (px: number) => number;
  }

  /**
   * Расширяет интерфейс Theme Material-UI для включения токенов дизайна и функции px.
   *
   * @typedef {Object} Theme
   * @property {Tokens} tokens - Токены дизайна.
   * @property {(px: number) => number} px - Функция для преобразования пикселей в специфические для темы единицы измерения.
   */
  export interface Theme {
    tokens: Tokens;
    px: (px: number) => number;
  }
}

/**
 * Создает палитру Material-UI с использованием предоставленных токенов дизайна.
 *
 * @function createPalette
 * @param {Tokens} tokens - Токены дизайна для создания палитры.
 * @returns {Palette} - Созданный объект палитры.
 */
export const createPalette = (tokens: Tokens) => {
  return {
    primary: {
      main: tokens.colors.original.brand.primary,
      dark: tokens.colors.original.brand.tertirary,
      light: tokens.colors.original.brand.secondary,
      contrastText: tokens.colors.text.dark.primary,
      quaternary: tokens.colors.original.brand.quaternary,
    },
    secondary: {
      main: tokens.colors.accent.text.error,
      contrastText: tokens.colors.text.dark.primary,
    },
    error: {
      main: tokens.colors.accent.text.error,
      light: tokens.colors.accent.bg.error,
      stroke: tokens.colors.accent.stroke.error,
      hover: '#ffbcbc', // TODO - исправить нет в токенах
      dark: '#cd2c2c', // TODO - исправить нет в токенах
    },
    success: {
      main: tokens.colors.accent.text.success,
      light: tokens.colors.accent.bg.success,
      stroke: tokens.colors.accent.stroke.success,
    },
    review: {
      main: tokens.colors.accent.text.review,
      light: tokens.colors.accent.bg.review,
      stroke: tokens.colors.accent.stroke.review,
    },
    warning: {
      main: tokens.colors.accent.text.warning,
      light: tokens.colors.accent.bg.warning,
      stroke: tokens.colors.accent.stroke.warning,
    },
    blue: {
      main: tokens.colors.accent.text.blue,
      light: tokens.colors.accent.bg.blue,
      stroke: tokens.colors.accent.stroke.blue,
    },
    background: {
      paper: tokens.colors.bg.light.primary,
      default: tokens.colors.bg.light.secondary,
      primary: tokens.colors.original.brand.secondary,
      lightPrimary: tokens.colors.bg.light.primary,
      lightSecondary: tokens.colors.bg.light.secondary,
      darkPrimary: tokens.colors.bg.dark.primary,
      darkSecondary: tokens.colors.bg.dark.secondary,
      greyscalePrimary: tokens.colors.bg.greyscale.primary,
      greyscaleSecondary: tokens.colors.bg.greyscale.secondary,
      greyscaleTertiary: tokens.colors.bg.greyscale.tertirary,
      gradientPurple: '#8349ff', // TODO - исправить нет в токенах
    },
    text: {
      primary: tokens.colors.text.light.primary,
      secondary: tokens.colors.text.light.secondary,
      tertiary: tokens.colors.text.light.tertirary,
      disabled: tokens.colors.text.light.tertirary,
    },
    stroke: {
      primary: tokens.colors.stroke.primary,
      secondary: tokens.colors.stroke.secondary,
      tertiary: tokens.colors.stroke.tertirary,
    },
  } as const;
};
