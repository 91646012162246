import React from 'react';

import classNames from 'classnames';
import { SvgIcon } from '@material-ui/core';

import { IconProps } from '../Icon.types';
import { useStyles } from '../Icon.styles';

export const AdvertisingLinkIcon = ({ size, color }: IconProps) => {
  const classes = useStyles();

  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(
        size && classes[`icon_size_${size}` as const],
        color && classes[`icon_color_${color}` as const],
      )}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5688 6.9136C18.3723 6.87366 18.166 6.90436 17.996 7.00346L17.9708 7.01813L14.214 8.89345H7.60156C6.83128 8.89345 6.14867 9.17895 5.66422 9.62244C5.22265 10.0267 4.90156 10.7967 4.90156 11.4894C4.90156 12.182 5.22262 12.9518 5.66422 13.3561C5.91225 13.5831 6.21221 13.7688 6.5493 13.8959C6.87061 14.0171 7.22564 14.0851 7.60156 14.0851H8.51693H9.34554H11.3339H14.214L17.9708 15.9606L17.996 15.9752C18.1117 16.0427 18.2526 16.0819 18.4016 16.0819C18.6196 16.0819 18.8022 16.0013 18.9247 15.8891C19.0459 15.7781 19.1016 15.6455 19.1016 15.5157V7.463C19.1016 7.37271 19.0738 7.27777 19.012 7.18886C18.9202 7.05689 18.7655 6.95357 18.5688 6.9136ZM17.1165 5.43538C17.6853 5.11338 18.3346 5.03206 18.9278 5.15261C19.5307 5.27512 20.1077 5.61348 20.4905 6.16388C20.7556 6.54511 20.9016 6.99594 20.9016 7.463V15.5157C20.9016 16.1949 20.599 16.7946 20.1412 17.2137C19.6846 17.6317 19.0672 17.879 18.4016 17.879C17.9524 17.879 17.5077 17.7647 17.1165 17.5433L13.7891 15.8822H11.6517H9.82391H8.47094H7.60156C7.26733 15.8822 6.94068 15.8481 6.62569 15.7832C5.79286 15.6116 5.04161 15.2243 4.44774 14.6807C3.5864 13.8922 3.10156 12.6168 3.10156 11.4894C3.10156 10.3619 3.58636 9.08639 4.44774 8.29786C5.26623 7.54858 6.38362 7.09634 7.60156 7.09634H13.7891L17.1165 5.43538Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9016 7.99489L14.9016 14.9836L13.1016 14.9836L13.1016 7.99489H14.9016Z"
      />
      <path d="M8.7774 17.6174L8.47094 15.8822H7.60156C7.26733 15.8822 6.94068 15.8481 6.62569 15.7832L7.00475 17.9295C7.12205 18.5937 7.51026 19.1387 8.02694 19.4834C8.54283 19.8275 9.19211 19.9767 9.8501 19.8609C10.5081 19.745 11.0671 19.3831 11.434 18.8836C11.8015 18.3833 11.9795 17.7386 11.8622 17.0744L11.6517 15.8822H9.82391L10.0896 17.3864C10.1161 17.5364 10.079 17.6894 9.98245 17.8209C9.88532 17.9531 9.73139 18.0569 9.53754 18.0911C9.34368 18.1252 9.16348 18.0802 9.0269 17.9891C8.89112 17.8985 8.80388 17.7673 8.7774 17.6174Z" />
    </SvgIcon>
  );
};
