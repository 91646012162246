import React, { useMemo, useEffect } from 'react';

import classNames from 'classnames';
import {
  Dialog as MUIDialog,
  DialogProps as MUIDialogProps,
} from '@material-ui/core';

import { useIsInsideContainer } from '../hooks';

import { useStyles } from './Dialog.styles';

interface DialogProps extends MUIDialogProps {
  withoutRadius?: boolean;
}

/**
 * Компонент диалога, который расширяет диалоговый компонент Material-UI дополнительными опциями стилизации.
 *
 * @param {Object} props - Свойства, которые можно передать в компонент Dialog.
 * @param {boolean} [props.fullScreen=false] - Если true, диалог будет полноэкранным на мобильных устройствах.
 * @param {boolean} [props.withoutRadius] - Если true, диалог не будет иметь закруглённых углов.
 * @param {ReactNode} props.children - Содержимое, которое будет отображаться внутри диалога.
 * @param {Partial<MUIDialogProps['classes']>} [props.classes] - Пользовательские CSS-классы для переопределения стилей по умолчанию.
 * @param {boolean} props.open - Если true, диалог открыт.
 * @param {...MUIDialogProps} props - Дополнительные свойства, которые можно передать в компонент Dialog Material-UI.
 * @returns {JSX.Element | null} JSX-элемент, представляющий диалог, если он открыт, в противном случае null.
 */

export const Dialog = ({
  fullScreen = false,
  withoutRadius,
  children,
  classes,
  open,
  ...props
}: DialogProps) => {
  const styles = useStyles();
  const isInsideContainer = useIsInsideContainer();

  const modalClasses = useMemo(
    () => ({
      ...classes,
      paper: classNames(
        styles.dialogPaper,
        classes?.paper,
        withoutRadius && styles.dialogWithoutRadius,
      ),
    }),
    [classes, styles, withoutRadius],
  );

  useEffect(() => {
    if (isInsideContainer) {
      document.body.style.overflow = open ? 'hidden' : '';
    }
  }, [open, isInsideContainer]);

  if (open) {
    return (
      <MUIDialog
        classes={modalClasses}
        fullScreen={fullScreen}
        className={classNames('aqa_dialog', props.className)}
        open={open}
        data-testid="dialog-test-id"
        {...props}
      >
        {children}
      </MUIDialog>
    );
  }

  return null;
};
