import { useLocation, useHistory } from 'react-router-dom';

export const useRedirects = () => {
  const location = useLocation();
  const history = useHistory();

  const path = location.pathname;
  const hash = location.hash;
  const query = location.search;
  const pathWithQuery = path + query;

  if (path === '/') {
    if (hash === '#employee') {
      history.push(`/employee/events${query}`);
    }

    if (hash === '#company') {
      history.push(`/company/events${query}`);
    }
  }

  if (path.startsWith('/events')) {
    if (hash === '#company') {
      history.push(`/company${pathWithQuery}`);
    }

    if (hash === '#employee' || hash === '') {
      history.push(`/employee${pathWithQuery}`);
    }
  }

  if (path.startsWith('/candidates')) {
    history.push(`/company${pathWithQuery}`);
  }

  if (path.startsWith('/company/organization')) {
    history.push(`/organization${query}`);
  }

  if (path.startsWith('/policy')) {
    if (path === '/policy' && (hash === '' || hash === '#employee')) {
      history.push(`/employee/policy${query}`);
    } else {
      history.push(`/company${pathWithQuery}`);
    }
  }

  if (path.startsWith('/vacation')) {
    if (hash === '' || hash === '#employee') {
      history.push(`/employee${pathWithQuery}`);
    }

    if (hash === '#company') {
      history.push(`/company${pathWithQuery}`);
    }
  }
};
