import React, { ReactElement } from 'react';

import { Box } from '@vk-hr-tek/ui/Box';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { Tooltip } from '@vk-hr-tek/ui/Tooltip';

import { scaleBarInputConfigs } from './ScaleBarInput.configs';

const SCALE_BAR_ELEMENTS_COUNT = 5;

/**
 * Функция для определения конфигурации на основе текущего значения и целевого значения.
 * @function getConfig
 * @param {number} value - Текущее значение шкалы.
 * @param {number} target - Целевое значение для шкалы.
 * @returns {typeof scaleBarInputConfigs[keyof typeof scaleBarInputConfigs]} Объект конфигурации для шкалы.
 */

const getConfig = (value: number, target: number) => {
  let type: keyof typeof scaleBarInputConfigs;

  if (value < target) type = 'low';
  else if (value === target) type = 'normal';
  else type = 'high';

  return scaleBarInputConfigs[type];
};

/**
 * Интерфейс, определяющий свойства компонента ScaleBarInput.
 * @typedef {Object} ScaleBarProps
 * @property {number | undefined} [target] - Целевое значение для шкалы (по умолчанию 0).
 * @property {number | undefined} [value] - Текущее значение шкалы (по умолчанию 0).
 * @property {boolean | undefined} [showLabel] - Флаг отображения метки (по умолчанию true).
 * @property {((index: number) => React.ReactElement) | undefined} [renderTooltip] - Пользовательская функция для рендера подсказки.
 * @property {'sm' | 'md' | undefined} [size] - Размер элементов шкалы ('sm' или 'md', по умолчанию 'md').
 * @property {'text' | 'stroke' | undefined} [color] - Цветовая схема шкалы ('text' или 'stroke', по умолчанию 'text').
 * @property {((value: number) => void) | undefined} [onChange] - Коллбэк-функция, вызываемая при изменении значения.
 * @property {boolean | undefined} [disabled] - Флаг, указывающий, отключена ли шкала (по умолчанию false).
 */

interface ScaleBarProps {
  target?: number;
  value?: number;
  showLabel?: boolean;
  renderTooltip?: (index: number) => ReactElement | undefined;
  size?: 'sm' | 'md';
  color?: 'text' | 'stroke';
  onChange?: (value: number) => void;
  disabled?: boolean;
}

/**
 * Компонент ScaleBarInput используется для отображения шкалы с интерактивными элементами.
 * Он поддерживает отображение меток, подсказок и обработку изменений значения.
 *
 * @component
 * @param {ScaleBarProps} props - Свойства для компонента.
 * @param {number | undefined} props.target - Целевое значение для шкалы (по умолчанию 0).
 * @param {number | undefined} props.value - Текущее значение шкалы (по умолчанию 0).
 * @param {boolean | undefined} props.showLabel - Флаг отображения метки (по умолчанию true).
 * @param {((index: number) => React.ReactElement) | undefined} props.renderTooltip - Пользовательская функция для рендера подсказки.
 * @param {'sm' | 'md' | undefined} props.size - Размер элементов шкалы ('sm' или 'md', по умолчанию 'md').
 * @param {'text' | 'stroke' | undefined} props.color - Цветовая схема шкалы ('text' или 'stroke', по умолчанию 'text').
 * @param {((value: number) => void) | undefined} props.onChange - Коллбэк-функция, вызываемая при изменении значения.
 * @param {boolean | undefined} props.disabled - Флаг, указывающий, отключена ли шкала (по умолчанию false).
 * @returns {React.ReactElement} Отрендеренный компонент ScaleBarInput.
 */

export const ScaleBarInput = ({
  target = 0,
  value = 0,
  renderTooltip,
  size = 'md',
  color = 'text',
  onChange,
  showLabel = true,
  disabled,
}: ScaleBarProps) => {
  const config = getConfig(value, target);

  const handleChange = (i: number) => {
    if (disabled) {
      return;
    }
    onChange?.(i);
  };

  return (
    <Box display="flex" flexDirection="column" gap="8" width="100%">
      {showLabel && (
        <Box>
          <Typography variant="caption" color={config.text.color}>
            {config.text.label}
          </Typography>
        </Box>
      )}
      <Box display="flex" alignItems="center" gap="4">
        {new Array(SCALE_BAR_ELEMENTS_COUNT).fill(null).map((_, index) => {
          const tooltip = renderTooltip?.(index);
          const colorCell =
            color === 'stroke' ? config.level.strokeColor : config.level.color;

          return (
            <Tooltip
              disabled={!tooltip}
              title={tooltip || ''}
              placement="bottom"
              key={index}
            >
              <Box
                display="flex"
                flexGrow={1}
                height={size === 'md' ? 8 : 4}
                radius="m"
                bgcolor={index < value ? colorCell : 'stroke.primary'}
                onClick={() => handleChange(index + 1)}
                cursor={onChange && !disabled ? 'pointer' : 'default'}
                key={index}
              />
            </Tooltip>
          );
        })}
      </Box>
    </Box>
  );
};
