import React, { ReactNode } from 'react';

import { selectUserRepresentative, usePermissions } from '@vk-hr-tek/app/user';

import { AbilityContext } from '@app/ability/Can';
import { useSelector } from '@app/hooks';
import createAbilityFor from '@app/ability/ability';

interface AbilityProviderProps {
  children: ReactNode;
  role: 'employee' | 'company' | 'general';
}

export const AbilityProvider = ({ children, role }: AbilityProviderProps) => {
  const userRepresentative = useSelector(selectUserRepresentative);

  const {
    view_events: canSeeEvents,
    view_event_to_paper: canSeeEventsToPaper,
    view_employees: canSeeEmployees,
    view_policies: canSeePolicies,
    view_company: canSeeCompany,
    view_company_profiles: canSeeCompanyProfiles,
    view_settings: canSeeSettings,
    view_attorneys: canSeeAttorneys,
    view_candidates: canSeeCandidates,
    view_vacations: canSeeVacations,
    view_vacations_settings: canSeeVacationsSettings,
    view_payslips: canSeePayslips,
    view_smev_errors: canSeeSmevErrors,
    view_personal_profile: canSeePersonalProfile,
    view_absences: canSeeAbsences,
    view_competencies: canSeeCompetencies,
    view_units: canSeeUnits,
  } = usePermissions(role);

  const ability = createAbilityFor({
    role,
    userRepresentative,
    canSeeEvents,
    canSeeEventsToPaper,
    canSeeEmployees,
    canSeePolicies,
    canSeeCompany,
    canSeeCompanyProfiles,
    canSeeSettings,
    canSeeCandidates,
    canSeeAttorneys,
    canSeeVacations,
    canSeeVacationsSettings,
    canSeePayslips,
    canSeeSmevErrors,
    canSeePersonalProfile,
    canSeeAbsences,
    canSeeCompetencies,
    canSeeUnits,
  });

  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  );
};
