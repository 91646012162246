import { Type } from 'class-transformer';
import {
  IsArray,
  IsNotEmpty,
  IsOptional,
  IsString,
  IsUUID,
} from 'class-validator';

export class GetCreatableEventTypesForEmployeeDto {
  @IsUUID()
  @IsString()
  @IsNotEmpty()
  id?: string;

  @IsUUID()
  @IsString()
  @IsOptional()
  parentEventId?: string;

  @IsUUID()
  @IsString()
  @IsOptional()
  eventTypeGroup?: string;

  @Type(() => String)
  @IsArray()
  @IsOptional()
  tag?: string[];
}
