import { FiltersPresetWithRootState as RootState } from './filtersPreset.state';

export const selectSavedFiltersPresets = (state: RootState) =>
  state.filtersPreset.items;

export const selectFilterPresetsStatus = (state: RootState) =>
  state.filtersPreset.status;

export const selectAvailableFiltersError = (state: RootState) =>
  state.filtersPreset.filterPreset.availableFilters.error;

export const selectAvailableFiltersStatus = (state: RootState) =>
  state.filtersPreset.filterPreset.availableFilters.status;

export const selectAvailableFilters = (state: RootState) =>
  state.filtersPreset.filterPreset.availableFilters.filters;

export const selectInitialValues = (state: RootState) =>
  state.filtersPreset.filterPreset.initialValues.values;
