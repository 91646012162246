import React, { ChangeEvent, ReactNode, useEffect, useState } from 'react';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Search } from '@material-ui/icons';
import classNames from 'classnames';

import { useDebounce } from '@vk-hr-tek/core/hooks';
import { CloseIcon } from '@vk-hr-tek/ui/icons/CloseIcon';

import { CircularProgress } from '../../CircularProgress';
import { Box } from '../../Box';
import { Option } from '../dropdownOption';
import { Label, Preloader, Badge } from '../common';
import { CancelIcon } from '../../icons';
import { useAutocompleteReducer } from '../hooks';
import { Filter } from '../types';

import { ListBox } from './ListBox';
import { useStyles } from './AutocompleteInput.styles';

const DEBOUNCE_TIMEOUT = 200;

type ValueType = string | undefined;

interface AutocompleteInputProps {
  label: string;
  search?: boolean;
  value: ValueType;
  onInputValueChange?: (newValue: string) => void;
  onChange: (value: ValueType) => void;
  getSearchOptionLabel?: (option: Option) => string;
  placeholder?: string;
  name?: string;
  required?: boolean;
  clearable?: boolean;
  disabled?: boolean;
  limit?: number;
  loadItems: (filter: Filter) => Promise<Option[]>;
  renderOption?: (option: Option) => ReactNode;
  tooltip?: ReactNode | null;
  error?: boolean;
  helperText?: string;
  loading?: boolean;
  additionalFilters?: Record<string, string | number>;
  minInputValueLength?: number;
  testId?: string;
}

/**
 * AutocompleteInput - компонент для отображения поля ввода с автодополнением.
 *
 * @param {AutocompleteInputProps} props - Свойства компонента.
 * @param {string} props.label - Текст метки.
 * @param {ValueType} props.value - Текущее значение поля ввода.
 * @param {(value: ValueType) => void} props.onChange - Обработчик изменения значения.
 * @param {string} [props.placeholder] - Текст подсказки.
 * @param {string} [props.name] - Имя поля ввода.
 * @param {boolean} [props.required=false] - Обязательное ли поле ввода.
 * @param {boolean} [props.clearable=false] - Разрешено ли очистить поле ввода.
 * @param {boolean} [props.disabled=false] - Заблокирован ли компонент.
 * @param {number} [props.limit=50] - Максимальное количество элементов для загрузки.
 * @param {(filter: Filter) => Promise<Option[]>} props.loadItems - Функция для загрузки элементов.
 * @param {(option: Option) => ReactNode} [props.renderOption] - Функция для рендера опции.
 * @param {ReactNode | null} [props.tooltip=null] - Всплывающая подсказка.
 * @param {boolean} [props.error=false] - Наличие ошибки в поле ввода.
 * @param {string} [props.helperText] - Дополнительный текст помощи.
 * @param {boolean} [props.loading=false] - Состояние загрузки.
 * @param {Record<string, string | number>} [props.additionalFilters] - Дополнительные фильтры для загрузки элементов.
 * @param {number} [props.minInputValueLength=1] - Минимальная длина значения для начала загрузки элементов.
 * @param {string} [props.testId] - Идентификатор для тестирования.
 * @returns {JSX.Element} Возвращает JSX-элемент представляющий компонент AutocompleteInput.
 */

export const AutocompleteInput = ({
  label,
  search,
  value,
  onChange,
  placeholder,
  name,
  required = false,
  clearable = false,
  disabled = false,
  limit = 50,
  loadItems,
  renderOption,
  tooltip,
  error = false,
  helperText,
  loading = false,
  additionalFilters,
  minInputValueLength = 1,
  testId,
  onInputValueChange,
  getSearchOptionLabel,
}: AutocompleteInputProps) => {
  const classes = useStyles();

  const { items, status, hasMore, requestItems, resetItems } =
    useAutocompleteReducer<Option>(loadItems);

  const [isLoading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [selectedItem, setSelectedItem] = useState<Option | null>(null);
  const [filter, setFilter] = useState<Filter>({
    limit,
    offset: 0,
    query: '',
  });
  const [open, setOpen] = useState(false);

  const isItemsLoading = isLoading || status === 'loading';
  const debouncedInputValue = useDebounce(inputValue, DEBOUNCE_TIMEOUT);

  const autocompleteOpenHandler = () => {
    setOpen(true);
  };

  const autocompleteCloseHandler = () => {
    setOpen(false);
  };

  const autocompleteFocusHandler = () => {
    setInputValue('');
  };

  const autocompleteGetInputValue = () => {
    if (loading) {
      return 'Загрузка...';
    } else if (!open && !inputValue) {
      return selectedItem?.label || '';
    }

    return inputValue;
  };

  const autocompleteChangeHandler = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    event: ChangeEvent<Record<string, any>>,
    nextValue: Option | null,
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions

    onChange(nextValue?.value || '');
    setSelectedItem(nextValue);
    resetItems();
  };

  const autocompleteInputChangeHandler = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    event: ChangeEvent<Record<string, any>>,
    nextValue: string,
    reason: string,
  ) => {
    if (reason === 'reset') {
      setInputValue('');
      setFilter({ ...filter, offset: 0, query: '' });
      return;
    }

    if (reason === 'clear') {
      setSelectedItem(null);
    }

    if (reason === 'input') {
      setLoading(true);
      setFilter({ ...filter, offset: 0, query: nextValue });
    }

    if (onInputValueChange) {
      onInputValueChange(nextValue);
    }

    setInputValue(nextValue);
  };

  const autocompleteGetOptionSelected = (option: Option) =>
    option.value === value;

  const autocompleteGetOptionLabel = (option: Option) => option.label;

  const requestNextItems = () => {
    requestItems?.({ ...filter, ...additionalFilters }, 'get');
    setFilter({
      ...filter,
      offset: filter.offset + filter.limit,
    });
  };

  useEffect(() => {
    if (
      !open ||
      (debouncedInputValue !== '' &&
        debouncedInputValue.length < minInputValueLength)
    ) {
      return;
    }

    requestItems?.(
      {
        ...filter,
        ...additionalFilters,
        offset: 0,
        query: debouncedInputValue,
      },
      'search',
    ).then(() => {
      setFilter({
        ...filter,
        offset: filter.limit,
      });
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, debouncedInputValue]);

  useEffect(() => {
    if (!open && !value) {
      setInputValue('');
      setSelectedItem(null);
    }
  }, [open, value]);

  useEffect(() => {
    (async () => {
      if (value && !inputValue) {
        const itemsById = await requestItems?.(
          { limit: 50, offset: 0, query: '', id: value, ...additionalFilters },
          'search',
        );

        const itemById = itemsById?.find((item) => item.value === value);

        setSelectedItem(itemById || null);
      }
    })();

    // eslint-disable-next-line
  }, []);

  return (
    <Box
      className={classNames(
        `${search ? '' : classes.autocompleteInput}`,
        'aqa_autocomplete_input',
        classes.autocompleteInputContainer,
      )}
    >
      {!search && label && (
        <Label
          label={label}
          required={required}
          tooltip={tooltip}
          className="aqa_autocomplete_input_label"
        />
      )}

      <Autocomplete
        blurOnSelect
        data-testid={testId}
        classes={{
          paper: classes.list,
          option: classNames(classes.option, {
            [classes.defaultOption]: !renderOption,
          }),
        }}
        open={open}
        onOpen={autocompleteOpenHandler}
        value={value ? selectedItem : undefined}
        disableClearable={!clearable || !value}
        onClose={autocompleteCloseHandler}
        onChange={autocompleteChangeHandler}
        onFocus={autocompleteFocusHandler}
        disabled={disabled || loading}
        inputValue={autocompleteGetInputValue()}
        onInputChange={autocompleteInputChangeHandler}
        ListboxComponent={
          ListBox as unknown as React.ComponentType<
            React.HTMLAttributes<HTMLElement>
          >
        }
        ListboxProps={{
          dataLength: items.length,
          next: requestNextItems,
          hasMore,
          loading: isItemsLoading,
          long: !!renderOption,
        }}
        renderOption={renderOption}
        getOptionSelected={autocompleteGetOptionSelected}
        getOptionLabel={
          search ? getSearchOptionLabel : autocompleteGetOptionLabel
        }
        options={!filter.offset && isItemsLoading ? [] : items}
        loading={isItemsLoading}
        loadingText={
          <Box display="flex" justifyContent="center" p="16">
            <CircularProgress size={30} />
          </Box>
        }
        noOptionsText="Ничего не найдено..."
        closeIcon={search ? <CloseIcon /> : <CancelIcon color="disabled" />}
        {...(search ? { popupIcon: null } : {})}
        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            classes={{
              root: `${classes.input} ${
                search ? classes.search : ''
              } aqa_select`,
            }}
            variant="outlined"
            placeholder={placeholder || label}
            error={error}
            helperText={helperText}
            {...(loading
              ? {
                  InputProps: {
                    endAdornment: <Preloader />,
                  },
                }
              : {
                  InputProps: {
                    ...params.InputProps,
                    startAdornment: search ? (
                      <Search
                        className={classes.searchIcon}
                        fontSize="small"
                        color="inherit"
                      />
                    ) : (
                      selectedItem?.badge &&
                      !open &&
                      !inputValue && (
                        <Box pl="16" mr="-8">
                          <Badge
                            color={selectedItem.badge?.color}
                            title={selectedItem.badge?.title}
                            description={selectedItem.badge?.description}
                          />
                        </Box>
                      )
                    ),
                  },
                })}
          />
        )}
      />
    </Box>
  );
};
