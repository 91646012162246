import React, { useCallback } from 'react';

import { Box } from '@vk-hr-tek/ui/Box';
import { Paper } from '@vk-hr-tek/ui/Paper';
import { useInject } from '@vk-hr-tek/core/ioc';
import { UserRouter } from '@vk-hr-tek/app/user/types';

import { UnitSectionLabel } from './UnitSectionLabel';
import { UnitInfo } from './UnitInfo';

interface CompanyUnitProps {
  employeeId: string;
  companyId: string;
  companyUnit: {
    unit: {
      id: string;
      name: string;
    };
    allSetting?: {
      id: string;
      name: string;
    }[];
    directSetting?: {
      id: string;
      name: string;
    }[];
    roleInUnit: {
      label: string;
      role: 'manager' | 'assistant';
    };
    neverSetting?: {
      id: string;
      name: string;
    }[];
  };
}

export const CompanyUnit = ({
  employeeId,
  companyId,
  companyUnit: { unit, allSetting, directSetting, roleInUnit, neverSetting },
}: CompanyUnitProps) => {
  const router = useInject<UserRouter>(UserRouter);

  const redirectToApprovalSettings = useCallback(() => {
    router.goToProfileApprovalSettings({
      employeeId,
      companyId,
      unitId: unit.id,
    });
  }, [router, companyId, employeeId, unit.id]);

  return (
    <Box mt="16">
      <Paper>
        <Box p="20">
          <UnitInfo
            unit={unit}
            roleInUnit={roleInUnit}
            redirectToApprovalSettings={redirectToApprovalSettings}
          />
          <Box mt="16" display="flex" gap="12" flexWrap="wrap">
            {!!allSetting?.length && (
              <UnitSectionLabel
                settingList={allSetting}
                label="Согласую всех"
                chipColor="green"
              />
            )}
            {!!directSetting?.length && (
              <UnitSectionLabel
                settingList={directSetting}
                label="Только прямых"
                chipColor="purple"
              />
            )}
            {!!neverSetting?.length && (
              <UnitSectionLabel
                settingList={neverSetting}
                label="Не согласую никого"
                chipColor="darkgray"
              />
            )}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};
