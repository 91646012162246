import { useTheme } from '@material-ui/core';

import { useMatchQuery } from './useMatchQuery';

/**
 * Пользовательский хук, который определяет, является ли текущий размер экрана таблетом или большим.
 *
 * @returns {boolean} - Возвращает true, если размер экрана 'sm' или больше, иначе false.
 */

export const useIsTablet = () => {
  const theme = useTheme();

  return useMatchQuery(theme.breakpoints.up('sm'));
};
