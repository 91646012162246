import { Type } from 'class-transformer';
import { IsArray, IsNotEmpty, IsString, MaxLength } from 'class-validator';

import { PersonalLinkSettingsDto } from './personal-link-settings.dto';

const MAX_NAME_LENGTH = 50;

export class PersonalLinksSettingsDto {
  @MaxLength(MAX_NAME_LENGTH, { message: 'Не более 50 символов' })
  @IsString({ message: 'Обязательное поле' })
  @IsNotEmpty({ message: 'Обязательное поле' })
  name: string;

  @Type(() => PersonalLinkSettingsDto)
  @IsArray()
  @IsNotEmpty({ message: 'Обязательное поле' })
  personalLinks: PersonalLinkSettingsDto[];
}
