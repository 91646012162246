import {
  SidebarCandidatesPageIcon,
  SidebarCompanyPageIcon,
  SidebarEmployeesPageIcon,
  SidebarEventsPageIcon,
  SidebarPayslipsPageIcon,
  SidebarPolicyPageIcon,
  SidebarSettingsPageIcon,
  SidebarVacationsPageIcon,
  SidebarMyCalendarPageIcon,
  SidebarCompetenciesPageIcon,
  SidebarPersonalPageIcon,
  IconProps,
} from '@vk-hr-tek/ui/icons';

import { Resources } from '../ability';

export interface Module {
  basename: string;
  icon?: React.ComponentType<IconProps>;
  resource: Resources;
  showInNavigation: boolean;
  withSide: boolean;
  isPrivate: boolean;
}

export type ModulesNames =
  | 'dashboard'
  | 'absences'
  | 'auth'
  | 'user'
  | 'create_certificate'
  | 'events'
  | 'settings'
  | 'candidates'
  | 'employees'
  | 'policy'
  | 'payslips'
  | 'organization'
  | 'competencies'
  | 'vacations'
  | 'personal';

export type ModulesConfig = Record<
  Exclude<ModulesNames, 'events' | 'settings'>,
  Module
> & {
  events: Module & {
    children: Record<'events' | 'paper', Module>;
  };
  dashboard: Module & {
    children: Record<'widgets', Module>;
  };
  settings: Module & {
    children: Record<
      | 'settings'
      | 'templates'
      | 'groups'
      | 'profiles'
      | 'substitutes'
      | 'vacations'
      | 'company'
      | 'attorneys'
      | 'attorney_users'
      | 'unep_types',
      Module
    >;
  };
};

export const modules: ModulesConfig = {
  dashboard: {
    basename: '',
    icon: SidebarEventsPageIcon,
    resource: 'Dashboard',
    showInNavigation: false,
    withSide: false,
    isPrivate: true,
    children: {
      widgets: {
        basename: '',
        resource: 'Dashboard',
        showInNavigation: true,
        withSide: true,
        isPrivate: true,
      },
    },
  },
  auth: {
    basename: 'auth',
    resource: 'all',
    showInNavigation: false,
    withSide: false,
    isPrivate: false,
  },
  user: {
    basename: 'profile',
    resource: 'User',
    showInNavigation: false,
    withSide: false,
    isPrivate: true,
  },
  create_certificate: {
    basename: 'create-certificate',
    resource: 'User',
    showInNavigation: false,
    withSide: false,
    isPrivate: true,
  },
  personal: {
    basename: 'personal',
    resource: 'Personal',
    icon: SidebarPersonalPageIcon,
    showInNavigation: true,
    withSide: true,
    isPrivate: true,
  },
  events: {
    basename: 'events',
    icon: SidebarEventsPageIcon,
    resource: 'Events',
    showInNavigation: true,
    withSide: true,
    isPrivate: true,
    children: {
      events: {
        basename: '',
        resource: 'Events',
        showInNavigation: true,
        withSide: true,
        isPrivate: true,
      },
      paper: {
        basename: 'paper',
        resource: 'PaperEvents',
        showInNavigation: true,
        withSide: true,
        isPrivate: true,
      },
    },
  },
  candidates: {
    basename: 'candidates',
    resource: 'Candidates',
    icon: SidebarCandidatesPageIcon,
    showInNavigation: true,
    withSide: true,
    isPrivate: true,
  },
  employees: {
    basename: 'employees',
    resource: 'Employees',
    icon: SidebarEmployeesPageIcon,
    showInNavigation: true,
    withSide: true,
    isPrivate: true,
  },
  policy: {
    basename: 'policy',
    resource: 'Policy',
    icon: SidebarPolicyPageIcon,
    showInNavigation: true,
    withSide: true,
    isPrivate: true,
  },
  absences: {
    basename: 'absences',
    resource: 'Absences',
    icon: SidebarMyCalendarPageIcon,
    showInNavigation: true,
    withSide: true,
    isPrivate: true,
  },
  payslips: {
    basename: 'payslips',
    resource: 'Payslips',
    icon: SidebarPayslipsPageIcon,
    showInNavigation: true,
    withSide: true,
    isPrivate: true,
  },
  organization: {
    basename: 'organization',
    resource: 'Organization',
    icon: SidebarCompanyPageIcon,
    showInNavigation: true,
    withSide: true,
    isPrivate: true,
  },
  settings: {
    basename: 'settings',
    resource: 'Settings',
    icon: SidebarSettingsPageIcon,
    showInNavigation: true,
    withSide: true,
    isPrivate: true,
    children: {
      settings: {
        basename: '',
        resource: 'SettingsAdmin',
        showInNavigation: true,
        withSide: true,
        isPrivate: true,
      },
      templates: {
        basename: 'templates',
        resource: 'SettingsAdmin',
        showInNavigation: true,
        withSide: true,
        isPrivate: true,
      },
      groups: {
        basename: 'groups',
        resource: 'SettingsAdmin',
        showInNavigation: true,
        withSide: true,
        isPrivate: true,
      },
      profiles: {
        basename: 'profiles',
        resource: 'CompanyProfiles',
        showInNavigation: true,
        withSide: true,
        isPrivate: true,
      },
      substitutes: {
        basename: 'substitutes',
        resource: 'SettingsAdmin',
        showInNavigation: true,
        withSide: true,
        isPrivate: true,
      },
      vacations: {
        basename: 'vacations',
        resource: 'VacationsSettings',
        showInNavigation: true,
        withSide: true,
        isPrivate: true,
      },
      company: {
        basename: 'company',
        resource: 'SettingsAdmin',
        showInNavigation: true,
        withSide: true,
        isPrivate: true,
      },
      attorneys: {
        basename: 'attorneys',
        resource: 'Attorneys',
        showInNavigation: true,
        withSide: true,
        isPrivate: true,
      },
      attorney_users: {
        basename: 'attorneys-users',
        resource: 'Attorneys',
        showInNavigation: true,
        withSide: true,
        isPrivate: true,
      },
      unep_types: {
        basename: 'sign-types',
        resource: 'SettingsAdmin',
        showInNavigation: true,
        withSide: true,
        isPrivate: true,
      },
    },
  },
  vacations: {
    basename: 'vacations',
    resource: 'Vacations',
    icon: SidebarVacationsPageIcon,
    showInNavigation: true,
    withSide: true,
    isPrivate: true,
  },
  competencies: {
    basename: 'competencies',
    resource: 'Competencies',
    icon: SidebarCompetenciesPageIcon,
    showInNavigation: true,
    withSide: true,
    isPrivate: true,
  },
};
