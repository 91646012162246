import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { classToPlain } from 'class-transformer';
import { FORM_ERROR } from 'final-form';

import { AppError } from '@vk-hr-tek/core/error';
import { PersonalLinksSettingsDto } from '@vk-hr-tek/app/dashboard/dto/widgets/personal-links-settings.dto';
import { showCustomNotification } from '@vk-hr-tek/core/notifications';
import { History } from '@vk-hr-tek/core/history';

import {
  DashboardService,
  UserPersonalLinksSettingsResponse,
} from '@app/gen/dashboard';
import { ThunkExtra } from '@app/store';

import { DashboardState } from '../dashboard.state';

export const getPersonalLinksSettings = createAsyncThunk<
  UserPersonalLinksSettingsResponse,
  void,
  ThunkExtra
>(
  'personalLinks/getPersonalLinksSettings',
  async (_, { rejectWithValue, extra: { inject } }) => {
    try {
      const service = inject(DashboardService);

      const result = await service.getUserPersonalLinksSettings();

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const setPersonalLinksSettings = createAsyncThunk<
  void,
  {
    values: PersonalLinksSettingsDto;
    actions: {
      resolve: (value: unknown) => void;
      reject: (value: unknown) => void;
    };
  },
  ThunkExtra
>(
  'personalLinks/setPersonalLinksSettings',
  async (
    { values, actions },
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      const history = inject<History>(History);
      const service = inject(DashboardService);

      await service.userWidgetPersonalLinksSettings({
        body: {
          custom_name: values.name,
          personal_links: values.personalLinks,
        },
      });

      actions.resolve(null);
      history.push('/');
      dispatch(
        showCustomNotification([
          {
            title: 'Виджет успешно сохранён!',
            message: 'Персональные ссылки',
            type: 'info',
          },
        ]),
      );
    } catch (err) {
      const history = inject<History>(History);

      actions.reject({ [FORM_ERROR]: err });
      history.push('/');
      dispatch(
        showCustomNotification([
          {
            title: 'Не удалось сохранить виджет',
            message: 'Персональные ссылки',
            type: 'error',
          },
        ]),
      );
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const PersonalLinksSettingsReducers = (
  builder: ActionReducerMapBuilder<DashboardState>,
) => {
  builder.addCase(getPersonalLinksSettings.pending, (state) => {
    state.personalLinksSettings.status = 'loading';
    state.personalLinksSettings.links = [];
    state.personalLinksSettings.error = null;
  });
  builder.addCase(getPersonalLinksSettings.fulfilled, (state, { payload }) => {
    state.personalLinksSettings.status = 'complete';
    state.personalLinksSettings.links = payload.personal_links;
    state.personalLinksSettings.name = payload.custom_name;
    state.personalLinksSettings.error = null;
  });
  builder.addCase(
    getPersonalLinksSettings.rejected,
    (state, { payload, error }) => {
      state.personalLinksSettings.status = 'failed';
      state.personalLinksSettings.links = [];
      state.personalLinksSettings.name = undefined;
      state.personalLinksSettings.error =
        payload ||
        ({
          info: (error && error.message) || 'Unknown error',
          status: 500,
          source: 'client',
          title: 'Internal client error',
        } as AppError);
    },
  );
};
