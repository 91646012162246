import React, { ReactNode } from 'react';

import {
  FieldProps,
  FieldRenderProps,
  FieldInputProps,
  useField,
} from 'react-final-form';

import { ValidateType } from '@vk-hr-tek/core/validation';

import { AutocompleteInput as Input, Option, Filter } from '../../input';

/**
 * Функция для обработки изменения значения инпута
 * @param {FieldInputProps<string | string[] | number | number[] | undefined, HTMLElement>} input - Пропсы инпута из react-final-form
 * @param {((value: string | undefined) => void) | undefined} onChange - Дополнительная функция для обработки изменения значения
 * @returns {function(value: string | undefined): void} - Функция, которая вызывает onChange из react-final-form и дополнительную onChange функцию, если она передана
 */
const handleChange =
  (
    input: FieldInputProps<
      string | string[] | number | number[] | undefined,
      HTMLElement
    >,
    onChange: ((value: string | undefined) => void) | undefined,
  ) =>
  (value: string | undefined) => {
    input.onChange(value);
    onChange?.(value);
  };

/**
 * Интерфейс для пропсов компонента AutocompleteInput
 * @interface AutocompleteInputProps
 * @property {string} label - Метка для инпута
 * @property {string} name - Уникальное имя поля формы
 * @property {((value: string | undefined) => void) | undefined} [onChange] - Дополнительная функция для обработки изменения значения
 * @property {string | undefined} [placeholder] - Текст плейсхолдера для инпута
 * @property {boolean} [required=false] - Флаг, указывающий, является ли поле обязательным
 * @property {boolean} [clearable=false] - Флаг, указывающий, можно ли очистить значение инпута
 * @property {boolean} [disabled=false] - Флаг, указывающий, заблокировано ли поле для ввода
 * @property {boolean} [loading=false] - Флаг, указывающий, идет ли загрузка данных
 * @property {number} [limit=50] - Ограничение количества загружаемых опций
 * @property {(filter: Filter) => Promise<Option[]>} loadItems - Функция для загрузки опций по фильтру
 * @property {(option: Option) => ReactNode | undefined} [renderOption] - Функция для рендера опции
 * @property {ReactNode | null | undefined} [tooltip] - Тултип для инпута
 * @property {boolean} [showError=true] - Флаг, указывающий, следует ли показывать ошибки
 * @property {Record<string, string | number> | undefined} [additionalFilters] - Дополнительные фильтры для загрузки опций
 * @property {number | undefined} [minInputValueLength] - Минимальная длина значения инпута для начала загрузки опций
 * @property {string | undefined} [testId] - Атрибут для тестирования компонента
 */
interface AutocompleteInputProps {
  label: string;
  search?: boolean;
  name: string;
  onChange?: (value: string | undefined) => void;
  placeholder?: string;
  required?: boolean;
  clearable?: boolean;
  disabled?: boolean;
  loading?: boolean;
  limit?: number;
  loadItems: (filter: Filter) => Promise<Option[]>;
  renderOption?: (option: Option) => ReactNode;
  tooltip?: ReactNode | null;
  showError?: boolean;
  additionalFilters?: Record<string, string | number>;
  minInputValueLength?: number;
  testId?: string;
}

/**
 * Компонент автодополнения для формы
 * @param {string} label - Метка для инпута
 * @param {string} name - Уникальное имя поля формы
 * @param {((value: string | undefined) => void) | undefined} [onChange] - Дополнительная функция для обработки изменения значения
 * @param {string | undefined} [placeholder] - Текст плейсхолдера для инпута
 * @param {boolean} [required=false] - Флаг, указывающий, является ли поле обязательным
 * @param {boolean} [clearable=false] - Флаг, указывающий, можно ли очистить значение инпута
 * @param {boolean} [disabled=false] - Флаг, указывающий, заблокировано ли поле для ввода
 * @param {boolean} [loading=false] - Флаг, указывающий, идет ли загрузка данных
 * @param {number} [limit=50] - Ограничение количества загружаемых опций
 * @param {(filter: Filter) => Promise<Option[]>} loadItems - Функция для загрузки опций по фильтру
 * @param {(option: Option) => ReactNode | undefined} [renderOption] - Функция для рендера опции
 * @param {ReactNode | null | undefined} [tooltip] - Тултип для инпута
 * @param {boolean} [showError=true] - Флаг, указывающий, следует ли показывать ошибки
 * @param {Record<string, string | number> | undefined} [additionalFilters] - Дополнительные фильтры для загрузки опций
 * @param {number | undefined} [minInputValueLength] - Минимальная длина значения инпута для начала загрузки опций
 * @param {string | undefined} [testId] - Атрибут для тестирования компонента
 * @param {string | number | undefined} [defaultValue] - Значение по умолчанию для поля формы
 * @param {ValidateType[] | ValidateType | undefined} [validate] - Валидаторы для поля формы
 * @returns {JSX.Element} - Элемент компонента автодополнения
 */
export const AutocompleteInput = ({
  label,
  search,
  name,
  onChange,
  placeholder,
  required = false,
  clearable = false,
  disabled = false,
  loading = false,
  limit = 50,
  loadItems,
  renderOption,
  tooltip,
  showError = true,
  additionalFilters,
  minInputValueLength,
  testId,
  ...rest
}: {
  defaultValue?: string | number;
  validate?: ValidateType[] | ValidateType;
} & AutocompleteInputProps &
  FieldProps<string | undefined, FieldRenderProps<string | undefined>>) => {
  const { input, meta } = useField(name, {
    ...rest,
  });

  return (
    <Input
      {...input}
      search={search}
      testId={testId}
      label={label}
      onChange={handleChange(input, onChange)}
      placeholder={placeholder}
      required={required}
      clearable={clearable}
      disabled={disabled}
      loading={loading}
      limit={limit}
      loadItems={loadItems}
      renderOption={renderOption}
      error={meta.touched && !!meta.error}
      helperText={showError && meta.touched && meta.error}
      tooltip={tooltip}
      additionalFilters={additionalFilters}
      minInputValueLength={minInputValueLength}
    />
  );
};
