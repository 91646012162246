import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ palette, breakpoints, tokens, px }) => ({
  radio: {
    display: 'none',
  },

  row: {
    gap: tokens.spacing['8'],
    [breakpoints.up('md')]: {
      gap: tokens.spacing['12'],
    },
  },

  label: {
    marginLeft: 0,
    marginRight: 0,
  },

  chip: {
    backgroundColor: tokens.colors.bg.light.primary,
    borderColor: tokens.colors.stroke.primary,

    '&.MuiChip-outlined:hover': {
      backgroundColor: tokens.colors.bg.greyscale.primary,
    },

    '& .MuiChip-label': {
      display: 'flex',
      padding: 0,
      color: tokens.colors.text.light.primary,
      whiteSpace: 'normal',
    },

    '& .MuiChip-icon': {
      margin: 0,
      color: tokens.colors.accent.stroke.blue,
    },
  },

  large: {
    height: px(48),
    paddingLeft: tokens.spacing['16'],
    paddingRight: tokens.spacing['16'],
    paddingTop: px(14), // TODO - fix нет в токенах
    paddingBottom: px(14), // TODO - fix нет в токенах

    '& .MuiChip-icon': {
      width: px(24),
      height: px(24),

      '& > svg': {
        width: px(24),
        height: px(24),
      },
    },
  },

  medium: {
    height: px(40),
    paddingLeft: tokens.spacing['16'],
    paddingRight: tokens.spacing['16'],
    paddingTop: px(10), // TODO - fix нет в токенах
    paddingBottom: px(10), // TODO - fix нет в токенах

    '& .MuiChip-icon': {
      width: px(20),
      height: px(20),

      '& > svg': {
        width: px(20),
        height: px(20),
      },
    },
  },

  small: {
    height: px(32),
    paddingLeft: tokens.spacing['12'],
    paddingRight: tokens.spacing['12'],
    paddingTop: px(6), // TODO - fix нет в токенах
    paddingBottom: px(6), // TODO - fix нет в токенах

    '& .MuiChip-icon': {
      width: px(20),
      height: px(20),

      '& > svg': {
        width: px(20),
        height: px(20),
      },
    },
  },

  counter: {
    display: 'inline',
    paddingLeft: tokens.spacing['8'],
    paddingRight: tokens.spacing['8'],
    paddingTop: tokens.spacing['2'],
    paddingBottom: tokens.spacing['2'],
    backgroundColor: tokens.colors.bg.greyscale.primary,
    color: tokens.colors.text.light.primary,
    borderRadius: tokens.radius.m,
    fontSize: tokens.typography.variant.caption.fontSize,
  },

  active: {
    backgroundColor: tokens.colors.original.brand.secondary,
    borderColor: tokens.colors.original.brand.primary,

    '& .MuiChip-label': {
      color: tokens.colors.original.brand.primary,
    },

    '& $counter': {
      backgroundColor: tokens.colors.original.brand.primary,
      color: tokens.colors.bg.light.primary,
    },

    '& .MuiChip-icon': {
      color: tokens.colors.original.brand.primary,
    },

    '&.MuiChip-outlined': {
      '&:hover': {
        backgroundColor: tokens.colors.original.brand.quaternary,
      },
    },
  },

  error: {
    backgroundColor: tokens.colors.accent.bg.error,
    borderColor: tokens.colors.accent.stroke.error,

    '& .MuiChip-label': {
      color: tokens.colors.text.light.primary,
    },

    '& $counter': {
      backgroundColor: tokens.colors.bg.light.primary,
      color: tokens.colors.text.light.primary,
    },

    '&.MuiChip-outlined': {
      '&:hover': {
        borderColor: palette.error.hover,
        backgroundColor: tokens.colors.accent.bg.error,
      },
    },
  },

  disabled: {
    borderColor: tokens.colors.bg.greyscale.primary,

    '& .MuiChip-label': {
      color: tokens.colors.text.light.tertirary,
    },

    '& $counter': {
      backgroundColor: tokens.colors.bg.greyscale.primary,
      color: tokens.colors.text.light.tertirary,
    },

    '&.Mui-disabled': {
      opacity: 1,
    },
  },

  activeDisabled: {
    backgroundColor: tokens.colors.bg.greyscale.secondary,
    borderColor: tokens.colors.text.light.tertirary,
  },

  purple: {
    '& .MuiChip-icon': {
      color: tokens.colors.accent.stroke.review,
    },

    '&$active:not($disabled):not($error)': {
      backgroundColor: tokens.colors.accent.bg.review,
      borderColor: tokens.colors.accent.text.review,

      '& .MuiChip-label': {
        color: tokens.colors.accent.text.review,
      },

      '& $counter': {
        backgroundColor: tokens.colors.accent.text.review,
      },

      '& .MuiChip-icon': {
        color: tokens.colors.accent.text.review,
      },

      '&.MuiChip-outlined': {
        '&:hover': {
          backgroundColor: tokens.colors.accent.stroke.review,
        },
      },
    },
  },

  orange: {
    '& .MuiChip-icon': {
      color: tokens.colors.accent.stroke.warning,
    },

    '&$active:not($disabled):not($error)': {
      backgroundColor: tokens.colors.accent.bg.warning,
      borderColor: tokens.colors.accent.text.warning,

      '& .MuiChip-label': {
        color: tokens.colors.accent.text.warning,
      },

      '& $counter': {
        backgroundColor: tokens.colors.accent.text.warning,
      },

      '& .MuiChip-icon': {
        color: tokens.colors.accent.text.warning,
      },

      '&.MuiChip-outlined': {
        '&:hover': {
          backgroundColor: tokens.colors.accent.stroke.warning,
        },
      },
    },
  },

  red: {
    '& .MuiChip-icon': {
      color: tokens.colors.accent.stroke.error,
    },

    '&$active:not($disabled):not($error)': {
      backgroundColor: tokens.colors.accent.bg.error,
      borderColor: tokens.colors.accent.text.error,

      '& .MuiChip-label': {
        color: tokens.colors.accent.text.error,
      },

      '& $counter': {
        backgroundColor: tokens.colors.accent.text.error,
      },

      '& .MuiChip-icon': {
        color: tokens.colors.accent.text.error,
      },

      '&.MuiChip-outlined': {
        '&:hover': {
          backgroundColor: tokens.colors.accent.stroke.error,
        },
      },
    },
  },

  blue: {
    '& .MuiChip-icon': {
      color: tokens.colors.accent.stroke.blue,
    },

    '&$active:not($disabled):not($error)': {
      backgroundColor: tokens.colors.accent.bg.blue,
      borderColor: tokens.colors.accent.text.blue,

      '& .MuiChip-label': {
        color: tokens.colors.accent.text.blue,
      },

      '& .MuiChip-icon': {
        color: tokens.colors.original.brand.primary,
      },

      '&.MuiChip-outlined': {
        '&:hover': {
          backgroundColor: tokens.colors.accent.stroke.blue,
        },
      },
    },
  },

  green: {
    '& .MuiChip-icon': {
      color: tokens.colors.accent.stroke.success,
    },

    '&$active:not($disabled):not($error)': {
      backgroundColor: tokens.colors.accent.bg.success,
      borderColor: tokens.colors.accent.text.success,

      '& .MuiChip-label': {
        color: tokens.colors.accent.text.success,
      },

      '& $counter': {
        backgroundColor: tokens.colors.accent.text.success,
      },

      '& .MuiChip-icon': {
        color: tokens.colors.accent.text.success,
      },

      '&.MuiChip-outlined': {
        '&:hover': {
          backgroundColor: tokens.colors.accent.stroke.success,
        },
      },
    },
  },
}));
