/* eslint-disable @typescript-eslint/no-explicit-any */
import { injectable } from 'inversify';

import { LogOptions } from './logger.types';

export const loggingLevels = ['debug', 'info', 'warning', 'error', 'fatal'];

@injectable()
export abstract class Logger {
  /** Абстрактный метод
   * Логирует сообщение об ошибке
   * */
  abstract log(message: any, { level, tags, context }: LogOptions): void;

  /** Приватный метод
   * Определяет будет ли сообщение об ошибке залогировано
   * @param message Сообщение об ошибке
   * @param options Опции содержат данные, которые необходимо залогировать, а так же level, определяющий будет ли произведено логирование
   * @returns {void}
   *  */
  private logEvent(message: any, options: LogOptions) {
    const getCurrentLevelLog = () => {
      if (loggingLevels.indexOf(window.REACT_APP_VKHRTEK_LOG_LEVEL) !== -1) {
        return window.REACT_APP_VKHRTEK_LOG_LEVEL;
      }

      return process.env.NODE_ENV === 'production' ? 'info' : 'debug';
    };

    if (
      loggingLevels.indexOf(getCurrentLevelLog()) <=
      loggingLevels.indexOf(options.level)
    ) {
      this.log(message, options);
    }
  }

  /** Логирует сообщение об ошибке типа "debug"
   * @param message Сообщение об ошибке
   * @param options Опции, определяют что будет залогировано
   * @returns {void}
   *  */
  debug(message: any, options: Omit<LogOptions, 'level'> = {}) {
    this.logEvent(message, { level: 'debug', ...options });
  }

  /** Логирует сообщение об ошибке типа "info"
   * @param message Сообщение об ошибке
   * @param options Опции, определяют что будет залогировано
   * @returns {void}
   *  */
  info(message: any, options: Omit<LogOptions, 'level'> = {}) {
    this.logEvent(message, { level: 'info', ...options });
  }

  /** Логирует сообщение об ошибке типа "warning"
   * @param message Сообщение об ошибке
   * @param options Опции, определяют что будет залогировано
   * @returns {void}
   *  */
  warning(message: any, options: Omit<LogOptions, 'level'> = {}) {
    this.logEvent(message, { level: 'warning', ...options });
  }

  /** Логирует сообщение об ошибке типа "error"
   * @param message Сообщение об ошибке
   * @param options Опции, определяют что будет залогировано
   * @returns {void}
   *  */
  error(message: any, options: Omit<LogOptions, 'level'> = {}) {
    this.logEvent(message, { level: 'error', ...options });
  }

  /** Логирует сообщение об ошибке типа "fatal"
   * @param message Сообщение об ошибке
   * @param options Опции, определяют что будет залогировано
   * @returns {void}
   *  */
  fatal(message: any, options: Omit<LogOptions, 'level'> = {}) {
    this.logEvent(message, { level: 'fatal', ...options });
  }
}
