import React from 'react';

import { useTheme } from '@material-ui/core';

export const WidgetsPageIcon = () => {
  const theme = useTheme();

  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.9 21.9C25.9 21.4029 25.497 21 25 21C24.5029 21 24.1 21.4029 24.1 21.9V24.0999H21.9C21.4029 24.0999 21 24.5029 21 24.9999C21 25.497 21.4029 25.8999 21.9 25.8999H24.1V28.1C24.1 28.597 24.5029 29 25 29C25.497 29 25.9 28.597 25.9 28.1V25.8999H28.1C28.597 25.8999 29 25.497 29 24.9999C29 24.5029 28.597 24.0999 28.1 24.0999H25.9V21.9Z"
        fill={theme.tokens.colors.original.brand.primary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2667 11C14.2777 11 14.2889 11 14.3 11L15.7333 11C16.1248 11 16.4713 11 16.7585 11.0234C17.0643 11.0484 17.3811 11.1044 17.6896 11.2616C18.1412 11.4917 18.5083 11.8588 18.7384 12.3104C18.8956 12.6189 18.9516 12.9357 18.9766 13.2415C19 13.5287 19 13.8752 19 14.2667V15.7333C19 16.1248 19 16.4713 18.9766 16.7585C18.9516 17.0643 18.8956 17.3811 18.7384 17.6896C18.5083 18.1412 18.1412 18.5083 17.6896 18.7384C17.3811 18.8956 17.0643 18.9516 16.7585 18.9766C16.4713 19 16.1248 19 15.7333 19H14.2667C13.8752 19 13.5287 19 13.2415 18.9766C12.9357 18.9516 12.6189 18.8956 12.3104 18.7384C11.8588 18.5083 11.4917 18.1412 11.2616 17.6896C11.1044 17.3811 11.0484 17.0643 11.0234 16.7585C11 16.4713 11 16.1248 11 15.7333L11 14.3C11 14.2889 11 14.2777 11 14.2667C11 13.8752 11 13.5287 11.0234 13.2415C11.0484 12.9357 11.1044 12.6189 11.2616 12.3104C11.4917 11.8588 11.8588 11.4917 12.3104 11.2616C12.6189 11.1044 12.9357 11.0484 13.2415 11.0234C13.5287 11 13.8752 11 14.2667 11ZM13.3881 12.8174C13.1936 12.8333 13.14 12.8591 13.1276 12.8654C13.0147 12.9229 12.9229 13.0147 12.8654 13.1276C12.8591 13.14 12.8333 13.1936 12.8174 13.3881C12.8007 13.5931 12.8 13.8651 12.8 14.3V15.7C12.8 16.1349 12.8007 16.4069 12.8174 16.6119C12.8333 16.8064 12.8591 16.86 12.8654 16.8724C12.9229 16.9853 13.0147 17.0771 13.1276 17.1346C13.14 17.1409 13.1936 17.1667 13.3881 17.1826C13.5931 17.1993 13.8651 17.2 14.3 17.2H15.7C16.1349 17.2 16.4069 17.1993 16.6119 17.1826C16.8064 17.1667 16.86 17.1409 16.8724 17.1346C16.9853 17.0771 17.0771 16.9853 17.1346 16.8724C17.1409 16.86 17.1667 16.8064 17.1826 16.6119C17.1993 16.4069 17.2 16.1349 17.2 15.7V14.3C17.2 13.8651 17.1993 13.5931 17.1826 13.3881C17.1667 13.1936 17.1409 13.14 17.1346 13.1276C17.0771 13.0147 16.9853 12.9229 16.8724 12.8654C16.86 12.8591 16.8064 12.8333 16.6119 12.8174C16.4069 12.8007 16.1349 12.8 15.7 12.8H14.3C13.8651 12.8 13.5931 12.8007 13.3881 12.8174Z"
        fill={theme.tokens.colors.original.brand.primary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2667 21C14.2777 21 14.2889 21 14.3 21L15.7333 21C16.1248 21 16.4713 21 16.7585 21.0234C17.0643 21.0484 17.3811 21.1044 17.6896 21.2616C18.1412 21.4917 18.5083 21.8588 18.7384 22.3104C18.8956 22.6189 18.9516 22.9357 18.9766 23.2415C19 23.5287 19 23.8752 19 24.2667V25.7333C19 26.1248 19 26.4713 18.9766 26.7585C18.9516 27.0643 18.8956 27.3811 18.7384 27.6896C18.5083 28.1412 18.1412 28.5083 17.6896 28.7384C17.3811 28.8956 17.0643 28.9516 16.7585 28.9766C16.4713 29 16.1248 29 15.7333 29H14.2667C13.8752 29 13.5287 29 13.2415 28.9766C12.9357 28.9516 12.6189 28.8956 12.3104 28.7384C11.8588 28.5083 11.4917 28.1412 11.2616 27.6896C11.1044 27.3811 11.0484 27.0643 11.0234 26.7585C11 26.4713 11 26.1248 11 25.7333L11 24.3C11 24.2889 11 24.2777 11 24.2667C11 23.8752 11 23.5287 11.0234 23.2415C11.0484 22.9357 11.1044 22.6189 11.2616 22.3104C11.4917 21.8588 11.8588 21.4917 12.3104 21.2616C12.6189 21.1044 12.9357 21.0484 13.2415 21.0234C13.5287 21 13.8752 21 14.2667 21ZM13.3881 22.8174C13.1936 22.8333 13.14 22.8591 13.1276 22.8654C13.0147 22.9229 12.9229 23.0147 12.8654 23.1276C12.8591 23.14 12.8333 23.1936 12.8174 23.3881C12.8007 23.5931 12.8 23.8651 12.8 24.3V25.7C12.8 26.1349 12.8007 26.4069 12.8174 26.6119C12.8333 26.8064 12.8591 26.86 12.8654 26.8724C12.9229 26.9853 13.0147 27.0771 13.1276 27.1346C13.14 27.1409 13.1936 27.1667 13.3881 27.1826C13.5931 27.1993 13.8651 27.2 14.3 27.2H15.7C16.1349 27.2 16.4069 27.1993 16.6119 27.1826C16.8064 27.1667 16.86 27.1409 16.8724 27.1346C16.9853 27.0771 17.0771 26.9853 17.1346 26.8724C17.1409 26.86 17.1667 26.8064 17.1826 26.6119C17.1993 26.4069 17.2 26.1349 17.2 25.7V24.3C17.2 23.8651 17.1993 23.5931 17.1826 23.3881C17.1667 23.1936 17.1409 23.14 17.1346 23.1276C17.0771 23.0147 16.9853 22.9229 16.8724 22.8654C16.86 22.8591 16.8064 22.8333 16.6119 22.8174C16.4069 22.8007 16.1349 22.8 15.7 22.8H14.3C13.8651 22.8 13.5931 22.8007 13.3881 22.8174Z"
        fill={theme.tokens.colors.original.brand.primary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.2667 11C24.2777 11 24.2889 11 24.3 11L25.7333 11C26.1248 11 26.4713 11 26.7585 11.0234C27.0643 11.0484 27.3811 11.1044 27.6896 11.2616C28.1412 11.4917 28.5083 11.8588 28.7384 12.3104C28.8956 12.6189 28.9516 12.9357 28.9766 13.2415C29 13.5287 29 13.8752 29 14.2667V15.7333C29 16.1248 29 16.4713 28.9766 16.7585C28.9516 17.0643 28.8956 17.3811 28.7384 17.6896C28.5083 18.1412 28.1412 18.5083 27.6896 18.7384C27.3811 18.8956 27.0643 18.9516 26.7585 18.9766C26.4713 19 26.1248 19 25.7333 19H24.2667C23.8752 19 23.5287 19 23.2415 18.9766C22.9357 18.9516 22.6189 18.8956 22.3104 18.7384C21.8588 18.5083 21.4917 18.1412 21.2616 17.6896C21.1044 17.3811 21.0484 17.0643 21.0234 16.7585C21 16.4713 21 16.1248 21 15.7333L21 14.3C21 14.2889 21 14.2777 21 14.2667C21 13.8752 21 13.5287 21.0234 13.2415C21.0484 12.9357 21.1044 12.6189 21.2616 12.3104C21.4917 11.8588 21.8588 11.4917 22.3104 11.2616C22.6189 11.1044 22.9357 11.0484 23.2415 11.0234C23.5287 11 23.8752 11 24.2667 11ZM23.3881 12.8174C23.1936 12.8333 23.14 12.8591 23.1276 12.8654C23.0147 12.9229 22.9229 13.0147 22.8654 13.1276C22.8591 13.14 22.8333 13.1936 22.8174 13.3881C22.8007 13.5931 22.8 13.8651 22.8 14.3V15.7C22.8 16.1349 22.8007 16.4069 22.8174 16.6119C22.8333 16.8064 22.8591 16.86 22.8654 16.8724C22.9229 16.9853 23.0147 17.0771 23.1276 17.1346C23.14 17.1409 23.1936 17.1667 23.3881 17.1826C23.5931 17.1993 23.8651 17.2 24.3 17.2H25.7C26.1349 17.2 26.4069 17.1993 26.6119 17.1826C26.8064 17.1667 26.86 17.1409 26.8724 17.1346C26.9853 17.0771 27.0771 16.9853 27.1346 16.8724C27.1409 16.86 27.1667 16.8064 27.1826 16.6119C27.1993 16.4069 27.2 16.1349 27.2 15.7V14.3C27.2 13.8651 27.1993 13.5931 27.1826 13.3881C27.1667 13.1936 27.1409 13.14 27.1346 13.1276C27.0771 13.0147 26.9853 12.9229 26.8724 12.8654C26.86 12.8591 26.8064 12.8333 26.6119 12.8174C26.4069 12.8007 26.1349 12.8 25.7 12.8H24.3C23.8651 12.8 23.5931 12.8007 23.3881 12.8174Z"
        fill={theme.tokens.colors.original.brand.primary}
      />
    </svg>
  );
};
