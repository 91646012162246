import React from 'react';

import {
  useField,
  FieldProps,
  FieldRenderProps,
  FieldInputProps,
} from 'react-final-form';

import { ValidateType } from '@vk-hr-tek/core/validation';

import { SelectInput as Input, OptionBadge } from '../../input';

/**
 * Функция-обработчик изменения значения поля ввода.
 * @param {FieldInputProps<string | string[] | number | number[] | undefined, HTMLElement>} input - Объект с методами и свойствами для управления значением поля ввода.
 * @param {function(value: string | number | undefined): void} onChange - Функция, вызываемая при изменении значения поля ввода.
 * @returns {function(value: string | number | undefined): void} - Функция, которая изменяет значение поля ввода и вызывает переданную функцию-обработчик.
 */
const handleChange =
  (
    input: FieldInputProps<
      string | string[] | number | number[] | undefined,
      HTMLElement
    >,
    onChange: (value: string | number | undefined) => void,
  ) =>
  (value: string | number | undefined) => {
    input.onChange(value);

    if (onChange) {
      onChange(value);
    }
  };

/**
 * Компонент ввода выбора.
 * @param {string} label - Метка для поля ввода.
 * @param {string} tooltip - Всплывающая подсказка для поля ввода.
 * @param {string} name - Имя поля ввода, используемое для связывания с формой.
 * @param {function(value: string | number | undefined): void} onChange - Функция, вызываемая при изменении значения поля ввода.
 * @param {string} placeholder - Значение по умолчанию отображаемое в поле ввода до выбора значения.
 * @param {boolean} showError - Флаг, определяющий, следует ли отображать ошибку при взаимодействии с полем.
 * @param {boolean} required - Флаг, указывающий, является ли поле обязательным для заполнения.
 * @param {boolean} disabled - Флаг, определяющий, доступно ли поле для взаимодействия.
 * @param {boolean} autocomplete - Флаг, указывающий, поддерживает ли поле автозавершение.
 * @param {boolean} clearable - Флаг, определяющий, можно ли очистить значение поля.
 * @param {boolean} loading - Флаг, указывающий, находится ли поле в состоянии загрузки.
 * @param {{label: string, value: string | number, badge?: OptionBadge, group?: string}[]} items - Массив элементов выбора.
 * @param {string | string[] | number | number[]} [defaultValue] - Значение поля ввода по умолчанию.
 * @param {ValidateType[] | ValidateType} [validate] - Функция или массив функций валидации.
 * @param {function(item: {label: string, value: string | number, badge?: OptionBadge, group?: string}): JSX.Element} [renderOption] - Функция для рендера опции выбора.
 * @param {string} [testId] - Идентификатор для тестирования компонента.
 * @param {FieldProps<string | string[] | number | number[] | undefined, FieldRenderProps<string | string[] | number | number[] | undefined>>} rest - Дополнительные свойства для передачи в поле ввода.
 */
const SelectInput = ({
  label,
  tooltip,
  name,
  onChange,
  placeholder,
  showError = true,
  required = false,
  disabled = false,
  autocomplete = false,
  clearable = false,
  loading = false,
  items,
  defaultValue,
  renderOption,
  testId,
  ...rest
}: {
  defaultValue?: string | string[] | number | number[];
  validate?: ValidateType[] | ValidateType;
  items: {
    label: string;
    value: string | number;
    badge?: OptionBadge;
    group?: string;
  }[];
} & FieldProps<
  string | string[] | number | number[] | undefined,
  FieldRenderProps<string | string[] | number | number[] | undefined>
>) => {
  const { input, meta } = useField(name, {
    initialValue: defaultValue,
    ...rest,
  });

  return (
    <Input
      {...input}
      label={label}
      onChange={handleChange(input, onChange)}
      placeholder={placeholder}
      value={input.value}
      items={items}
      required={required}
      disabled={disabled}
      autocomplete={autocomplete}
      clearable={clearable}
      loading={loading}
      error={meta.touched && !!meta.error}
      helperText={showError && meta.touched && meta.error}
      tooltip={tooltip}
      renderOption={renderOption}
      testId={testId}
    />
  );
};

export default SelectInput;
