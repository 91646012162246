/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  configureStore,
  combineReducers,
  ThunkAction,
  Action,
  Reducer,
  ThunkDispatch,
  AnyAction,
} from '@reduxjs/toolkit';
import { interfaces, Container } from 'inversify';

import { inject, Inject } from '@vk-hr-tek/core/ioc';
import { AppError } from '@vk-hr-tek/core/error';
import { downloadNotificationReducer as downloadNotification } from '@vk-hr-tek/core/download-notification/slice';
import { notificationReducer as notifications } from '@vk-hr-tek/core/notifications';
import { cryptoReducer as crypto } from '@vk-hr-tek/core/crypto';
import { chatReducer as chat } from '@vk-hr-tek/core/chat';
import { translationsReducer as translations } from '@vk-hr-tek/core/translations/slice';
import {
  HTTP_NOT_UNAUTHORIZED_STATUS,
  HTTP_SERVICE_UNAVAILABLE_STATUS,
} from '@vk-hr-tek/core/http/codes';

import { authReducer as auth, logout } from '../auth/slice';
import { userReducer as user } from '../user/slice';
import { policyReducer as policy } from '../policy/slice';
import {
  dashboardReducer as dashboard,
  initialState,
} from '../dashboard/slice';
import { absencesReducer as absences } from '../absences/slice';
import { creationEventReducer as creationEvent } from '../event-creation/slice';
import {
  layoutReducer as layout,
  setServiceUnavailable,
} from '../layout/slice';
import { eventsReducer as events } from '../events/slice';
import { filtersPresetReducer as filtersPreset } from '../filtersPreset/slice';

export const createStore = (container?: Container) => {
  const staticReducers = {
    events,
    auth,
    user,
    policy,
    notifications,
    downloadNotification,
    crypto,
    layout,
    chat,
    dashboard,
    translations,
    absences,
    creationEvent,
    filtersPreset,
  };

  const asyncReducers: Record<string, Reducer> = {};

  const createReducer = () =>
    combineReducers({
      ...staticReducers,
      ...asyncReducers,
    });

  const logoutMiddleware = (store: any) => (next: any) => (action: any) => {
    if (
      action.type.endsWith('/rejected') &&
      !action.type.startsWith('auth/') &&
      action.payload &&
      action.payload.status === HTTP_NOT_UNAUTHORIZED_STATUS
    ) {
      store.dispatch(logout({ skipLogout: true }));
    }

    return next(action);
  };

  const errorMiddleware = (store: any) => (next: any) => (action: any) => {
    if (
      action.payload &&
      action.payload.status === HTTP_SERVICE_UNAVAILABLE_STATUS
    ) {
      store.dispatch(setServiceUnavailable(true));
    }

    return next(action);
  };

  const rootReducer = () => (state: any, action: any) => {
    if (
      action.type === 'auth/logout/fulfilled' ||
      action.type === 'core/redux/reset-store'
    ) {
      state = undefined;
    }

    if (action.type === 'user/setUserRole/pending') {
      state = { user: state.user, auth: state.auth };
    }

    if (action.type === 'user/changeRole') {
      state = {
        user: state.user,
        layout: state.layout,
        dashboard: {
          ...initialState,
          fastActionData: state.dashboard?.fastActionData,
        },
      };
    }

    return createReducer()(state, action);
  };

  const store = configureStore({
    reducer: rootReducer(),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: {
            inject: <T>(service: interfaces.ServiceIdentifier<T>) =>
              inject(service, container),
          },
        },
      })
        .concat(logoutMiddleware)
        .concat(errorMiddleware),
  });

  const injectReducer = (key: string, asyncReducer: Reducer) => {
    if (!asyncReducers[key]) {
      asyncReducers[key] = asyncReducer;
      store.replaceReducer(rootReducer());
    }
  };

  return { store, injectReducer };
};

type Store = ReturnType<typeof createStore>['store'];
export type AppDispatch = Store['dispatch'];
export type RootState = ReturnType<Store['getState']>;
export type AppDispatchWithLocal<T> = ThunkDispatch<
  T,
  { inject: Inject },
  AnyAction
>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export interface ThunkExtra<T = RootState> {
  extra: {
    inject: Inject;
  };
  state: T;
  dispatch: AppDispatchWithLocal<T>;
  rejectValue: AppError;
}

export interface ThunkValuesAndActions<T> {
  values: T;
  actions: {
    resolve: (value: unknown) => void;
    reject: (value: unknown) => void;
  };
}
