import { inject, injectable } from 'inversify';

import { History } from '@vk-hr-tek/core/history';
import { RoleService } from '@vk-hr-tek/core/http';

import { modules } from '../../modules';
import { PersonalRouter } from '../../../personal/types';

@injectable()
export class PersonalRouterService implements PersonalRouter {
  constructor(
    @inject(History) private history: History,
    @inject(RoleService) private readonly role: RoleService,
  ) {}

  goToList(userId: string, query?: string) {
    if (!query || query === '?') {
      this.history.push(`/${modules.personal.basename}/${userId}`);
      return;
    }

    this.history.push({
      pathname: `/${modules.personal.basename}/${userId}`,
      search: query.startsWith('?') ? query : `?${query}`,
    });
  }

  goToWorkBook(userId: string, employeeId: string) {
    this.history.push(
      `/${modules.personal.basename}/${userId}/workbook?employee_id=${employeeId}`,
    );
  }

  goToEducation(userId: string, employeeId: string) {
    this.history.push(
      `/${modules.personal.basename}/${userId}/education?employee_id=${employeeId}`,
    );
  }

  goToEmploymentContract(userId: string, employeeId: string) {
    this.history.push(
      `/${modules.personal.basename}/${userId}/employment-contract?employee_id=${employeeId}`,
    );
  }

  goToWorkHistory(userId: string, employeeId: string) {
    this.history.push(
      `/${modules.personal.basename}/${userId}/workhistory?employee_id=${employeeId}`,
    );
  }

  goToAwardsPage(userId: string, employeeId: string) {
    this.history.push(
      `/${modules.personal.basename}/${userId}/awards?employee_id=${employeeId}`,
    );
  }
}
