import React, { useCallback } from 'react';

import {
  Field,
  FieldProps,
  FieldRenderProps,
  FieldInputProps,
} from 'react-final-form';

import { ChipsInput as Input, ChipsInputItem } from '../../input';

/**
 * Компонент ввода чипсов (ChipsInput) с интеграцией в react-final-form.
 * Поддерживает различные типы данных, валидацию, подсказки и другие функции.
 *
 * @param {Object} props - Свойства компонента.
 * @param {('number'|'string')} [props.type] - Тип данных, ожидаемых на входе.
 * @param {string} props.label - Метка для поля ввода.
 * @param {string} props.name - Уникальное имя поля для react-final-form.
 * @param {function} [props.onChange] - Колбэк-функция на изменение значения.
 * @param {Array<{label: string, value: (string|number)}>} props.items - Массив элементов для чипсов.
 * @param {boolean} [props.required=false] - Флаг обязательности поля.
 * @param {boolean} [props.disabled=false] - Флаг отключения поля.
 * @param {('large'|'medium'|'small')} [props.size='large'] - Размер поля.
 * @param {boolean} [props.showError=true] - Флаг отображения ошибок.
 * @param {boolean} [props.loading=false] - Флаг загрузки данных.
 * @param {string} [props.tooltip] - Текст подсказки.
 * @param {string} [props.testId] - Идентификатор для тестирования.
 * @param {Object} [props.rest] - Дополнительные свойства для передачи в react-final-form.
 */

export const ChipsInput = ({
  type,
  label,
  name,
  onChange,
  items,
  required,
  disabled = false,
  size = 'large',
  showError = true,
  loading = false,
  tooltip,
  testId,
  ...rest
}: {
  type?: 'number' | 'string';
  items: ChipsInputItem[];
} & FieldProps<
  string | number | undefined,
  FieldRenderProps<string | number | undefined>
>) => {
  const handleChange = useCallback(
    (input: FieldInputProps<string | number | undefined, HTMLElement>) =>
      (nextValue: string | number | undefined) => {
        const convertedVal =
          type === 'number' && typeof nextValue === 'string'
            ? parseInt(nextValue, 10)
            : nextValue;

        input.onChange(convertedVal);

        if (onChange) {
          onChange(convertedVal);
        }
      },
    [type, onChange],
  );

  return (
    <Field name={name} {...rest} disabled={disabled}>
      {({ input, meta }) => {
        return (
          <Input
            {...input}
            testId={testId}
            items={items}
            tooltip={tooltip}
            value={input.value}
            onChange={handleChange(input)}
            size={size}
            required={required}
            error={meta.touched && !!meta.error}
            helperText={showError && meta.touched && meta.error}
            label={label}
            loading={loading}
            disabled={disabled}
            activeSingleOption={false}
          />
        );
      }}
    </Field>
  );
};
