import React from 'react';

import { InputAdornment } from '@material-ui/core';

import { CancelIcon } from '../../../icons';
import { Box } from '../../../Box';

interface ClearButtonProps {
  className?: string;
  color?: 'disabled' | 'error';
  onClick: () => void;
}

/**
 * ClearButton - компонент для отображения кнопки очистки.
 *
 * @param {ClearButtonProps} props - Свойства компонента.
 * @param {string} [props.className=''] - Дополнительный CSS-класс.
 * @param {'disabled' | 'error'} [props.color='disabled'] - Цвет иконки кнопки.
 * @param {() => void} props.onClick - Обработчик клика по кнопке.
 * @returns {JSX.Element} Возвращает JSX-элемент представляющий компонент ClearButton.
 */

export const ClearButton = ({
  className = '',
  color = 'disabled',
  onClick,
}: ClearButtonProps) => (
  <InputAdornment className={className} position="end">
    <Box onClick={onClick}>
      <CancelIcon color={color} />
    </Box>
  </InputAdornment>
);
