import { inject, injectable } from 'inversify';

import { History } from '@vk-hr-tek/core/history';
import { RoleService } from '@vk-hr-tek/core/http';

import { DashboardRouter } from '../../../dashboard/types';
import { modules } from '../../modules';

@injectable()
export class DashboardRouterService implements DashboardRouter {
  constructor(
    @inject(History) private history: History,
    @inject(RoleService) private readonly role: RoleService,
  ) {}

  goBack(prevState: { pathname: string; search: string }) {
    this.history.push(prevState);
  }

  goToEventList(role: 'employee' | 'company', query?: string) {
    if (!query || query === '?') {
      this.history.push(`/${role}/${modules.events.basename}`);
      return;
    }

    this.history.push({
      pathname: `/${role}/${modules.events.basename}`,
      search: query.startsWith('?') ? query : `?${query}`,
    });
  }

  goToPolicyList(query?: string) {
    if (!query || query === '?') {
      this.history.push(`/${this.role.get()}/${modules.policy.basename}`);
      return;
    }

    this.history.push({
      pathname: `/${this.role.get()}/${modules.policy.basename}`,
      search: query.startsWith('?') ? query : `?${query}`,
    });
  }

  goToVacationList(query?: string) {
    if (!query || query === '?') {
      this.history.push(`/${this.role.get()}/${modules.vacations.basename}`);
      return;
    }

    this.history.push({
      pathname: `/${this.role.get()}/${modules.vacations.basename}`,
      search: query.startsWith('?') ? query : `?${query}`,
    });
  }

  goToList() {
    this.history.push('/');
  }

  goToWidgetsList() {
    this.history.push('/widgets');
  }

  goToPersonalLinksSettings() {
    this.history.push('/widgets/personal_links');
  }
}
