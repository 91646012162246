import { useTheme as useMUITheme } from '@material-ui/core';

/**
 * Пользовательский хук, который возвращает название текущей темы.
 *
 * @returns {string} Название текущей темы, как указано в объекте tokens темы.
 */
export const useTheme = () => {
  const theme = useMUITheme();

  return theme.tokens.name;
};
