import React from 'react';

import { Box } from '@vk-hr-tek/ui/Box';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { Grid } from '@vk-hr-tek/ui/Grid';
import { List, ListItem } from '@vk-hr-tek/ui/List';
import { ColoredChip } from '@vk-hr-tek/ui/ColoredChip';
import { UsersIcon } from '@vk-hr-tek/ui/icons';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks';
import { Tooltip } from '@vk-hr-tek/ui/Tooltip';
import { ProfileItem } from '@vk-hr-tek/app/app/gen/users';
import { AttorneyType } from '@vk-hr-tek/app/app/types';

import { Attorney } from '../Attorney';

interface AttorneysRolesListProps {
  group: {
    id: string;
    name: string;
    roles: {
      id: string;
      name: string;
      profiles?: ProfileItem[] | undefined;
    }[];
    attorneys: AttorneyType[];
  };
}

export const AttorneysRolesList = ({ group }: AttorneysRolesListProps) => {
  const isDesktop = useIsDesktop();

  return (
    <Box>
      <Grid container>
        <Grid item xs={12} md={3}>
          <Box mb={{ xs: '8', md: '24' }}>
            <Typography
              variant={isDesktop ? 'body2' : 'body3'}
              color="text.light.tertirary"
            >
              Роли
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={9}>
          <Box display="flex" flexWrap="wrap" gap="8" mb={{ xs: '16' }}>
            {group.roles.map(({ id, name, profiles }) => (
              <Tooltip
                disabled={!profiles}
                key={id}
                title={
                  <Box fontSize={12}>
                    <Box>
                      <Typography color="inherit" variant="subtitle2">
                        Профили:
                      </Typography>
                    </Box>
                    <Box mt="8" ml="24">
                      <List listStyleType="disc">
                        {profiles?.map((profile) => (
                          <ListItem key={profile.id}>{profile.name}</ListItem>
                        ))}
                      </List>
                    </Box>
                  </Box>
                }
                placement="right"
              >
                <Box key={id}>
                  <ColoredChip
                    label={name}
                    color="gray"
                    endIcon={!!profiles && <UsersIcon fontSize="inherit" />}
                  />
                </Box>
              </Tooltip>
            ))}
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={3}>
          <Box mb={{ xs: '8', md: '0' }}>
            <Typography
              variant={isDesktop ? 'body2' : 'body3'}
              color="text.light.tertirary"
            >
              Список МЧД
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={9}>
          <Box display="flex" flexWrap="wrap" gap="16">
            {!!group.attorneys.length
              ? group.attorneys.map((attorney) => (
                  <Box
                    flexBasis={isDesktop ? 'calc(50% - 8px)' : '100%'}
                    key={attorney.id}
                    flexGrow={0}
                    flexShrink={0}
                  >
                    <Attorney key={attorney.id} {...attorney} />
                  </Box>
                ))
              : '-'}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
