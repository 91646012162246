import { injectable, inject } from 'inversify';

import { History } from '@vk-hr-tek/core/history';
import { RoleService } from '@vk-hr-tek/core/http';

import { FiltersPresetsRouter } from '../../../filtersPreset/types';

const PAGE_CODES = {
  events: 'events',
  schedule_events: 'vacations',
  absence_events: 'absences',
  employees: 'employees',
  company_policies: 'policy',
};

@injectable()
export class FiltersPresetsRouterService implements FiltersPresetsRouter {
  constructor(
    @inject(History) private history: History,
    @inject(RoleService) private readonly role: RoleService,
  ) {}

  goToPresetEdit(
    id: string,
    listCode:
      | 'events'
      | 'schedule_events'
      | 'absence_events'
      | 'employees'
      | 'company_policies',
  ) {
    this.history.push(
      `/${this.role.get()}/${PAGE_CODES[listCode]}/filters_preset_edit/${id}`,
    );
  }

  goToList(
    listCode:
      | 'events'
      | 'schedule_events'
      | 'absence_events'
      | 'employees'
      | 'company_policies',
  ) {
    this.history.push(`/${this.role.get()}/${PAGE_CODES[listCode]}`);
  }
}
