import { injectable, inject } from 'inversify';
import { plainToClass } from 'class-transformer';

import { ValidationService } from '@vk-hr-tek/core/validation';
import { UnmarshallerService } from '@vk-hr-tek/core/unmarshaller';
import { FiltersResponse, Filter, FilterService } from '@vk-hr-tek/core/filter';
import { HttpService, RoleService } from '@vk-hr-tek/core/http';

import {
  CompanyPolicyListResponse,
  EmployeePolicyListResponse,
} from '@app/gen/policy';
import { UserRoleEnum } from '@app/types';

import { GetPolicyDto, MarkReadDto } from '../dto';

@injectable()
export class PolicyListService {
  constructor(
    private validator: ValidationService,
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
    private filterService: FilterService,
    @inject(RoleService) private role: RoleService,
  ) {}

  async get(
    getPolicyDto: GetPolicyDto,
    availableFilters: Filter[],
    policyVersionIds?: string[],
  ) {
    const policyDto = plainToClass(
      this.filterService.buildDto(GetPolicyDto, availableFilters),
      getPolicyDto,
    );

    if (!policyDto.filters?.preset_id) {
      await this.validator.validateOrReject(policyDto);
    }

    const userRole = this.role.get();
    /* istanbul ignore next */
    const { limit = 0, offset = 50, filters } = policyDto;

    let combineFilters: Record<string, any>;

    if (filters && policyVersionIds) {
      combineFilters = { ...filters, policy_version_ids: policyVersionIds };
    } else if (policyVersionIds) {
      combineFilters = { policy_version_ids: policyVersionIds };
    } else if (filters) {
      combineFilters = filters;
    } else {
      combineFilters = {};
    }

    const getCompanyList = async () =>
      this.unmarshaller.unmarshall(
        await this.http.post(
          `/policy/company/list`,
          {
            pagination: {
              limit,
              offset,
            },
            filters: combineFilters,
          },
          { withSide: true, reloadIfOldVersion: true },
        ),
        CompanyPolicyListResponse,
      );

    const getEmployeeList = async () =>
      this.unmarshaller.unmarshall(
        await this.http.post(
          `/policy/employee/list`,
          {
            pagination: {
              limit,
              offset,
            },
            filters: combineFilters,
          },
          { withSide: true, reloadIfOldVersion: true },
        ),
        EmployeePolicyListResponse,
      );

    if (userRole === UserRoleEnum.Company) {
      const result = await getCompanyList();

      return {
        data: result.policies,
        totalCount: result.total_count,
        totalVersionCount: result.total_version_count,
      };
    }

    const result = await getEmployeeList();

    return {
      data: result.policies,
      totalCount: null,
      totalVersionCount: null,
    };
  }

  async getFilters() {
    const result = await this.unmarshaller.unmarshall(
      await this.http.get(
        `/policy/list/available_filters`,
        {},
        { withSide: true, reloadIfOldVersion: true },
      ),
      FiltersResponse,
    );

    return result.filters;
  }

  async markRead(markReadDto: MarkReadDto) {
    await this.validator.validateOrReject(markReadDto);

    const { policyId, policyVersionId } = markReadDto;

    const result = await this.http.post(
      `/policy/${policyId}/version/${policyVersionId}/mark_read`,
      {},
      { withSide: true },
    );

    return result;
  }
}
