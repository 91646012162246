import React from 'react';

import HelpIcon from '@material-ui/icons/Help';
import FileCopyIcon from '@material-ui/icons/FilterNone';

import { Box } from '@vk-hr-tek/ui/Box';
import { Button } from '@vk-hr-tek/ui/Button';
import { EllipsisText } from '@vk-hr-tek/ui/EllipsisText';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks';
import { IconButton } from '@vk-hr-tek/ui/IconButton';
import { Tooltip } from '@vk-hr-tek/ui/Tooltip';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { selectToken1C, selectToken1CStatus } from '@vk-hr-tek/app/user';
import { useSelector } from '@vk-hr-tek/app/app/hooks';

interface TokenGenerationContentProps {
  handleClick: () => void;
}

export const TokenGenerationContent = ({
  handleClick,
}: TokenGenerationContentProps) => {
  const token1C = useSelector(selectToken1C);
  const token1CStatus = useSelector(selectToken1CStatus);
  const isDesktop = useIsDesktop();

  const subtitleVariant = isDesktop ? 'subtitle1' : 'subtitle2';
  const textVariant = isDesktop ? 'body2' : 'body3';
  return (
    <Box
      display={isDesktop ? 'flex' : 'block'}
      flexDirection="column"
      justifyContent="space-between"
      gap="16"
    >
      <Box>
        <Box pb="8">
          <Typography variant={subtitleVariant}>Генерация токена</Typography>
        </Box>
        <Typography variant={textVariant} color="text.light.tertirary">
          После генерации нового токена нужно будет заново авторизоваться во
          всех 1С базах, где вы работаете с КЭДО
        </Typography>
      </Box>

      {token1C && (
        <Box
          p="12"
          radius="m"
          bgcolor="bg.greyscale.primary"
          display="flex"
          gap="8"
        >
          <Box display="flex" flexDirection="column" gap="8">
            <Box display="flex" alignItems="center">
              <Box mr="8">Токен</Box>
              <Tooltip title="Токен виден, пока вы не обновите страницу (по требованиям безопасности)">
                <HelpIcon color="disabled" fontSize="small" />
              </Tooltip>
            </Box>
            <Box>
              <EllipsisText wordBreak="break-all" rows={1}>
                <Typography variant="body3" color="text.light.tertirary">
                  {token1C}
                </Typography>
              </EllipsisText>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center">
            <IconButton
              type="button"
              size="small"
              onClick={() => {
                navigator.clipboard.writeText(token1C);
              }}
            >
              <FileCopyIcon color="primary" fontSize="medium" />
            </IconButton>
          </Box>
        </Box>
      )}

      <Box mt={!isDesktop ? '8' : '0'}>
        <Button
          onClick={handleClick}
          fullWidth={!isDesktop}
          size="small"
          loading={token1CStatus === 'loading'}
        >
          {(token1CStatus === 'idle' || token1CStatus === 'failed') &&
            'Сгенерировать'}
          {token1CStatus === 'complete' && 'Сгенерировать еще раз'}
        </Button>
      </Box>
    </Box>
  );
};
