import React from 'react';

import { Typography } from '../../../Typography';
import { Box } from '../../../Box';

interface FileErrorProps {
  text: string;
}

/**
 * FileError - компонент для отображения ошибки файла.
 *
 * @param {FileErrorProps} props - Свойства компонента.
 * @param {string} [props.text='Что-то пошло не так'] - Текст ошибки.
 * @returns {JSX.Element} Возвращает JSX-элемент представляющий компонент FileError.
 */

export const FileError = ({ text }: FileErrorProps) => {
  return (
    <Box pt="4">
      <Typography variant="caption" color="accent.text.error">
        {text || 'Что-то пошло не так'}
      </Typography>
    </Box>
  );
};
