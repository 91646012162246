import React, { forwardRef } from 'react';

import { InfiniteScroll } from '../../InfiniteScroll';
import { CircularProgress } from '../../CircularProgress';
import { Box } from '../../Box';

import useStyles from './ListBox.styles';

/**
 * Компонент ListBox представляет собой контейнер для списков с бесконечной прокруткой.
 * Он использует компонент InfiniteScroll для загрузки дополнительных элементов при прокрутке.
 *
 * @param {boolean} hasMore - Флаг, указывающий, есть ли еще элементы для загрузки.
 * @param {number} dataLength - Текущее количество элементов в списке.
 * @param {() => void} next - Функция, вызываемая для загрузки следующей порции элементов.
 * @param {boolean} loading - Флаг, указывающий, что данные загружаются.
 * @param {React.ReactNode | React.ReactNode[]} children - Дочерние элементы списка.
 * @param {React.HTMLAttributes<HTMLDivElement>} props - Дополнительные свойства HTML-элемента div.
 * @param {React.Ref<HTMLDivElement>} ref - Реф для доступа к внутреннему элементу div.
 * @returns {React.ReactNode} Компонент ListBox.
 */

export const ListBox = forwardRef<
  HTMLDivElement,
  {
    hasMore: boolean;
    dataLength: number;
    next: () => void;
    loading: boolean;
    children?: React.ReactNode | React.ReactNode[];
  }
>(({ hasMore, dataLength, next, loading, children, ...props }, ref) => {
  const classes = useStyles();

  return (
    <div
      {...props}
      id="scrollable"
      ref={ref}
      className={classes.scrollableWrapper}
    >
      <InfiniteScroll
        next={next}
        dataLength={dataLength}
        hasMore={hasMore}
        loader={
          loading && (
            <Box display="flex" justifyContent="center" p="16">
              <CircularProgress size={30} />
            </Box>
          )
        }
        endMessage={<div />}
        scrollableTarget="scrollable"
      >
        <ul className={classes.listBox}>{children}</ul>
      </InfiniteScroll>
    </div>
  );
});
