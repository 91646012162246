import {
  IsNotEmpty,
  IsArray,
  ValidateNested,
  IsOptional,
  IsString,
  IsUUID,
} from 'class-validator';
import { Type } from 'class-transformer';

import { BatchEventDto } from './batch-event.dto';

export class SendBatchEventsDto {
  @ValidateNested({ each: true })
  @Type(() => BatchEventDto)
  @IsArray()
  @IsNotEmpty()
  events: BatchEventDto[];

  @IsString({ each: true })
  @IsArray()
  @IsOptional()
  tags?: string[];

  @IsUUID()
  @IsString()
  @IsOptional()
  companyId?: string;
}
