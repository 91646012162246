import React, { useState, useCallback } from 'react';

import { Link } from '../Link';

import { RedoCountdown } from './RedoCountdown';

export const CODE_RESEND_DELAY_MS = 59000;

export interface RedoProps {
  label: string;
  action: () => void;
  after?: number;
  timestamp?: number;
  disabled?: boolean;
}

/**
 * Компонент Redo, который позволяет пользователю выполнять определённое действие после истечения заданного периода отсчёта.
 * Он переключается между отображением отсчёта времени и кликабельной ссылкой в зависимости от текущего времени и времени окончания отсчёта.
 *
 * @param {RedoProps} props - Свойства компонента Redo.
 * @param {string} props.label - Текст метки для ссылки или отсчёта времени.
 * @param {() => void} props.action - Действие, которое будет выполнено при клике на ссылку.
 * @param {number} [props.after=CODE_RESEND_DELAY_MS] - Задержка в миллисекундах, по истечении которой действие можно будет выполнять снова.
 * @param {number} [props.timestamp] - Начальная временная метка для отсчёта времени. Если не указана, используется текущее время.
 * @param {boolean} [props.disabled=false] - Логическое значение, указывающее, отключён ли компонент.
 * @returns {JSX.Element} - Отрендеренный компонент Redo, либо RedoCountdown, либо Link.
 */

export const Redo = ({
  label,
  action,
  after = CODE_RESEND_DELAY_MS,
  timestamp,
  disabled = false,
}: RedoProps) => {
  const calcEndDate = (timestamp ?? Date.now()) + after;
  const [endDate, setEndDate] = useState(calcEndDate);
  const [isTimerVisible, setIsTimerVisible] = useState(Date.now() < endDate);

  const toggleTimerVisibility = useCallback(() => {
    setIsTimerVisible((prevShowTimer) => !prevShowTimer);
  }, []);

  const handleSendAgainClick = useCallback(() => {
    setEndDate(Date.now() + after);
    toggleTimerVisibility();
    action();
  }, [after, action, toggleTimerVisibility]);

  return isTimerVisible ? (
    <RedoCountdown
      label={label}
      disabled={disabled}
      finalDate={endDate}
      toggleTimerVisibility={toggleTimerVisibility}
    />
  ) : (
    <Link onClick={handleSendAgainClick} disabled={disabled} stroke={false}>
      {label}
    </Link>
  );
};
