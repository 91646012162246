import React from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import classNames from 'classnames';

import { ExpandMoreIcon } from '../../icons';
import { Box } from '../../Box';
import { Typography } from '../../Typography';
import { Badge } from '../../Badge';
import { EllipsisText } from '../../EllipsisText';

import useStyles from './DropdownInput.styles';

interface DropdownInputProps<T> {
  items: {
    label: string;
    value: T;
    active?: boolean;
  }[];
  value: T | T[] | null;
  onChange: (value: T) => void;
  testId?: string;
}

/**
 * DropdownInput - компонент для создания выпадающего списка выбора.
 *
 * @template T - Тип значения, который может быть строкой или числом.
 * @param {DropdownInputProps<T>} props - Свойства компонента.
 * @param {Array<{ label: string, value: T, active?: boolean }>} props.items - Массив элементов для отображения в выпадающем меню.
 * Каждый элемент должен содержать метку (`label`) и значение (`value`), опционально может быть указано, является ли элемент активным (`active`).
 * @param {T | T[] | null} props.value - Текущее выбранное значение или массив значений. Может быть null, если ничего не выбрано.
 * @param {(value: T) => void} props.onChange - Функция, вызываемая при изменении выбранного значения.
 * Принимает новое значение в качестве аргумента.
 * @param {string} [props.testId] - Опциональный ID для тестирования компонента.
 * @returns {JSX.Element} Возвращает JSX-элемент представляющий компонент DropdownInput.
 */
export const DropdownInput = <T extends string | number>({
  value,
  items,
  testId,
  onChange,
}: DropdownInputProps<T>) => {
  const classes = useStyles();

  const selectedOption = items.find((option) => option.value === value);
  const hasActiveOptions = items.some((item) => !!item.active);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Box
        className={classNames(classes.dropdown, 'aqa_dropdown_input')}
        display="flex"
        alignItems="center"
        onClick={handleClick}
        gap="8"
        testId={testId}
      >
        <Typography variant="h6" component="div" noWrap>
          {selectedOption ? selectedOption.label : '-'}
        </Typography>

        {items.length > 1 && <ExpandMoreIcon color="primary" />}

        {hasActiveOptions && (
          <Box
            display="flex"
            alignSelf="flex-start"
            width={6}
            height="100%"
            ml="-8"
            position="relative"
          >
            <Badge visible={hasActiveOptions} />
          </Box>
        )}
      </Box>
      {items.length > 1 && (
        <Menu
          elevation={0}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          getContentAnchorEl={null}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          classes={{
            list: classes.menuList,
            paper: classes.menuPaper,
          }}
        >
          {items.map((item) => (
            <MenuItem
              value={item.value}
              key={item.value}
              onClick={() => {
                onChange(item.value);
                handleClose();
              }}
              className={classes.menuItem}
            >
              <Typography
                color={
                  item.value === value
                    ? 'original.brand.primary'
                    : 'text.light.primary'
                }
                variant="body3"
              >
                <EllipsisText rows={1}>{item.label}</EllipsisText>
              </Typography>
              {item.active && (
                <Box display="flex" height={18} width={12} justifyContent="end">
                  <Box
                    width={6}
                    height={6}
                    bgcolor="accent.text.error"
                    radius="round"
                  />
                </Box>
              )}
            </MenuItem>
          ))}
        </Menu>
      )}
    </div>
  );
};
