import { SerializedError } from '@reduxjs/toolkit';

import { AppError } from '@vk-hr-tek/core/error';
import type { UnitNodeLabeled, UnitTypeOption } from '@vk-hr-tek/core/units';

import {
  EventBatchEmployee,
  EmployeeItem,
  CreateEventCompanyItem,
  EventBatchItem,
  AssignableRoles,
  CreateEventTypeOptionsCopyDocument,
  CreateEventTypeOptionsCopyAttribute,
} from '@app/gen/events';
import { RootState } from '@app/store';
import { CreateEventOption } from '@app/types';

import { EventTypeItemWithGroup } from '../types';

export interface CreationState {
  status: 'idle' | 'loading' | 'complete' | 'failed';
  error: AppError | null;
  creation: {
    status: 'idle' | 'loading' | 'complete' | 'failed';
    options: CreateEventOption[] | null;
    employees: {
      status: 'idle' | 'loading' | 'complete' | 'failed';
      list: EmployeeItem[];
    };
    items: CreateEventCompanyItem[] | null;
    error: AppError | null;
    canCreateBatch: boolean;
    canCreateForAnother: boolean;
    eventTypes: EventTypeItemWithGroup[] | null;
    employeeEventTypes: EventTypeItemWithGroup[] | null;
    selected: {
      company: { id: string; name: string };
      eventType: { id: string; name: string; documentInfoRequired: boolean };
      employees: EventBatchEmployee[];
      existingBatches: EventBatchItem[];
      unitTypeOptions?: UnitTypeOption[];
      rootUnit?: UnitNodeLabeled | null;
      employeesLoadingError?: boolean;
    } | null;
  };
  createEventTypeOptions: {
    status: 'idle' | 'loading' | 'complete' | 'failed';
    options: Record<
      string,
      {
        assignableRoles?: AssignableRoles;
        copyDocuments?: CreateEventTypeOptionsCopyDocument[];
        copyAttributes?: CreateEventTypeOptionsCopyAttribute[];
      }
    >;
    error: AppError | null;
  };
}

export const initialState: CreationState = {
  status: 'idle',
  error: null,
  creation: {
    employees: {
      status: 'idle',
      list: [],
    },
    status: 'idle',
    options: null,
    items: null,
    eventTypes: null,
    employeeEventTypes: null,
    error: null,
    canCreateBatch: false,
    canCreateForAnother: false,
    selected: null,
  },
  createEventTypeOptions: {
    status: 'idle',
    options: {},
    error: null,
  },
};

export interface CreationWithRootState extends RootState {
  creationEvent: CreationState;
}

export const startLoading = (state: CreationState) => {
  state.status = 'loading';
  state.error = null;
};

export const completeLoading = (state: CreationState) => {
  state.status = 'complete';
  state.error = null;
};

export const setError = (
  state: CreationState,
  { payload, error }: { payload?: AppError; error: SerializedError },
) => {
  state.status = 'failed';
  state.error =
    payload ||
    ({
      info: (error && error.message) || 'Unknown error',
      status: 500,
      source: 'client',
      title: 'Internal client error',
    } as AppError);
};
