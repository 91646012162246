import { IsNotEmpty, IsString, IsIn } from 'class-validator';

import { FiltersPresetDto } from './filters-preset.dto';

export class UpdateFiltersPresetDto {
  @IsString({
    groups: ['form'],
  })
  @IsNotEmpty({
    groups: ['form'],
  })
  name: string;

  @IsIn(['purple', 'orange', 'red', 'green', 'blue'], { groups: ['form'] })
  @IsString({
    groups: ['form'],
  })
  @IsNotEmpty({
    groups: ['form'],
  })
  color: string;

  filters: FiltersPresetDto;
}
