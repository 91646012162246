import React from 'react';

import { Typography } from '@vk-hr-tek/ui/Typography';
import { Box } from '@vk-hr-tek/ui/Box';
import { useSelector } from '@vk-hr-tek/app/app/hooks';
import { selectUser } from '@vk-hr-tek/app/user';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks';
import { Divider } from '@vk-hr-tek/ui/Divider';

import { EmployeeInfo } from '../EmployeeInfo';

import { CertificateInfo } from './CertificateInfo';
import { UkepCertificatesList } from './UkepCertificatesList';

export const GeneralInfo = () => {
  const user = useSelector(selectUser);
  const isDesktop = useIsDesktop();

  if (!user) {
    return null;
  }

  return (
    <Box
      radius={isDesktop ? 'l' : '0'}
      border={isDesktop ? '1px solid' : 0}
      borderColor="stroke.primary"
      bgcolor="bg.light.primary"
    >
      <Box gap="24" display="flex" flexDirection="column">
        <Box
          gap="16"
          display="flex"
          flexDirection="column"
          paddingX="24"
          paddingY="20"
        >
          <Typography
            variant={isDesktop ? 'h6' : 'subtitle1'}
            color="text.light.primary"
          >
            Общая информация
          </Typography>
          <Box
            gap={isDesktop ? '24' : '8'}
            display="flex"
            flexDirection={isDesktop ? 'row' : 'column'}
          >
            <Typography variant="body2" color="text.light.tertirary">
              Электронная подпись
            </Typography>
            <Box gap="12" display="flex" flexDirection="column">
              <CertificateInfo certificate={user.certificate} />

              {!!user.ukepCertificates?.length && (
                <UkepCertificatesList
                  ukepCertificates={user.ukepCertificates}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      {user.employees.map((employee) => (
        <Box key={employee.id}>
          <Divider />
          <EmployeeInfo employee={employee} />
        </Box>
      ))}
    </Box>
  );
};
