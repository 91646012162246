export interface FiltersPresetsRouter {
  goToPresetEdit(
    id: string,
    listCode:
      | 'events'
      | 'schedule_events'
      | 'absence_events'
      | 'employees'
      | 'company_policies',
  ): void;

  goToList(
    listCode:
      | 'events'
      | 'schedule_events'
      | 'absence_events'
      | 'employees'
      | 'company_policies',
  ): void;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FiltersPresetsRouter = Symbol('FiltersPresetsRouter');
