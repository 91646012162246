import React, { ReactNode } from 'react';

import { Box } from '../Box';
import { Typography } from '../Typography';
import { Link } from '../Link';

interface DialogContentProps {
  onClose: () => void;
  title?: string;
  titleChip?: ReactNode;
  fullScreen?: boolean;
  actions?: React.ReactNode;
  children?: React.ReactNode;
}

/**
 * Компонент DialogContent представляет содержимое диалогового окна.
 * @param {Function} onClose - Функция, вызываемая при закрытии диалогового окна.
 * @param {string} [title] - Заголовок диалогового окна.
 * @param {ReactNode} [titleChip] - Дополнительный компонент, отображаемый рядом с заголовком.
 * @param {boolean} [fullScreen] - Флаг, определяющий, занимает ли диалоговое окно весь экран.
 * @param {ReactNode} [actions] - Компоненты действий, отображаемые в нижней части диалогового окна.
 * @param {ReactNode} [children] - Дочерние элементы, отображаемые в основном содержимом диалогового окна.
 */

export const DialogContent = ({
  children,
  fullScreen,
  title,
  titleChip,
  actions,
  onClose,
}: DialogContentProps) => {
  return (
    <Box display="flex" flexDirection="column" flexGrow="1">
      <Box
        py={fullScreen ? '32' : '48'}
        px={fullScreen ? '16' : '48'}
        flexGrow="1"
      >
        {title && (
          <Box
            display="flex"
            alignItems="flex-start"
            mb="24"
            flexDirection="column"
          >
            {fullScreen && (
              <Box mb="8">
                <Link size="small" onClick={onClose} stroke={false}>
                  Назад
                </Link>
              </Box>
            )}
            <Box display="flex" justifyContent="space-between" width="100%">
              <Typography variant="h5">{title}</Typography>
              {titleChip}
            </Box>
          </Box>
        )}
        {children}
      </Box>
      {actions &&
        (fullScreen ? (
          <Box display="flex" justifyContent="center" gap="16" px="16" pb="24">
            {actions}
          </Box>
        ) : (
          <Box
            display="flex"
            justifyContent="flex-end"
            gap="16"
            p="16"
            bgcolor="bg.greyscale.primary"
          >
            {actions}
          </Box>
        ))}
    </Box>
  );
};
