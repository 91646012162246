import React from 'react';

import classNames from 'classnames';

import { Box } from '../Box';

import {
  CounterBackgroundColor,
  CounterColor,
  CounterSize,
} from './Counter.types';
import { useStyles } from './Counter.styles';

/**
 * Интерфейс, представляющий свойства для компонента Counter.
 *
 * @interface CounterProps
 * @property {keyof typeof CounterBackgroundColor} [backgroundColor] - Фоновый цвет счетчика. По умолчанию 'white'.
 * @property {string} [className] - Дополнительное имя CSS-класса для применения к счетчику.
 * @property {keyof typeof CounterColor} [color] - Цвет текста счетчика.
 * @property {number | string} counter - Значение счетчика для отображения.
 * @property {boolean} [signed] - Флаг для отображения знака '+' для положительных чисел. По умолчанию false.
 * @property {boolean} [moreThanMax] - Флаг для отображения знака '+' для указания, что значение счетчика превышает максимальное. По умолчанию false.
 * @property {keyof typeof CounterSize} [size] - Размер счетчика. По умолчанию 'medium'.
 */

export type CounterProps = {
  backgroundColor?: keyof typeof CounterBackgroundColor;
  className?: string;
  color?: keyof typeof CounterColor;
  counter: number | string;
  signed?: boolean;
  moreThanMax?: boolean;
  size?: keyof typeof CounterSize;
};

/**
 * Компонент, который отображает счетчик с возможностью стилизации и добавления знаков.
 *
 * @component
 * @param {CounterProps} props - Свойства для компонента Counter.
 * @param {keyof typeof CounterBackgroundColor} [props.backgroundColor] - Фоновый цвет счетчика. По умолчанию 'white'.
 * @param {string} [props.className] - Дополнительное имя CSS-класса для применения к счетчику.
 * @param {keyof typeof CounterColor} [props.color] - Цвет текста счетчика.
 * @param {number | string} props.counter - Значение счетчика для отображения.
 * @param {boolean} [props.signed] - Флаг для отображения знака '+' для положительных чисел. По умолчанию false.
 * @param {boolean} [props.moreThanMax] - Флаг для отображения знака '+' для указания, что значение счетчика превышает максимальное. По умолчанию false.
 * @param {keyof typeof CounterSize} [props.size] - Размер счетчика. По умолчанию 'medium'.
 * @returns {JSX.Element} Компонент Counter.
 */

export const Counter = ({
  backgroundColor = 'white',
  className,
  color,
  counter,
  signed = false,
  size = 'medium',
  moreThanMax = false,
}: CounterProps) => {
  const classes = useStyles({
    backgroundColor: CounterBackgroundColor[backgroundColor],
    color: color,
    size: CounterSize[size],
    signed,
  });

  const sign = signed && counter > 0 ? '+' : '';

  return (
    <Box className={classNames(classes.counter, className)}>
      {sign}
      {counter}
      {moreThanMax ? '+' : ''}
    </Box>
  );
};
