import React, { ReactNode } from 'react';

import useStyles from './EllipsisText.styles';

interface EllipsisTextProps {
  rows?: number;
  children: ReactNode;
  wordBreak?: 'normal' | 'break-all' | 'keep-all' | 'break-word';
  hyphens?: boolean;
}

/**
 * Компонент для отображения текста с многоточием, если он переполняет заданное количество строк.
 *
 * @param {Object} props - Свойства, которые можно передать компоненту EllipsisText.
 * @param {number} [props.rows=2] - Максимальное количество строк, которое текст должен занимать перед усечением многоточием.
 * @param {React.ReactNode} props.children - Текстовое содержимое для отображения.
 * @param {('normal' | 'break-all' | 'keep-all' | 'break-word')} [props.wordBreak='normal'] - Свойство word-break для контроля переноса строк.
 * @param {boolean} [props.hyphens=false] - Если true, перенос слов будет осуществляться с использованием дефисов.
 * @returns {JSX.Element} JSX-элемент, представляющий текст с многоточием.
 */

export const EllipsisText = ({
  rows = 2,
  wordBreak = 'normal',
  children,
  hyphens = false,
}: EllipsisTextProps) => {
  const classes = useStyles({ rows, wordBreak, hyphens });

  return (
    <span
      className={`${classes.ellipsis} aqa_ellipsis_text`}
      data-testid="ellipsis-text-test-id"
    >
      {children}
    </span>
  );
};
