/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Field, FieldProps, FieldRenderProps } from 'react-final-form';

import { CheckboxInput as Input } from '../../input';

/**
 * Компонент для ввода чекбокса с поддержкой react-final-form.
 * @param {string} label - Текст метки для чекбокса.
 * @param {string} labelPlacement - Позиционирование метки относительно чекбокса.
 * @param {string} labelColor - Цвет метки.
 * @param {string} labelVariant - Вариант оформления метки.
 * @param {string} name - Имя поля формы.
 * @param {string} tooltip - Текст подсказки.
 * @param {boolean} disabled - Флаг отключения чекбокса.
 * @param {function} onChange - Функция для обработки изменения состояния чекбокса.
 * @param {object} rest - Дополнительные свойства, передаваемые в поле формы.
 * @returns {JSX.Element} Компонент ввода чекбокса.
 */
export const CheckboxInput = ({
  label,
  labelPlacement,
  labelColor,
  labelVariant,
  name,
  tooltip,
  disabled,
  onChange,
  ...rest
}: FieldProps<boolean | undefined, FieldRenderProps<boolean | undefined>>) => (
  <Field name={name} {...rest}>
    {({ input }) => (
      <Input
        {...input}
        label={label}
        labelPlacement={labelPlacement}
        labelColor={labelColor}
        labelVariant={labelVariant}
        checked={input.value}
        tooltip={tooltip}
        disabled={disabled}
        onChange={onChange || input.onChange}
      />
    )}
  </Field>
);
