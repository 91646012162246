import { injectable } from 'inversify';

import { PersonalLink } from '@app/gen/dashboard';

import { PersonalLinksIcons } from '../types';

@injectable()
export class PersonalLinksSettingsMapper {
  processSettingsInitialValues(
    widgetName: string | undefined,
    links: PersonalLink[],
  ) {
    return {
      name: widgetName || 'Персональные ссылки',
      personalLinks: links.length
        ? links.map(({ name, url, icon }) => ({
            name,
            url,
            icon,
          }))
        : [{ icon: PersonalLinksIcons.globe }],
    };
  }
}
