import React from 'react';

import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const BookmarkIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fontSize="inherit"
      viewBox={props.viewBox ? props.viewBox : '0 0 20 20'}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.31739 1.5H10.6826C11.6351 1.49999 12.3956 1.49999 13.0099 1.55018C13.6399 1.60165 14.1818 1.70963 14.6795 1.96322C15.4791 2.37068 16.1293 3.02085 16.5368 3.82054C16.7904 4.31824 16.8984 4.86012 16.9498 5.49013C17 6.10439 17 6.86493 17 7.81737V14.3871C17 15.1634 17 15.7979 16.9556 16.2876C16.9118 16.7707 16.8159 17.268 16.5041 17.6561C16.0729 18.1929 15.4197 18.5027 14.7312 18.4969C14.2333 18.4927 13.7875 18.2523 13.3858 17.9806C12.9784 17.705 12.4872 17.3036 11.8861 16.8123L11.0441 16.1242C10.765 15.8961 10.5869 15.7512 10.442 15.6497C10.3049 15.5536 10.2405 15.5281 10.203 15.5175C10.0702 15.4802 9.92977 15.4802 9.79702 15.5175C9.75946 15.5281 9.69513 15.5536 9.55801 15.6497C9.41314 15.7512 9.23499 15.8961 8.95589 16.1242L8.11389 16.8123C7.51279 17.3036 7.02155 17.705 6.61422 17.9806C6.21249 18.2523 5.76673 18.4927 5.26883 18.4969C4.58035 18.5027 3.92713 18.1929 3.49592 17.6561C3.18408 17.268 3.08819 16.7707 3.04439 16.2876C2.99998 15.7979 2.99999 15.1635 3 14.3871V7.81739C2.99999 6.86494 2.99999 6.1044 3.05018 5.49013C3.10165 4.86012 3.20963 4.31824 3.46322 3.82054C3.87068 3.02085 4.52085 2.37068 5.32054 1.96322C5.81824 1.70963 6.36012 1.60165 6.99013 1.55018C7.6044 1.49999 8.36495 1.49999 9.31739 1.5Z"
      />
    </SvgIcon>
  );
};
