import React from 'react';

import { useTranslations } from '@vk-hr-tek/core/hooks';
import { Box } from '@vk-hr-tek/ui/Box';
import { Grid } from '@vk-hr-tek/ui/Grid';
import { Typography } from '@vk-hr-tek/ui/Typography';

interface DeleteSubstituteFormContentProps {
  employeeCompanyName: string;
  substituteName: string;
}

export const DeleteSubstituteFormContent = ({
  employeeCompanyName,
  substituteName,
}: DeleteSubstituteFormContentProps) => {
  const { t } = useTranslations();

  return (
    <Box display="flex" flexDirection="column" height="100%" p="48">
      <Box pb="24">
        <Typography variant="h5">Удаление заместителя</Typography>
      </Box>
      <Box>
        <Box pb="16">
          <Typography color="text.light.tertirary">
            {t('user.profile.substitutes.employeeDeleteConfirmationText')}
          </Typography>
        </Box>
        <Box p="16" radius="l" bgcolor="bg.greyscale.primary">
          <Grid container spacing="8">
            <Grid item xs={4}>
              <Typography color="text.light.tertirary">Юрлицо:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2">{employeeCompanyName}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography color="text.light.tertirary">Заместитель:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2">{substituteName}</Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
