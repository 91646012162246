import React from 'react';

import { useStyles } from './Content.styles';

/**
 * Интерфейс, представляющий свойства для компонента Content.
 *
 * @interface ContentProps
 * @property {React.ReactNode | React.ReactNode[]} children - Дочерние элементы, которые будут отображены внутри компонента Content.
 */

interface ContentProps {
  children: React.ReactNode | React.ReactNode[];
}

/**
 * Компонент, который оборачивает своих детей в стили и реф.
 *
 * @component
 * @param {ContentProps} props - Свойства для компонента Content.
 * @param {React.ReactNode | React.ReactNode[]} props.children - Дочерние элементы, которые будут отображены внутри компонента Content.
 * @param {React.ForwardedRef<HTMLDivElement>} ref - Реф, перенаправляемый к базовому элементу div.
 * @returns {JSX.Element} Компонент Content.
 */

export const Content = React.forwardRef<HTMLDivElement, ContentProps>(
  ({ children }, ref) => {
    const classes = useStyles();

    return (
      <div
        ref={ref}
        className={`${classes.content} aqa_content`}
        data-testid="aqa_content"
      >
        {children}
      </div>
    );
  },
);
