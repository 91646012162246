import React, { ReactElement, Children } from 'react';

import { ButtonGroup as MUIButtonGroup } from '@material-ui/core';
import classNames from 'classnames';

import { ButtonSize } from '../Button';

import { useStyles } from './ButtonGroup.styles';

/**
 * Интерфейс свойств для компонента ButtonGroup.
 */
interface ButtonGroupProps {
  /**
   * Имя класса для группы кнопок.
   */
  className?: string;

  /**
   * Идентификатор для группы кнопок.
   */
  id?: string;

  /**
   * Кнопочные элементы, которые будут отображаться внутри группы.
   */
  children: ReactElement[] | ReactElement;

  /**
   * Ориентация группы кнопок.
   * @default 'horizontal'
   */
  orientation?: 'horizontal' | 'vertical';

  /**
   * Вариант группы кнопок.
   * @default 'contained'
   */
  variant?: 'contained' | 'silver';

  /**
   * Определяет, должны ли кнопки в группе быть разделены.
   * @default false
   */
  separated?: boolean;

  /**
   * Размер кнопок в группе.
   * @default 'medium'
   */
  size?: ButtonSize;

  /**
   * Определяет, должна ли группа кнопок быть отключена.
   * @default false
   */
  disabled?: boolean;

  /**
   * Обработчик события клика.
   */
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

/**
 * Пользовательский компонент группы кнопок, построенный с использованием Material-UI.
 *
 * @component
 * @param {ButtonGroupProps} props - Свойства для компонента ButtonGroup.
 * @param {string} [props.className] - Имя класса для группы кнопок.
 * @param {string} [props.id] - Идентификатор для группы кнопок.
 * @param {ReactElement[] | ReactElement} props.children - Кнопочные элементы, которые будут отображаться внутри группы.
 * @param {'horizontal' | 'vertical'} [props.orientation='horizontal'] - Ориентация группы кнопок.
 * @param {'contained' | 'silver'} [props.variant='contained'] - Вариант группы кнопок.
 * @param {boolean} [props.separated=false] - Определяет, должны ли кнопки в группе быть разделены.
 * @param {ButtonSize} [props.size='medium'] - Размер кнопок в группе.
 * @param {boolean} [props.disabled=false] - Определяет, должна ли группа кнопок быть отключена.
 * @param {(e: React.MouseEvent<HTMLElement>) => void} [props.onClick] - Обработчик события клика.
 * @returns {JSX.Element} Компонент ButtonGroup.
 */

export const ButtonGroup = ({
  className,
  children,
  orientation = 'horizontal',
  variant = 'contained',
  separated = false,
  size = 'medium',
  disabled = false,
  ...props
}: ButtonGroupProps) => {
  const classes = useStyles();

  const newChildren = Children.map(children, (childElement: ReactElement) => {
    const { className: classNameElement, ...propsElement } = childElement.props;

    const buttonType = childElement.type as { name?: string };
    const isButton = buttonType?.name === 'Button';

    return {
      ...childElement,
      props: {
        ...propsElement,
        size,
        className: classNames(
          classNameElement,
          classes.element,
          variant === 'contained' && classes.elementContained,
          variant === 'silver' && classes.elementSilver,
          separated && classes.elementSeparated,
          isButton && variant === 'contained' && classes.buttonContainedHover,
          isButton && variant === 'silver' && classes.buttonSilverHover,
        ),
      },
    };
  });

  const buttonGroupStyles = classNames(
    classes.buttonGroup,
    separated && classes.buttonGroupSeparated,
    variant === 'silver' && classes.buttonGroupSilver,
    variant === 'silver' && disabled && classes.buttonGroupSilverDisabled,
    size === 'large' && classes.sizeLarge,
    className,
    'aqa_button_group',
  );

  return (
    <MUIButtonGroup
      {...props}
      className={buttonGroupStyles}
      children={newChildren}
      disabled={disabled}
      orientation={orientation}
      variant="contained"
    />
  );
};
