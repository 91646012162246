import {
  AbilityBuilder,
  ForcedSubject,
  Ability,
  AbilityClass,
} from '@casl/ability';

import { UserRoleEnum } from '../types';

const actions = [
  'manage',
  'create',
  'edit',
  'cancel',
  'read',
  'printFlow',
] as const;
const subjects = [
  'Employees',
  'Dashboard',
  'Absences',
  'Candidates',
  'Candidate',
  'Events',
  'EventTypes',
  'EventBatch',
  'PaperEvents',
  'User',
  'Policy',
  'Organization',
  'Competencies',
  'all',
  'Settings',
  'CompanyProfiles',
  'SettingsAdmin',
  'SettingsAdmin',
  'Attorneys',
  'Vacations',
  'VacationsSettings',
  'Payslips',
  'Personal',
  'SmevErrors',
] as const;

export type AppAbilities = [
  typeof actions[number],
  (
    | typeof subjects[number]
    | ForcedSubject<Exclude<typeof subjects[number], 'all'>>
  ),
];

export type Resources = AppAbilities[1];
export type AppAbility = Ability<AppAbilities>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppAbility = Ability as AbilityClass<AppAbility>;

export type AbilityConfig = {
  role: string;
  userRepresentative: boolean;
  canSeeEvents: boolean;
  canSeeEventsToPaper: boolean;
  canSeeEmployees: boolean;
  canSeePolicies: boolean;
  canSeeCompany: boolean;
  canSeeCompanyProfiles: boolean;
  canSeeSettings: boolean;
  canSeeCandidates: boolean;
  canSeeAttorneys: boolean;
  canSeeVacations: boolean;
  canSeeVacationsSettings: boolean;
  canSeePayslips: boolean;
  canSeeSmevErrors: boolean;
  canSeeAbsences: boolean;
  canSeeCompetencies: boolean;
  canSeePersonalProfile: boolean;
  canSeeUnits: boolean;
};

export default function defineAbilityFor({
  role,
  userRepresentative,
  canSeeEvents,
  canSeeEventsToPaper,
  canSeeEmployees,
  canSeePolicies,
  canSeeCompany,
  canSeeCompanyProfiles,
  canSeeSettings,
  canSeeCandidates,
  canSeeAttorneys,
  canSeeVacations,
  canSeeVacationsSettings,
  canSeePayslips,
  canSeeSmevErrors,
  canSeeAbsences,
  canSeeCompetencies,
  canSeePersonalProfile,
  canSeeUnits,
}: AbilityConfig) {
  const { can, cannot, build } = new AbilityBuilder(AppAbility);

  can('manage', 'all');

  if (role === UserRoleEnum.Employee) {
    cannot('read', 'Employees');
    cannot('cancel', 'Events');
    cannot('create', 'EventTypes');
    cannot('read', 'Candidates');
    cannot('create', 'Candidates');
    cannot('read', 'PaperEvents');
    cannot('create', 'Policy');
    cannot('edit', 'Policy');
    cannot('create', 'Organization');
    cannot('edit', 'Organization');
    cannot('read', 'Settings');
    cannot('read', 'CompanyProfiles');
    cannot('read', 'Attorneys');
    cannot('read', 'SettingsAdmin');
    cannot('printFlow', 'Events');
  }

  if (role === UserRoleEnum.Company) {
    cannot('read', 'Competencies');
  }

  if (!canSeeUnits) {
    cannot('read', 'Organization');
  }

  if (!canSeeEmployees) {
    cannot('read', 'Employees');
  }

  if (!canSeeEvents) {
    cannot('read', 'Events');
  }

  if (!canSeeEventsToPaper) {
    cannot('read', 'PaperEvents');
  }

  if (!canSeePolicies) {
    cannot('read', 'Policy');
  }

  if (!canSeeCompany) {
    cannot('create', 'Organization');
    cannot('edit', 'Organization');
  }

  if (!canSeeCandidates) {
    cannot('read', 'Candidates');
    cannot('create', 'Candidates');
  }

  if (!canSeeVacations) {
    cannot('read', 'Vacations');
  }

  if (!canSeeVacationsSettings) {
    cannot('read', 'VacationsSettings');
  }

  if (!canSeeSmevErrors) {
    cannot('read', 'SmevErrors');
  }

  if (!canSeePayslips) {
    cannot('read', 'Payslips');
  }

  if (role === UserRoleEnum.Company) {
    cannot('read', 'Personal');
  }

  if (canSeeSettings || canSeeAttorneys || canSeeVacationsSettings) {
    can('read', 'Settings');

    if (!canSeeSettings) {
      cannot('read', 'SettingsAdmin');
    }

    if (!canSeeAttorneys) {
      cannot('read', 'Attorneys');
    }

    if (!canSeeVacationsSettings) {
      cannot('read', 'VacationsSettings');
    }
  } else {
    cannot('read', 'Settings');
    cannot('read', 'CompanyProfiles');
    cannot('read', 'SettingsAdmin');
    cannot('read', 'Attorneys');
    cannot('read', 'SettingsAdmin');
    cannot('read', 'VacationsSettings');
  }

  if (!canSeeCompanyProfiles) {
    cannot('read', 'CompanyProfiles');
  }

  if (!canSeeAbsences) {
    cannot('read', 'Absences');
  }

  if (!canSeeCompetencies) {
    cannot('read', 'Competencies');
  }

  if (!canSeePersonalProfile) {
    cannot('read', 'Personal');
  }

  if (window.REACT_APP_VKHRTEK_LK_DISABLED && !userRepresentative) {
    cannot('manage', 'all');
    cannot('read', 'Settings');
  }

  return build();
}
