import React, { useEffect } from 'react';

import { useDispatch, useSelector } from '@vk-hr-tek/app/app/hooks';
import { Box } from '@vk-hr-tek/ui/Box';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks';
import { Paper } from '@vk-hr-tek/ui/Paper';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { ColoredChip } from '@vk-hr-tek/ui/ColoredChip';

import {
  generate1CToken,
  get1CTokenStatus,
  reset1CToken,
  selectToken1CExpiresAt,
  selectToken1CExpiresAtStatus,
  selectToken1CStatus,
} from '../../../slice';

import { TokenGenerationContent } from './TokenGenerationContent';

export const TokenGeneration = () => {
  const dispatch = useDispatch();
  const isDesktop = useIsDesktop();

  const token1CStatus = useSelector(selectToken1CStatus);
  const token1CExpiresAt = useSelector(selectToken1CExpiresAt);
  const token1CExpiresAtStatus = useSelector(selectToken1CExpiresAtStatus);

  const textVariant = isDesktop ? 'body2' : 'body3';
  const isRenderTokenStatusLabel =
    token1CExpiresAtStatus === 'expired' || token1CExpiresAtStatus === 'active';
  const token1CStatusLabelColor =
    token1CExpiresAtStatus === 'expired' ? 'red' : 'gray';

  const handleClick = () => {
    if (token1CStatus !== 'loading') {
      dispatch(generate1CToken());
    }
  };

  const setTokenColorChipLabel = (status: string) => {
    switch (status) {
      case 'active':
        return `Последний день работы: ${token1CExpiresAt}`;

      case 'expired':
        return 'Истек срок годности';

      default:
        return '';
    }
  };

  useEffect(() => {
    if (token1CStatus !== 'loading') dispatch(get1CTokenStatus());
  }, [dispatch, token1CStatus]);

  useEffect(() => {
    return () => {
      dispatch(reset1CToken());
    };
  }, [dispatch]);

  return (
    <Box
      gap="16"
      display="flex"
      flexDirection="column"
      radius={isDesktop ? 'l' : '0'}
      border={isDesktop ? '1px solid' : 0}
      borderColor="stroke.primary"
      bgcolor="bg.light.primary"
      paddingX="24"
      paddingY="20"
    >
      <Box display="flex" flexDirection="column" gap={isDesktop ? '16' : '8'}>
        <Box display="flex" flexDirection="column" gap="16">
          <Typography variant={isDesktop ? 'h6' : 'subtitle1'}>
            Токен доступа для 1С
          </Typography>
          {isRenderTokenStatusLabel && (
            <Box>
              <ColoredChip
                label={setTokenColorChipLabel(token1CExpiresAtStatus)}
                color={token1CStatusLabelColor}
              />
            </Box>
          )}
        </Box>
        <Typography variant={textVariant} color="text.light.tertirary">
          Для работы в модуле 1С сгенерируйте токен. Срок действия токена —
          90 дней
        </Typography>
      </Box>
      <Paper>
        <Box p={isDesktop ? '20' : '16'}>
          <TokenGenerationContent handleClick={handleClick} />

          {token1CStatus === 'failed' && (
            <Box mt="8">
              <Typography color="accent.text.error" variant="body3">
                Во время генерации токена произошла ошибка. Попробуйте еще раз.
              </Typography>
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
};
