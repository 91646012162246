import { createEntityAdapter, EntityState } from '@reduxjs/toolkit';

import { AppError } from '@vk-hr-tek/core/error';
import { Filter } from '@vk-hr-tek/core/filter';

import { FilterPreset } from '@app/gen/tools';
import { RootState } from '@app/store';

export interface FiltersPresetState {
  status: 'idle' | 'loading' | 'complete' | 'failed';
  error: AppError | null;
  items: FilterPreset[];
  currentList:
    | 'events'
    | 'schedule_events'
    | 'absence_events'
    | 'employees'
    | 'company_policies'
    | null;
  filterPreset: {
    availableFilters: {
      filters: Filter[];
      status: 'idle' | 'loading' | 'complete' | 'failed';
      error: AppError | null;
    };
    initialValues: {
      values: FilterPreset | null;
      status: 'idle' | 'loading' | 'complete' | 'failed';
      error: AppError | null;
    };
  };
}

export interface FiltersPresetWithRootState extends RootState {
  filtersPreset: FiltersPresetState & EntityState<unknown>;
}

export const initialState: FiltersPresetState = {
  status: 'idle',
  error: null,
  items: [],
  currentList: null,
  filterPreset: {
    availableFilters: {
      status: 'idle',
      error: null,
      filters: [],
    },
    initialValues: {
      status: 'idle',
      error: null,
      values: null,
    },
  },
};

export const filtersPresetAdapter = createEntityAdapter();
