import React, { ReactNode } from 'react';

import FormControl from '@material-ui/core/FormControl';
import classNames from 'classnames';

import { Typography } from '../../Typography';
import { Box } from '../../Box';
import { RadioInput } from '..';
import { Label } from '../common';

import useStyles from './MultipleRadioInput.styles';

interface MultipleTextInputProps {
  name: string;
  groupValue: 'all' | 'none' | 'never' | 'hand';
  values: {
    id: string | number;
    value: 'always' | 'direct_only' | 'never';
  }[];
  label: string;
  options: { label: string; id: string | number }[];
  trueLabel: string;
  falseLabel: string;
  neverLabel?: string;
  allTrueLabel?: string;
  allFalseLabel?: string;
  allNeverLabel?: string;
  allSplitLabel?: string;
  onChangeForAllElements: (optionValue: string | number) => void;
  onChangeForElement: (
    id: string | number,
  ) => (optionValue: string | number) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  tooltip?: ReactNode | null;
  required?: boolean;
  disabled?: boolean;
}

/**
 * Компонент MultipleRadioInput представляет собой настраиваемую группу радиокнопок, которая позволяет использовать несколько радиокнопок.
 * Он поддерживает необязательные метки, всплывающие подсказки, обработку ошибок и отключение состояния.
 *
 * @component
 * @param {MultipleRadioInputProps} props - Свойства для компонента.
 * @param {string} props.name - Атрибут name для полей радиокнопок.
 * @param {('all' | 'none' | 'never' | 'hand')} props.groupValue - Текущее значение группы радиокнопок.
 * @param {{ id: string | number; value: 'always' | 'direct_only' | 'never' }[]} props.values - Массив текущих значений радиокнопок.
 * @param {string} props.label - Метка для группы радиокнопок.
 * @param {{ label: string; id: string | number }[]} props.options - Массив опций для отдельных радиокнопок.
 * @param {string} props.trueLabel - Метка для опции 'true' в отдельных радиокнопках.
 * @param {string} props.falseLabel - Метка для опции 'false' в отдельных радиокнопках.
 * @param {string} [props.neverLabel] - Необязательная метка для опции 'never' в отдельных радиокнопках.
 * @param {string} [props.allTrueLabel=`Везде ${props.trueLabel.toLowerCase()}`] - Метка для опции 'all' в групповой радиокнопке.
 * @param {string} [props.allFalseLabel=`Везде ${props.falseLabel.toLowerCase()}`] - Метка для опции 'none' в групповой радиокнопке.
 * @param {string} [props.allNeverLabel=`Не согласует ${props.neverLabel?.toLowerCase()}`] - Метка для опции 'never' в групповой радиокнопке.
 * @param {string} [props.allSplitLabel='Ручной выбор'] - Метка для опции 'hand' в групповой радиокнопке.
 * @param {(optionValue: string | number) => void} props.onChangeForAllElements - Функция обратного вызова, которая вызывается при изменении значения групповой радиокнопки.
 * @param {(id: string | number) => (optionValue: string | number) => void} props.onChangeForElement - Функция обратного вызова, которая вызывается при изменении значения отдельной радиокнопки.
 * @param {() => void} [props.onFocus=() => {}] - Функция обратного вызова, которая вызывается, когда радиокнопка получает фокус.
 * @param {() => void} [props.onBlur=() => {}] - Функция обратного вызова, которая вызывается, когда радиокнопка теряет фокус.
 * @param {ReactNode | null} [props.tooltip=null] - Необязательная всплывающая подсказка для отображения с меткой.
 * @param {boolean} [props.required=false] - Указывает, является ли группа радиокнопок обязательной.
 * @param {boolean} [props.disabled=false] - Указывает, отключена ли группа радиокнопок.
 * @returns {React.ReactElement} Отрендеренный компонент MultipleRadioInput.
 */

export const MultipleRadioInput = ({
  disabled = false,
  values,
  label,
  tooltip,
  groupValue,
  options,
  trueLabel,
  falseLabel,
  neverLabel,
  allTrueLabel = `Везде ${trueLabel.toLowerCase()}`,
  allFalseLabel = `Везде ${falseLabel.toLowerCase()}`,
  allNeverLabel = `Не согласует ${neverLabel?.toLowerCase()}`,
  allSplitLabel = 'Ручной выбор',
  onChangeForAllElements,
  onChangeForElement,
  required = false,
  ...rest
}: MultipleTextInputProps) => {
  const classes = useStyles();

  return (
    <FormControl
      className={classNames(
        classes.multipleRadioInput,
        'aqa_multiple_radion_input',
      )}
    >
      {label && <Label label={label} tooltip={tooltip} required={required} />}

      <Box pb="20">
        <RadioInput
          {...rest}
          row
          value={groupValue}
          disabled={disabled}
          items={[
            { label: allTrueLabel, value: 'all' },
            { label: allFalseLabel, value: 'none' },
            ...(neverLabel ? [{ label: allNeverLabel, value: 'never' }] : []),
            { label: allSplitLabel, value: 'hand' },
          ]}
          onChange={onChangeForAllElements}
        />
      </Box>
      <Box border={1} borderColor="stroke.primary" radius="l" px="16">
        {options.map(({ id, label: optionLabel }, index) => (
          <Box
            py="8"
            borderTop={0}
            borderRight={0}
            borderLeft={0}
            border={index === options.length - 1 ? 0 : 1}
            key={id}
            borderColor="stroke.primary"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap="24"
          >
            <Typography variant="body2">{optionLabel}</Typography>
            <Box flexShrink={0} py="8">
              <RadioInput
                {...rest}
                row
                disabled={disabled}
                value={String(
                  values
                    ? values.find((opt) => opt.id === id)?.value || false
                    : false,
                )}
                items={[
                  { label: trueLabel, value: 'always' },
                  { label: falseLabel, value: 'direct_only' },
                  ...(neverLabel
                    ? [{ label: neverLabel, value: 'never' }]
                    : []),
                ]}
                onChange={onChangeForElement(id)}
              />
            </Box>
          </Box>
        ))}
      </Box>
    </FormControl>
  );
};
