import React, {
  FocusEventHandler,
  ReactNode,
  useState,
  useCallback,
  KeyboardEventHandler,
  ChangeEvent,
  useEffect,
} from 'react';

import InputMask from 'react-input-mask';
import classNames from 'classnames';
import { TextField, FormControl } from '@material-ui/core';

import { Logger } from '@vk-hr-tek/core/logger';
import { useInject } from '@vk-hr-tek/core/ioc';

import { Label, Preloader, ClearButton } from '../common';

import { ShowPasswordButton } from './ShowPasswordButton';
import useStyles from './TextInput.styles';

const handleOnWheel: EventListener = (e) => e.preventDefault();

const formatChars = {
  '9': '[0-9]',
  a: '[A-Za-z]',
  '*': '[A-Za-z0-9А-Яа-я]',
  я: '[А-Яа-я]',
};

interface TextInputProps {
  id?: string;
  name?: string;
  type?: string;
  label?: string;
  value?: string | undefined;
  placeholder?: string;
  onChange: (value: string | undefined) => void;
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onKeyPress?: KeyboardEventHandler<HTMLInputElement>;
  tooltip?: ReactNode | null;
  mask?: string;
  error?: boolean;
  helperText?: string;
  required?: boolean;
  disabled?: boolean;
  loading?: boolean;
  multiline?: boolean;
  clearable?: boolean;
  rows?: number;
  autocomplete?: string;
  inputMode?:
    | 'search'
    | 'text'
    | 'none'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal';
  testId?: string;
}

/**
 * TextInput - это универсальный компонент поля ввода, который поддерживает различные типы
 * ввода, включая маскированный ввод, переключение видимости пароля, состояние загрузки
 * и возможность очистки ввода.
 *
 * @param {Object} props - Свойства компонента.
 * @param {string} [props.id] - Атрибут ID для поля ввода.
 * @param {string} [props.name] - Атрибут name для поля ввода.
 * @param {string} [props.type='text'] - Тип поля ввода (например, 'text', 'password').
 * @param {string} [props.label] - Текст метки для поля ввода.
 * @param {string | undefined} [props.value] - Текущее значение поля ввода.
 * @param {string} [props.placeholder] - Текст плейсхолдера для поля ввода.
 * @param {(value: string | undefined) => void} props.onChange - Функция обратного вызова для обработки изменений значения ввода.
 * @param {FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>} [props.onFocus=() => {}] - Функция обратного вызова для обработки событий фокуса.
 * @param {FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>} [props.onBlur=() => {}] - Функция обратного вызова для обработки событий потери фокуса.
 * @param {KeyboardEventHandler<HTMLInputElement>} [props.onKeyPress=() => {}] - Функция обратного вызова для обработки событий нажатия клавиш.
 * @param {ReactNode | null} [props.tooltip=null] - Содержимое всплывающей подсказки, отображаемой с меткой.
 * @param {string} [props.mask] - Шаблон маски для поля ввода.
 * @param {boolean} [props.error=false] - Если true, поле ввода будет отмечено как имеющее ошибку.
 * @param {string} [props.helperText=''] - Вспомогательный текст, отображаемый под полем ввода.
 * @param {boolean} [props.required=false] - Если true, поле ввода будет отмечено как обязательное.
 * @param {boolean} [props.disabled=false] - Если true, компонент будет отключен.
 * @param {boolean} [props.loading=false] - Если true, компонент будет показывать индикатор загрузки.
 * @param {boolean} [props.multiline=false] - Если true, поле ввода будет текстовой областью с несколькими строками.
 * @param {boolean} [props.clearable=false] - Если true, кнопка очистки будет отображаться, когда поле ввода имеет значение.
 * @param {number} [props.rows=1] - Количество строк для текстовой области с несколькими строками.
 * @param {string} [props.autocomplete] - Атрибут autocomplete для поля ввода.
 * @param {string} [props.inputMode] - Режим ввода для поля ввода (например, 'search', 'numeric').
 * @param {string} [props.testId='test-id-tree-view-autocomplete'] - Тестовый ID для компонента.
 * @returns {JSX.Element} - JSX компонента.
 */

export const TextInput = ({
  id,
  type = 'text',
  label,
  value,
  placeholder,
  onChange,
  onFocus = () => {},
  onBlur = () => {},
  onKeyPress = () => {},
  tooltip = null,
  mask,
  error = false,
  helperText = '',
  required = false,
  disabled = false,
  loading = false,
  multiline = false,
  clearable = false,
  testId,
  rows = 1,
  inputMode,
  autocomplete,
  ...rest
}: TextInputProps) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const logger = useInject(Logger);

  const handleToggleShowPassword = useCallback(() => {
    setShowPassword((prev) => !prev);
  }, []);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value),
    [onChange],
  );

  useEffect(() => {
    if (
      'OTPCredential' in window &&
      'navigator' in window &&
      'credentials' in window.navigator &&
      autocomplete === 'one-time-code'
    ) {
      const ac = new AbortController();
      window?.navigator?.credentials
        ?.get({
          // eslint-disable-next-line
          // @ts-ignore
          otp: { transport: ['sms'] },
          signal: ac.signal,
        })
        .then((otp) => {
          // eslint-disable-next-line
          // @ts-ignore;
          Promise.resolve(otp?.code).then((code) => {
            onChange(code);
          });
        })
        .catch((e) => {
          logger.error(e, {
            tags: {
              vkdoc_error_type: 'webotp',
            },
          });
        });
      return () => {
        ac.abort();
      };
    }
  }, [autocomplete, logger, onChange]);

  const buttons =
    type === 'password' ? (
      <ShowPasswordButton
        isShow={showPassword}
        disabled={disabled}
        error={error}
        onClick={handleToggleShowPassword}
      />
    ) : (
      !multiline &&
      !disabled &&
      clearable &&
      value && (
        <ClearButton
          className={classes.clearButton}
          onClick={() => onChange('')}
          color={error ? 'error' : 'disabled'}
        />
      )
    );

  return (
    <FormControl className={classNames(classes.textInput, 'aqa_text_input')}>
      {label && <Label label={label} required={required} tooltip={tooltip} />}

      {mask ? (
        <InputMask
          {...rest}
          {...{ formatChars }}
          mask={mask}
          value={value}
          onChange={handleChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          disabled={disabled || loading}
        >
          {() => (
            <TextField
              name={(rest as any).name}
              autoComplete={autocomplete || 'off'}
              inputMode={inputMode}
              data-testid={testId}
              classes={{ root: classes.input }}
              variant="outlined"
              fullWidth
              placeholder={placeholder || label}
              error={error}
              helperText={helperText}
              disabled={disabled || loading}
              InputProps={{
                id: id,
                className: 'textFieldInput aqa_input',
              }}
            />
          )}
        </InputMask>
      ) : (
        <TextField
          {...rest}
          autoComplete={autocomplete || 'off'}
          data-testid={testId}
          classes={{ root: classes.input }}
          type={showPassword ? 'text' : type}
          inputMode={inputMode}
          value={
            loading && (type !== 'password' || showPassword)
              ? 'Загрузка...'
              : value
          }
          onChange={handleChange}
          onFocus={(event) => {
            onFocus(event);
            event.target.addEventListener('wheel', handleOnWheel);
          }}
          onBlur={(event) => {
            onBlur(event);
            event.target.removeEventListener('wheel', handleOnWheel);
          }}
          onKeyPress={onKeyPress}
          variant="outlined"
          fullWidth
          placeholder={placeholder || label}
          error={error}
          helperText={helperText}
          disabled={disabled || loading}
          multiline={multiline}
          minRows={rows}
          InputProps={{
            id: id,
            className: 'textFieldInput aqa_input',
            classes: { adornedEnd: classes.adornedEnd },
            endAdornment: loading ? <Preloader /> : buttons,
          }}
        />
      )}
    </FormControl>
  );
};
