import React from 'react';

import { Box } from '@vk-hr-tek/ui/Box';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { ColoredChip } from '@vk-hr-tek/ui/ColoredChip';
import { Button } from '@vk-hr-tek/ui/Button';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks';

interface UnitInfoProps {
  unit: {
    id: string;
    name: string;
  };
  roleInUnit: {
    label: string;
    role: 'manager' | 'assistant';
  };
  redirectToApprovalSettings: () => void;
}

export const UnitInfo = ({
  unit,
  roleInUnit,
  redirectToApprovalSettings,
}: UnitInfoProps) => {
  const isDesktop = useIsDesktop();

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box
        display="flex"
        alignItems="center"
        gap="12"
        justifyContent={isDesktop ? '' : 'space-between'}
        width="100%"
      >
        <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>
          {unit.name}
        </Typography>
        <Box alignSelf={isDesktop ? '' : 'flex-start'}>
          <ColoredChip
            label={roleInUnit.label}
            color="gray"
            size="extraSmall"
          />
        </Box>
      </Box>
      {isDesktop && roleInUnit.role === 'manager' && (
        <Button
          onClick={redirectToApprovalSettings}
          variant="primary"
          size="small"
        >
          Редактировать
        </Button>
      )}
    </Box>
  );
};
