import React, { useCallback } from 'react';

import { MonthView } from 'react-calendar';
import { eachMonthOfInterval, max, min, subMonths, addMonths } from 'date-fns';
import { isEqual, omit } from 'lodash';

import { Calendar } from '@vk-hr-tek/core/calendar';
import { useInject } from '@vk-hr-tek/core/ioc';

import { Tooltip } from '../../../../Tooltip';
import { DropdownButton } from '../../../../DropdownButton';
import { MenuItem } from '../../../../MenuItem';
import { Typography } from '../../../../Typography';
import { Link } from '../../../../Link';
import { Box } from '../../../../Box';
import { BasketIcon } from '../../../../icons';
import { useIsDesktop } from '../../../../hooks';

import useStyles from './Month.styles';

type Value = [Date, Date];

/**
 * Интерфейс, определяющий пропсы для компонента Month.
 */
interface MonthProps {
  /**
   * Выбранный год для отображения в календаре.
   * @type {number}
   */
  year: number;

  /**
   * Выбранный месяц для отображения в календаре (0-11).
   * @type {number}
   */
  month: number;

  /**
   * Массив выбранных диапазонов дат.
   * @type {Value[] | undefined}
   */
  value?: Value[];

  /**
   * Callback-функция для обработки изменения даты.
   * @param {Date} date - Новая выбранная дата.
   * @returns {void}
   */
  onChange: (date: Date) => void;

  /**
   * Минимально выбираемая дата.
   * @type {Date}
   */
  minDate: Date;

  /**
   * Максимально выбираемая дата.
   * @type {Date}
   */
  maxDate: Date;

  /**
   * Дополнительная функция для создания текста всплывающей подсказки для заданной даты.
   * @param {Date} date - Дата, для которой нужно создать текст всплывающей подсказки.
   * @returns {string}
   */
  createTooltipText?: (date: Date) => string;

  /**
   * Дополнительная функция для определения, должна ли дата быть отключена.
   * @param {Date} date - Дата для проверки.
   * @param {Date | null} rangeStart - Начальная дата текущего диапазона.
   * @returns {boolean}
   */
  shouldDisableDate?: (date: Date, rangeStart: Date | null) => boolean;

  /**
   * Callback-функция для обработки удаления даты.
   * @param {Date} date - Дата для удаления.
   * @returns {void}
   */
  onDelete: (date: Date) => void;

  /**
   * Дополнительная callback-функция для обработки клика по диапазону.
   * @param {Value} value - Нажатый диапазон дат.
   * @returns {void}
   */
  onRangeClick?: (value: Value) => void;

  /**
   * Массив праздничных дат.
   * @type {Date[] | undefined}
   */
  holidays?: Date[];

  /**
   * Текущая дата, на которую наведён курсор.
   * @type {Date | null}
   */
  hoverDate: Date | null;

  /**
   * Функция для установки даты, на которую наведён курсор.
   * @param {Date | null} date - Новая дата, на которую наведён курсор.
   * @returns {void}
   */
  setHoverDate: (date: Date | null) => void;

  /**
   * Текущий диапазон дат, или массив из двух null.
   * @type {Value | [null, null]}
   */
  range: Value | [null, null];

  /**
   * Если true, компонент находится в режиме только для чтения.
   * @type {boolean | undefined}
   */
  readonly?: boolean;

  /**
   * Если true, компонент отключён.
   * @type {boolean | undefined}
   */
  disabled?: boolean;

  /**
   * Если true, компонент позволяет очистку выбранных дат.
   * @type {boolean | undefined}
   */
  clearable?: boolean;

  /**
   * Дополнительная callback-функция для обработки очистки выбранных дат.
   * @returns {void}
   */
  onClear?: () => void;

  /**
   * Массив удалённых диапазонов дат.
   * @type {Value[] | undefined}
   */
  deletedRanges?: Value[];
}

/**
 * Компонент Month, закэшированный с помощью React.memo, для отображения месяца в календаре.
 * @param {MonthProps} props - Свойства, переданные в компонент Month.
 * @returns {JSX.Element} - Отрисованный компонент Month.
 */

export const Month = React.memo(
  ({
    year,
    month,
    value,
    onChange,
    minDate,
    maxDate,
    createTooltipText,
    shouldDisableDate,
    onDelete,
    holidays,
    hoverDate,
    setHoverDate,
    range,
    readonly,
    disabled,
    onRangeClick,
    clearable,
    onClear,
    deletedRanges,
  }: MonthProps) => {
    const classes = useStyles();
    const calendar = useInject(Calendar);
    const isDesktop = useIsDesktop();

    const activeStartDate = new Date(year, month);
    const monthName = activeStartDate.toLocaleString('ru-RU', {
      month: 'long',
    });
    const formattedMonthName = monthName.replace(
      monthName[0],
      monthName[0].toUpperCase(),
    );

    const handleClear = useCallback(() => {
      if (onClear) {
        onClear();
      }
    }, [onClear]);

    const createTileContent = useCallback(
      ({ date }) => {
        const clickedRange = value?.find(
          (selectedRange) =>
            date >= selectedRange[0] && date <= selectedRange[1],
        );

        const handleRangeClick = () => {
          if (onRangeClick && clickedRange) {
            onRangeClick(clickedRange);
          }
        };

        const onDateClick = () => {
          if (!range[0]) {
            setHoverDate(date);
          }
        };

        const onMouseEnter = () => {
          if (range[0]) {
            setHoverDate(date);
          }
        };

        const onMouseLeave = () => {
          if (
            date &&
            !range[0] &&
            hoverDate &&
            calendar.isSameDay(date, hoverDate)
          ) {
            setHoverDate(null);
          }
        };

        const tooltipText = createTooltipText ? createTooltipText(date) : '';

        if (!!clickedRange && onRangeClick) {
          return (
            <Tooltip
              placement="top"
              title={tooltipText}
              disabled={!tooltipText}
            >
              <span>
                <DropdownButton
                  variant="quaternary"
                  verticalPosition="bottom"
                  horizontalPosition="right"
                  button={({ onClick }) => (
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: 32,
                        height: 32,
                      }}
                      onClick={(e) => {
                        onClick(e);
                        handleRangeClick();
                      }}
                      onMouseEnter={onMouseEnter}
                      onMouseLeave={onMouseLeave}
                    />
                  )}
                  menuItems={[
                    <MenuItem key="1" onClick={() => onDelete(date)}>
                      {isDesktop ? (
                        'Удалить диапазон'
                      ) : (
                        <Box display="flex" alignItems="center" gap="8">
                          <BasketIcon color="action" size="small" />
                          <Box>Удалить диапазон</Box>
                        </Box>
                      )}
                    </MenuItem>,
                  ]}
                />
              </span>
            </Tooltip>
          );
        }

        return (
          <Tooltip placement="top" title={tooltipText} disabled={!tooltipText}>
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: 32,
                height: 32,
              }}
              onClick={onDateClick}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            />
          </Tooltip>
        );
      },
      [
        isDesktop,
        calendar,
        createTooltipText,
        hoverDate,
        range,
        onRangeClick,
        setHoverDate,
        onDelete,
        value,
      ],
    );

    const createTileClassName = useCallback(
      ({ date }) => {
        let classname = `${classes.day}`;

        const isInRange =
          !!date &&
          !!range[0] &&
          !!hoverDate &&
          ((date >= range[0] && date <= hoverDate) ||
            (date >= hoverDate && date <= range[0]));
        const isRangeStart =
          isInRange &&
          date &&
          range[0] &&
          hoverDate &&
          ((calendar.isSameDay(date, range[0]) && hoverDate >= range[0]) ||
            (calendar.isSameDay(date, hoverDate) && hoverDate < range[0]));

        if (isInRange) {
          classname = `${classname} ${classes.selectedRangeDay}`;
        }

        if (isRangeStart) {
          classname = `${classname} ${classes.rangeStart}`;
        }

        if (!(shouldDisableDate ? shouldDisableDate(date, range[0]) : false)) {
          const isRangeEnd =
            isInRange &&
            date &&
            range[0] &&
            hoverDate &&
            ((calendar.isSameDay(date, hoverDate) && range[0] <= hoverDate) ||
              (calendar.isSameDay(date, range[0]) && range[0] > hoverDate));

          if (isRangeEnd) {
            classname = `${classname} ${classes.rangeEnd}`;
          }

          value?.forEach((selectedRange) => {
            if (
              !isInRange &&
              date >= selectedRange[0] &&
              date <= selectedRange[1]
            ) {
              classname = `${classname} ${classes.selectedRangeDay}`;

              if (calendar.isSameDay(date, selectedRange[0])) {
                classname = `${classname} ${classes.selectedRangeDayStart}`;
              }

              if (calendar.isSameDay(date, selectedRange[1])) {
                classname = `${classname} ${classes.selectedRangeDayEnd}`;
              }
            }
          });
        }

        holidays?.forEach((holiday) => {
          if (calendar.isSameDay(date, holiday)) {
            classname = `${classname} ${classes.holiday}`;
          }
        });

        deletedRanges?.forEach((deleted) => {
          if (date >= deleted[0] && date <= deleted[1] && !range[0]) {
            classname = `${classname} ${classes.deletedRangeDay}`;

            if (calendar.isSameDay(date, deleted[0]) && !range[0]) {
              classname = `${classname} ${classes.deletedRangeDayStart}`;
            }

            if (calendar.isSameDay(date, deleted[1]) && !range[0]) {
              classname = `${classname} ${classes.deletedRangeDayEnd}`;
            }
          }
        });

        if (readonly) {
          classname = `${classname} ${classes.readOnly}`;
        }

        return classname;
      },
      [
        calendar,
        classes.day,
        classes.holiday,
        classes.rangeEnd,
        classes.rangeStart,
        classes.selectedRangeDay,
        classes.selectedRangeDayEnd,
        classes.selectedRangeDayStart,
        classes.readOnly,
        classes.deletedRangeDay,
        classes.deletedRangeDayStart,
        classes.deletedRangeDayEnd,
        holidays,
        hoverDate,
        range,
        shouldDisableDate,
        value,
        readonly,
        deletedRanges,
      ],
    );

    return (
      <div>
        <div className={classes.monthName}>
          <Typography variant="subtitle2">{formattedMonthName}</Typography>
          {clearable && (
            <Link stroke={false} onClick={handleClear}>
              Сбросить
            </Link>
          )}
        </div>
        <div className={classes.root}>
          <MonthView
            showFixedNumberOfWeeks
            showNeighboringMonth
            valueType="day"
            activeStartDate={activeStartDate}
            value={range}
            locale="ru"
            onClick={onChange}
            minDate={minDate}
            maxDate={maxDate}
            tileClassName={createTileClassName}
            tileContent={createTileContent}
            tileDisabled={({ date }) => {
              if (disabled) {
                return value ? !calendar.isWithinIntervals(date, value) : true;
              }

              return shouldDisableDate
                ? shouldDisableDate(date, range[0])
                : false;
            }}
          />
        </div>
      </div>
    );
  },
  (prev, next) => {
    return (
      isEqual(
        omit(prev, 'hoverDate', 'range'),
        omit(next, 'hoverDate', 'range'),
      ) &&
      !(
        !prev.hoverDate ||
        !prev.range[0] ||
        eachMonthOfInterval({
          start: subMonths(
            min(
              [
                prev.hoverDate,
                next.hoverDate,
                prev.range[0],
                next.range[0],
              ].filter((date: Date | null): date is Date => !!date),
            ),
            1,
          ),
          end: addMonths(
            max(
              [
                prev.hoverDate,
                next.hoverDate,
                prev.range[0],
                next.range[0],
              ].filter((date: Date | null): date is Date => !!date),
            ),
            1,
          ),
        })
          .map((date) => date.getMonth())
          .includes(next.month)
      )
    );
  },
);
