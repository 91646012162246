import { IsString, IsArray, IsNotEmpty } from 'class-validator';

import { FilterPreset } from '@app/gen/tools';

export class DeleteFiltersPresetsDto {
  @IsString({ each: true })
  @IsArray()
  @IsNotEmpty()
  filtersPresets: FilterPreset[];
}
