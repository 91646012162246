import React from 'react';

import { useIsDesktop } from '../../hooks';
import { Button } from '../../Button';

/**
 * Компонент кнопки для формы.
 * @param {React.ReactNode} children - Дочерние элементы, отображаемые внутри кнопки.
 * @param {boolean} [disabled=false] - Флаг, указывающий, отключена ли кнопка.
 * @param {boolean} [loading=false] - Флаг, указывающий, находится ли кнопка в состоянии загрузки.
 */
interface FormButtonProps {
  children: React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
}

/**
 * Компонент кнопки для формы.
 * @param {FormButtonProps} props - Свойства компонента.
 * @returns {React.ReactElement} - Элемент кнопки.
 */
export const FormButton = ({
  children,
  disabled = false,
  loading = false,
}: FormButtonProps) => {
  const isDesktop = useIsDesktop();

  return (
    <Button
      className="qaSubmitButton"
      disabled={disabled}
      fullWidth
      loading={loading}
      size={isDesktop ? 'large' : 'medium'}
      type="submit"
    >
      {children}
    </Button>
  );
};
