import { useTheme } from '@material-ui/core';

import { useMatchQuery } from './useMatchQuery';

/**
 * Пользовательский хук, который определяет, является ли текущий размер экрана-extra большим настольным.
 *
 * @returns {boolean} - Возвращает true, если размер экрана 'xl' или больше, иначе false.
 */

export const useIsDesktopExtraLarge = () => {
  const theme = useTheme();

  return useMatchQuery(theme.breakpoints.up('xl'));
};
