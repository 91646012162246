import { injectable } from 'inversify';

import { HttpService } from '@vk-hr-tek/core/http';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { UnmarshallerService } from '@vk-hr-tek/core/unmarshaller';
import { FilterService } from '@vk-hr-tek/core/filter';
import { Calendar } from '@vk-hr-tek/core/calendar';

import { FilterPreset, ListFilterPresetsResponse } from '@app/gen/tools';

import {
  GetFiltersPresetDto,
  CreateFiltersPresetDto,
  UpdateFiltersPresetDto,
} from '../dto';

@injectable()
export class FiltersPresetService {
  constructor(
    private validator: ValidationService,
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
    private filterService: FilterService,
    private calendar: Calendar,
  ) {}

  async getFiltersPresets(dto: GetFiltersPresetDto) {
    await this.validator.validateOrReject(dto, GetFiltersPresetDto);

    const result = await this.unmarshaller.unmarshall(
      await this.http.get(
        `/filter_preset`,
        {
          list_code: dto.listCode,
        },
        { withSide: true },
      ),
      ListFilterPresetsResponse,
    );

    return result.presets || [];
  }

  async createFilterPreset(dto: CreateFiltersPresetDto) {
    await this.validator.validateOrReject(dto, CreateFiltersPresetDto);

    for (const filter in dto.filters.filters) {
      const filterValue = dto.filters.filters[filter];
      this.filterService.sanitizeDates(filterValue);
    }

    const result = await this.unmarshaller.unmarshall(
      await this.http.post('/filter_preset', dto, { withSide: true }),
      FilterPreset,
    );

    return result;
  }

  async updateFilterPreset({
    values,
    filterPresetId,
  }: {
    values: CreateFiltersPresetDto;
    filterPresetId: string;
  }) {
    await this.validator.validateOrReject(values, UpdateFiltersPresetDto);

    const sanitizedFilters: Record<string, any> = {};

    for (const filter in values.filters.filters) {
      const filterValue = values.filters.filters[filter];
      sanitizedFilters[filter] = filterValue;
      if (
        typeof filterValue === 'object' &&
        (filterValue.start ?? filterValue.end)
      ) {
        sanitizedFilters[filter] = {};
        if (filterValue?.start) {
          sanitizedFilters[filter].start = this.calendar.startOfDay(
            new Date(filterValue.start),
          );
        }
        if (filterValue?.end) {
          sanitizedFilters[filter].end = this.calendar.endOfDay(
            new Date(filterValue.end),
          );
        }
      }
    }

    const result = await this.unmarshaller.unmarshall(
      await this.http.put(
        `/filter_preset/${filterPresetId}`,
        {
          ...values,
          filters: {
            ...values.filters,
            filters: sanitizedFilters,
          },
        },
        {
          withSide: true,
        },
      ),
      FilterPreset,
    );

    return result;
  }
}
