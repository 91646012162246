import React, { ReactNode } from 'react';

import { Field, FieldInputProps } from 'react-final-form';

import { RadioInputComplexLabel as Input } from '../../input';

/**
 * Функция обработчик изменения значения радиокнопки.
 * @param {FieldInputProps<string | undefined, HTMLElement>} input - Объект с инпут-пропсами из react-final-form.
 * @param {(value: string | number) => void} [onChange] - Дополнительная функция обратного вызова при изменении значения.
 * @returns {(value: string | number) => void} - Функция изменения значения, которая вызывает обновление инпута и дополнительный обработчик при его наличии.
 */
const handleChange =
  (
    input: FieldInputProps<string | undefined, HTMLElement>,
    onChange?: (value: string | number) => void,
  ) =>
  (value: string | number) => {
    input.onChange(value);

    if (onChange) {
      onChange(value);
    }
  };

/**
 * Интерфейс пропсов компонента RadioInputComplexLabel.
 * @template T - Тип метки для опций радиокнопок.
 * @property {string} name - Имя поля формы.
 * @property {(value: string | number) => void} [onChange] - Функция обратного вызова при изменении значения.
 * @property {string} [defaultValue] - Значение по умолчанию для поля.
 * @property {{ value: string | number; label: T }[]} options - Массив опций для радиокнопок.
 * @property {(item: T) => ReactNode} render - Функция рендера метки.
 * @property {(value: string | undefined) => React.ReactNode} [after] - Функция рендера дополнительного контента после радиокнопок.
 */
interface RadioInputComplexLabelProps<T> {
  name: string;
  onChange?: (value: string | number) => void;
  defaultValue?: string;
  options: { value: string | number; label: T }[];
  render: (item: T) => ReactNode;
  after?: (value: string | undefined) => React.ReactNode;
}

/**
 * Компонент радиокнопок с сложными метками.
 * @template T - Тип метки для опций радиокнопок.
 * @param {RadioInputComplexLabelProps<T>} props - Пропсы компонента.
 * @returns {React.ReactNode} - Рендерит компонент радиокнопок или null, если опций нет.
 */
export const RadioInputComplexLabel = <T,>({
  name,
  onChange,
  options,
  defaultValue,
  render,
  ...rest
}: RadioInputComplexLabelProps<T>) => {
  if (!options || !options.length) {
    return null;
  }

  return (
    <Field name={name} initialValue={defaultValue} {...rest}>
      {({ input }) => (
        <Input
          {...input}
          items={options}
          onChange={handleChange(input, onChange)}
          render={render}
        />
      )}
    </Field>
  );
};
