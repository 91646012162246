import React from 'react';

import { useTheme } from '@material-ui/core';

export const QuickActionsIcon = () => {
  const theme = useTheme();

  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 12C0 5.37258 5.37258 0 12 0H28C34.6274 0 40 5.37258 40 12V28C40 34.6274 34.6274 40 28 40H12C5.37258 40 0 34.6274 0 28V12Z"
        fill={theme.tokens.colors.bg.greyscale.primary}
      />
      <g clipPath="url(#clip0_17826_58867)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.1491 13.902C16.1491 13.4049 16.552 13.002 17.0491 13.002H26.1C26.3388 13.002 26.5677 13.0968 26.7365 13.2657C26.9053 13.4346 27.0001 13.6636 27 13.9023L26.9961 22.9025C26.9959 23.3996 26.5928 23.8023 26.0957 23.8021C25.5986 23.8019 25.1959 23.3988 25.1961 22.9017L25.1991 16.0757L14.5364 26.7383C14.1849 27.0898 13.6151 27.0898 13.2636 26.7383C12.9121 26.3869 12.9121 25.817 13.2636 25.4656L23.9272 14.802H17.0491C16.552 14.802 16.1491 14.399 16.1491 13.902Z"
          fill={theme.tokens.colors.stroke.tertirary}
        />
      </g>
      <defs>
        <clipPath id="clip0_17826_58867">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
