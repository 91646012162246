import { IsNotEmpty, IsString, IsIn } from 'class-validator';

import { FilterType } from '@vk-hr-tek/core/filter';

export class FiltersPresetDto {
  filters?: FilterType;

  @IsIn([
    'events',
    'schedule_events',
    'absence_events',
    'employees',
    'company_policies',
  ])
  @IsString()
  @IsNotEmpty()
  filter_type: string;
}
