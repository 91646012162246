import { Type } from 'class-transformer';
import {
  IsNotEmpty,
  IsOptional,
  IsString,
  IsArray,
  ArrayMinSize,
  IsUUID,
  IsDateString,
} from 'class-validator';

import type { UnitType } from '@vk-hr-tek/core/units';
import type { FileInputValue } from '@vk-hr-tek/ui/input';

export class CreateEventBatchDto {
  @IsUUID(undefined, {
    message: 'Вы указали неверную компанию',
    groups: ['default', 'child'],
  })
  @IsNotEmpty({
    message: 'Вы забыли выбрать компанию',
    groups: ['default', 'child'],
  })
  companyId: string;

  @IsUUID(undefined, {
    message: 'Вы указали неверный тип',
    groups: ['default', 'child'],
  })
  @IsNotEmpty({
    message: 'Вы забыли выбрать тип',
    groups: ['default', 'child'],
  })
  eventTypeId: string;

  @ArrayMinSize(1, {
    message: 'Вы забыли выбрать сотрудников',
    groups: ['default', 'child'],
  })
  @Type(() => String)
  @IsArray({ groups: ['default', 'child'] })
  @IsNotEmpty({
    message: 'Вы забыли выбрать сотрудников',
    groups: ['default', 'child'],
  })
  employees: string[];

  @IsUUID(undefined, {
    message: 'Вы указали неверный тип',
    groups: ['default', 'child'],
  })
  @IsOptional({ groups: ['default', 'child'] })
  eventBatchId?: string;

  @IsString({
    message: 'Вы ввели неверное название',
    groups: ['default', 'child'],
  })
  @IsNotEmpty({
    message: 'Вы забыли ввести название',
    groups: ['default', 'child'],
  })
  eventBatchName: string;

  @Type(() => String)
  @IsNotEmpty({ message: 'Вы забыли загрузить документ', groups: ['default'] })
  document: FileInputValue;

  @IsString({ groups: ['default', 'child'] })
  @IsOptional({ groups: ['default', 'child'] })
  unitType?: UnitType;

  @IsString({ groups: ['default', 'child'] })
  @IsOptional({ groups: ['default', 'child'] })
  unitId?: string;

  @IsDateString(undefined, { groups: ['default', 'child'] })
  @IsOptional({ groups: ['default', 'child'] })
  documentDate?: string;

  @IsString({ groups: ['default', 'child'] })
  @IsOptional({ groups: ['default', 'child'] })
  documentNumber?: string;

  @IsArray({ groups: ['default', 'child'] })
  @IsOptional({ groups: ['default', 'child'] })
  assigned_roles?: string[];

  @IsUUID(undefined, {
    groups: ['default', 'child'],
  })
  @IsOptional({ groups: ['default', 'child'] })
  parentEventId?: string;
}
