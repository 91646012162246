import { AppError } from '@vk-hr-tek/core/error';

import {
  UserPermissions,
  UserResponse as User,
  CompanyEventTypeItem,
} from '@app/gen/users';
import { Unit } from '@app/gen/settings';
import { CandidateItem } from '@app/gen/candidates';

export interface Employee {
  id: string;
  name: string;
  personnelNumber: string;
  email: string | undefined;
  position: string | undefined;
  phone: string | undefined;
  company: {
    id: string;
    name: string;
  };
}

export interface EventType {
  substitutes: Employee[];
  eventType: {
    id: string;
    name: string;
  };
}

export interface CompanyPositionSubstitutes {
  company: { id: string; name: string };
  employee: {
    id: string;
    personalNumber: string;
    position: string;
    showPosition: boolean;
  };
  eventTypeGroup: {
    eventType: {
      id: string;
      name: string;
    };
    substitutes: {
      id: string;
      name: string;
      personalNumber: string;
    }[];
  }[];
  manageableUnits?: {
    unit: {
      id: string;
      name: string;
    };
    allSetting?: {
      id: string;
      name: string;
    }[];
    directSetting?: {
      id: string;
      name: string;
    }[];
    roleInUnit: {
      label: string;
      role: 'manager' | 'assistant';
    };
    neverSetting?: {
      id: string;
      name: string;
    }[];
  }[];
}

export interface UserState {
  status: 'idle' | 'loading' | 'complete' | 'failed';
  data:
    | (Omit<User, 'certificate'> & {
        certificate: Omit<User['certificate'], 'status'> & {
          status:
            | 'waiting_to_create'
            | 'need_verify'
            | 'waiting_confirmation'
            | 'verifying'
            | 'released'
            | 'expired'
            | 'error'
            | 'need_verify_code'
            | 'release_paused';
        };
      })
    | null;
  error: AppError | null;
  candidateUser: {
    status: 'idle' | 'loading' | 'complete' | 'failed';
    error: AppError | null;
    entity: CandidateItem | null;
  };
  subscriptions: {
    telegram:
      | 'idle'
      | 'enablingInProgress'
      | 'disablingInProgress'
      | 'enabled'
      | 'disabled';
  };
  processes: {
    certificateCreationInit: {
      inProgress: boolean;
      error: AppError | null;
    };
    certificateCreationVerify: {
      inProgress: boolean;
      error: AppError | null;
    };
  };
  token1C: {
    status: 'idle' | 'loading' | 'complete' | 'failed';
    expiresStatus: 'idle' | 'not_issued' | 'active' | 'expired';
    expiresAt: string | null;
    value: string | null;
    error: AppError | null;
  };
  permissions: Record<
    'company' | 'employee' | 'general',
    Required<UserPermissions>
  >;
  currentRequestId: string | null;
  eventTypes: Record<
    string,
    {
      status: 'idle' | 'loading' | 'complete' | 'failed';
      types: EventType[];
      error: AppError | null;
    }
  >;
  companyEventTypes: {
    status: 'idle' | 'loading' | 'complete' | 'failed';
    items: CompanyEventTypeItem[];
    error: AppError | null;
  };

  substitutes: {
    status: 'idle' | 'loading' | 'complete' | 'failed';
    items: CompanyPositionSubstitutes[];
    error: AppError | null;
  };

  unit: {
    status: 'idle' | 'loading' | 'complete' | 'failed';
    error: AppError | null;
    entity: Unit | null;
  };
}

const permissions = {
  view_events: false,
  view_event_to_paper: false,
  view_employees: false,
  view_policies: false,
  view_company: false,
  view_company_profiles: false,
  view_settings: false,
  view_attorneys: false,
  view_candidates: false,
  view_vacations: false,
  view_vacations_settings: false,
  view_payslips: false,
  view_smev_errors: false,
  view_absences: false,
  view_personal_profile: false,
  view_competencies: false,
  view_units: false,
};

export const initialState: UserState = {
  status: 'idle',
  data: null,
  error: null,
  candidateUser: {
    status: 'idle',
    error: null,
    entity: null,
  },
  subscriptions: {
    telegram: 'idle',
  },
  processes: {
    certificateCreationInit: {
      inProgress: false,
      error: null,
    },
    certificateCreationVerify: {
      inProgress: false,
      error: null,
    },
  },
  token1C: {
    status: 'idle',
    expiresStatus: 'idle',
    expiresAt: null,
    value: null,
    error: null,
  },
  permissions: {
    employee: permissions,
    company: permissions,
    general: permissions,
  },
  currentRequestId: null,
  eventTypes: {},
  companyEventTypes: {
    status: 'idle',
    items: [],
    error: null,
  },
  substitutes: {
    status: 'idle',
    items: [],
    error: null,
  },
  unit: {
    status: 'idle',
    error: null,
    entity: null,
  },
};
