import { injectable, inject } from 'inversify';

import { History } from '@vk-hr-tek/core/history';
import { RoleService } from '@vk-hr-tek/core/http';

import { OrganizationRouter } from '../../../organization/types';
import { modules } from '../../modules';

@injectable()
export class OrganizationRouterService implements OrganizationRouter {
  constructor(
    @inject(History) private history: History,
    @inject(RoleService) private readonly role: RoleService,
  ) {}

  goCreateUnit(query: string) {
    this.history.push({
      pathname: `/${modules.organization.basename}/create-unit`,
      search: query.startsWith('?') ? query : `?${query}`,
    });
  }

  goEditUnit(query: string) {
    this.history.push({
      pathname: `/${modules.organization.basename}/edit-unit`,
      search: query.startsWith('?') ? query : `?${query}`,
    });
  }

  goToList(query?: string) {
    if (!query || query === '?') {
      this.history.push(`/${modules.organization.basename}`);
      return;
    }

    this.history.push({
      pathname: `/${modules.organization.basename}`,
      search: query.startsWith('?') ? query : `?${query}`,
    });
  }

  goToPersonalDetail(userId: string, query?: string) {
    let formattedQuery = '';

    if (query) {
      formattedQuery = query.startsWith('?')
        ? `employee_id=${query}`
        : `?employee_id=${query}`;
    }

    this.history.push({
      pathname: `/${modules.personal.basename}/${userId}`,
      search: formattedQuery,
    });
  }
}
