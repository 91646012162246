import React from 'react';

import { Help as HelpIcon } from '@material-ui/icons';

import { Box } from '@vk-hr-tek/ui/Box';
import { Tooltip } from '@vk-hr-tek/ui/Tooltip';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks';

interface CertificateInfoProps {
  certificate: {
    exists: boolean;
    type: string;
    description: string;
    activity: string;
  };
}

export const CertificateInfo = ({ certificate }: CertificateInfoProps) => {
  const isDesktop = useIsDesktop();

  const tooltipPlacement = isDesktop ? 'right' : 'bottom';

  return (
    <Box gap="8" display="flex" flexDirection="column">
      <Box gap="4" display="flex">
        <Typography variant="subtitle1" color="text.light.primary">
          {certificate.type}
        </Typography>
        <Tooltip title={certificate.description} placement={tooltipPlacement}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            cursor="pointer"
          >
            <HelpIcon color="disabled" fontSize="small" />
          </Box>
        </Tooltip>
      </Box>

      <Typography variant="body2" color="text.light.primary">
        {certificate.activity}
      </Typography>
    </Box>
  );
};
