import React from 'react';

import { useTheme } from '@material-ui/core';

export const PersonalLinksIcon = () => {
  const theme = useTheme();

  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 12C0 5.37258 5.37258 0 12 0H28C34.6274 0 40 5.37258 40 12V28C40 34.6274 34.6274 40 28 40H12C5.37258 40 0 34.6274 0 28V12Z"
        fill={theme.tokens.colors.original.brand.secondary}
      />
      <g clipPath="url(#clip0_17583_59516)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.4895 12.2932C23.2085 10.5741 25.9956 10.5741 27.7146 12.2932C29.4337 14.0122 29.4337 16.7993 27.7146 18.5184L26.6431 19.5899C26.2915 19.9415 25.7214 19.9415 25.3698 19.5899C25.0182 19.2383 25.0182 18.6682 25.3698 18.3166L26.4413 17.245C27.4571 16.2292 27.4571 14.5823 26.4413 13.5665C25.4255 12.5507 23.7786 12.5507 22.7628 13.5665L19.2258 17.1035C18.21 18.1193 18.21 19.7663 19.2258 20.782C19.5774 21.1337 19.5774 21.7038 19.2258 22.0554C18.8741 22.407 18.3041 22.407 17.9524 22.0554C16.2334 20.3363 16.2334 17.5492 17.9524 15.8302L21.4895 12.2932ZM20.782 17.9524C21.1337 17.6008 21.7038 17.6008 22.0554 17.9524C23.7744 19.6715 23.7744 22.4586 22.0554 24.1776L18.5184 27.7146C16.7993 29.4337 14.0122 29.4337 12.2932 27.7146C10.5741 25.9956 10.5741 23.2085 12.2932 21.4895L13.415 20.3677C13.7666 20.016 14.3367 20.016 14.6883 20.3677C15.0399 20.7193 15.0399 21.2894 14.6883 21.641L13.5665 22.7628C12.5507 23.7786 12.5507 25.4255 13.5665 26.4413C14.5823 27.4571 16.2292 27.4571 17.245 26.4413L20.782 22.9043C21.7978 21.8885 21.7978 20.2416 20.782 19.2258C20.4304 18.8741 20.4304 18.3041 20.782 17.9524Z"
          fill={theme.tokens.colors.original.brand.primary}
        />
      </g>
      <defs>
        <clipPath id="clip0_17583_59516">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
