import React, { useRef, useEffect, useCallback } from 'react';

import Countdown from 'react-countdown';
import classNames from 'classnames';

import { FormatService } from '@vk-hr-tek/core/format';
import { useInject } from '@vk-hr-tek/core/ioc';

import useStyles from './RedoCountdown.styles';

/**
 * A RedoCountdown component that displays a countdown timer until a specified final date.
 * It uses the `react-countdown` library to handle the countdown and provides a label with the remaining time.
 *
 * @param {RedoCountdownProps} props - The properties of the RedoCountdown component.
 * @param {string} props.label - The label text to be displayed before the countdown.
 * @param {boolean} props.disabled - A boolean indicating whether the countdown is disabled.
 * @param {number} props.finalDate - The timestamp in milliseconds until which the countdown should run.
 * @param {() => void} props.toggleTimerVisibility - A function to toggle the visibility of the countdown.
 * @returns {JSX.Element} - The rendered RedoCountdown component.
 */

interface RedoCountdownProps {
  label: string;
  disabled: boolean;
  finalDate: number;
  toggleTimerVisibility: () => void;
}

export const RedoCountdown = ({
  label,
  disabled,
  finalDate,
  toggleTimerVisibility,
}: RedoCountdownProps) => {
  const classes = useStyles();
  const format = useInject(FormatService);

  const counterRef = useRef<Countdown | null>(null);

  const pluralizeSeconds = useCallback(
    (count: number) => {
      return format.pluralize(count, ['секунда', 'секунды', 'секунд'], {
        withZeroPad: true,
      });
    },
    [format],
  );

  useEffect(() => {
    const isStarted = !!counterRef.current?.isStarted();

    let timer: ReturnType<typeof setTimeout>;
    if (!isStarted && !disabled) {
      timer = setTimeout(() => {
        counterRef.current?.start();
      });
    }

    if (isStarted && disabled) {
      counterRef.current?.pause();
    }

    return () => {
      clearTimeout(timer);
    };
  }, [disabled]);

  return (
    <>
      <div className={classes.label}>{label} через:</div>
      <Countdown
        key={finalDate}
        date={finalDate}
        autoStart={false}
        onComplete={toggleTimerVisibility}
        ref={counterRef}
        renderer={({ seconds }) => (
          <div
            className={classNames(
              classes.countdown,
              disabled && classes.countdownDisabled,
              'aqa_redo_countdown',
            )}
          >
            {pluralizeSeconds(seconds)}
          </div>
        )}
      />
    </>
  );
};
