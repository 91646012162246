import React, { ReactNode } from 'react';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import { Link } from '../Link';
import { Box } from '../Box';

import { useStyles } from './RedirectCard.styles';

export interface RedirectCardProps {
  onClick: () => void;
  fullWidth?: boolean;
  children: ReactNode;
}

/**
 * Компонент RedirectCard, который отображает кликабельную карту с необязательным стилем полной ширины.
 * Использует иконку Material-UI ArrowForwardIosIcon для указания направления перенаправления.
 *
 * @param {RedirectCardProps} props - Свойства компонента RedirectCard.
 * @param {() => void} props.onClick - Обработчик клика для карты.
 * @param {boolean} [props.fullWidth=false] - Логическое значение, указывающее, должна ли карта занимать всю ширину своего контейнера.
 * @param {ReactNode} props.children - Дочерние элементы, которые будут отображены внутри карты.
 * @returns {JSX.Element} - Отрендеренный компонент RedirectCard.
 */

export const RedirectCard = ({
  onClick,
  children,
  fullWidth = false,
}: RedirectCardProps) => {
  const classes = useStyles();

  return (
    <Link onClick={onClick} stroke={false} variant="simple">
      <Box
        className={classes.card}
        display="flex"
        alignItems="center"
        gap="8"
        p="16"
        bgcolor="bg.greyscale.primary"
        radius="l"
        width={fullWidth ? '100%' : 'auto'}
        testId="redirect-card-test-id"
      >
        {children}

        <Box display="flex" alignItems="center" ml="auto">
          <ArrowForwardIosIcon
            fontSize="small"
            color="disabled"
            data-testid="arrow-forward-ios-icon-test-id"
          />
        </Box>
      </Box>
    </Link>
  );
};
