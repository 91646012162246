import React, { useCallback, useState, useMemo } from 'react';

import { eachDayOfInterval, getMonth } from 'date-fns';

import { Calendar } from '@vk-hr-tek/core/calendar';
import { useInject } from '@vk-hr-tek/core/ioc';

import { Box } from '../../Box';

import { Month } from './Calendar';

type Value = [Date, Date];

/**
 * Интерфейс свойств компонента YearCalendarInput.
 * @interface YearCalendarInputProps
 * @property {number} [year] - Год, для которого отображается календарь. По умолчанию текущий год.
 * @property {Value[]} value - Массив выбранных диапазонов дат.
 * @property {(ranges: Value[]) => void} onChange - Функция, вызываемая при изменении выбранных диапазонов дат.
 * @property {Date} [minDate] - Минимальная дата, доступная для выбора. По умолчанию минимальная дата календаря.
 * @property {Date} [maxDate] - Максимальная дата, доступная для выбора. По умолчанию максимальная дата календаря.
 * @property {(date: Date, rangeStart: Date | null) => boolean} [shouldDisableDate] - Функция, определяющая, должна ли дата быть отключена для выбора.
 * @property {(date: Date) => string} [createTooltipText] - Функция, возвращающая текст подсказки для указанной даты.
 * @property {(selectedRange: Value) => void} [onRangeClick] - Функция, вызываемая при клике на выбранный диапазон дат.
 * @property {(index: number) => void} onDelete - Функция, вызываемая при удалении диапазона дат по индексу.
 * @property {Date[]} [holidays] - Массив праздничных дат.
 * @property {boolean} [readonly] - Флаг, указывающий, является ли компонент только для чтения.
 * @property {boolean} [disabled] - Флаг, указывающий, отключен ли компонент.
 * @property {() => void} [onNoAvailableRange] - Функция, вызываемая, если нет доступных диапазонов дат.
 * @property {Value[]} [deletedRanges] - Массив удаленных диапазонов дат.
 * @property {(value: Value | [null, null]) => void} [onSelectCurrentInterval] - Функция, вызываемая при выборе текущего интервала дат.
 */
export interface YearCalendarInputProps {
  year?: number;
  value: Value[];
  onChange: (ranges: Value[]) => void;
  minDate?: Date;
  maxDate?: Date;
  shouldDisableDate?: (date: Date, rangeStart: Date | null) => boolean;
  createTooltipText?: (date: Date) => string;
  onRangeClick?: (selectedRange: Value) => void;
  onDelete: (index: number) => void;
  holidays?: Date[];
  readonly?: boolean;
  disabled?: boolean;
  onNoAvailableRange?: () => void;
  deletedRanges?: Value[];
  onSelectCurrentInterval?: (value: Value | [null, null]) => void;
}

/**
 * Реакт-компонент, который отображает календарь для заданного года, позволяя пользователям выбирать диапазоны дат.
 * Отображается в виде сетки из 12 месяцев и предоставляет функциональность для добавления, удаления и просмотра диапазонов.
 * @param props - Свойства для компонента YearCalendarInput.
 * @returns Реакт-элемент, представляющий компонент YearCalendarInput.
 */

export const YearCalendarInput = ({
  year = new Date().getFullYear(),
  value,
  onChange,
  minDate = Calendar.minDate,
  maxDate = Calendar.maxDate,
  shouldDisableDate,
  createTooltipText,
  onRangeClick,
  holidays,
  readonly,
  disabled,
  onDelete,
  onNoAvailableRange,
  deletedRanges,
  onSelectCurrentInterval,
}: YearCalendarInputProps) => {
  const calendar = useInject(Calendar);

  const [range, setRange] = useState<Value | [null, null]>([null, null]);
  const [hoverDate, setHoverDate] = useState<Date | null>(null);
  const [hasAvailableDates, setHasAvailableDates] = useState(true);

  const handleReset = useCallback(() => {
    setRange([null, null]);
    onSelectCurrentInterval?.([null, null]);
  }, [onSelectCurrentInterval]);

  const days = useMemo(
    () =>
      eachDayOfInterval({
        start: new Date(`${year}-01-01`),
        end: new Date(`${year}-12-31`),
      }),
    [year],
  );

  const handleChange = useCallback(
    (nextValue: Date) => {
      if (readonly || disabled) return;

      if (!range[0] && !range[1]) {
        setRange([nextValue, nextValue]);
        onSelectCurrentInterval?.([nextValue, nextValue]);
        setHasAvailableDates(true);

        if (
          onNoAvailableRange &&
          shouldDisableDate &&
          days.every((day) => shouldDisableDate(day, nextValue))
        ) {
          setHasAvailableDates(false);
          onNoAvailableRange();
        }
      } else if (
        range[0] &&
        range[1] &&
        calendar.isSameDay(range[0], range[1])
      ) {
        let newValue: Value;

        if (nextValue >= range[0]) {
          newValue = [range[0], nextValue];
        } else {
          newValue = [nextValue, range[0]];
        }

        setRange([null, null]);
        onSelectCurrentInterval?.([null, null]);
        onChange([...value, newValue]);
      }
    },
    [
      readonly,
      disabled,
      range,
      calendar,
      onSelectCurrentInterval,
      onNoAvailableRange,
      shouldDisableDate,
      days,
      onChange,
      value,
    ],
  );

  const handleDelete = useCallback(
    (date: Date) => {
      onDelete(
        value.findIndex(
          (selectedRange) =>
            date >= selectedRange[0] && date <= selectedRange[1],
        ),
      );
    },
    [onDelete, value],
  );

  return (
    <Box mb="8" display="flex" justifyContent="center" flexWrap="wrap">
      {new Array(12).fill(null).map((_, index) => (
        <Month
          year={year}
          month={index}
          key={getMonth(index)}
          value={value}
          onChange={handleChange}
          minDate={minDate}
          maxDate={maxDate}
          createTooltipText={createTooltipText}
          shouldDisableDate={shouldDisableDate}
          holidays={holidays}
          onRangeClick={onRangeClick}
          hoverDate={hoverDate}
          setHoverDate={setHoverDate}
          range={range}
          readonly={readonly}
          disabled={disabled}
          onDelete={handleDelete}
          clearable={
            !hasAvailableDates && !!range[0] && range[0].getMonth() === index
          }
          onClear={handleReset}
          deletedRanges={deletedRanges}
        />
      ))}
    </Box>
  );
};
