import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { classToPlain } from 'class-transformer';
import { FORM_ERROR } from 'final-form';

import { AppError } from '@vk-hr-tek/core/error';
import { FiltersResponse } from '@vk-hr-tek/core/filter';
import {
  showCustomNotification,
  showNotification,
  showError,
} from '@vk-hr-tek/core/notifications';

import { FilterPreset, ToolsService } from '@app/gen/tools';
import { UsersService } from '@app/gen/users';

import { ThunkExtra } from '../../../app/store';
import { AvailableFiltersService, FiltersPresetService } from '../../services';
import { FiltersPresetState, FiltersPresetWithRootState } from '..';
import {
  CreateFiltersPresetDto,
  DeleteFiltersPresetsDto,
  GetFiltersPresetDto,
  UpdateFiltersPresetDto,
} from '../../dto';

export const getFiltersPresets = createAsyncThunk<
  FilterPreset[],
  GetFiltersPresetDto,
  ThunkExtra<FiltersPresetWithRootState>
>(
  'filterPresets/getFiltersPresets',
  async (dto, { rejectWithValue, getState, extra: { inject } }) => {
    try {
      const state = getState().filtersPreset;
      const service = inject(FiltersPresetService);

      if (state.currentList === dto.listCode) {
        return state.items;
      }

      const result = await service.getFiltersPresets(dto);

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const deleteFiltersPresets = createAsyncThunk<
  string[],
  {
    values: DeleteFiltersPresetsDto;
    actions: {
      resolve: (value: unknown) => void;
    };
  },
  ThunkExtra<FiltersPresetWithRootState>
>(
  'filterPresets/deleteFiltersPresets',
  async (
    { values: { filtersPresets }, actions },
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      const toolsService = inject(ToolsService);

      const ids = filtersPresets.map((filtersPreset) => filtersPreset.id);

      await toolsService.deleteFilterPresets({ body: { ids } });

      if (filtersPresets.length > 1) {
        dispatch(showNotification(`Выборки успешно удалены (${ids.length})`));
      } else {
        dispatch(
          showCustomNotification([
            {
              type: 'info',
              title: 'Выборка успешно удалена',
              message: filtersPresets[0].name,
            },
          ]),
        );
      }

      actions.resolve(null);
      return ids;
    } catch (err) {
      if (filtersPresets.length > 1) {
        dispatch(
          showError(
            `Не удалось удалить выборки (${filtersPresets.length})\nПопробуйте еще раз позже`,
          ),
        );
      } else {
        dispatch(
          showCustomNotification([
            {
              type: 'error',
              title: 'Не удалось удалить выборку\nПопробуйте еще раз позже',
              message: filtersPresets[0].name,
            },
          ]),
        );
      }

      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const createFiltersPresets = createAsyncThunk<
  FilterPreset,
  {
    values: CreateFiltersPresetDto;
    actions: {
      resolve: (value: string) => void;
      reject: (value: unknown) => void;
    };
  },
  ThunkExtra<FiltersPresetWithRootState>
>(
  'filterPresets/createFiltersPresets',
  async (
    { values, actions },
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      const service = inject(FiltersPresetService);

      const result = await service.createFilterPreset(values);

      dispatch(
        showCustomNotification([
          {
            type: 'info',
            title: 'Выборка успешно сохранена',
            message: values.name,
          },
        ]),
      );

      actions.resolve(result.id);

      return result;
    } catch (err) {
      dispatch(
        showCustomNotification([
          {
            type: 'error',
            title: 'Не удалось сохранить выборку\nПопробуйте еще раз позже',
            message: values.name,
          },
        ]),
      );
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getPresetInitialValues = createAsyncThunk<
  FilterPreset,
  {
    presetId: string;
  },
  ThunkExtra<FiltersPresetWithRootState>
>(
  'filterPresets/getPresetInitialValues',
  async ({ presetId }, { rejectWithValue, extra: { inject } }) => {
    try {
      const toolsService = inject(ToolsService);
      const result = await toolsService.getFilterPreset({
        params: {
          filter_preset_id: presetId,
        },
      });

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const updateFilterPreset = createAsyncThunk<
  void,
  {
    values: UpdateFiltersPresetDto;
    actions: {
      resolve: () => void;
      reject: (value: unknown) => void;
    };
    filterPresetId: string;
  },
  ThunkExtra<FiltersPresetWithRootState>
>(
  'filterPresets/updateFilterPreset',
  async (
    { values, actions, filterPresetId },
    { rejectWithValue, extra: { inject }, dispatch },
  ) => {
    try {
      const service = inject(FiltersPresetService);
      await service.updateFilterPreset({
        values,
        filterPresetId,
      });

      actions.resolve();
      dispatch(
        showCustomNotification([
          {
            type: 'info',
            title: 'Выборка успешно изменена',
            message: values.name,
          },
        ]),
      );
    } catch (err) {
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getAvailableFilters = createAsyncThunk<
  FiltersResponse,
  {
    listCode:
      | 'events'
      | 'schedule_events'
      | 'absence_events'
      | 'employees'
      | 'company_policies';
    scheduleId: {
      scheduleId: string;
    };
    presetId: string;
  },
  ThunkExtra<FiltersPresetWithRootState>
>(
  'filterPresets/getAvailableFilters',
  async (
    { listCode, scheduleId, presetId },
    { rejectWithValue, extra: { inject }, dispatch },
  ) => {
    try {
      const availableFiltersService = inject(AvailableFiltersService);

      dispatch(getPresetInitialValues({ presetId }));

      let result;

      switch (listCode) {
        case 'events':
          result = await availableFiltersService.getEventsFilters();
          return { filters: result };
        case 'schedule_events':
          result = await availableFiltersService.getSheduleFilters(scheduleId);
          return { filters: result };
        case 'absence_events':
          result = await availableFiltersService.getAbsenceFilters();
          return { filters: result };
        case 'employees':
          const employeesGenerated = inject(UsersService);
          result = await employeesGenerated.getAvailableEmployeeFilters();
          return result;
        case 'company_policies':
          result = await availableFiltersService.getPolicyFilters();
          return { filters: result };
        default:
          return { filters: [] };
      }
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const filtersPresetsReducers = (
  builder: ActionReducerMapBuilder<FiltersPresetState>,
) => {
  builder.addCase(getFiltersPresets.pending, (state) => {
    state.status = 'loading';
    state.error = null;
  });
  builder.addCase(getFiltersPresets.fulfilled, (state, { payload, meta }) => {
    state.status = 'complete';
    state.error = null;
    state.items = payload;
    state.currentList = meta.arg.listCode;
  });
  builder.addCase(getFiltersPresets.rejected, (state, { payload, error }) => {
    state.status = 'failed';
    state.items = [];
    state.error =
      /* istanbul ignore next */
      payload ||
      ({
        info: (error && error.message) || 'Unknown error',
        status: 500,
        source: 'client',
        title: 'Internal client error',
      } as AppError);
  });
  builder.addCase(createFiltersPresets.fulfilled, (state) => {
    state.currentList = null;
  });
  builder.addCase(updateFilterPreset.fulfilled, (state) => {
    state.currentList = null;
  });
  builder.addCase(deleteFiltersPresets.fulfilled, (state, { payload }) => {
    state.items = state.items.filter(({ id }) => !payload.includes(id));
  });
  builder.addCase(getAvailableFilters.pending, (state) => {
    state.filterPreset.availableFilters.status = 'loading';
    state.filterPreset.availableFilters.error = null;
    state.filterPreset.availableFilters.filters = [];
  });
  builder.addCase(getAvailableFilters.fulfilled, (state, { payload }) => {
    state.filterPreset.availableFilters.status = 'complete';
    state.filterPreset.availableFilters.error = null;
    state.filterPreset.availableFilters.filters = payload.filters.filter(
      (filter) => filter.key !== 'actor',
    );
  });
  builder.addCase(getAvailableFilters.rejected, (state, { payload, error }) => {
    state.filterPreset.availableFilters.status = 'failed';
    state.filterPreset.availableFilters.filters = [];
    state.filterPreset.availableFilters.error =
      payload ||
      ({
        info: (error && error.message) || 'Unknown error',
        status: 500,
        source: 'client',
        title: 'Internal client error',
      } as AppError);
  });
  builder.addCase(getPresetInitialValues.pending, (state) => {
    state.filterPreset.initialValues.status = 'loading';
    state.filterPreset.initialValues.error = null;
  });
  builder.addCase(getPresetInitialValues.fulfilled, (state, { payload }) => {
    state.filterPreset.initialValues.status = 'complete';
    state.filterPreset.initialValues.error = null;
    state.filterPreset.initialValues.values = payload;
  });
  builder.addCase(
    getPresetInitialValues.rejected,
    (state, { payload, error }) => {
      state.filterPreset.initialValues.status = 'failed';
      state.filterPreset.initialValues.values = null;
      state.filterPreset.initialValues.error =
        payload ||
        ({
          info: (error && error.message) || 'Unknown error',
          status: 500,
          source: 'client',
          title: 'Internal client error',
        } as AppError);
    },
  );
  builder.addCase(updateFilterPreset.pending, (state) => {
    state.filterPreset.initialValues.status = 'loading';
    state.filterPreset.initialValues.error = null;
  });
  builder.addCase(updateFilterPreset.rejected, (state, { payload, error }) => {
    state.filterPreset.initialValues.status = 'failed';
    state.filterPreset.initialValues.error =
      payload ||
      ({
        info: (error && error.message) || 'Unknown error',
        status: 500,
        source: 'client',
        title: 'Internal client error',
      } as AppError);
  });
};
