import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createAction,
  EntityState,
} from '@reduxjs/toolkit';
import { classToPlain } from 'class-transformer';

import { AppError } from '@vk-hr-tek/core/error';

import {
  CreateEventCompanyItem,
  EventListItem as EventListEntity,
} from '@app/gen/events';
import { ThunkExtra } from '@app/store';

import { EventsListService } from '../../services';
import { EventsState, EventsWithRootState } from '../events.state';
import { EventTypeItemWithGroup } from '../../types/EventTypeItemWithGroup';

export const getCanCreateEvent = createAsyncThunk<
  boolean,
  undefined,
  ThunkExtra<EventsWithRootState>
>(
  'createEvent/getCanCreateEvent',
  async (_, { rejectWithValue, extra: { inject } }) => {
    try {
      const service = inject(EventsListService);
      const res = await service.getCreateOptionsCompany({
        withEventTypes: true,
      });

      return !!res.items.length;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const setCreateItems = createAction<CreateEventCompanyItem[]>(
  'events/setCreateItems',
);

export const setCreateEventTypes = createAction<EventTypeItemWithGroup[]>(
  'events/setCreateEventTypes',
);

export const creationReducers = (
  builder: ActionReducerMapBuilder<EntityState<EventListEntity> & EventsState>,
) => {
  builder.addCase(getCanCreateEvent.pending, (state) => {
    state.canCreateEvent = false;
  });
  builder.addCase(getCanCreateEvent.fulfilled, (state, action) => {
    state.canCreateEvent = action.payload;
  });
  builder.addCase(getCanCreateEvent.rejected, (state) => {
    state.canCreateEvent = false;
  });
};
