import React from 'react';

import { Badge } from '../common';
import { EllipsisText } from '../../EllipsisText';
import { Box } from '../../Box';
import { Typography } from '../../Typography';
import { Tooltip } from '../../Tooltip';

import { Option } from './dropdownOption.types';

interface ExtendedOption extends Option {
  subtitle?: string;
}

/**
 * Функция renderTwoLineOption рендерит опцию с двумя строками.
 * Опция может содержать подзаголовок и значок (badge).
 *
 * @param {Option} option - Объект опции, содержащий label и badge.
 * @returns {JSX.Element} Компонент опции с двумя строками.
 *
 * @example
 * <Box>
 *   {renderTwoLineOption({ label: 'Заголовок, Подзаголовок', badge: { title: 'Info', description: 'Описание', color: 'primary', position: 'top' } })}
 * </Box>
 */

export const renderTwoLineOption = (option: Option) => {
  const [subtitle, titleFirst, titleSecond] = option.label.split(', ');
  const { badge } = option;

  const isBottom = badge?.position === 'bottom';

  let title = '';

  if (subtitle && !titleFirst) {
    title = subtitle;
  } else {
    const titleSecondFormatted = titleSecond ? `, ${titleSecond}` : '';
    title = `${titleFirst || ''}${titleSecondFormatted}`;
  }

  return (
    <Box py={badge ? '8' : '12'}>
      {title && (
        <Box>
          <Typography variant="body3">{title}</Typography>
        </Box>
      )}
      <Box
        display="flex"
        alignItems="center"
        flexDirection="row"
        gap="8"
        mt="4"
      >
        {badge && (
          <Box>
            <Badge
              title={badge.title}
              description={badge.description}
              color={badge.color}
            />
          </Box>
        )}
        {title !== subtitle && (
          <Box order={isBottom ? 0 : 1}>
            <Typography
              component="div"
              variant={title ? 'caption' : 'body3'}
              color="text.light.tertirary"
            >
              {subtitle}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

/**
 * Функция renderOptionWithBadge рендерит опцию с значком (badge).
 *
 * @param {Option} option - Объект опции, содержащий label и badge.
 * @returns {JSX.Element} Компонент опции с значком.
 *
 * @example
 * <Box>
 *   {renderOptionWithBadge({ label: 'Заголовок', badge: { title: 'Info', description: 'Описание', color: 'primary' } })}
 * </Box>
 */
export const renderOptionWithBadge = (option: Option) => {
  const { badge, label } = option;

  return (
    <Box py={badge ? '8' : '12'}>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="row"
        gap="8"
        mt="4"
      >
        {badge && (
          <Box>
            <Badge
              title={badge.title}
              description={badge.description}
              color={badge.color}
            />
          </Box>
        )}
        <Box>
          <Typography
            component="div"
            variant="body3"
            color="text.light.primary"
          >
            {label}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

/**
 * Функция renderTwoLineOptionEllipsis рендерит опцию с двумя строками,
 * где первая строка содержит основной заголовок, а вторая строка содержит
 * подзаголовок, обрезанный с помощью EllipsisText.
 *
 * @param {Option} option - Объект опции, содержащий label.
 * @returns {JSX.Element} Компонент опции с двумя строками и обрезанным подзаголовком.
 *
 * @example
 * <Box>
 *   {renderTwoLineOptionEllipsis({ label: 'Заголовок, Подзаголовок, Дополнительный текст' })}
 * </Box>
 */
export const renderTwoLineOptionEllipsis = (option: Option) => {
  const [title, ...rest] = option.label.split(',');

  return (
    <Box py="12">
      <Box>
        <Typography variant="body3">{title}</Typography>
      </Box>
      <Box mt="2">
        <Typography variant="caption" color="text.light.tertirary">
          <EllipsisText>{rest?.join(', ')}</EllipsisText>
        </Typography>
      </Box>
    </Box>
  );
};

/**
 * Функция renderLineOptionWithTooltip рендерит опцию с подсказкой (tooltip).
 * Основной текст опции обрезается с помощью EllipsisText.
 *
 * @param {Option} option - Объект опции, содержащий label.
 * @returns {JSX.Element} Компонент опции с подсказкой.
 *
 * @example
 * <Box>
 *   {renderLineOptionWithTooltip({ label: 'Длинный заголовок, который нужно обрезать' })}
 * </Box>
 */
export const renderLineOptionWithTooltip = (option: Option) => {
  const { label } = option;

  return (
    <Tooltip title={label} placement="right">
      <Box py="12">
        <Box py="2">
          <Typography variant="body3">
            <EllipsisText rows={1}>{label}</EllipsisText>
          </Typography>
        </Box>
      </Box>
    </Tooltip>
  );
};

/**
 * Функция renderLineOptionWithDisabledItemAndTooltip рендерит опцию с подсказкой (tooltip),
 * если опция отключена. Иначе рендерит обычную опцию.
 *
 * @param {Option} option - Объект опции, содержащий label, disabled и tooltipText.
 * @returns {JSX.Element} Компонент опции с подсказкой, если она отключена, иначе обычная опция.
 *
 * @example
 * <Box>
 *   {renderLineOptionWithDisabledItemAndTooltip({ label: 'Отключенная опция', disabled: true, tooltipText: 'Эта опция недоступна' })}
 * </Box>
 */
export const renderLineOptionWithDisabledItemAndTooltip = (option: Option) => {
  const { disabled, label, tooltipText } = option;

  if (disabled && tooltipText) {
    return (
      <Box>
        <Tooltip title={tooltipText} placement="right">
          <Box py="12" px="16" display="inline-block" cursor="pointer">
            <Typography variant="body3" color="text.light.tertirary">
              <EllipsisText rows={1}>{label}</EllipsisText>
            </Typography>
          </Box>
        </Tooltip>
      </Box>
    );
  } else {
    return (
      <Box py="8">
        <Typography variant="body3">{label}</Typography>
      </Box>
    );
  }
};

/**
 * Функция renderTwoLineOptionWithSeparateSubtitle рендерит опцию с двумя строками,
 * где первая строка содержит основной заголовок (label), а вторая строка содержит
 * отдельный подзаголовок (subtitle).
 *
 * @param {ExtendedOption} option - Объект опции, содержащий label и subtitle.
 * @returns {JSX.Element} Компонент опции с двумя строками и отдельным подзаголовком.
 *
 * @example
 * <Box>
 *   {renderTwoLineOptionWithSeparateSubtitle({ label: 'Заголовок', subtitle: 'Подзаголовок' })}
 * </Box>
 */
export const renderTwoLineOptionWithSeparateSubtitle = (
  option: ExtendedOption,
) => {
  const { subtitle, label } = option;

  return (
    <Box py="12">
      <Box>
        <Typography variant="body3">{label}</Typography>
      </Box>
      <Box
        display="flex"
        alignItems={'center'}
        flexDirection={'row'}
        gap="8"
        mt="4"
      >
        <Box>
          <Typography
            component="div"
            variant="caption"
            color="text.light.tertirary"
          >
            {subtitle}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
