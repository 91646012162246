import React, { forwardRef } from 'react';

import MUIPaper, { PaperProps } from '@material-ui/core/Paper';

/**
 * Компонент Paper, который обёртывает компонент Paper из Material-UI.
 * Он добавляет стандартный className 'aqa_paper' и дополняет его любыми
 * дополнительными className, предоставленными через props.
 *
 * @param {JSX.IntrinsicAttributes & PaperProps} props - Свойства компонента Paper.
 * @param {React.Ref<HTMLElement>} ref - Реф к базовому DOM-элементу.
 * @returns {JSX.Element} - Отрендеренный компонент Paper.
 */

export const Paper = forwardRef<
  HTMLElement,
  JSX.IntrinsicAttributes & PaperProps
>((props: JSX.IntrinsicAttributes & PaperProps, ref) => {
  return (
    <MUIPaper
      {...props}
      ref={ref}
      className={props.className ? `${props.className} aqa_paper` : 'aqa_paper'}
      data-testid="paper-test-id"
    >
      {props.children}
    </MUIPaper>
  );
});
