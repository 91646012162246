import React from 'react';

import MUIDivider from '@material-ui/core/Divider';

/**
 * Компонент для отображения горизонтальной линии-разделителя.
 *
 * @returns {JSX.Element} JSX-элемент, представляющий разделитель.
 */
export const Divider = () => {
  return <MUIDivider className="aqa_divider" data-testid="divider-testid" />;
};
