import React from 'react';

import { Help as HelpIcon } from '@material-ui/icons';

import { Box } from '@vk-hr-tek/ui/Box';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { Tooltip } from '@vk-hr-tek/ui/Tooltip';
import { EllipsisText } from '@vk-hr-tek/ui/EllipsisText';

interface UkepCertificatesListProps {
  ukepCertificates: {
    activity: string;
    companyId: string;
    companyName: string;
    description: string;
    issuedBy: string;
    owner: string;
    serialNumber: string;
    type: string;
  }[];
}

export const UkepCertificatesList = ({
  ukepCertificates,
}: UkepCertificatesListProps) => {
  const isDesktop = useIsDesktop();

  const tooltipPlacement = isDesktop ? 'right' : 'bottom';

  return (
    <Box>
      {ukepCertificates.map((certificate) => (
        <Box
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          key={`${certificate.companyId}-${certificate.serialNumber}`}
          gap="8"
        >
          <Box display="flex" alignItems="center" gap="8">
            <Typography variant="subtitle1" color="text.light.primary">
              {certificate.type}
            </Typography>
            <Tooltip
              title={certificate.description}
              placement={tooltipPlacement}
            >
              <HelpIcon color="disabled" fontSize="small" />
            </Tooltip>
          </Box>
          <Box display="flex" flexDirection="column" gap="4" width="100%">
            <Typography variant="body2" color="text.light.primary">
              {certificate.activity}
            </Typography>
            <Typography variant="body2" color="text.light.primary">
              {certificate.issuedBy}
            </Typography>
            <Typography variant="body2" color="text.light.primary">
              <EllipsisText>{certificate.owner}</EllipsisText>
            </Typography>
            <Typography variant="body2" color="text.light.primary">
              <EllipsisText wordBreak="break-all">
                {certificate.serialNumber}
              </EllipsisText>
            </Typography>
            <Typography variant="body2" color="text.light.tertirary">
              {certificate.companyName}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};
