/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, ReactElement } from 'react';

import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import { Button, ButtonSize } from '../Button';

import { useStyles } from './DropdownButton.styles';

interface DropdownButtonProps {
  variant: 'secondary' | 'tertiaryLight' | 'quaternary';
  verticalPosition: 'top' | 'bottom';
  horizontalPosition: 'left' | 'right';
  menuItems: React.ReactNode | React.ReactNode[];
  disabled?: boolean;
  onOpen?(): void;
  testId?: string;
  button?: (options: {
    onClick: (event: React.KeyboardEvent | React.MouseEvent) => void;
    isOpened: boolean;
    isDisabled: boolean;
  }) => ReactElement;
  size?: ButtonSize;
  horizonIcon?: boolean;
}

/**
 * Компонент для отображения кнопки с выпадающим меню.
 *
 * @param {Object} props - Свойства, которые можно передать в компонент DropdownButton.
 * @param {('secondary' | 'tertiaryLight' | 'quaternary')} props.variant - Вариант кнопки.
 * @param {('top' | 'bottom')} props.verticalPosition - Вертикальное положение меню относительно кнопки.
 * @param {('left' | 'right')} props.horizontalPosition - Горизонтальное положение меню относительно кнопки.
 * @param {React.ReactNode | React.ReactNode[]} props.menuItems - Элементы, отображаемые в выпадающем меню.
 * @param {boolean} [props.disabled=false] - Если true, кнопка будет отключена.
 * @param {() => void} [props.onOpen] - Колбэк-функция для обработки открытия меню.
 * @param {string} [props.testId] - Тестовый ID для кнопки.
 * @param {(options: { onClick: (event: React.KeyboardEvent | React.MouseEvent) => void; isOpened: boolean; isDisabled: boolean; }) => ReactElement} [props.button] - Пользовательская кнопка.
 * @param {ButtonSize} [props.size='small'] - Размер кнопки.
 * @returns {JSX.Element} JSX-элемент, представляющий кнопку с выпадающим меню и связанным с ней меню.
 */

export const DropdownButton = ({
  variant,
  menuItems,
  verticalPosition,
  horizontalPosition,
  onOpen,
  disabled = false,
  button,
  testId,
  size = 'small',
  horizonIcon,
}: DropdownButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | (EventTarget & Element)>(
    null,
  );

  const classes = useStyles();

  const handleOpen = (event: React.KeyboardEvent | React.MouseEvent) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    onOpen?.();
  };

  const handleClose = (event: React.KeyboardEvent | React.MouseEvent) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const icon = horizonIcon ? (
    <MoreHorizIcon
      color={variant === 'quaternary' ? 'primary' : 'inherit'}
      data-testid="dropdown-button-more-vert-icon"
    />
  ) : (
    <MoreVertIcon
      color={variant === 'quaternary' ? 'primary' : 'inherit'}
      data-testid="dropdown-button-more-vert-icon"
    />
  );

  if (disabled) {
    return button ? (
      <>
        {button({
          onClick: handleOpen,
          isOpened: !!anchorEl,
          isDisabled: disabled,
        })}
      </>
    ) : (
      <Button
        variant={variant}
        size={size}
        icon={icon}
        disabled={disabled}
        onClick={handleOpen}
        disableRipple
      />
    );
  }

  return (
    <>
      {button ? (
        <>
          {button({
            onClick: handleOpen,
            isOpened: !!anchorEl,
            isDisabled: disabled,
          })}
        </>
      ) : (
        <Button
          variant={variant}
          size={size}
          testId={testId}
          icon={icon}
          onClick={handleOpen}
          disableRipple
        />
      )}
      <Menu
        classes={{
          list: classes.menuList,
          paper: `${verticalPosition === 'bottom' ? classes.paper : ''}`,
        }}
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: verticalPosition,
          horizontal: horizontalPosition,
        }}
        transformOrigin={{
          vertical: verticalPosition === 'top' ? 'bottom' : 'top',
          horizontal: horizontalPosition,
        }}
        transitionDuration={{
          appear: 200,
          enter: 200,
          exit: 0,
        }}
        data-testid="dropdownButton"
      >
        <div onClick={handleClose}>{menuItems}</div>
      </Menu>
    </>
  );
};
