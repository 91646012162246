/* eslint-disable */
import { Type, Transform } from 'class-transformer';
import {
  IsIn,
  IsArray,
  IsString,
  IsUUID,
  IsNotEmpty,
  ValidateNested,
  IsOptional,
} from 'class-validator';

import { EventTypeItem } from '@app/gen/events';

export class CreateEventOption {
  @IsString()
  @IsNotEmpty()
  option_name: string;

  @IsUUID()
  @IsNotEmpty()
  employee_id: string;

  @IsUUID()
  @IsNotEmpty()
  event_type_id: string;

  @IsString()
  @IsNotEmpty()
  event_type_name: string;

  @Transform((_) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  employee_name: string;

  @IsString()
  @IsNotEmpty()
  personnel_number: string;
}

export class EventTypeGroup {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsIn(['group'])
  @IsString()
  @IsNotEmpty()
  type: 'group';

  @IsString()
  @IsNotEmpty()
  label: string;

  @ValidateNested({ each: true })
  @Type(() => EventTypeItem)
  @IsArray()
  @IsNotEmpty()
  items: EventTypeItem[];
}

export class CreateEventEmployee {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  personnel_number: string;

  @Transform((_) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  name: string;

  @IsArray()
  @IsNotEmpty()
  event_types: (EventTypeGroup | EventTypeItem)[];

  @IsString()
  @IsOptional()
  position?: string;
}
