import { Type } from 'class-transformer';
import {
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  IsBoolean,
  IsArray,
} from 'class-validator';

export class GetOptionsEmployees {
  @IsString()
  @IsNotEmpty()
  companyId: string;

  @IsNumber()
  @IsOptional()
  limit?: number;

  @IsNumber()
  @IsOptional()
  offset?: number;

  @IsString()
  @IsOptional()
  query?: string;

  @IsBoolean()
  @IsOptional()
  noEmployee?: boolean = false;

  @IsBoolean()
  @IsOptional()
  batchEnabled?: boolean = true;

  @IsBoolean()
  @IsOptional()
  forAnother?: boolean = false;

  @IsString()
  @IsOptional()
  parentEventId?: string;

  @IsString()
  @IsOptional()
  eventTypeGroup?: string;

  @Type(() => String)
  @IsArray()
  @IsOptional()
  tag?: string[];
}
