import React, { ChangeEvent } from 'react';

import InputMask from 'react-input-mask';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';

import { Preloader } from '../common';

import useStyles from './SuggestedInput.styles';

interface TextFieldMaskedProps {
  mask: string;
  loading?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>, nextValue: string) => void;
}

/**
 * Соответствие символов формата регулярным выражениям.
 * Используется компонентом InputMask для определения правил маски.
 */
const formatChars = {
  '9': '[0-9]',
  a: '[A-Za-z]',
  '*': '[A-Za-z0-9А-Яа-я]',
  я: '[А-Яа-я]',
};

/**
 * TextFieldMasked — компонент, который предоставляет поле ввода с маской, используя Material-UI's TextField.
 * Он поддерживает различные форматы масок и может отображать индикатор загрузки.
 *
 * @param {Object} props - Свойства компонента.
 * @param {string} props.mask - Маска, которая будет применена к полю ввода.
 * @param {boolean} [props.loading=false] - Если true, поле ввода будет показывать индикатор загрузки.
 * @param {(event: ChangeEvent<HTMLInputElement>, nextValue: string) => void} props.onChange - Функция обратного вызова для обработки изменений значения ввода.
 * @param {string} [props.name] - Атрибут name для поля ввода.
 * @param {boolean} [props.error=false] - Если true, поле ввода будет отмечено как с ошибкой.
 * @param {string | ReactNode} [props.helperText] - Вспомогательный текст, который будет отображаться с полем ввода.
 * @param {TextFieldProps} [props.rest] - Дополнительные свойства для Material-UI TextField.
 * @returns {JSX.Element} - JSX компонента.
 */

export const TextFieldMasked = ({
  mask,
  name,
  error,
  helperText,
  loading,
  onChange,
  ...rest
}: TextFieldMaskedProps & TextFieldProps) => {
  const classes = useStyles();

  /**
   * Обрабатывает изменения значения в поле ввода.
   * Вызывает предоставленную функцию onChange с событием и новым значением ввода.
   *
   * @param {ChangeEvent<HTMLInputElement>} e - Событие изменения.
   */

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e, e.target.value);
  };

  return (
    <InputMask
      {...{ formatChars }}
      value={rest.inputProps?.value}
      onFocus={rest.inputProps?.onFocus}
      onBlur={rest.inputProps?.onBlur}
      onKeyPress={rest.inputProps?.onKeyPress}
      mask={mask}
      name={name}
      onChange={handleChange}
      disabled={rest.disabled}
    >
      {() => (
        <TextField
          {...rest}
          inputRef={rest?.inputProps?.ref}
          name={name}
          classes={{ root: classes.inputRoot }}
          autoComplete="off"
          variant="outlined"
          fullWidth
          error={error}
          helperText={helperText}
          placeholder={rest.placeholder}
          {...(loading
            ? {
                InputProps: {
                  endAdornment: <Preloader />,
                },
              }
            : {})}
        />
      )}
    </InputMask>
  );
};
