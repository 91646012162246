import React from 'react';

import { Box } from '../Box';
import { Typography } from '../Typography';

/**
 * Компонент, который отображает сообщение о недоступности функции в мобильной версии.
 * Сообщение центрируется как по вертикали, так и по горизонтали внутри полной высоты коробки (минус 60px).
 *
 * @returns {JSX.Element} JSX-элемент, представляющий сообщение "Недоступно в мобильной версии".
 */

export const MobileUnavailable = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    height="calc(100vh - 60px)"
    className="aqa_mobile_unavailable"
    testId="mobile-unavailable-test-id"
  >
    <Typography variant="body2" color="text.light.secondary">
      Недоступно в мобильной версии
    </Typography>
  </Box>
);
