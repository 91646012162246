import React from 'react';

import MUIMenuItem from '@material-ui/core/MenuItem';

import { useStyles } from './MenuItem.styles';

export interface MenuItemProps {
  children: React.ReactNode | React.ReactNode[];
  disabled?: boolean;
  onClick?(): void;
  testId?: string;
}

/**
 * Компонент, который рендерит элемент меню с необязательным стилизированием, состоянием отключения и обработчиком клика.
 * Использует компонент `MenuItem` из Material-UI и может содержать любые ReactNode в качестве своих дочерних элементов.
 *
 * @param {Object} props - Свойства компонента MenuItem.
 * @param {React.ReactNode | React.ReactNode[]} props.children - Дочерние узлы, которые будут отображены внутри элемента меню.
 * @param {boolean} [props.disabled=false] - Флаг, определяющий, должен ли элемент меню быть отключён.
 * @param {Function} [props.onClick] - Функция обратного вызова, которая будет вызвана при клике на элемент меню.
 * @param {string} [props.testId] - Тестовый ID для элемента меню, который облегчает тестирование.
 * @returns {JSX.Element} JSX-элемент, представляющий стилизованный элемент меню.
 */

export const MenuItem = ({
  children,
  onClick,
  disabled,
  testId,
}: MenuItemProps) => {
  const classes = useStyles();

  return (
    <MUIMenuItem
      disabled={disabled}
      data-testid={testId}
      onClick={onClick}
      className={classes.menuItem}
    >
      {children}
    </MUIMenuItem>
  );
};
