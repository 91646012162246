import React from 'react';

import {
  Field,
  FieldProps,
  FieldRenderProps,
  FieldInputProps,
} from 'react-final-form';

import { RadioInput as Input } from '../../input';

/**
 * Функция-обработчик изменения значения радио-кнопки.
 * Обновляет значение в поле формы и вызывает пользовательский обработчик изменения, если он предоставлен.
 *
 * @param {FieldInputProps<string | undefined, HTMLElement>} input - Объект, предоставляемый react-final-form для управления значением поля ввода.
 * @param {function} onChange - Пользовательский обработчик изменения значения.
 * @returns {function} - Возвращает функцию, которая обновляет значение и вызывает пользовательский обработчик.
 */
const handleChange =
  (
    input: FieldInputProps<string | undefined, HTMLElement>,
    onChange: (value: string | number) => void,
  ) =>
  (value: string | number) => {
    input.onChange(value);

    if (onChange) {
      onChange(value);
    }
  };

/**
 * Компонент радио-кнопок.
 * Оборачивает компонент RadioInput из модуля input в поле формы react-final-form.
 *
 * @param {Object} props - Свойства компонента.
 * @param {string} [props.label] - Подпись для группы радио-кнопок.
 * @param {string} [props.tooltip] - Текст подсказки, отображаемый при наведении на радио-кнопки.
 * @param {string} props.name - Уникальное имя поля ввода.
 * @param {function} [props.onChange] - Пользовательский обработчик изменения значения.
 * @param {Array} props.options - Массив опций для радио-кнопок.
 * @param {string | number} props.options[].value - Значение опции.
 * @param {string} props.options[].label - Подпись опции.
 * @param {boolean} [props.options[].disabled] - Флаг, указывающий, должна ли опция быть отключена.
 * @param {string} [props.defaultValue] - Значение по умолчанию для поля ввода.
 * @param {boolean} [props.required] - Флаг, указывающий, является ли поле обязательным.
 * @param {boolean} [props.disabled] - Флаг, указывающий, должно ли поле быть отключено.
 * @param {function} [props.after] - Функция, возвращающая дополнительный контент после группы радио-кнопок.
 * @returns {React.ReactNode} - Возвращает группу радио-кнопок, обернутую в поле формы.
 */
const RadioInput = ({
  label,
  tooltip,
  name,
  onChange,
  options,
  defaultValue,
  required,
  disabled,
  ...rest
}: {
  defaultValue?: string;
  options: { value: string | number; label: string; disabled?: boolean }[];
  after?: (value: string | undefined) => React.ReactNode;
} & FieldProps<string | undefined, FieldRenderProps<string | undefined>>) => {
  if (!options || !options.length) {
    return null;
  }

  return (
    <Field name={name} initialValue={defaultValue} {...rest}>
      {({ input }) => (
        <Input
          {...input}
          items={options}
          label={label}
          onChange={handleChange(input, onChange)}
          required={required}
          disabled={disabled}
          tooltip={tooltip}
        />
      )}
    </Field>
  );
};

export default RadioInput;
