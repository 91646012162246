import { createSlice } from '@reduxjs/toolkit';

import { initialState, dashboardAdapter } from './dashboard.state';
import {
  calendarReducers,
  dashboardDetailReducers,
  electronicServicesReducers,
  personalLinksReducers,
  fastActionsReducers,
  taskListReducers,
  partitionsReducers,
  widgetsReducers,
  PersonalLinksSettingsReducers,
} from './actions';

export const dashboard = createSlice({
  name: 'dashboard',
  initialState: dashboardAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: (builder) => {
    dashboardDetailReducers(builder);
    electronicServicesReducers(builder);
    personalLinksReducers(builder);
    fastActionsReducers(builder);
    taskListReducers(builder);
    calendarReducers(builder);
    partitionsReducers(builder);
    widgetsReducers(builder);
    PersonalLinksSettingsReducers(builder);
  },
});

export const dashboardReducer = dashboard.reducer;
