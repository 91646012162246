/* eslint-disable @typescript-eslint/no-explicit-any */
import { useReducer } from 'react';

import { AppError } from '@vk-hr-tek/core/error';

import { Filter } from '../types';

/**
 * Тип статуса, который может иметь автозаполнение.
 * - 'idle': Автозаполнение находится в состоянии покоя.
 * - 'loading': Выполняется запрос за элементами автозаполнения.
 * - 'complete': Запрос завершился успешно, элементы получены.
 * - 'failed': Запрос завершился с ошибкой.
 */
type Status = 'idle' | 'loading' | 'complete' | 'failed';

/**
 * Интерфейс состояния автозаполнения.
 *
 * @template T - Тип элементов, используемых в автозаполнении.
 * @property {Status} status - Текущий статус автозаполнения.
 * @property {T[]} items - Массив текущих элементов автозаполнения.
 * @property {AppError | null} error - Ошибка, если запрос завершился неудачей.
 * @property {boolean} hasMore - Флаг, указывающий на наличие дополнительных элементов.
 */
interface State<T> {
  status: Status;
  items: T[];
  error: AppError | null;
  hasMore: boolean;
}

/**
 * Перечисление типов действий для управления состоянием автозаполнения.
 *
 * @enum {string}
 * @property {string} setStatus - Действие для установки статуса.
 * @property {string} setItems - Действие для установки элементов.
 * @property {string} addItems - Действие для добавления элементов к текущему массиву.
 * @property {string} setError - Действие для установки ошибки.
 * @property {string} setHasMore - Действие для установки флага о наличии дополнительных элементов.
 * @property {string} resetState - Действие для сброса состояния к начальному.
 */
enum Actions {
  setStatus = 'setStatus',
  setItems = 'setItems',
  addItems = 'addItems',
  setError = 'setError',
  setHasMore = 'setHasMore',
  resetState = 'resetState',
}

interface Action<T = any> {
  type: Actions;
  payload?: T;
}

/**
 * Хук useAutocompleteReducer используется для управления состоянием автозаполнения
 * в компонентах ввода. Он предоставляет функции для установки статуса, элементов, ошибок
 * и флага о наличии дополнительных элементов, а также для выполнения запросов за элементами
 * и сброса состояния.
 *
 * @template T - Тип элементов, используемых в автозаполнении.
 * @param {(filter: Filter) => Promise<T[]>} [callback] - Асинхронная функция, которая будет вызываться
 * для получения элементов автозаполнения на основе переданного фильтра.
 *
 * @returns {Object} Объект с текущим состоянием и функциями для управления им.
 * @returns {Status} state.status - Текущий статус ('idle', 'loading', 'complete', 'failed').
 * @returns {T[]} state.items - Массив текущих элементов автозаполнения.
 * @returns {AppError | null} state.error - Ошибка, если запрос завершился неудачей.
 * @returns {boolean} state.hasMore - Флаг, указывающий на наличие дополнительных элементов.
 * @returns {(payload: Status) => Action<Status>} setStatus - Функция для установки статуса.
 * @returns {(payload: T[]) => Action<T[]>} setItems - Функция для установки элементов.
 * @returns {(payload: T[]) => Action<T[]>} addItems - Функция для добавления элементов к текущему массиву.
 * @returns {(payload: AppError | null) => Action<AppError | null>} setError - Функция для установки ошибки.
 * @returns {(payload: boolean) => Action<boolean>} setHasMore - Функция для установки флага о наличии дополнительных элементов.
 * @returns {() => Action} resetState - Функция для сброса состояния к начальному.
 * @returns {(filter: Filter, type: 'get' | 'search') => Promise<T[]> | undefined} requestItems - Функция для выполнения запроса за элементами.
 * @returns {() => void} resetItems - Функция для сброса состояния.
 *
 */

export const useAutocompleteReducer = <T>(
  callback?: (filter: Filter) => Promise<T[]>,
) => {
  const initialState: State<T> = {
    status: 'idle',
    items: [],
    error: null,
    hasMore: true,
  };

  const reducer = (state: State<T>, { type, payload }: Action): State<T> => {
    switch (type) {
      case Actions.setStatus:
        return {
          ...state,
          status: payload,
        };

      case Actions.setItems:
        return {
          ...state,
          items: payload,
        };

      case Actions.addItems:
        return {
          ...state,
          items: [...state.items, ...payload],
        };

      case Actions.setError:
        return {
          ...state,
          error:
            payload ||
            ({
              info: 'Что-то пошло не так',
              status: 500,
              source: 'client',
              title: 'Internal client error',
            } as AppError),
        };

      case Actions.setHasMore:
        return {
          ...state,
          hasMore: payload,
        };

      case Actions.resetState:
        return initialState;

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const setStatus = (payload: Status): Action<Status> => ({
    type: Actions.setStatus,
    payload,
  });

  const setItems = (payload: T[]): Action<T[]> => ({
    type: Actions.setItems,
    payload,
  });

  const addItems = (payload: T[]): Action<T[]> => ({
    type: Actions.addItems,
    payload,
  });

  const setError = (payload: AppError | null): Action<AppError | null> => ({
    type: Actions.setError,
    payload,
  });

  const setHasMore = (payload: boolean): Action<boolean> => ({
    type: Actions.setHasMore,
    payload,
  });

  const resetState = (): Action => ({
    type: Actions.resetState,
  });

  const requestItems = async (filter: Filter, type: 'get' | 'search') => {
    try {
      dispatch(setStatus('loading'));

      const items = (await callback?.(filter)) || [];

      if (type === 'get') {
        dispatch(addItems(items));
      } else {
        dispatch(setItems(items));
      }

      dispatch(setHasMore(!!items.length));
      dispatch(setStatus('complete'));

      return items;
    } catch (err) {
      setStatus('failed');
      dispatch(setError(err as AppError));
    }
  };

  const resetItems = () => dispatch(resetState());

  return {
    ...state,
    requestItems: callback ? requestItems : undefined,
    resetItems,
  };
};
