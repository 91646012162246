import {
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
} from 'class-validator';

export class GetCreateEventTypeParticipantOptionsDto {
  @IsUUID()
  @IsNotEmpty()
  eventTypeId: string;

  @IsUUID()
  @IsNotEmpty()
  roleId: string;

  @IsNumber()
  @IsOptional()
  limit?: number;

  @IsNumber()
  @IsOptional()
  offset?: number;

  @IsString()
  @IsOptional()
  search?: string;
}
