import React, { ReactNode } from 'react';

import classNames from 'classnames';

import useStyles from './List.styles';

/**
 * Интерфейс, определяющий пропсы для компонента List.
 */
export interface ListProps {
  /**
   * Стиль маркера для элементов списка. Значение по умолчанию — 'none'.
   * Возможные значения включают 'disc', 'circle', 'square', 'decimal', 'lower-alpha', 'upper-alpha' и другие.
   */
  listStyleType?: string;

  /**
   * Левое поле (pl) для списка. Значение по умолчанию — 0.
   */
  pl?: number;

  /**
   * Дочерние элементы списка. Обычно это элементы <li>.
   */
  children: ReactNode;
}

/**
 * Пользовательский компонент неупорядоченного списка.
 *
 * @param {ListProps} props - Пропсы для компонента List.
 * @returns {JSX.Element} - Элемент неупорядоченного списка с применёнными стилями.
 */

export const List = ({
  listStyleType = 'none',
  pl = 0,
  children,
}: ListProps) => {
  const classes = useStyles({ listStyleType, pl });

  return <ul className={classNames(classes.list, 'aqa_list')}>{children}</ul>;
};
