import React, { useState } from 'react';

import ClearIcon from '@material-ui/icons/Clear';
import Chip from '@material-ui/core/Chip';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import AppsIcon from '@material-ui/icons/Apps';

import { Box } from '../../Box';
import { Tooltip } from '../../Tooltip';

import { useStyles } from './SelectedOptionsInput.styles';

/**
 * Интерфейс, определяющий структуру Option.
 * @typedef {Object} Option
 * @property {string} label - Надпись опции.
 * @property {string} value - Значение опции.
 * @property {{title: string, subtitle?: string} | undefined} [tooltip] - Необязательная информация для всплывающей подсказки.
 * @property {string | undefined} [prefix] - Необязательный префикс для отображения перед надписью опции.
 * @property {boolean | undefined} [error] - Указывает, находится ли опция в состоянии ошибки.
 */

type Option = {
  label: string;
  value: string;
  tooltip?: {
    title: string;
    subtitle?: string;
  };
  prefix?: string;
  error?: boolean;
};

/**
 * Интерфейс, определяющий свойства компонента SelectedOptionsInput.
 * @typedef {Object} SelectedOptionsInputProps
 * @property {Option[]} options - Список выбранных опций для отображения.
 * @property {((option: Option) => void) | undefined} [onDelete] - Функция обратного вызова, которая вызывается при удалении опции.
 * @property {((option: Option) => React.ReactElement) | undefined} [renderTooltip] - Пользовательская функция рендеринга для всплывающей подсказки.
 * @property {number | undefined} [limit] - Максимальное количество опций для отображения на странице (по умолчанию 15).
 * @property {number | undefined} [maxWidth] - Максимальная ширина компонента.
 * @property {boolean | undefined} [withTooltip] - Указывает, следует ли отображать всплывающие подсказки для опций (по умолчанию false).
 */
interface SelectedOptionsInputProps {
  options: Option[];
  onDelete?: (option: Option) => void;
  renderTooltip?: (option: Option) => React.ReactElement;
  limit?: number;
  maxWidth?: number;
  withTooltip?: boolean;
}

/**
 * Компонент SelectedOptionsInput используется для отображения списка выбранных опций.
 * Он поддерживает удаление опций, всплывающие подсказки (tooltips) и постраничную навигацию.
 *
 * @component
 * @param {SelectedOptionsInputProps} props - Свойства для компонента.
 * @param {Option[]} props.options - Список выбранных опций для отображения.
 * @param {((option: Option) => void) | undefined} props.onDelete - Функция обратного вызова, которая вызывается при удалении опции.
 * @param {((option: Option) => React.ReactElement) | undefined} props.renderTooltip - Пользовательская функция рендеринга для всплывающей подсказки.
 * @param {number | undefined} props.limit - Максимальное количество опций для отображения на странице (по умолчанию 15).
 * @param {number | undefined} props.maxWidth - Максимальная ширина компонента.
 * @param {boolean | undefined} props.withTooltip - Флаг, указывающий, следует ли отображать всплывающие подсказки для опций (по умолчанию false).
 * @returns {React.ReactElement} Отрендеренный компонент SelectedOptionsInput.
 */

export const SelectedOptionsInput = ({
  options,
  onDelete,
  limit = 15,
  renderTooltip,
  withTooltip,
  maxWidth,
}: SelectedOptionsInputProps) => {
  const classes = useStyles({ maxWidth });
  const [page, setPage] = useState(1);

  const shownOptions = options.slice(0, limit * page);

  return (
    <div>
      <Box display="flex" flexWrap="wrap">
        {shownOptions.map((option) => (
          <Box m="4" key={option.value} color="accent.text.error">
            <Tooltip
              disabled={!withTooltip}
              title={renderTooltip ? renderTooltip(option) : option.label}
              placement="top"
            >
              <Chip
                classes={{
                  root: classes.chipInput,
                  deleteIcon: option.error ? classes.iconError : '',
                }}
                label={`${option.prefix ? `${option.prefix}, ` : ''}${
                  option.label
                }`}
                onDelete={() => {
                  if (!onDelete) {
                    return;
                  }

                  onDelete(option);
                }}
                deleteIcon={onDelete ? <ClearIcon /> : undefined}
                className={option.error ? classes.chipError : classes.chip}
              />
            </Tooltip>
          </Box>
        ))}
      </Box>
      <Box display="flex">
        {options.length > shownOptions.length && (
          <Box m="4">
            <Box
              className={classes.button}
              gap="8"
              onClick={() => setPage((currentPage) => currentPage + 1)}
            >
              Показать еще <MoreHorizIcon />
            </Box>
          </Box>
        )}
        {options.length > shownOptions.length && (
          <Box m="4">
            <Box
              className={classes.button}
              gap="8"
              onClick={() => setPage(Math.ceil(options.length / limit))}
            >
              Показать все {options.length} <AppsIcon />
            </Box>
          </Box>
        )}
        {options.length <= shownOptions.length && options.length > limit && (
          <Box m="4">
            <Box className={classes.button} onClick={() => setPage(1)}>
              Скрыть
            </Box>
          </Box>
        )}
      </Box>
    </div>
  );
};
