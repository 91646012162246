import React, { ReactNode } from 'react';

import { FormControlLabel, Switch } from '@material-ui/core';

import { Box } from '../../Box';
import { Label } from '../common';

import useStyles from './SwitchInput.styles';

interface SwitchInputProps {
  label: string;
  onChange: (check: boolean) => void;
  checked: boolean;
  disabled?: boolean;
  labelPlacement?: 'start' | 'end';
  tooltip?: ReactNode | null;
}

/**
 * SwitchInput — это компонент, который предоставляет переключатель с меткой.
 * Он поддерживает настраиваемое положение метки, отключённое состояние и всплывающие подсказки.
 *
 * @param {Object} props - Свойства компонента.
 * @param {string} props.label - Текст метки для переключателя.
 * @param {(check: boolean) => void} props.onChange - Функция обратного вызова для обработки изменений состояния переключателя.
 * @param {boolean} props.checked - Текущее состояние переключателя (включён/выключен).
 * @param {boolean} [props.disabled=false] - Если true, переключатель будет отключён.
 * @param {'start' | 'end'} [props.labelPlacement='start'] - Положение метки относительно переключателя ('start' или 'end').
 * @param {ReactNode | null} [props.tooltip=null] - Содержимое всплывающей подсказки, отображаемой с меткой.
 * @returns {JSX.Element} - JSX компонента.
 */

export const SwitchInput = ({
  label,
  onChange,
  disabled = false,
  labelPlacement = 'start',
  checked,
  tooltip,
}: SwitchInputProps) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      classes={{ root: classes.root }}
      control={
        <Switch
          checked={checked}
          disableRipple
          classes={{
            root: classes.switchRoot,
            track: classes.track,
            switchBase: classes.switchBase,
            disabled: classes.disabled,
            thumb: classes.thumb,
          }}
          color="primary"
          disabled={disabled}
          onChange={(event, check) => {
            onChange(check);
          }}
        />
      }
      label={
        label ? (
          <Box
            mr={labelPlacement === 'start' ? '16' : '0'}
            ml={labelPlacement === 'end' ? '16' : '0'}
            mb="-4"
          >
            <Label label={label} tooltip={tooltip} />
          </Box>
        ) : null
      }
      labelPlacement={labelPlacement}
      className="aqa_switch_input"
    />
  );
};
