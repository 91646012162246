import { injectable } from 'inversify';

@injectable()
export class DownloadService {
  async doDownload(
    file: Blob | Promise<Blob>,
    filename: string,
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        const blob = await file;
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = url;
        link.download = filename;
        link.target = '_blank';
        link.click();
        link.remove();
        URL.revokeObjectURL(url);

        resolve(undefined);
      } catch (err) {
        reject(err);
      }
    });
  }
}
