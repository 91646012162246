export interface PersonalRouter {
  goToList(userId: string, query?: string): void;
  goToWorkBook(userId: string, employeeId: string): void;
  goToEducation(userId: string, employeeId: string): void;
  goToEmploymentContract(userId: string, employeeId: string): void;
  goToWorkHistory(userId: string, employeeId: string): void;
  goToAwardsPage(userId: string, employeeId: string): void;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PersonalRouter = Symbol('PersonalRouter');
