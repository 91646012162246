import React from 'react';

import classNames from 'classnames';

import { PreviewButtons } from './Buttons';
import { useStyles } from './Preview.styles';

/**
 * Интерфейс пропсов компонента PreviewImage.
 * @interface PreviewImageProps
 * @property {string} url - URL изображения для предварительного просмотра.
 * @property {'auto' | 'square'} shape - Форма отображения изображения ('auto' или 'square').
 * @property {boolean} clickable - Определяет, является ли изображение кликабельным.
 * @property {() => Promise<Blob | { file: Promise<Blob>; filename?: string }>} [onClickDownload] - Функция для загрузки изображения.
 * @property {boolean} [withCollapsableFooter] - Определяет, нужно ли отображать сворачиваемый нижний колонтитул.
 * @property {boolean} [centerImage] - Определяет, нужно ли центрировать изображение.
 */
interface PreviewImageProps {
  url: string;
  shape: 'auto' | 'square';
  clickable: boolean;
  onClickDownload?: () => Promise<
    Blob | { file: Promise<Blob>; filename?: string }
  >;
  withCollapsableFooter?: boolean;
  centerImage?: boolean;
}

/**
 * Компонент для предварительного просмотра изображения.
 * @param {PreviewImageProps} props - Пропсы компонента.
 * @returns {React.ReactElement} Элемент React.
 */
export const PreviewImage = ({
  url,
  shape,
  clickable,
  onClickDownload,
  withCollapsableFooter,
  centerImage,
}: PreviewImageProps) => {
  const classes = useStyles();

  return (
    <div
      className={classNames(
        classes.root,
        classes.collapseWrapper,
        centerImage && classes.centerImage,
        'aqa_file_preview_image',
      )}
    >
      {shape === 'square' && <div className={classes.square} />}
      {clickable ? (
        <a href={url} target="_blank" rel="noreferrer">
          <img
            className={classNames(
              classes.image,
              shape === 'square' && classes.image_square,
            )}
            src={url}
            alt=""
          />
        </a>
      ) : (
        <img
          className={classNames(
            classes.image,
            shape === 'square' && classes.image_square,
          )}
          src={url}
          alt=""
        />
      )}
      {onClickDownload && (
        <PreviewButtons
          withCollapsableFooter={withCollapsableFooter}
          onClickDownload={onClickDownload}
        />
      )}
    </div>
  );
};
