import React, { ReactNode } from 'react';

import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { Fade } from '@material-ui/core';

import { LocalStorage } from '@vk-hr-tek/core/local-storage';
import { useInject } from '@vk-hr-tek/core/ioc';
import { Box } from '@vk-hr-tek/ui/Box';
import {
  SidebarHomePageIcon,
  SidebarCompanyPageIcon,
} from '@vk-hr-tek/ui/icons';
import { Tooltip } from '@vk-hr-tek/ui/Tooltip';
import { SidebarMenuIcon } from '@vk-hr-tek/ui/icons/SidebarIcons';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { Link } from '@vk-hr-tek/ui/Link';

import Can from '@app/ability/Can';
import { navigationItems } from '@app/router';
import { useDispatch, useSelector } from '@app/hooks';
import { ROLES } from '@app/constants';
import { UserRoleEnum } from '@app/types';

import { selectSidebarState, setSidebarState } from '../../slice';
import {
  selectStatus,
  selectUserRepresentative,
  selectHasCompanySide,
  selectUser,
} from '../../../user';
import { AbilityProvider } from '../../AbilityProvider';

import { useStyles } from './Sidebar.styles';
import { SidebarItem } from './SidebarItems';
import {
  TIMEOUT_FOR_FADE,
  SIDEBAR_EXPAND_BUTTON_CLOSED_WIDTH,
  SIDEBAR_EXPAND_BUTTON_OPENED_WIDTH,
} from './Sidebar.constants';
import { SidebarMenu } from './SidebarMenu';

interface SidebarProps {
  logo: ReactNode;
  vkTheme: boolean;
}

export const Sidebar = ({ logo, vkTheme = false }: SidebarProps) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const user = useSelector(selectUser);

  const localStorageService = useInject<LocalStorage>(LocalStorage);

  const sidebarOpened = useSelector(selectSidebarState);
  const userPermisionsStatus = useSelector(selectStatus);
  const userRepresentative = useSelector(selectUserRepresentative);
  const hasCompanySide = useSelector(selectHasCompanySide);

  const isSkeletonLoading = userPermisionsStatus === 'loading';

  const sidebarIconWidth = sidebarOpened
    ? SIDEBAR_EXPAND_BUTTON_OPENED_WIDTH
    : SIDEBAR_EXPAND_BUTTON_CLOSED_WIDTH;

  const styles = useStyles({
    sidebarIconWidth,
    vkTheme,
  });

  const handleSidebarOpen = () => {
    dispatch(setSidebarState(true));
    localStorageService.setItem('sidebarState', 'true');
  };

  const handleIsActive = (path: string, href: string): boolean => {
    if (href === '/') {
      return path === href;
    } else if (href === '/settings') {
      return (
        path === href ||
        path.includes('/event-types') ||
        path.includes('/settings')
      );
    } else if (path.startsWith('/personal')) {
      const currentUserId = pathname.split('/personal/')[1] || '';
      return user?.id !== currentUserId;
    } else {
      return path.startsWith(href) || path === href;
    }
  };

  const handleSidebarClose = () => {
    dispatch(setSidebarState(false));
    localStorageService.setItem('sidebarState', 'false');
  };

  const handleSidebarState = () =>
    sidebarOpened ? handleSidebarClose() : handleSidebarOpen();

  const filteredRoles = ROLES().filter((item) => {
    if (window.REACT_APP_VKHRTEK_LK_DISABLED && userRepresentative) {
      return item.role === UserRoleEnum.Company;
    }

    if (!hasCompanySide) {
      return item.role === UserRoleEnum.Employee;
    }

    return item;
  });

  const organizationItem = navigationItems.find(
    (item) => item.basename === 'organization',
  );

  return (
    <Box
      className={classNames(
        styles.sidebar,
        'aqa_sidebar',
        sidebarOpened && styles.sidebarOpened,
      )}
    >
      <Box className={classNames(styles.sidebarLogo, 'aqa_sidebarLogo')}>
        <Link to="/">{logo}</Link>
      </Box>

      <Box className={styles.overlayTop} />

      <Box className={classNames(styles.sidebarMenu, 'aqa_sidebarMenu')}>
        <Box>
          <SidebarItem
            href="/"
            icon={<SidebarHomePageIcon />}
            aqaClass="aqa_dashboard"
            title="Главная"
            sidebarOpened={sidebarOpened}
            isActive={handleIsActive(pathname, '/')}
            skeletonLoading={isSkeletonLoading}
          />
          <AbilityProvider role="general">
            <Can do="read" on="Organization">
              {organizationItem && (
                <SidebarItem
                  href={`/${organizationItem.basename}`}
                  icon={<SidebarCompanyPageIcon />}
                  aqaClass={organizationItem.aqaClass}
                  title="Компания"
                  sidebarOpened={sidebarOpened}
                  isActive={handleIsActive(
                    pathname,
                    `/${organizationItem.basename}`,
                  )}
                  skeletonLoading={isSkeletonLoading}
                />
              )}
            </Can>
          </AbilityProvider>
        </Box>

        {filteredRoles.map(({ role, title }) => (
          <AbilityProvider key={title} role={role}>
            <SidebarMenu
              menuLabel={title}
              sidebarOpened={sidebarOpened}
              skeletonLoading={isSkeletonLoading}
              userRole={role}
              key={role}
            />
          </AbilityProvider>
        ))}
      </Box>

      <Box className={styles.overlayBottom} />

      <Box
        className={classNames(
          styles.sideBarExpandButton,
          'aqa_sidebarExpandButton',
        )}
        onClick={handleSidebarState}
      >
        <Tooltip
          placement="right"
          title="Развернуть меню"
          disabled={sidebarOpened}
        >
          <Box
            minWidth="24px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            className="aqa_sidebarExpandIcon"
          >
            <SidebarMenuIcon rotated={sidebarOpened} />
          </Box>
        </Tooltip>

        <Fade in={sidebarOpened} timeout={TIMEOUT_FOR_FADE}>
          <Box
            width="100%"
            mr="auto"
            height="20px"
            className="aqa_sidebarExpandText"
          >
            <Typography variant="body3" color="text.light.tertirary" noWrap>
              Скрыть меню
            </Typography>
          </Box>
        </Fade>
      </Box>
    </Box>
  );
};
