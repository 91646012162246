import React, { ReactNode, useCallback } from 'react';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Chip from '@material-ui/core/Chip';
import classNames from 'classnames';

import { Box } from '../../Box';
import { CircularProgress } from '../../CircularProgress';
import { Label } from '../common';
import { Typography } from '../../Typography';

import { ChipsInputItem } from './ChipsInput.types';
import { useStyles } from './ChipsInput.styles';

interface ChipsInputProps {
  items: ChipsInputItem[];
  label?: string;
  value: string | number | undefined;
  onChange: (value: string | number) => void;
  required?: boolean;
  error?: boolean;
  helperText?: string;
  tooltip?: ReactNode | null;
  disabled?: boolean;
  loading?: boolean;
  size?: 'large' | 'medium' | 'small';
  activeSingleOption?: boolean;
  testId?: string;
}

/**
 * ChipsInput - компонент для выбора значения с помощью чипов.
 *
 * @param {ChipsInputProps} props - Свойства компонента.
 * @param {Array<{ label: string; counter?: string | number; value: string | number; }>} props.items - Массив элементов для отображения в виде чипов.
 * @param {string} [props.label] - Текст метки.
 * @param {string | number | undefined} props.value - Текущее выбранное значение.
 * @param {(value: string | number) => void} props.onChange - Обработчик изменения значения.
 * @param {boolean} [props.required=false] - Флаг, указывающий на обязательность поля.
 * @param {boolean} [props.error] - Флаг, указывающий на наличие ошибки.
 * @param {string} [props.helperText] - Вспомогательный текст, отображаемый при ошибке.
 * @param {ReactNode | null} [props.tooltip=null] - Вспомогательный текст или узел, отображаемый в виде подсказки.
 * @param {boolean} [props.disabled=false] - Флаг, указывающий на отключенность компонента.
 * @param {boolean} [props.loading=false] - Флаг, указывающий на состояние загрузки.
 * @param {'large' | 'medium' | 'small'} [props.size='large'] - Размер чипов.
 * @param {boolean} [props.activeSingleOption=true] - Флаг, указывающий на активность единственного опции.
 * @param {string} [props.testId] - Идентификатор для тестирования.
 * @returns {JSX.Element} Возвращает JSX-элемент представляющий компонент ChipsInput.
 */

export const ChipsInput = ({
  label,
  items,
  value,
  onChange,
  required = false,
  error,
  helperText,
  disabled = false,
  loading = false,
  tooltip = null,
  size = 'large',
  activeSingleOption = true,
  testId,
  ...rest
}: ChipsInputProps) => {
  const classes = useStyles();

  const handleChange = useCallback(
    ({
      target: { value: inputValue },
    }: React.ChangeEvent<HTMLInputElement>) => {
      onChange(inputValue);
    },
    [onChange],
  );

  return (
    <FormControl className="aqa_chips_input" component="fieldset">
      {label && (
        <Label
          label={label}
          className="aqa_chips_input_label"
          required={required}
          tooltip={tooltip}
        />
      )}

      <RadioGroup
        row
        data-testid={testId}
        value={value}
        onChange={handleChange}
        className={`${classes.row} aqa_chip_button`}
      >
        {items.map(
          ({ label: itemLabel, value: itemValue, counter, color, icon }) => {
            const active =
              itemValue === value || (activeSingleOption && items.length === 1);

            return (
              <Box key={itemValue}>
                <FormControlLabel
                  key={itemValue}
                  value={itemValue}
                  className={classes.label}
                  disabled={disabled}
                  control={
                    <Radio className={classes.radio} icon="" checkedIcon="" />
                  }
                  label={
                    <Chip
                      {...rest}
                      label={
                        loading ? (
                          <CircularProgress
                            size={20}
                            color={itemValue === value ? 'primary' : 'inherit'}
                          />
                        ) : (
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            gap="8"
                            ml={icon && itemLabel ? '2' : '0'}
                          >
                            {itemLabel}
                            {!!counter && (
                              <Box className={classes.counter}>{counter}</Box>
                            )}
                          </Box>
                        )
                      }
                      variant="outlined"
                      disabled={disabled}
                      clickable
                      icon={!loading && icon ? <Box>{icon}</Box> : undefined}
                      className={classNames(
                        classes.chip,
                        classes[size],
                        error && classes.error,
                        disabled && classes.disabled,
                        active && classes.active,
                        active && disabled && classes.activeDisabled,
                        color && classes[color],
                      )}
                    />
                  }
                />
              </Box>
            );
          },
        )}
      </RadioGroup>

      {error && (
        <Box mt="4">
          <Typography variant="caption" color="accent.text.error">
            {helperText}
          </Typography>
        </Box>
      )}
    </FormControl>
  );
};
