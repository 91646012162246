import { Type } from 'class-transformer';
import {
  IsInt,
  IsOptional,
  IsIn,
  Min,
  IsString,
  IsUUID,
  IsArray,
} from 'class-validator';

import { FilterType } from '@vk-hr-tek/core/filter';

export class GetEventsDto {
  @IsString({ each: true })
  @IsArray()
  @IsOptional()
  tags?: string[];

  @IsUUID()
  @IsString()
  @IsOptional()
  companyId?: string;

  @Min(0)
  @IsInt()
  @Type(() => Number)
  @IsOptional()
  offset = 0;

  @Min(1)
  @IsInt()
  @Type(() => Number)
  @IsOptional()
  // eslint-disable-next-line no-magic-numbers
  limit = 50;

  @IsIn(['id', 'created_at'])
  @IsString()
  @IsOptional()
  sortBy?: string;

  @IsString({ each: true })
  @IsArray()
  @IsOptional()
  sortByIds?: string[];

  @IsIn(['asc', 'desc'])
  @IsString()
  @IsOptional()
  sortOrder?: string;

  filters?: FilterType;
}
