import { useEffect } from 'react';

import { ForcedSubject } from '@casl/ability';
import { useLocation, useRouteMatch } from 'react-router-dom';

import { t } from '../translations/t';

type Resources =
  | 'Employees'
  | 'Dashboard'
  | 'Absences'
  | 'Candidates'
  | 'Candidate'
  | 'Events'
  | 'EventTypes'
  | 'EventBatch'
  | 'PaperEvents'
  | 'User'
  | 'Policy'
  | 'Organization'
  | 'Competencies'
  | 'all'
  | 'Settings'
  | 'CompanyProfiles'
  | 'SettingsAdmin'
  | 'SettingsAdmin'
  | 'Attorneys'
  | 'Vacations'
  | 'VacationsSettings'
  | 'Payslips'
  | 'Personal'
  | 'SmevErrors'
  | ForcedSubject<
      | 'Employees'
      | 'Dashboard'
      | 'Absences'
      | 'Candidates'
      | 'Candidate'
      | 'Events'
      | 'EventTypes'
      | 'EventBatch'
      | 'PaperEvents'
      | 'User'
      | 'Policy'
      | 'Organization'
      | 'Competencies'
      | 'Settings'
      | 'CompanyProfiles'
      | 'SettingsAdmin'
      | 'SettingsAdmin'
      | 'Attorneys'
      | 'Vacations'
      | 'VacationsSettings'
      | 'Payslips'
      | 'Personal'
      | 'SmevErrors'
    >;

/** Установить заголовок страницы в браузере
 * @param {{ resource: Resources, basename: string; href: string }[]} metaTitles
 * @returns {void}
 *  */
export const useMetaTitles = (
  metaTitles: {
    resource?: Resources;
    basename?: string;
    href: string;
    submenu?: {
      aqaClass: string;
      href: string;
      resource: Resources;
      basename: string;
    }[];
    completeTitle?: {
      company: string;
      employee: string;
    };
  }[],
  role: 'employee' | 'company',
) => {
  const { pathname } = useLocation();

  const {
    resource,
    basename,
    submenu,
    href = 'not-found',
    completeTitle,
  } = metaTitles.find((item) => {
    if (item.href === '/') {
      return pathname === item.href;
    } else {
      return (
        pathname.startsWith(item.href) ||
        pathname.startsWith(`/company${item.href}`) ||
        pathname.startsWith(`/employee${item.href}`)
      );
    }
  }) || {};

  const findedSubmenu =
    submenu &&
    submenu.find(
      (item) =>
        pathname === `/company${item.href}` ||
        pathname === `/employee${item.href}`,
    );

  const submenuTitle =
    findedSubmenu &&
    t(
      `modules.${resource}.${
        findedSubmenu.basename === 'sign-types'
          ? 'unep-types'
          : findedSubmenu.basename
      }.${role}Title`,
    );

  const titleWithSubmenu =
    submenuTitle || t(`modules.${resource}.${basename}.${role}Title`);

  const title = completeTitle ? completeTitle[role] : titleWithSubmenu || '';

  const matchGeneral = useRouteMatch(href);
  const employeeMatch = useRouteMatch(`/employee${href}`);
  const companyMatch = useRouteMatch(`/company${href}`);
  const match = matchGeneral || employeeMatch || companyMatch;

  useEffect(() => {
    if (!match) {
      document.title = `VK HR Tek - Страница не найдена`;
    } else {
      document.title = `VK HR Tek${title ? ` - ${title}` : ''}`;
    }
  }, [pathname, title, href, match, role]);
};
