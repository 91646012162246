import React from 'react';

import { Field, FieldProps, FieldRenderProps } from 'react-final-form';

import { MultipleTextInput as Input } from './../../input';

/**
 * Компонент для ввода нескольких текстовых значений.
 * @param {string} [id] - Уникальный идентификатор элемента.
 * @param {string} name - Имя поля формы.
 * @param {string} [type="text"] - Тип поля ввода.
 * @param {string} [label] - Метка поля ввода.
 * @param {string} [placeholder] - Подсказка для поля ввода.
 * @param {number} [max=3] - Максимальное количество текстовых полей.
 * @param {function} [onChange] - Коллбэк функция, вызываемая при изменении значений.
 * @param {string} [tooltip] - Текст всплывающей подсказки.
 * @param {boolean} [showError=true] - Флаг для отображения ошибок.
 * @param {boolean} [required=false] - Флаг обязательности поля.
 * @param {boolean} [disabled=false] - Флаг для отключения поля.
 * @param {boolean} [loading=false] - Флаг состояния загрузки.
 * @param {string} [testId] - Идентификатор для тестирования.
 * @param {Object} rest - Дополнительные пропсы передаваемые в Field.
 * @returns {JSX.Element} Компонент поля ввода с несколькими текстовыми значениями.
 */
export const MultipleTextInput = ({
  id,
  name,
  type,
  label,
  placeholder,
  max = 3,
  onChange,
  tooltip,
  showError = true,
  required = false,
  disabled = false,
  loading = false,
  testId,
  ...rest
}: FieldProps<string | undefined, FieldRenderProps<string | undefined>>) => {
  return (
    <Field name={name} {...rest}>
      {({ input, meta }) => {
        const values = Array.isArray(input.value) ? [...input.value] : [];

        const handleChange = (index: number, value: string) => {
          values[index] = value;
          input.onChange(values);

          if (onChange) {
            onChange(values);
          }
        };

        return (
          <Input
            {...input}
            id={id}
            testId={testId}
            label={label}
            required={required}
            disabled={disabled}
            values={values}
            onChange={handleChange}
            type={type}
            error={meta.touched && !!meta.error}
            helperText={showError && meta.touched && meta.error}
            placeholder={placeholder}
            tooltip={tooltip}
            max={max}
            loading={loading}
          />
        );
      }}
    </Field>
  );
};
