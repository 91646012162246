import { injectable, inject } from 'inversify';

import { History } from '@vk-hr-tek/core/history';
import { RoleService } from '@vk-hr-tek/core/http';

import { AbsencesRouter } from '../../../absences/types';
import { modules } from '../../modules';

@injectable()
export class AbsencesRouterService implements AbsencesRouter {
  constructor(
    @inject(History) private history: History,
    @inject(RoleService) private readonly role: RoleService,
  ) {}

  goToVacationDetail(query?: string) {
    if (!query || query === '?') {
      this.history.push({
        pathname: `/${this.role.get()}/${modules.vacations.basename}`,
      });
      return;
    }

    this.history.push({
      pathname: `/${this.role.get()}/${modules.vacations.basename}`,
      search: query.startsWith('?') ? query : `?${query}`,
    });
  }

  goToDetail(id: string) {
    this.history.push(`/${this.role.get()}/${modules.events.basename}/${id}`);
  }

  goToList(query?: string) {
    if (!query || query === '?') {
      this.history.push(`/${this.role.get()}/${modules.absences.basename}`);
      return;
    }

    this.history.push({
      pathname: `/${this.role.get()}/${modules.absences.basename}`,
      search: query.startsWith('?') ? query : `?${query}`,
    });
  }

  goToPaperList(query?: string) {
    if (!query || query === '?') {
      this.history.push(
        `/${this.role.get()}/${modules.absences.basename}/${
          modules.events.children.paper.basename
        }`,
      );
      return;
    }

    this.history.push({
      pathname: `/${this.role.get()}/${modules.absences.basename}/${
        modules.events.children.paper.basename
      }`,
      search: query.startsWith('?') ? query : `?${query}`,
    });
  }

  goToCreateType() {
    this.history.push(
      `/${this.role.get()}/${modules.absences.basename}/create-type`,
    );
  }

  goToCreate(query: string) {
    this.history.push({
      pathname: `/${this.role.get()}/${modules.absences.basename}/create`,
      search: query.startsWith('?') ? query : `?${query}`,
    });
  }

  goToCompanyAbsencesDetail(query?: string) {
    if (!query || query === '?') {
      this.history.push(
        `/${this.role.get()}/${modules.absences.basename}/detail`,
      );
      return;
    }

    this.history.push({
      pathname: `/${this.role.get()}/${modules.absences.basename}/detail`,
      search: query.startsWith('?') ? query : `?${query}`,
    });
  }

  goToCreateBatch({
    companyId,
    eventTypeId,
    employeeId,
    parentEventId,
  }: {
    companyId: string;
    eventTypeId: string;
    employeeId?: string;
    parentEventId?: string;
  }) {
    let searchString = `?companyId=${companyId}&eventTypeId=${eventTypeId}`;

    if (employeeId) {
      searchString += `&employeeId=${employeeId}`;
    }

    if (parentEventId) {
      searchString += `&parentEventId=${parentEventId}`;
    }

    this.history.push({
      pathname: `/${this.role.get()}/${modules.absences.basename}/create-batch`,
      search: searchString,
    });
  }

  goBack(prevState: { pathname: string; search: string }) {
    this.history.push(prevState);
  }
}
