import React from 'react';

import { SvgIcon } from '@material-ui/core';
import classNames from 'classnames';

import { IconProps } from './Icon.types';
import { useStyles } from './Icon.styles';

export const DownloadIcon = ({ size, color }: IconProps) => {
  const classes = useStyles();
  return (
    <SvgIcon
      className={classNames(
        size && classes[`icon_size_${size}` as const],
        color && classes[`icon_color_${color}` as const],
      )}
      viewBox="0 0 24 24"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0018 2C12.4989 2 12.9018 2.40294 12.9018 2.9V13.9017L17.4643 9.27068C17.8132 8.9166 18.383 8.91236 18.7371 9.2612C19.0912 9.61004 19.0954 10.1799 18.7466 10.534L12.643 16.7292C12.4736 16.9011 12.2423 16.9978 12.001 16.9976C11.7596 16.9973 11.5285 16.9002 11.3595 16.728L5.26985 10.5225C4.92171 10.1677 4.92708 9.5979 5.28184 9.24975C5.63661 8.90161 6.20644 8.90698 6.55458 9.26174L11.1018 13.8955V2.9C11.1018 2.40294 11.5048 2 12.0018 2ZM4.00232 20.1C4.00232 19.6029 4.40526 19.2 4.90232 19.2H19.1014C19.5984 19.2 20.0014 19.6029 20.0014 20.1C20.0014 20.597 19.5984 21 19.1014 21H4.90232C4.40526 21 4.00232 20.597 4.00232 20.1Z"
        fill="white"
      />
    </SvgIcon>
  );
};
