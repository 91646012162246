import React from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import classNames from 'classnames';
import { Check as CheckIcon } from '@material-ui/icons';
import { Checkbox as MUICheckbox } from '@material-ui/core';

import { Box } from '../Box';

import useStyles from './Checkbox.styles';

/**
 * Интерфейс, представляющий свойства для компонента Checkbox.
 *
 * @interface CheckboxProps
 * @property {boolean} [checked=false] - Указывает, отмечен ли флажок.
 * @property {boolean} [disabled=false] - Указывает, отключен ли флажок.
 * @property {boolean} [indeterminate=false] - Указывает, находится ли флажок в неопределенном состоянии.
 * @property {(value: boolean) => void} [onChange=() => {}] - Функция для обработки события изменения флажка.
 * @property {boolean} [stopPropagation=false] - Указывает, следует ли останавливать распространение события при клике и фокусировке.
 * @property {number} [verticalClickArea=0] - Размер вертикальной области клика для флажка.
 * @property {string} [testId] - Необязательный идентификатор теста для флажка.
 * @property {boolean} [withoutMargin=false] - Указывает, следует ли удалять стандартный отступ у флажка.
 */

interface CheckboxProps {
  checked?: boolean;
  disabled?: boolean;
  indeterminate?: boolean;
  onChange?: (value: boolean) => void;
  stopPropagation?: boolean;
  verticalClickArea?: number;
  testId?: string;
  withoutMargin?: boolean;
}

/**
 * Компонент, отображающий флажок с различными состояниями и настраиваемыми опциями.
 * Поддерживает состояния отмеченного, отключенного и неопределенного флажка, а также может обрабатывать события с возможностью остановки распространения.
 *
 * @component
 * @param {CheckboxProps} props - Свойства для компонента Checkbox.
 * @param {boolean} [props.checked=false] - Указывает, отмечен ли флажок.
 * @param {boolean} [props.disabled=false] - Указывает, отключен ли флажок.
 * @param {boolean} [props.indeterminate=false] - Указывает, находится ли флажок в неопределенном состоянии.
 * @param {(value: boolean) => void} [props.onChange=() => {}] - Функция для обработки события изменения флажка.
 * @param {boolean} [props.stopPropagation=false] - Указывает, следует ли останавливать распространение события при клике и фокусировке.
 * @param {number} [props.verticalClickArea=0] - Размер вертикальной области клика для флажка.
 * @param {string} [props.testId] - Необязательный идентификатор теста для флажка.
 * @param {boolean} [props.withoutMargin=false] - Указывает, следует ли удалять стандартный отступ у флажка.
 * @returns {JSX.Element} Компонент Checkbox.
 */

export const Checkbox = ({
  checked,
  disabled,
  indeterminate,
  onChange = () => {},
  stopPropagation,
  verticalClickArea = 0,
  testId,
  withoutMargin = false,
  ...rest
}: CheckboxProps) => {
  const classes = useStyles({ verticalClickArea });

  const checkbox = (
    <MUICheckbox
      {...rest}
      checked={checked}
      data-testid={testId}
      checkedIcon={
        <Box
          className={classNames(classes.iconChecked, {
            [classes.iconDisabled]: disabled,
          })}
          fontSize={16}
        >
          <CheckIcon fontSize="inherit" />
        </Box>
      }
      className={classNames(
        'aqa_checkbox',
        classes.input,
        classes.verticalClickArea,
        withoutMargin && classes.withoutMargin,
      )}
      color="primary"
      disabled={disabled}
      indeterminate={indeterminate}
      onChange={(event) => {
        event.stopPropagation();
        onChange(event.target.checked);
      }}
    />
  );

  if (stopPropagation) {
    return (
      <FormControlLabel
        classes={{
          root: classes.controlLabel,
        }}
        onClick={(event) => event.stopPropagation()}
        onFocus={(event) => event.stopPropagation()}
        control={checkbox}
        label=""
      />
    );
  }

  return checkbox;
};
