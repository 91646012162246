import React, { createContext, ReactNode } from 'react';

import { SizeMe } from 'react-sizeme';

/**
 * Контекст для предоставления ширины корневого контейнера его потомкам.
 * @type {React.Context<number | null>}
 */

export const RootWidthContext = createContext<number | null>(null);

interface RootWidthContextProviderProps {
  children: ReactNode | ReactNode[];
}

/**
 * Компонент-провайдер для RootWidthContext, который использует `react-sizeme` для измерения ширины корневого контейнера.
 *
 * @param {RootWidthContextProviderProps} props - Свойства компонента RootWidthContextProvider.
 * @param {React.ReactNode | React.ReactNode[]} props.children - Дочерние элементы, которые будут отображены внутри провайдера.
 * @returns {JSX.Element} - Отображённый компонент RootWidthContextProvider, который предоставляет контекст ширины корневого контейнера.
 */

export const RootWidthContextProvider = ({
  children,
}: RootWidthContextProviderProps) => (
  <SizeMe>
    {({ size }) => (
      <div id="sizeme" style={{ width: '100%' }}>
        <div
          style={
            {
              containerType: 'inline-size',
              width: size.width,
            } as Record<string, string | number>
          }
        >
          <RootWidthContext.Provider value={size.width}>
            {children}
          </RootWidthContext.Provider>
        </div>
      </div>
    )}
  </SizeMe>
);
