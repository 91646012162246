import React, { ReactNode } from 'react';

import classNames from 'classnames';

import useStyles from './ListItem.styles';
interface ListItemProps {
  children: ReactNode;
}

/**
 * Компонент элемента списка.
 *
 * @param {ListItemProps} props - Свойства компонента.
 * @param {ReactNode} props.children - Дочерние элементы, которые будут отображены внутри элемента списка.
 * @returns {JSX.Element} Элемент списка с заданными дочерними элементами.
 */

export const ListItem = ({ children }: ListItemProps) => {
  const classes = useStyles();

  return (
    <li className={classNames(classes.listItem, 'aqa_list_item')}>
      {children}
    </li>
  );
};
