export interface DashboardRouter {
  goBack(prevState: { pathname: string; search: string }): void;
  goToEventList(side?: 'employee' | 'company', query?: string): void;
  goToPolicyList(): void;
  goToVacationList(): void;
  goToList(): void;
  goToWidgetsList(): void;
  goToPersonalLinksSettings(): void;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DashboardRouter = Symbol('DashboardRouter');
