import React, { Field, FieldProps, FieldRenderProps } from 'react-final-form';

import { SwitchInput as Input } from '../../input';

/**
 * Интерфейс пропсов для компонента SwitchInput
 * @interface SwitchInputProps
 * @property {string} label - Текст подписи к переключателю
 * @property {(check: boolean) => void} [onChange] - Функция обратного вызова при изменении состояния переключателя
 * @property {boolean} [disabled] - Флаг, указывающий, отключен ли переключатель
 * @property {'start' | 'end'} [labelPlacement] - Расположение подписи относительно переключателя
 */
interface SwitchInputProps {
  label: string;
  onChange?: (check: boolean) => void;
  disabled?: boolean;
  labelPlacement?: 'start' | 'end';
}

/**
 * Компонент переключателя с подписью
 * @component SwitchInput
 * @param {SwitchInputProps & FieldProps<boolean | undefined, FieldRenderProps<boolean | undefined>>} props - Пропсы компонента
 * @param {string} props.label - Текст подписи к переключателю
 * @param {(check: boolean) => void} [props.onChange] - Функция обратного вызова при изменении состояния переключателя
 * @param {boolean} [props.disabled] - Флаг, указывающий, отключен ли переключатель
 * @param {'start' | 'end'} [props.labelPlacement] - Расположение подписи относительно переключателя
 * @param {string} [props.tooltip] - Текст всплывающей подсказки
 * @returns {JSX.Element} Компонент переключателя
 */
export const SwitchInput = ({
  label,
  onChange,
  disabled,
  labelPlacement,
  tooltip,
  ...rest
}: SwitchInputProps &
  FieldProps<boolean | undefined, FieldRenderProps<boolean | undefined>>) => {
  return (
    <Field {...rest}>
      {({ input }) => {
        return (
          <Input
            {...input}
            checked={!!input.value}
            label={label}
            onChange={(check) => {
              input.onChange(check);
              if (onChange) {
                onChange(check);
              }
            }}
            disabled={disabled}
            labelPlacement={labelPlacement}
            tooltip={tooltip}
          />
        );
      }}
    </Field>
  );
};
