import React from 'react';

import classNames from 'classnames';
import { SvgIcon } from '@material-ui/core';

import { IconProps } from '../Icon.types';
import { useStyles } from '../Icon.styles';

export const GlobeLinkIcon = ({ size, color }: IconProps) => {
  const classes = useStyles();

  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(
        size && classes[`icon_size_${size}` as const],
        color && classes[`icon_color_${color}` as const],
      )}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.90156 11.9996C3.90156 11.2733 3.99715 10.5694 4.17644 9.89961H8.22712C8.14706 10.5709 8.10156 11.2723 8.10156 11.9996C8.10156 12.7269 8.14706 13.4284 8.22711 14.0996H4.17644C3.99715 13.4299 3.90156 12.7259 3.90156 11.9996ZM8.53331 15.8996H4.90055C5.9426 17.7929 7.71983 19.2246 9.84763 19.8101C9.3718 18.7707 8.87482 17.4443 8.53331 15.8996ZM14.1555 19.8101C16.2833 19.2246 18.0605 17.7929 19.1026 15.8996H15.4698C15.1283 17.4443 14.6313 18.7707 14.1555 19.8101ZM13.6215 15.8996C13.1788 17.7025 12.5145 19.1526 12.0016 20.0979C11.4887 19.1526 10.8243 17.7025 10.3816 15.8996H13.6215ZM13.9618 14.0996H10.0413C9.95273 13.4315 9.90156 12.7296 9.90156 11.9996C9.90156 11.2696 9.95273 10.5678 10.0414 9.89961H13.9618C14.0504 10.5678 14.1016 11.2696 14.1016 11.9996C14.1016 12.7296 14.0504 13.4315 13.9618 14.0996ZM15.776 14.0996H19.8267C20.006 13.4299 20.1016 12.7259 20.1016 11.9996C20.1016 11.2733 20.006 10.5694 19.8267 9.89961H15.776C15.8561 10.5709 15.9016 11.2723 15.9016 11.9996C15.9016 12.7269 15.8561 13.4284 15.776 14.0996ZM15.4698 8.09961H19.1026C18.0605 6.20628 16.2833 4.77458 14.1554 4.18911C14.6313 5.22849 15.1283 6.55495 15.4698 8.09961ZM9.84767 4.18911C7.71985 4.77458 5.94261 6.20629 4.90055 8.09961H8.53332C8.87484 6.55495 9.37183 5.22849 9.84767 4.18911ZM10.3816 8.09961H13.6215C13.1788 6.29674 12.5144 4.84667 12.0016 3.90144C11.4887 4.84667 10.8243 6.29674 10.3816 8.09961ZM12.0016 2.09961C6.53394 2.09961 2.10156 6.53199 2.10156 11.9996C2.10156 17.4672 6.53394 21.8996 12.0016 21.8996C17.4692 21.8996 21.9016 17.4672 21.9016 11.9996C21.9016 6.53199 17.4692 2.09961 12.0016 2.09961Z"
      />
    </SvgIcon>
  );
};
