import React from 'react';

import clsx from 'classnames';
import { IconButton } from '@material-ui/core';

import useStyles from './Day.styles';

export interface DayProps {
  children: React.ReactNode;
  className?: string;
  current?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  selected?: boolean;

  isEnd?: boolean;

  isStart?: boolean;

  holiday?: boolean;

  isInRange?: boolean;
}

/**
 * Day - компонент для отображения дня в календаре.
 *
 * @param {DayProps} props - Свойства компонента.
 * @param {React.ReactNode} props.children - Текст дня.
 * @param {string} [props.className] - Дополнительный CSS-класс.
 * @param {boolean} [props.current=false] - Является ли день сегодняшним.
 * @param {boolean} [props.disabled=false] - Отключён ли день.
 * @param {boolean} [props.hidden=false] - Скрыт ли день.
 * @param {boolean} [props.selected=false] - Выбран ли день.
 * @param {boolean} [props.isEnd=false] - Является ли день концом диапазона.
 * @param {boolean} [props.isStart=false] - Является ли день началом диапазона.
 * @param {boolean} [props.holiday=false] - Является ли день праздничным.
 * @param {boolean} [props.isInRange=false] - Является ли день частью выбранного диапазона.
 * @returns {JSX.Element} Возвращает JSX-элемент представляющий компонент Day.
 */

export const Day: React.FC<DayProps> = ({
  children,
  disabled,
  hidden,
  current,
  selected,
  isInRange,
  isEnd,
  isStart,
  holiday,
  className,
  ...other
}) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.dayWrapper,
        isInRange && classes.dayWrapperInRange,
        isStart && classes.dayWrapperStart,
        isEnd && classes.dayWrapperEnd,
      )}
    >
      <IconButton
        className={clsx(className, classes.day, {
          [classes.hidden]: hidden,
          [classes.current]: current,
          [classes.daySelected]: selected,
          [classes.dayDisabled]: disabled,
          [classes.holiday]: holiday,
          aqa_icon_button: true,
        })}
        tabIndex={hidden || disabled ? -1 : 0}
        disableRipple
        {...other}
      >
        {children}
      </IconButton>
    </div>
  );
};
