import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { classToPlain } from 'class-transformer';

import { DashboardService, Widget } from '@vk-hr-tek/app/app/gen/dashboard';
import { AppError } from '@vk-hr-tek/core/error';

import { ThunkExtra } from '@app/store';

import { DashboardState } from '..';

export const changeUserWidget = createAsyncThunk<Widget, Widget, ThunkExtra>(
  'widgets/changeUserWidget',
  async (widget, { rejectWithValue, extra: { inject } }) => {
    try {
      const dashboardService = inject(DashboardService);

      await dashboardService.userWidget({
        body: { switched_on: !widget.switched_on },
        params: { widget_type: widget.type },
      });

      return widget;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const widgetsReducers = (
  builder: ActionReducerMapBuilder<DashboardState>,
) => {
  builder.addCase(changeUserWidget.pending, (state) => {
    state.widgets.status = 'loading';
  });
  builder.addCase(changeUserWidget.fulfilled, (state, { payload }) => {
    state.widgets.status = 'complete';
    state.layout = [
      ...state.layout.map((layoutItem) => {
        if (layoutItem.type === payload.type) {
          return { ...layoutItem, switched_on: !layoutItem.switched_on };
        }

        return layoutItem;
      }),
    ];
  });
  builder.addCase(changeUserWidget.rejected, (state, { payload, error }) => {
    state.widgets.status = 'failed';
    state.widgets.error =
      payload ||
      ({
        info: (error && error.message) || 'Что-то пошло не так',
        status: 500,
        source: 'client',
        title: 'Internal client error',
      } as AppError);
  });
};
