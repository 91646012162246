import React, { ReactNode } from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { Box } from '../../Box';

import { useStyles } from './RadioInputComplexLabel.styles';

/**
 * Интерфейс, определяющий свойства компонента RadioInputComplexLabel.
 * @typedef {Object} RadioInputProps<T>
 * @template T - Тип метки.
 * @property {Array<{ label: T; value: string | number }>} items - Массив элементов, каждый из которых содержит метку и значение.
 * @property {string | number | undefined} value - Текущее выбранное значение.
 * @property {(value: string | number) => void} onChange - Функция обратного вызова, которая вызывается при изменении выбранного значения.
 * @property {(item: T) => ReactNode} render - Функция для рендера метки каждого элемента.
 */
interface RadioInputProps<T> {
  items: {
    label: T;
    value: string | number;
  }[];
  value: string | number | undefined;
  onChange: (value: string | number) => void;
  render: (item: T) => ReactNode;
}

/**
 * Компонент RadioInputComplexLabel представляет собой настраиваемый радио-ввод с комплексными метками.
 * Он позволяет рендерить пользовательские компоненты меток для каждого варианта радио.
 *
 * @component
 * @param {RadioInputProps<T>} props - Свойства компонента.
 * @param {Array<{ label: T; value: string | number }>} props.items - Массив элементов, каждый из которых содержит метку и значение.
 * @param {string | number | undefined} props.value - Текущее выбранное значение.
 * @param {(value: string | number) => void} props.onChange - Функция обратного вызова, которая вызывается при изменении выбранного значения.
 * @param {(item: T) => ReactNode} props.render - Функция для рендера метки каждого элемента.
 * @returns {React.ReactElement} Отрендеренный компонент RadioInputComplexLabel.
 */

export const RadioInputComplexLabel = <T,>({
  items,
  value,
  onChange,
  render,
  ...rest
}: RadioInputProps<T>) => {
  const classes = useStyles();

  return (
    <FormControl component="fieldset" classes={{ root: classes.formRoot }}>
      <RadioGroup
        {...rest}
        row={false}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        className="aqa_radioinput_block"
      >
        {items.map((item) => (
          <Box key={item.value} className={classes.itemBox}>
            <FormControlLabel
              key={item.value}
              value={item.value}
              classes={{
                root: classes.itemRoot,
              }}
              control={
                <Radio
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  classes={{
                    root: classes.inputRoot,
                    checked: classes.checked,
                  }}
                />
              }
              label={render(item.label)}
              labelPlacement="end"
            />
          </Box>
        ))}
      </RadioGroup>
    </FormControl>
  );
};
