import React from 'react';

import { InputAdornment } from '@material-ui/core';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';

import { Box } from '../../Box';

interface ShowPasswordButtonProps {
  isShow: boolean;
  disabled: boolean;
  error: boolean;
  onClick: () => void;
}

/**
 * Определяет цвет иконки в зависимости от состояний disabled и error.
 *
 * @param {boolean} disabled - Если true, кнопка отключена.
 * @param {boolean} error - Если true, есть ошибка в поле ввода.
 * @returns {'disabled' | 'error' | 'primary'} - Цвет иконки.
 */

const getIconColor = (disabled: boolean, error: boolean) => {
  if (disabled) return 'disabled';
  if (error) return 'error';
  return 'primary';
};

/**
 * ShowPasswordButton - это компонент, который переключает видимость поля ввода пароля.
 * Он отображает либо иконку видимости, либо иконку невидимости в зависимости от пропса `isShow`.
 *
 * @param {Object} props - Свойства компонента.
 * @param {boolean} props.isShow - Указывает, является ли пароль в данный момент видимым.
 * @param {boolean} props.disabled - Если true, кнопка будет отключена.
 * @param {boolean} props.error - Если true, кнопка будет отмечена цветом ошибки.
 * @param {() => void} props.onClick - Функция обратного вызова для обработки событий клика.
 * @returns {JSX.Element} - JSX компонента.
 */
export const ShowPasswordButton = ({
  isShow,
  disabled,
  error,
  onClick,
}: ShowPasswordButtonProps) => (
  <InputAdornment position="end">
    <Box height={24} onClick={onClick}>
      {isShow ? (
        <VisibilityIcon color={getIconColor(disabled, error)} />
      ) : (
        <VisibilityOffIcon color={getIconColor(disabled, error)} />
      )}
    </Box>
  </InputAdornment>
);
