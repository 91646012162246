import {
  ActionReducerMapBuilder,
  createAction,
  createAsyncThunk,
  EntityState,
} from '@reduxjs/toolkit';
import { classToPlain } from 'class-transformer';
import { FORM_ERROR } from 'final-form';

import { AppError } from '@vk-hr-tek/core/error';
import { Calendar } from '@vk-hr-tek/core/calendar';
import { Filter } from '@vk-hr-tek/core/filter';
import { UserRoleEnum } from '@vk-hr-tek/app/app/types';
import {
  VacationAvailableDaysParams,
  VacationAvailableDaysResponse,
  VacationScheduleMyDeadlineResponse,
  VacationsService,
} from '@vk-hr-tek/app/app/gen/vacations';

import { ThunkExtra, ThunkValuesAndActions } from '@app/store';
import {
  AbsenceCompanyItem,
  AbsenceListGroup,
  AbsenceListResponse,
} from '@app/gen/absences';
import { CreateEventResponse } from '@app/gen/events';

import {
  CreateEventFromAbsenceDto,
  GetDeadlineDto,
  GetCompanyAbsencesListDto,
  CreateEventFromButtonDto,
} from '../../dto';
import { AbsencesListService, AbsencesService } from '../../services';
import { AbsencesState } from '../../slice';
import { AbsencesRouter } from '../../types';
import {
  absencesAdapter,
  AbsencesWithRootState,
  setEventCreationError,
} from '../absences.state';

export const setFilters = createAction<Record<string, Filter[]>>(
  'absencesList/setFilters',
);

export const getAbsenceList = createAsyncThunk<
  AbsenceListResponse,
  GetCompanyAbsencesListDto & {
    role?: UserRoleEnum.Company | UserRoleEnum.Employee;
  },
  ThunkExtra
>(
  'absences/getCompanyAbsenceList',
  async (
    getAbsencesListDto,
    { rejectWithValue, getState, dispatch, extra: { inject } },
  ) => {
    const calendar = inject(Calendar);
    const absencesListService = inject(AbsencesListService);

    const { year, companyId, role } = getAbsencesListDto;
    delete getAbsencesListDto.role;

    try {
      const state = (getState() as AbsencesWithRootState).absences;

      let filters = state.absenceList.filters;

      if (!filters[companyId]) {
        const awailableFilters = (await absencesListService.getAbsenceFilters(
          companyId,
        )) as Filter[];
        filters = { [companyId]: awailableFilters };
        dispatch(setFilters(filters));
      }

      const result =
        role === UserRoleEnum.Company
          ? await absencesListService.getCompanyAbsencesList(getAbsencesListDto)
          : await absencesListService.getEmployeeAbsencesList(
              getAbsencesListDto,
            );

      await calendar.download(new Date(String(year)));

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getAdditionalCompanyAbsenceItems = createAsyncThunk<
  AbsenceListResponse,
  ThunkValuesAndActions<GetCompanyAbsencesListDto>,
  ThunkExtra<AbsencesWithRootState>
>(
  'absences/getAdditionalCompanyAbsenceItems',
  async (
    { values, actions },
    { rejectWithValue, getState, dispatch, extra: { inject } },
  ) => {
    try {
      const state = getState().absences;
      const absencesListService = inject(AbsencesListService);

      let filters = state.absenceList.filters;

      if (!filters[values.companyId]) {
        const awailableFilters = (await absencesListService.getAbsenceFilters(
          values.companyId,
        )) as Filter[];
        filters = { [values.companyId]: awailableFilters };
        dispatch(setFilters(filters));
      }

      const result = await absencesListService.getCompanyAbsencesList(values);
      actions.resolve(null);

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const createEventFromAbsence = createAsyncThunk<
  CreateEventResponse,
  CreateEventFromAbsenceDto,
  ThunkExtra
>(
  'absences/createEvent',
  async (
    { employeeId, eventTypeId, attributes },
    { rejectWithValue, extra: { inject } },
  ) => {
    try {
      const result = await inject(AbsencesService).createEventFromAbsence({
        eventTypeId,
        employeeId,
        attributes,
      });

      inject<AbsencesRouter>(AbsencesRouter).goToDetail(result.event_id);

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getDeadline = createAsyncThunk<
  VacationScheduleMyDeadlineResponse,
  GetDeadlineDto,
  ThunkExtra
>(
  'absences/getDeadline',
  async ({ companyId }, { rejectWithValue, extra: { inject } }) => {
    try {
      const result = await inject(AbsencesService).getVacationScheduleDeadline({
        companyId,
      });

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getAbsenceCompanies = createAsyncThunk<
  AbsenceCompanyItem[],
  undefined,
  ThunkExtra
>(
  'absences/getAbsenceCompanies',
  async (_, { rejectWithValue, extra: { inject } }) => {
    try {
      const result = await inject(AbsencesListService).getAbsenceCompanies();

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const resetEventCreationError = createAction(
  'absences/resetEventCreationError',
);

export const createEventFromButton = createAsyncThunk<
  CreateEventResponse,
  ThunkValuesAndActions<CreateEventFromButtonDto>,
  ThunkExtra
>(
  'createEvent',
  async ({ values, actions }, { rejectWithValue, extra: { inject } }) => {
    try {
      const absenceService = inject(AbsencesService);
      const result = await absenceService.createEvent(values);

      inject<AbsencesRouter>(AbsencesRouter).goToDetail(result.event_id);

      actions.resolve(null);

      return result;
    } catch (err) {
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getVacationAvailableDays = createAsyncThunk<
  VacationAvailableDaysResponse,
  VacationAvailableDaysParams,
  ThunkExtra
>(
  'absences/getVacationAvailableDays',
  async (params, { rejectWithValue, extra: { inject } }) => {
    try {
      const vacations = inject(VacationsService);

      const result = await vacations.vacationAvailableDays({
        params,
      });

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const absencesReducers = (
  builder: ActionReducerMapBuilder<
    EntityState<AbsenceListGroup> & AbsencesState
  >,
) => {
  builder.addCase(getVacationAvailableDays.fulfilled, (state, action) => {
    state.availableDays.data = action.payload;
    state.availableDays.status = 'complete';
  });
  builder.addCase(getVacationAvailableDays.pending, (state) => {
    state.availableDays.status = 'loading';
  });
  builder.addCase(getVacationAvailableDays.rejected, (state) => {
    state.availableDays.status = 'failed';
  });

  builder.addCase(setFilters, (state, action) => {
    state.absenceList.filters = action.payload;
  });

  builder.addCase(getAbsenceList.pending, (state) => {
    state.absenceList.status = 'loading';
  });
  builder.addCase(getAbsenceList.fulfilled, (state, { payload }) => {
    state.absenceList.status = 'complete';
    state.absenceList.items = payload.groups;
    state.absenceList.total = payload.total;
    state.currentIds = payload.groups.map(({ employee }) => employee.id);

    absencesAdapter.upsertMany(state, payload.groups);
  });
  builder.addCase(getAbsenceList.rejected, (state) => {
    state.absenceList.status = 'failed';
  });

  builder.addCase(getAdditionalCompanyAbsenceItems.pending, (state) => {
    state.absenceList.additionalStatus =
      state.absenceList.additionalStatus === 'failed'
        ? 'loadingAfterFail'
        : 'loading';
  });

  builder.addCase(
    getAdditionalCompanyAbsenceItems.fulfilled,
    (state, { payload }) => {
      state.absenceList.additionalStatus = 'complete';
      state.absenceList.total = payload.total;
      state.absenceList.items = [...state.absenceList.items, ...payload.groups];
      state.currentIds = [
        ...state.currentIds,
        ...payload.groups.map(({ employee }) => employee.id),
      ];

      absencesAdapter.upsertMany(state, payload.groups);
    },
  );

  builder.addCase(getAdditionalCompanyAbsenceItems.rejected, (state) => {
    state.absenceList.additionalStatus = 'failed';
  });

  builder.addCase(getAbsenceCompanies.pending, (state) => {
    state.absenceCompanies.status = 'loading';
    state.creation.status = 'loading';
  });
  builder.addCase(getAbsenceCompanies.fulfilled, (state, { payload }) => {
    state.absenceCompanies.status = 'complete';
    state.absenceCompanies.items = payload;
    state.creation.status = 'complete';
    state.creation.items = payload;
    state.canCreateEvent = !!payload.length;
  });
  builder.addCase(getAbsenceCompanies.rejected, (state) => {
    state.absenceCompanies.status = 'failed';
    state.creation.status = 'failed';
  });

  builder.addCase(getDeadline.pending, (state) => {
    state.deadline.status = 'loading';
    state.deadline.info = {};
  });
  builder.addCase(getDeadline.fulfilled, (state, { payload }) => {
    state.deadline.status = 'complete';
    state.deadline.info = payload;
  });
  builder.addCase(getDeadline.rejected, (state) => {
    state.deadline.status = 'failed';
    state.deadline.info = {};
  });

  builder.addCase(resetEventCreationError, (state) => {
    state.eventCreationError = null;
  });

  builder.addCase(
    createEventFromAbsence.rejected,
    (state, { payload, error, meta }) => {
      if (!meta.aborted) {
        setEventCreationError(state, { payload, error });
      }
    },
  );

  builder.addCase(createEventFromButton.pending, (state) => {
    state.creation.status = 'loading';
    state.creation.error = null;
  });
  builder.addCase(createEventFromButton.fulfilled, (state) => {
    state.creation.status = 'complete';
    state.creation.error = null;
  });
  builder.addCase(
    createEventFromButton.rejected,
    (state, { payload, error }) => {
      state.creation.status = 'failed';
      state.creation.error =
        payload ||
        ({
          info: (error && error.message) || 'Что-то пошло не так',
          status: 500,
          source: 'client',
          title: 'Internal client error',
        } as AppError);
    },
  );
};
