import React from 'react';

import { Typography } from '../../../Typography';
import { Box } from '../../../Box';

interface HelperTextProps {
  text: string;
}

/**
 * HelperText - компонент для отображения вспомогательного текста.
 *
 * @param {HelperTextProps} props - Свойства компонента.
 * @param {string} props.text - Текст для отображения.
 * @returns {JSX.Element} Возвращает JSX-элемент представляющий компонент HelperText.
 */

export const HelperText = ({ text }: HelperTextProps) => {
  return (
    <Box pt="4">
      <Typography variant="caption" color="text.light.tertirary">
        {text}
      </Typography>
    </Box>
  );
};
