import React, { ReactNode, KeyboardEvent, MouseEvent, useMemo } from 'react';

import classNames from 'classnames';
import { Fade } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { Box } from '@vk-hr-tek/ui/Box/Box';
import { Link } from '@vk-hr-tek/ui/Link';
import { Tooltip } from '@vk-hr-tek/ui/Tooltip';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { useInject } from '@vk-hr-tek/core/ioc';
import { AnalyticService, GoalsEnum } from '@vk-hr-tek/core/analytics';

import { TIMEOUT_FOR_FADE } from '../Sidebar.constants';

import { useStyles } from './SidebarItem.styles';

interface SidebarItemProps {
  title: string;
  sidebarOpened: boolean;
  icon: ReactNode;
  href: string;
  isActive: boolean;
  aqaClass: string;
  skeletonLoading: boolean;
  closeDrawer?: (event: KeyboardEvent | MouseEvent) => void;
}

export const SidebarItem = ({
  title,
  sidebarOpened,
  skeletonLoading,
  href,
  aqaClass,
  icon,
  isActive,
  closeDrawer,
}: SidebarItemProps) => {
  const styles = useStyles();
  const analyticService = useInject(AnalyticService);

  const pageMetaTitle = useMemo(
    () =>
      typeof document === 'object' &&
      document !== null &&
      !Array.isArray(document) &&
      document?.title
        ? document.title.split('-')
        : [],
    [],
  );

  const renderAqaClass = (className: string): string =>
    `${aqaClass}_${className}`;

  const handleLogAction = () => {
    analyticService.registerAction(GoalsEnum.openLink, {
      page: pageMetaTitle[pageMetaTitle.length - 1] || '',
      element: 'Боковое меню',
      block: 'Боковое меню',
      actionType: `Переход на страницу ${title}`,
    });
  };

  return (
    <Box>
      <Link
        onClick={closeDrawer}
        to={href}
        onLogAction={handleLogAction}
        variant="simple"
      >
        <Box
          className={classNames(
            styles.menuItem,
            isActive && styles.active,
            aqaClass,
          )}
        >
          <Tooltip title={title} placement="right" disabled={sidebarOpened}>
            <Box
              p="8"
              display="flex"
              minWidth={40}
              minHeight={40}
              alignItems="center"
              justifyContent="center"
              className={renderAqaClass('menuItemWithoutSubmenuIcon')}
            >
              {skeletonLoading ? <Skeleton width="100%" /> : icon}
            </Box>
          </Tooltip>

          <Fade in={sidebarOpened} timeout={TIMEOUT_FOR_FADE}>
            <Typography
              variant="body3"
              noWrap
              className={renderAqaClass('menuItemWithoutSubmenuText')}
            >
              {skeletonLoading ? <Skeleton width={200} /> : title}
            </Typography>
          </Fade>
        </Box>
      </Link>
    </Box>
  );
};
