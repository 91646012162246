import React, { ReactNode } from 'react';

import classNames from 'classnames';
import { FormControlLabel } from '@material-ui/core';

import { Box } from '../../Box';
import { Checkbox } from '../../Checkbox';
import { Label } from '../common';
import { TypographyColor } from '../../Typography';

import useStyles from './CheckboxInput.styles';

interface CheckboxInputProps {
  checked?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  indeterminate?: boolean;
  label: string;
  labelPlacement?: 'start' | 'end';
  labelColor?: TypographyColor;
  labelVariant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'body3'
    | 'caption'
    | 'button'
    | 'overline'
    | 'inherit';
  onChange?: (value: boolean) => void;
  tooltip?: ReactNode | null;
  testId?: string;
}

/**
 * CheckboxInput - компонент для отображения чекбокса с меткой.
 *
 * @param {CheckboxInputProps} props - Свойства компонента.
 * @param {boolean} [props.checked=false] - Состояние чекбокса.
 * @param {boolean} [props.disabled=false] - Заблокирован ли чекбокс.
 * @param {boolean} [props.fullWidth=false] - Занимает ли чекбокс всю ширину.
 * @param {boolean} [props.indeterminate=false] - Состояние неопределенности чекбокса.
 * @param {string} props.label - Текст метки.
 * @param {'start' | 'end'} [props.labelPlacement='end'] - Позиция метки относительно чекбокса.
 * @param {TypographyColor} [props.labelColor='text.light.secondary'] - Цвет текста метки.
 * @param {'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2' | 'body3' | 'caption' | 'button' | 'overline' | 'inherit'} [props.labelVariant='body3'] - Вариант текста метки.
 * @param {(value: boolean) => void} [props.onChange=() => {}] - Обработчик изменения состояния чекбокса.
 * @param {ReactNode | null} [props.tooltip=null] - Всплывающая подсказка.
 * @param {string} [props.testId] - Идентификатор для тестирования.
 * @returns {JSX.Element} Возвращает JSX-элемент представляющий компонент CheckboxInput.
 */

export const CheckboxInput = ({
  checked,
  disabled = false,
  fullWidth = false,
  indeterminate,
  label,
  labelPlacement = 'end',
  labelColor = 'text.light.secondary',
  labelVariant = 'body3',
  onChange = () => {},
  tooltip,
  testId,
  ...rest
}: CheckboxInputProps) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      className={classNames(
        classes.checkboxInput,
        'aqa_checkbox_input',
        fullWidth && classes.fullWidth,
      )}
      control={
        <Checkbox
          {...rest}
          checked={checked}
          disabled={disabled}
          indeterminate={indeterminate}
          onChange={onChange}
          testId={testId}
        />
      }
      label={
        <Box
          mr={labelPlacement === 'start' ? '8' : '0'}
          ml={labelPlacement === 'end' ? '8' : '0'}
        >
          <Label
            label={label}
            tooltip={tooltip}
            color={labelColor}
            variant={labelVariant}
            mb="0"
          />
        </Box>
      }
      labelPlacement={labelPlacement}
    />
  );
};
