import React from 'react';

import { InputAdornment } from '@material-ui/core';

import { CircularProgress } from '../../../CircularProgress';

import useStyles from './Preloader.style';

/**
 * Preloader - компонент для отображения индикатора загрузки в поле ввода.
 *
 * @returns {JSX.Element} Возвращает JSX-элемент представляющий компонент Preloader.
 */

export const Preloader = () => {
  const classes = useStyles();

  return (
    <InputAdornment position="end" className={classes.preloader}>
      <CircularProgress size={18} />
    </InputAdornment>
  );
};
