/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from 'react';

import MUIAvatar, {
  AvatarProps as MUIAvatarProps,
} from '@material-ui/core/Avatar';
import MUIBadge from '@material-ui/core/Badge';
import classNames from 'classnames';

import { useStyles } from './Avatar.styles';

/**
 * Возможные размеры аватара.
 *
 * @typedef {('extraLarge' | 'large' | 'medium' | 'small' | 'extraSmall')} AvatarSize
 */
export type AvatarSize =
  | 'extraLarge'
  | 'large'
  | 'medium'
  | 'small'
  | 'extraSmall';

/**
 * Интерфейс свойств для компонента Avatar.
 *
 * @interface AvatarProps
 * @extends {MUIAvatarProps}
 * @property {boolean} [withBadge=false] - Определяет, будет ли отображаться значок на аватаре.
 * @property {AvatarSize} [size='medium'] - Размер аватара.
 * @property {() => Promise<Blob>} [loadPhoto] - Функция, возвращающая промис, который разрешается в Blob объект, содержащий изображение аватара.
 */
type AvatarProps = MUIAvatarProps & {
  withBadge?: boolean;
  size?: AvatarSize;
  loadPhoto?: () => Promise<Blob>;
};

/**
 * Настраиваемый компонент аватара, построенный с использованием Material-UI.
 *
 * @component
 * @param {AvatarProps} props - Свойства для компонента Avatar.
 * @param {boolean} [props.withBadge=false] - Определяет, будет ли отображаться значок на аватаре.
 * @param {AvatarSize} [props.size='medium'] - Размер аватара.
 * @param {React.ReactNode} [props.children] - Содержимое, отображаемое внутри аватара.
 * @param {() => Promise<Blob>} [props.loadPhoto] - Функция, возвращающая промис, который разрешается в Blob объект, содержащий изображение аватара.
 * @param {string} [props.alt] - Альтернативный текст для изображения аватара.
 * @returns {JSX.Element} Компонент Avatar.
 */

export const Avatar = (props: AvatarProps) => {
  const { withBadge, size = 'medium', children, loadPhoto, alt } = props;

  const classes = useStyles();

  const [src, setSrc] = useState('');

  useEffect(() => {
    if (!src) {
      loadPhoto?.()
        .then((blob) => {
          setSrc(URL.createObjectURL(blob));
        })
        .catch(() => {
          // do nothing
        });
    }
  }, [loadPhoto, src]);

  return withBadge ? (
    <MUIBadge
      className={`aqa_avatar_badge ${classes.badge}`}
      overlap="circular"
      variant="dot"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Avatar
        className="aqa_avatar"
        alt={alt}
        children={children}
        src={src}
        size={size}
      />
    </MUIBadge>
  ) : (
    <MUIAvatar
      src={src}
      alt={alt}
      children={src ? <></> : children}
      data-testid="avatar"
      className={classNames('aqa_avatar', classes.avatar, classes[size])}
    />
  );
};
