import React, { ReactNode } from 'react';

import InputLabel from '@material-ui/core/InputLabel';
import classNames from 'classnames';

import { Box } from '../../../Box';
import { Typography, TypographyColor } from '../../../Typography';

import useStyles from './Label.styles';

interface LabelProps {
  label: string;
  tooltip?: ReactNode | null;
  color?: TypographyColor;
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'body3'
    | 'caption'
    | 'button'
    | 'overline'
    | 'inherit';
  required?: boolean;
  className?: string;
  inputId?: string;
  mb?: '0' | '8';
}

/**
 * Label - компонент для отображения метки поля ввода.
 *
 * @param {LabelProps} props - Свойства компонента.
 * @param {string} props.label - Текст метки.
 * @param {ReactNode | null} [props.tooltip=null] - Всплывающая подсказка.
 * @param {TypographyColor} [props.color='text.light.secondary'] - Цвет текста метки.
 * @param {'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2' | 'body3' | 'caption' | 'button' | 'overline' | 'inherit'} [props.variant='body3'] - Вариант текста метки.
 * @param {boolean} [props.required=false] - Обязательное поле?
 * @param {string} [props.className] - Дополнительный CSS-класс.
 * @param {string} [props.inputId] - ID связанного поля ввода.
 * @param {'0' | '8'} [props.mb='8'] - Отступ снизу.
 * @returns {JSX.Element} Возвращает JSX-элемент представляющий компонент Label.
 */

export const Label = ({
  label,
  tooltip = null,
  color = 'text.light.secondary',
  variant = 'body3',
  required = false,
  className,
  inputId,
  mb = '8',
}: LabelProps) => {
  const classes = useStyles({ mb });

  return (
    <InputLabel
      className={classNames(classes.label, className, 'aqa_input_label')}
      htmlFor={inputId}
    >
      <Typography color={color} variant={variant}>
        {label}
      </Typography>
      {required && <span className={classes.required}>*</span>}
      <Box ml="4">{tooltip}</Box>
    </InputLabel>
  );
};
