import React from 'react';

import {
  useField,
  FieldProps,
  FieldRenderProps,
  FormSpy,
  useForm,
} from 'react-final-form';

import { Calendar } from '@vk-hr-tek/core/calendar';
import { compose, ValidateType } from '@vk-hr-tek/core/validation';

import { DateInput as Input } from '../../input';

/**
 * Компонент для ввода даты с использованием календаря.
 * @param {string} label - Подпись поля ввода.
 * @param {string} name - Имя поля ввода, используется для связывания с формой.
 * @param {string} [tooltip] - Вспомогательный текст, отображаемый при наведении на поле.
 * @param {() => void} [onClear] - Функция, вызываемая при очистке поля.
 * @param {string} [placeholder='ДД.ММ.ГГГГ'] - Текст-заполнитель для поля ввода.
 * @param {boolean} [required=false] - Флаг, указывающий, является ли поле обязательным.
 * @param {boolean} [disablePast=false] - Флаг, запрещающий выбор прошлых дат.
 * @param {Date} [minDate=Calendar.minDate] - Минимальная дата, доступная для выбора.
 * @param {Date} [maxDate=Calendar.maxDate] - Максимальная дата, доступная для выбора.
 * @param {string} [recognizedValue] - Распознанное значение даты.
 * @param {(date: Date) => boolean} [shouldDisableDate] - Функция для определения, должна ли быть недоступна для выбора конкретная дата.
 * @param {string} [externalError] - Внешняя ошибка, отображаемая в поле.
 * @param {string} [otherSelectedDate] - Другая выбранная дата, может использоваться для валидации диапазона дат.
 * @param {boolean} [clearable=false] - Флаг, позволяющий очищать поле ввода.
 * @param {boolean} [disabled=false] - Флаг, отключающий поле ввода.
 * @param {boolean} [showRange=false] - Флаг, указывающий, должен ли отображаться диапазон дат.
 * @param {boolean} [alwaysShowClear=false] - Флаг, всегда отображающий кнопку очистки.
 * @param {boolean} [loading=false] - Флаг, указывающий, что поле находится в состоянии загрузки.
 * @param {boolean} [isRecognitionUsedBefore=false] - Флаг, указывающий, использовалась ли ранее распознавание значений.
 * @param {string} [testId] - Идентификатор для тестирования.
 * @param {ValidateType[]} [validateArray] - Массив валидаторов для поля.
 * @param {ValidateType[]} [warnings] - Массив валидаторов для предупреждений.
 * @param {(value: string | undefined) => React.ReactNode} [after] - Функция, возвращающая дополнительный контент после поля.
 * @param {FieldProps<string | undefined, FieldRenderProps<string | undefined>>} rest - Дополнительные пропсы для поля.
 * @returns {React.ReactNode} Компонент DateInput.
 */
export const DateInput = ({
  label,
  name,
  tooltip,
  onClear,
  placeholder = 'ДД.ММ.ГГГГ',
  required = false,
  disablePast = false,
  minDate = Calendar.minDate,
  maxDate = Calendar.maxDate,
  recognizedValue,
  shouldDisableDate,
  externalError,
  otherSelectedDate,
  clearable = false,
  disabled = false,
  showRange = false,
  alwaysShowClear = false,
  loading = false,
  isRecognitionUsedBefore = false,
  testId,
  validateArray,
  warnings,
  ...rest
}: { after?: (value: string | undefined) => React.ReactNode } & FieldProps<
  string | undefined,
  FieldRenderProps<string | undefined> & {
    validateArray?: ValidateType[] | undefined;
    warnings?: ValidateType[] | undefined;
  }
>) => {
  const { mutators } = useForm();

  const form = useForm();

  const { input, meta } = useField(name, {
    validate: compose(validateArray || []),
    ...rest,
  });

  const composedWarnings = compose(warnings || []);

  return (
    <>
      <Input
        {...input}
        testId={testId}
        formBlur={form.blur} //TODO find better solution use form.blur inside input.onChange
        label={label}
        tooltip={tooltip}
        onClear={onClear}
        disabled={disabled}
        placeholder={placeholder}
        error={!disabled && meta.touched && meta.error}
        warning={!disabled && meta.touched && meta.data?.warning}
        minDate={minDate}
        maxDate={maxDate}
        recognizedValue={recognizedValue}
        isRecognitionUsedBefore={isRecognitionUsedBefore}
        required={required}
        disablePast={disablePast}
        otherSelectedDate={otherSelectedDate}
        externalError={externalError}
        shouldDisableDate={shouldDisableDate}
        clearable={clearable}
        showRange={showRange}
        alwaysShowClear={alwaysShowClear}
        loading={loading}
      />
      <FormSpy
        subscription={{ values: true }}
        onChange={({ values }) => {
          mutators.setFieldData(input.name, {
            warning: composedWarnings(values[input.name], values),
          });
        }}
      />
    </>
  );
};
