import { useContext } from 'react';

import { useMediaQuery } from '@material-ui/core';

import { RootWidthContext } from '../../rootWidth';

/**
 * Пользовательский хук, который сопоставляет строку медиа-запроса и возвращает булево значение в зависимости от текущего размера экрана.
 * Он также учитывает контекст ширины корневого элемента для более точного сопоставления.
 *
 * @param {string} query - Строка медиа-запроса для сопоставления с текущим размером экрана.
 * @returns {boolean} - Возвращает true, если текущий размер экрана соответствует медиа-запросу, иначе false.
 */

export const useMatchQuery = (query: string) => {
  const rootWidth = useContext(RootWidthContext);
  const mediaQuery = useMediaQuery(query);

  if (typeof rootWidth === 'number') {
    const isMinWidth = query.includes('min-width');
    const isMaxWidth = query.includes('max-width');
    const queryWidth = parseInt(query.match(/\d+/) as unknown as string);

    if (isMinWidth) {
      return rootWidth >= queryWidth;
    }
    if (isMaxWidth) {
      return rootWidth <= queryWidth;
    }
  }

  return mediaQuery;
};
