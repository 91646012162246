import {
  ActionReducerMapBuilder,
  createAction,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import { classToPlain, plainToClass } from 'class-transformer';
import { FORM_ERROR } from 'final-form';

import { AppError } from '@vk-hr-tek/core/error';
import { showError, showNotification } from '@vk-hr-tek/core/notifications';
import { ActivityLogService } from '@vk-hr-tek/core/activity-log';
import { AnalyticService } from '@vk-hr-tek/core/analytics';
import { User as UserService } from '@vk-hr-tek/core/logger';
import { DownloadService } from '@vk-hr-tek/core/download';
import { awaitDispatch } from '@vk-hr-tek/core/redux';

import {
  GenerateOneCVKAccessTokenResponse,
  OneCVKAccessTokenStatusResponse,
  UserPermissionsResponse,
  UserResponse,
} from '@app/gen/users';
import { ThunkExtra, ThunkValuesAndActions } from '@app/store';
import { UserRoleEnum } from '@app/types';
import { CandidateResponse } from '@app/gen/candidates';

import {
  UpdateCompanyUnitDto,
  SetDefaultAttorneyDto,
  ExportAttorneyDto,
} from '../../dto';
import { getCompanyUnit, getCompanyUnitEventTypes } from '../actions';
import { UserUnitsService } from '../../services';
import {
  UserApiService,
  UserRouterService,
  UserEntityService,
} from '../../services/';
import { UserState } from '../user.state';
import { UserRouter } from '../../types';

const delay = (time: number) =>
  new Promise((resolve) => {
    setTimeout(() => resolve(null), time);
  });

export const getUserPermissions = createAsyncThunk<
  UserPermissionsResponse,
  undefined,
  ThunkExtra
>(
  'user/getUserPermissions',
  async (_, { rejectWithValue, extra: { inject } }) => {
    try {
      const result = await inject(UserApiService).getUserPermissions();
      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getUser = createAsyncThunk<
  [UserResponse, UserPermissionsResponse],
  undefined,
  ThunkExtra
>('user/getUser', async (_, { rejectWithValue, extra: { inject } }) => {
  try {
    const user = await inject(UserApiService).getUser();
    const router = inject<UserRouter>(UserRouter);
    const analyticsService = inject<AnalyticService>(AnalyticService);

    inject<UserEntityService>(UserService).save(user);

    if (user.certificate.status !== 'released') {
      router.goToCreateCertificate();
    }

    const permissions = await inject(UserApiService).getUserPermissions();
    analyticsService.setUser(user.id);

    return [user, permissions];
  } catch (err) {
    return rejectWithValue(classToPlain(err) as AppError);
  }
});

export const getCandidateUser = createAsyncThunk<
  CandidateResponse,
  undefined,
  ThunkExtra
>(
  'user/getCandidateUser',
  async (_, { rejectWithValue, extra: { inject } }) => {
    try {
      const result = await inject(UserApiService).getCandidateUser();

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const updateUserGroups = createAsyncThunk<
  UserResponse,
  void,
  ThunkExtra
>(
  'user/updateUserGroups',
  async (_, { rejectWithValue, extra: { inject } }) => {
    try {
      return await inject(UserApiService).getUser();
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const get1CTokenStatus = createAsyncThunk<
  OneCVKAccessTokenStatusResponse,
  void,
  ThunkExtra
>(
  'user/get1CTokenStatus',
  async (_, { rejectWithValue, extra: { inject } }) => {
    try {
      const service = inject(UserApiService);

      return await service.get1CTokenStatus();
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const pollUser = createAsyncThunk<UserResponse, undefined, ThunkExtra>(
  'user/pollUser',
  async (_, { rejectWithValue, getState, extra: { inject } }) => {
    try {
      const service = inject(UserApiService);
      const certificateStatus = getState().user.data?.certificate.status || '';

      let user = await service.getUser();

      while (user.certificate.status === certificateStatus) {
        const delayTime = 5000;
        await delay(delayTime);
        user = await service.getUser();
      }

      return user;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const subscribeOnTelegram = createAsyncThunk<
  void,
  undefined,
  ThunkExtra
>(
  'user/subscribeOnTelegram',
  async (_, { rejectWithValue, dispatch, extra: { inject } }) => {
    try {
      const result = await inject(UserApiService).getTelegramSubscriptionUrl();

      inject(UserRouterService).goToTelegram(result.redirect_url);
    } catch (err) {
      dispatch(
        showError('При подключении Telegram уведомлений произошла ошибка'),
      );

      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const cancelTelegramSubscription = createAsyncThunk<
  void,
  undefined,
  ThunkExtra
>(
  'user/cancelTelegramSubscription',
  async (_, { rejectWithValue, dispatch, extra: { inject } }) => {
    try {
      return await inject(UserApiService).clearTelegramSubscription();
    } catch (err) {
      dispatch(
        showError('При отключении Telegram уведомлений произошла ошибка'),
      );

      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const profileView = createAsyncThunk<void, undefined, ThunkExtra>(
  'user/profileView',
  async (_, { rejectWithValue, extra: { inject } }) => {
    try {
      await inject(ActivityLogService).profileView();
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const changeSide = createAsyncThunk<void, UserRoleEnum, ThunkExtra>(
  'user/changeSide',
  async (userRole, { rejectWithValue, extra: { inject } }) => {
    try {
      await inject(ActivityLogService).changeSide(userRole);
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const setUserRole = createAsyncThunk<void, UserRoleEnum, ThunkExtra>(
  'user/setUserRole',
  async (userRole, { rejectWithValue, dispatch }) => {
    try {
      dispatch(changeSide(userRole));
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const generate1CToken = createAsyncThunk<
  GenerateOneCVKAccessTokenResponse,
  void,
  ThunkExtra
>('user/generate1CToken', async (_, { rejectWithValue, extra: { inject } }) => {
  try {
    return await inject(UserApiService).getToken1C();
  } catch (err) {
    return rejectWithValue(classToPlain(err) as AppError);
  }
});

export const reset1CToken = createAction<void>('auth/reset1CToken');

export const changeRole = createAction<UserRoleEnum>('user/changeRole');

export const setDefaultAttorney = createAsyncThunk<
  void,
  ThunkValuesAndActions<SetDefaultAttorneyDto>,
  ThunkExtra
>(
  'user/setDefaultAttorney',
  async (
    { values, actions },
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      await inject(UserApiService).setDefaultAttorney(values);

      dispatch(showNotification('МЧД назначена активной!'));
      actions.resolve(null);
      dispatch(getUser());
    } catch (err) {
      dispatch(showError('Произошла ошибка при назначении активной МЧД'));
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const exportAttorney = createAsyncThunk<
  void,
  ExportAttorneyDto,
  ThunkExtra
>(
  'user/exportAttorney',
  async (
    exportAttorneyDto: ExportAttorneyDto,
    { rejectWithValue, extra: { inject } },
  ) => {
    try {
      const { file, filename } = await inject(UserApiService).exportAttorney(
        plainToClass(ExportAttorneyDto, exportAttorneyDto),
      );

      await inject(DownloadService).doDownload(
        file,
        filename || 'example.docx',
      );
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getUpdateSettingData = createAsyncThunk<
  undefined,
  { companyId: string; unitId: string },
  ThunkExtra
>(
  'user/getCreationData',
  async ({ companyId, unitId }, { rejectWithValue, dispatch }) => {
    try {
      await Promise.all([
        await awaitDispatch(dispatch(getCompanyUnitEventTypes({ companyId }))),
        await awaitDispatch(
          dispatch(
            getCompanyUnit({
              companyId,
              unitId,
            }),
          ),
        ),
      ]);
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const updateCompanyUnit = createAsyncThunk<
  void,
  {
    values: UpdateCompanyUnitDto;
    actions: {
      resolve: (value: unknown) => void;
      reject: (value: unknown) => void;
    };
  },
  ThunkExtra
>(
  'user/updateCompanyUnit',
  async (
    { values, actions },
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      const router = inject<UserRouter>(UserRouter);
      await inject(UserUnitsService).updateCompanyUnit(values);

      dispatch(
        showNotification('Согласования по бизнес-процессам успешно изменены'),
      );

      actions.resolve(null);

      router.goToProfile(`companyId-${values.companyId}`);
    } catch (err) {
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getUserReducers = (
  builder: ActionReducerMapBuilder<UserState>,
) => {
  builder.addCase(getUser.pending, (state) => {
    state.status = 'loading';
    state.error = null;
  });
  builder.addCase(getUser.fulfilled, (state, { payload }) => {
    const [user, permissions] = payload;

    state.status = 'complete';
    state.error = null;
    state.data = user;

    const defaultPermissions = {
      view_events: false,
      view_event_to_paper: false,
      view_employees: false,
      view_policies: false,
      view_company: false,
      view_company_profiles: false,
      view_settings: false,
      view_candidates: false,
      view_vacations: false,
      view_vacations_settings: false,
      view_smev_errors: false,
    };

    state.permissions = {
      ...defaultPermissions,
      employee: permissions.employee_permissions,
      company: permissions.company_permissions,
      general: permissions.general_permissions,
    };
  });
  builder.addCase(getUser.rejected, (state, { payload, error }) => {
    state.status = 'failed';
    state.error =
      /* istanbul ignore next */
      payload ||
      ({
        info: (error && error.message) || 'Unknown error',
        status: 500,
        source: 'client',
        title: 'Internal client error',
      } as AppError);
  });
  builder.addCase(pollUser.fulfilled, (state, { payload }) => {
    state.status = 'complete';
    state.error = null;
    state.data = payload;
  });
  builder.addCase(pollUser.rejected, (state, { payload, error }) => {
    state.status = 'failed';
    state.error =
      /* istanbul ignore next */
      payload ||
      ({
        info: (error && error.message) || 'Unknown error',
        status: 500,
        source: 'client',
        title: 'Internal client error',
      } as AppError);
  });
  builder.addCase(updateUserGroups.fulfilled, (state, { payload }) => {
    if (state?.data) {
      state.data = {
        ...state.data,
        groups: payload.groups,
      };
    }
  });
  builder.addCase(getUserPermissions.pending, (state, { meta }) => {
    state.status = 'loading';
    state.error = null;
    state.currentRequestId = meta.requestId;
  });
  builder.addCase(getUserPermissions.fulfilled, (state, { payload, meta }) => {
    if (state.currentRequestId === meta.requestId) {
      state.status = 'complete';
      state.error = null;

      const defaultPermissions = {
        view_events: false,
        view_event_to_paper: false,
        view_employees: false,
        view_policies: false,
        view_company: false,
        view_settings: false,
        view_candidates: false,
        view_vacations: false,
        view_vacations_settings: false,
      };

      state.permissions = {
        ...defaultPermissions,
        employee: payload.employee_permissions,
        company: payload.company_permissions,
        general: payload.general_permissions,
      };

      state.currentRequestId = null;
    }
  });
  builder.addCase(
    getUserPermissions.rejected,
    (state, { payload, error, meta }) => {
      if (state.currentRequestId === meta.requestId) {
        state.status = 'failed';
        state.error =
          payload ||
          ({
            info: (error && error.message) || 'Unknown error',
            status: 500,
            source: 'client',
            title: 'Internal client error',
          } as AppError);
        state.currentRequestId = null;
      }
    },
  );
  builder.addCase(subscribeOnTelegram.pending, (state) => {
    state.subscriptions.telegram = 'enablingInProgress';
  });
  builder.addCase(subscribeOnTelegram.fulfilled, (state) => {
    state.subscriptions.telegram = 'enabled';
  });
  builder.addCase(subscribeOnTelegram.rejected, (state) => {
    state.subscriptions.telegram = 'disabled';
  });
  builder.addCase(cancelTelegramSubscription.pending, (state) => {
    state.subscriptions.telegram = 'disablingInProgress';
  });
  builder.addCase(cancelTelegramSubscription.fulfilled, (state) => {
    state.subscriptions.telegram = 'disabled';
  });
  builder.addCase(cancelTelegramSubscription.rejected, (state) => {
    state.subscriptions.telegram = 'enabled';
  });
  builder.addCase(generate1CToken.pending, (state) => {
    state.token1C.status = 'loading';
  });
  builder.addCase(generate1CToken.fulfilled, (state, { payload }) => {
    state.token1C.status = 'complete';
    state.token1C.value = payload.access_token;
  });
  builder.addCase(generate1CToken.rejected, (state, { payload, error }) => {
    state.token1C.status = 'failed';
    state.token1C.error =
      /* istanbul ignore next */
      payload ||
      ({
        info: (error && error.message) || 'Unknown error',
        status: 500,
        source: 'client',
        title: 'Internal client error',
      } as AppError);
  });

  builder.addCase(get1CTokenStatus.fulfilled, (state, { payload }) => {
    state.token1C.expiresStatus = payload.status;
    state.token1C.expiresAt = payload.expires_at || null;
  });

  builder.addCase(get1CTokenStatus.rejected, (state, { payload, error }) => {
    state.token1C.error =
      /* istanbul ignore next */
      payload ||
      ({
        info: (error && error.message) || 'Unknown error',
        status: 500,
        source: 'client',
        title: 'Internal client error',
      } as AppError);
  });

  builder.addCase(reset1CToken, (state) => {
    state.token1C.status = 'idle';
    state.token1C.value = null;
  });

  builder.addCase(getCandidateUser.pending, (state) => {
    state.candidateUser.status = 'loading';
    state.candidateUser.error = null;
  });
  builder.addCase(getCandidateUser.fulfilled, (state, action) => {
    state.candidateUser.status = 'complete';
    state.candidateUser.entity = action.payload.candidate;
    state.candidateUser.error = null;
  });
  builder.addCase(getCandidateUser.rejected, (state, { payload, error }) => {
    state.candidateUser.status = 'failed';
    state.candidateUser.error =
      payload ||
      ({
        info:
          /* istanbul ignore next */ (error && error.message) ||
          'Unknown error',
        status: 500,
        source: 'client',
        title: 'Internal client error',
      } as AppError);
  });
};
