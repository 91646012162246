import React from 'react';

import MUIIconButton from '@material-ui/core/IconButton';
import {
  IconButtonClassKey,
  PropTypes,
  ButtonBaseActions,
} from '@material-ui/core';
import { TouchRippleProps } from '@material-ui/core/ButtonBase/TouchRipple';
import { ClassNameMap } from '@material-ui/styles';

/**
 * Пользовательский компонент IconButton, который расширяет компонент IconButton из Material-UI.
 * Он включает дополнительную функциональность для снятия фокуса с активного элемента при клике.
 *
 * @param {Object} props - Свойства, которые можно передать компоненту IconButton.
 * @param {PropTypes.Color} [props.color] - Цвет кнопки.
 * @param {boolean} [props.disableFocusRipple=false] - Если true, эффект всплеска при фокусировке с клавиатуры будет отключен.
 * @param {false | 'end' | 'start'} [props.edge] - Если указано, применяется небольшой фикс для выравнивания содержимого по началу или концу.
 * @param {'small' | 'medium'} [props.size='medium'] - Размер кнопки.
 * @param {'span'} [props.component] - Компонент, используемый для корневого узла.
 * @param {string} [props.className] - Переопределяет или расширяет стили, применяемые к компоненту.
 * @param {(e: React.MouseEvent<HTMLElement>) => void} [props.onClick] - Коллбэк, вызываемый при клике на кнопку.
 * @param {'button'} [props.type='button'] - Тип кнопки.
 * @param {Partial<ClassNameMap<IconButtonClassKey>>} [props.classes] - Переопределяет или расширяет стили, применяемые к компоненту.
 * @param {React.Ref<ButtonBaseActions>} [props.action] - HTML-элемент или функция, возвращающая один.
 * @param {React.Ref<unknown>} [props.buttonRef] - Ссылка, прикрепленная к элементу кнопки.
 * @param {boolean} [props.centerRipple=false] - Если true, эффект всплеска начинается по центру кнопки.
 * @param {React.ReactNode} [props.children] - Содержимое кнопки.
 * @param {boolean} [props.disabled=false] - Если true, кнопка будет отключена.
 * @param {boolean} [props.disableRipple=false] - Если true, эффект всплеска будет отключен.
 * @param {boolean} [props.disableTouchRipple=false] - Если true, эффект всплеска при касании будет отключен.
 * @param {boolean} [props.focusRipple=false] - Если true, кнопка будет показывать эффект всплеска при фокусировке.
 * @param {string} [props.focusVisibleClassName] - Переопределяет или расширяет стили, применяемые к кнопке с видимым фокусом.
 * @param {React.FocusEventHandler<any>} [props.onFocusVisible] - Коллбэк, вызываемый при фокусировке кнопки с клавиатуры.
 * @param {string | number} [props.tabIndex] - Атрибут tabindex.
 * @param {Partial<TouchRippleProps>} [props.TouchRippleProps] - Свойства, применяемые к элементу `TouchRipple`.
 * @returns {JSX.Element} JSX-элемент, представляющий кнопку IconButton.
 */

export const IconButton = (
  props: JSX.IntrinsicAttributes & {
    color?: PropTypes.Color;
    disableFocusRipple?: boolean;
    edge?: false | 'end' | 'start';
    size?: 'small' | 'medium';
    component?: 'span';
    className?: string;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    type?: 'button';
    classes?: Partial<ClassNameMap<IconButtonClassKey>>;
    testId?: string;
  } & {
    action?: React.Ref<ButtonBaseActions>;
    buttonRef?: React.Ref<unknown>;
    centerRipple?: boolean;
    children?: React.ReactNode;
    disabled?: boolean;
    disableRipple?: boolean;
    disableTouchRipple?: boolean;
    focusRipple?: boolean;
    focusVisibleClassName?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onFocusVisible?: React.FocusEventHandler<any>;
    tabIndex?: string | number;
    TouchRippleProps?: Partial<TouchRippleProps>;
  },
) => {
  const { onClick, testId = 'icon-button-test-id', ...rest } = props;

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }

    onClick?.(e);
  };

  return (
    <MUIIconButton
      {...rest}
      onClick={handleClick}
      className={
        props.className
          ? 'aqa_icon_button'
          : `${props.className} aqa_icon_button`
      }
      data-testid={testId}
    >
      {props.children}
    </MUIIconButton>
  );
};
