import React from 'react';

import {
  ClearSharp as ClearSharpIcon,
  ListAlt as ListAltIcon,
} from '@material-ui/icons';

import { Box } from '@vk-hr-tek/ui/Box';
import { EllipsisText } from '@vk-hr-tek/ui/EllipsisText';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { Link } from '@vk-hr-tek/ui/Link';

interface SubstituteContentProps {
  name: string;
  personalNumber: string;
  managableUnitEmployeeId: string;
  managableUnitCompanyName: string;
  id: string;
  eventTypeId: string;
  handleOpen: (
    selectEmployeeId: string,
    selectEmployeeCompanyName: string,
    selectedUserId: string,
    selectedUserName: string,
    selectedEventTypeId: string,
  ) => () => void;
}

export const SubstituteContent = ({
  name,
  personalNumber,
  managableUnitEmployeeId,
  managableUnitCompanyName,
  id,
  eventTypeId,
  handleOpen,
}: SubstituteContentProps) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="start"
      width="100%"
    >
      <Box>
        <Box fontSize={14} mb="4">
          <EllipsisText rows={1}>
            <Typography variant="body3">{name}</Typography>
          </EllipsisText>
        </Box>
        <Box display="flex" alignItems="center">
          <Box
            display="flex"
            alignItems="center"
            color="text.light.tertirary"
            mr="8"
            fontSize={16}
          >
            <ListAltIcon fontSize="inherit" />
          </Box>
          <EllipsisText rows={1} wordBreak="break-all">
            <Typography variant="caption" color="text.light.tertirary">
              {personalNumber}
            </Typography>
          </EllipsisText>
        </Box>
      </Box>

      <Link
        size="large"
        onClick={handleOpen(
          managableUnitEmployeeId,
          managableUnitCompanyName,
          id,
          name,
          eventTypeId,
        )}
        startIcon={<ClearSharpIcon color="action" />}
        stroke={false}
      />
    </Box>
  );
};
