import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { classToPlain } from 'class-transformer';

import { AppError } from '@vk-hr-tek/core/error';

import { ThunkExtra } from '@app/store';
import { PersonalLink, DashboardService } from '@app/gen/dashboard';

import { DashboardState } from '../dashboard.state';

export const getPersonalLinks = createAsyncThunk<
  PersonalLink[],
  undefined,
  ThunkExtra
>(
  'dashboard/getPersonalLinks',
  async (_, { rejectWithValue, extra: { inject } }) => {
    try {
      const service = inject(DashboardService);

      const result = await service.getWidgetPersonalLinks();

      return result.personal_links || [];
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const personalLinksReducers = (
  builder: ActionReducerMapBuilder<DashboardState>,
) => {
  builder.addCase(getPersonalLinks.pending, (state) => {
    state.personalLinks.status = 'loading';
  });
  builder.addCase(getPersonalLinks.fulfilled, (state, { payload }) => {
    state.personalLinks.status = 'complete';
    state.personalLinks.items = payload;
  });
  builder.addCase(getPersonalLinks.rejected, (state) => {
    state.personalLinks.status = 'failed';
  });
};
