import { injectable } from 'inversify';

import { FormatService } from '@vk-hr-tek/core/format';
import { Calendar } from '@vk-hr-tek/core/calendar';
import { Filter } from '@vk-hr-tek/core/filter';
import { ChipColorUnion } from '@vk-hr-tek/ui/ColoredChip/ColoredChip.types';
import { Logger } from '@vk-hr-tek/core/logger';

import {
  Event as EventDetailEntity,
  EventListItem as EventListEntity,
  Action,
  Attribute,
} from '@app/gen/events';

import {
  VACATION_START_ATTR_ID,
  VACATION_END_ATTR_ID,
  BUSINESS_TRIP_START_ATTR_ID,
  BUSINESS_TRIP_END_ATTR_ID,
  FIRST_VACATION_START_ATTR_ID,
  SECOND_VACATION_START_ATTR_ID,
  THIRD_VACATION_START_ATTR_ID,
  FOURTH_VACATION_START_ATTR_ID,
  FIFTH_VACATION_START_ATTR_ID,
  FIRST_VACATION_END_ATTR_ID,
  SECOND_VACATION_END_ATTR_ID,
  THIRD_VACATION_END_ATTR_ID,
  FOURTH_VACATION_END_ATTR_ID,
  FIFTH_VACATION_END_ATTR_ID,
  PERIOD_START_ID,
  PERIOD_END_ID,
} from '../events.constants';
import { EventUnitData } from '../types/EventUnitData';

const colors: Record<string, ChipColorUnion> = {
  completed: 'green',
  system: 'gray',
  in_paper: 'gray',
  upload: 'orange',
  accept: 'purple',
  unep_sign: 'blue',
  unep_sign_batch: 'blue',
  ukep_sign: 'blue',
  ukep_sign_batch: 'blue',
  pep_sign: 'blue',
  pep_sign_batch: 'blue',
  default: 'gray',
  competency_eval: 'gray',
  competency_profile: 'blue',
  canceled: 'red',
  generate_document_from_template: 'orange',
  declined: 'red',
  declined_sign: 'red',
  system_booking_hook: 'gray',
  system_booking_approve: 'purple',
  system_booking_limits_exceeded_approve: 'purple',
  system_booking_trip_create: 'gray',
  system_booking_trip_change: 'gray',
  system_booking_trip_ordering: 'blue',
  system_booking_trip_limit: 'blue',
  system_booking_trip_limit_approved: 'gray',
};

const vacationPeriods = [
  {
    start: VACATION_START_ATTR_ID,
    end: VACATION_END_ATTR_ID,
  },
  {
    start: FIRST_VACATION_START_ATTR_ID,
    end: FIRST_VACATION_END_ATTR_ID,
  },
  {
    start: SECOND_VACATION_START_ATTR_ID,
    end: SECOND_VACATION_END_ATTR_ID,
  },
  {
    start: THIRD_VACATION_START_ATTR_ID,
    end: THIRD_VACATION_END_ATTR_ID,
  },
  {
    start: FOURTH_VACATION_START_ATTR_ID,
    end: FOURTH_VACATION_END_ATTR_ID,
  },
  {
    start: FIFTH_VACATION_START_ATTR_ID,
    end: FIFTH_VACATION_END_ATTR_ID,
  },
];

@injectable()
export class EventsMapper {
  constructor(
    private format: FormatService,
    private calendar: Calendar,
    private logger: Logger,
  ) {}

  processEmployeeInitials(event: EventListEntity | EventDetailEntity) {
    if (event.employee) {
      return `${event.employee.second_name} ${event.employee.first_name[0]}. ${event.employee.middle_name[0]}.`;
    }
    return '';
  }

  processEmployeeFullName(event: EventListEntity | EventDetailEntity) {
    if (event.employee) {
      return `${event.employee.second_name} ${event.employee.first_name} ${event.employee.middle_name}`;
    }
    return '';
  }

  processEmployee(event: EventListEntity | EventDetailEntity) {
    if (event.employee) {
      const units: EventUnitData[] = [];

      if (event.employee.unit) {
        units.push({
          label: 'Управленческая',
          mobileLabel: 'Упр. структура',
          value: event.employee.unit,
          color: 'blue',
          width: '50%',
        });
      }

      if (event.employee.legal_unit) {
        units.push({
          label: 'Юридическая',
          mobileLabel: 'Юр. структура',
          value: event.employee.legal_unit,
          color: 'purple',
          width: '50%',
        });
      }

      if (units.length === 1) {
        units[0].width = '100%';
      }

      return {
        id: event.employee.id,
        name: `${event.employee.second_name} ${event.employee.first_name}`,
        fullName: this.processEmployeeFullName(event),
        avatar: `${event.employee.second_name[0]}${event.employee.first_name[0]}`,
        personnelNumber: event.employee.personnel_number,
        birthday: this.format.toDate(event.employee.birthday),
        dismissed:
          !!event.employee.dismissed_at &&
          Date.now() > +new Date(event.employee.dismissed_at),
        dismissedAt: event.employee.dismissed_at
          ? this.format.toDate(event.employee.dismissed_at)
          : null,
        phone: event.employee.phone ?? '-',
        email: event.employee.email ?? '-',
        units: units,
        position: event.employee.position,
        periodStartDate: this.format.toDate(
          event.attributes.find(({ id }) => id === PERIOD_START_ID)
            ?.value as string,
        ),
        periodEndDate: this.format.toDate(
          event.attributes.find(({ id }) => id === PERIOD_END_ID)
            ?.value as string,
        ),
      };
    }
  }

  processStageName(stage: Action) {
    try {
      switch (stage.type) {
        case 'system_booking_hook':
          return 'Обработка';
        case 'system_booking_approve':
          return 'Согласование';
        case 'system_booking_limits_exceeded_approve':
          return 'Согласование';
        case 'system_booking_trip_create':
          return 'Обработка';
        case 'system_booking_trip_change':
          return 'Обработка';
        case 'system_booking_trip_ordering':
          return 'Бронирование';
        case 'system_booking_trip_limit':
          return 'Бронирование';
        case 'system_booking_trip_limit_approved':
          return 'Обработка';
        default:
          return stage.name === 'Отказ от подписания' ? 'Отказано' : stage.name;
      }
    } catch (e) {
      this.logger.error(e);
    }
  }

  processStage(event: EventListEntity | EventDetailEntity) {
    try {
      if (!event.active_nodes || !event.active_nodes.length) {
        return;
      }

      const stage = event.active_nodes[0].action;

      let label = '';

      if (stage.type === 'upload') {
        label = 'Загрузить до';
      } else if (stage.type === 'accept') {
        label = 'Проверить до';
      } else if (
        stage.type === 'decline_sign' ||
        stage.type === 'declined_sign'
      ) {
        label = 'Отказано';
      } else if (
        stage.type === 'ukep_sign' ||
        stage.type === 'unep_sign' ||
        stage.type === 'ukep_sign_batch' ||
        stage.type === 'unep_sign_batch' ||
        stage.type === 'pep_sign' ||
        stage.type === 'pep_sign_batch'
      ) {
        label = 'Подписать до';
      } else if (stage.type === 'generate_document_from_template') {
        label = 'Создать до';
      }

      return {
        name: this.processStageName(stage),
        color: colors[stage.type] || colors.default,
        label,
      };
    } catch (e) {
      this.logger.error(e);
    }
  }

  processActiveStages(event: EventListEntity | EventDetailEntity) {
    if (!event.active_nodes || event.active_nodes.length === 0) {
      return;
    }

    return event.active_nodes.map(({ action, responsible, custom_state }) => ({
      actionName:
        custom_state?.name === 'Отказ от подписания' ||
        action.name === 'Отказ от подписания'
          ? 'Отказано'
          : custom_state?.name || action.name,
      responsible,
      description: custom_state?.description,
      ...(custom_state?.color ? { color: custom_state.color } : {}),
    }));
  }

  processVacation(event: EventListEntity | EventDetailEntity) {
    let start: Attribute | undefined;
    let end: Attribute | undefined;

    vacationPeriods.forEach((period) => {
      if (start && end) {
        return;
      }
      const startAttr = event.attributes.find(
        (item) => item.id === period.start,
      );
      const endAttr = event.attributes.find((item) => item.id === period.end);

      if (startAttr && endAttr) {
        start = startAttr;
        end = endAttr;
        return;
      }
    });

    const startDate = start ? new Date(start.value as string) : null;
    const endDate = end ? new Date(end.value as string) : null;
    let count: string | number = '';

    if (startDate && endDate) {
      count =
        startDate <= endDate
          ? this.calendar.countOfVacationDays(endDate, startDate)
          : 'Ошибка';
    }

    return {
      start: start ? this.format.toDate(start.value as string) : '',
      end: end ? this.format.toDate(end.value as string) : '',
      count,
      isHolidaysInVacationInterval: Boolean(
        startDate &&
          endDate &&
          startDate <= endDate &&
          this.calendar.countOfHolidaysInInterval(startDate, endDate),
      ),
    };
  }

  processBusinessTrip(event: EventListEntity | EventDetailEntity) {
    const start = event.attributes.find(
      (attr) => attr.id === BUSINESS_TRIP_START_ATTR_ID,
    );
    const end = event.attributes.find(
      (attr) => attr.id === BUSINESS_TRIP_END_ATTR_ID,
    );

    const startDate = start ? new Date(start.value as string) : null;
    const endDate = end ? new Date(end.value as string) : null;
    let count: string | number = '';

    if (startDate && endDate) {
      count =
        startDate <= endDate
          ? this.calendar.countOfVacationDays(endDate, startDate)
          : 'Ошибка';
    }

    return {
      start: start ? this.format.toDate(start.value as string) : '',
      end: end ? this.format.toDate(end.value as string) : '',
      count,
    };
  }

  processCertificates(
    certificates: {
      id: string;
      name: string;
      user: string;
      company: string;
      activeFrom: string;
      activeTo: string;
    }[],
  ) {
    return certificates.map((certificate) => ({
      ...certificate,
      activeFrom: this.format.toDate(certificate.activeFrom),
      activeTo: this.format.toDate(certificate.activeTo),
    }));
  }

  /* eslint-disable no-magic-numbers */

  processCompanyListFilters(filters: Filter[]): Filter[] {
    if (!filters) {
      return [];
    }

    const availableFilters = [
      ...filters.filter((filter) => filter.type !== 'timerange'),
      ...filters.filter((filter) => filter.type === 'timerange'),
    ];

    return availableFilters
      .map((filter) => {
        if (filter.type === 'timerange') {
          return {
            ...filter,
            width: 8 as const,
            newLine: true,
            fullWidth: false,
          };
        }

        if (filter.type === 'multiple-search' && filter.key === 'unit_ids') {
          return {
            ...filter,
            width: 8 as const,
            fullWidth: false,
          };
        }

        if (filter.type === 'search' && filter.key === 'parent_event_id') {
          return {
            ...filter,
            width: 8 as const,
            disabled: true,
          };
        }

        if (
          filter.type === 'multiple-search' &&
          filter.key === 'employee_ids'
        ) {
          return {
            ...filter,
            width: 4 as const,
            twoLine: true,
            minInputValueLength: 3,
            fullWidth: false,
          };
        }

        if (filter.type === 'select' && filter.key === 'event_type_group') {
          return {
            ...filter,
            width: 4 as const,
            options: [...filter.options].sort((a, b) =>
              (a.label || '').toLowerCase() > (b.label || '').toLowerCase()
                ? 1
                : -1,
            ),
            autocomplete: true,
          };
        }

        return {
          ...filter,
          width: 4 as const,
          fullWidth: false,
        };
      })
      .map((filter, index) => {
        if (index === 1) {
          return {
            ...filter,
            newLine: true,
          };
        }

        return filter;
      });
  }

  /* eslint-disable no-magic-numbers */

  processListFilters(filters: Filter[]) {
    return filters.map((filter) => {
      if (filter.type === 'multiple-search' && filter.key === 'employee_ids') {
        return {
          ...filter,
          twoLine: true,
          minInputValueLength: 3,
          fullWidth: false,
        };
      }

      if (filter.type === 'search' && filter.key === 'parent_event_id') {
        return {
          ...filter,
          width: 12 as const,
          disabled: true,
        };
      }

      if (filter.type === 'multiple-search' && filter.key === 'unit_ids') {
        return {
          ...filter,
          fullWidth: false,
        };
      }

      if (filter.type === 'select' && filter.key === 'event_batch') {
        return {
          ...filter,
          autocomplete: true,
        };
      }

      if (filter.type === 'select' && filter.key === 'event_type_group') {
        return {
          ...filter,
          options: [...filter.options].sort((a, b) =>
            (a.label || '').toLowerCase() > (b.label || '').toLowerCase()
              ? 1
              : -1,
          ),
          autocomplete: true,
        };
      }

      if (filter.type === 'timerange') {
        return {
          ...filter,
          width: 8 as const,
          newLine: true,
          fullWidth: false,
        };
      }

      return {
        ...filter,
        fullWidth: false,
      };
    });
  }

  processDeadline(deadline: string) {
    const durationDate = this.calendar.formatISO(
      this.calendar.subMinutes(new Date(deadline), 1),
    );

    return `${this.format.toTime(durationDate)} ${this.format.toDate(
      durationDate,
    )}`;
  }
}
