/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { useField } from 'react-final-form';

import { ValidateType, composeValidators } from '@vk-hr-tek/core/validation';

import { MultipleFileInput as Input } from '../../input';

/**
 * Интерфейс пропсов компонента MultipleFileInput
 * @typedef {Object} MultipleFileInputProps
 * @property {string} label - Метка поля ввода
 * @property {React.ReactNode} [tooltip] - Всплывающая подсказка
 * @property {boolean} [required=false] - Обязательность заполнения поля
 * @property {boolean} [disabled=false] - Состояние отключения поля
 * @property {string} name - Имя поля ввода, используемое в форме
 * @property {ValidateType[]} [validate] - Массив валидаторов для поля
 * @property {(file: File) => Promise<{ file_id: string; expired_at: string }>} [cache] - Функция для кэширования файла
 */
interface MultipleFileInputProps {
  label: string;
  tooltip?: React.ReactNode;
  required?: boolean;
  disabled?: boolean;
  name: string;
  validate?: ValidateType[];
  cache?: (file: File) => Promise<{ file_id: string; expired_at: string }>;
}

/**
 * Компонент для ввода нескольких файлов с возможностью валидации и кэширования
 * @param {MultipleFileInputProps} props - Пропсы компонента
 * @returns {React.ReactElement} Элемент React
 */
export const MultipleFileInput = ({
  label,
  tooltip,
  required,
  name,
  validate,
  disabled = false,
  cache,
}: MultipleFileInputProps) => {
  const validators = composeValidators(validate || []);

  const { input, meta } = useField(name, {
    validate: validators,
  });

  const { onChange, onBlur } = input;

  return (
    <Input
      {...input}
      value={input.value}
      label={label}
      disabled={disabled}
      required={required}
      tooltip={tooltip}
      onChange={onChange}
      onBlur={onBlur}
      cache={cache}
      error={meta.touched && meta.error}
      helperText={meta.error}
    />
  );
};
