/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import MUIGrid, {
  GridContentAlignment,
  GridDirection,
  GridItemsAlignment,
  GridJustification,
  GridTypeMap,
  GridWrap,
} from '@material-ui/core/Grid';
import { CommonProps } from '@material-ui/core/OverridableComponent';

import { GridContainer } from './GridContainer';
import { Spacing } from './Grid.types';

/**
 * @typedef {('auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12)} GridSize
 * - Размер сеточного элемента для различных размеров экранов.
 */

export type GridSize =
  | 'auto'
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12;

/**
 * Компонент для рендеринга сеточного макета, который может использоваться как контейнер или как элемент сетки.
 *
 * @param {Object} props - Свойства, которые можно передать компоненту Grid.
 * @param {Spacing} [props.spacing] - Пространство между элементами сетки.
 * @param {boolean} [props.container=false] - Определяет, является ли компонент Grid контейнером.
 * @param {GridContentAlignment} [props.alignContent] - Выравнивание содержимого по кросс-оси для контейнеров сетки.
 * @param {GridItemsAlignment} [props.alignItems] - Выравнивание элементов сетки по кросс-оси для контейнеров сетки.
 * @param {React.ReactNode} [props.children] - Дочерние элементы компонента Grid.
 * @param {boolean} [props.item=false] - Определяет, является ли компонент Grid элементом сетки.
 * @param {GridDirection} [props.direction] - Направление контейнера сетки.
 * @param {GridJustification} [props.justifyContent] - Выравнивание содержимого по главной оси для контейнеров сетки.
 * @param {boolean | GridSize} [props.lg] - Размер сеточного элемента для больших экранов.
 * @param {boolean | GridSize} [props.md] - Размер сеточного элемента для средних экранов.
 * @param {boolean | GridSize} [props.sm] - Размер сеточного элемента для маленьких экранов.
 * @param {GridWrap} [props.wrap] - Свойство flex wrap для контейнеров сетки.
 * @param {boolean | GridSize} [props.xl] - Размер сеточного элемента для очень больших экранов.
 * @param {boolean | GridSize} [props.xs] - Размер сеточного элемента для очень маленьких экранов.
 * @param {boolean} [props.zeroMinWidth=false] - Если true, элемент будет иметь нулевую минимальную ширину.
 * @returns {JSX.Element} JSX элемент, представляющий сеточный макет.
 */

export const Grid = ({
  spacing,
  container,
  ...props
}: JSX.IntrinsicAttributes & {
  alignContent?: GridContentAlignment;
  alignItems?: GridItemsAlignment;
  children?: React.ReactNode;
  container?: boolean;
  direction?: GridDirection;
  item?: boolean;
  justifyContent?: GridJustification;
  lg?: boolean | GridSize;
  md?: boolean | GridSize;
  sm?: boolean | GridSize;
  spacing?: Spacing;
  wrap?: GridWrap;
  xl?: boolean | GridSize;
  xs?: boolean | GridSize;
  zeroMinWidth?: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
} & CommonProps<GridTypeMap<{}, 'div'>> &
  Pick<any, string | number | symbol>) => {
  if (container) return <GridContainer {...props} spacing={spacing} />;

  return <MUIGrid {...props} />;
};
