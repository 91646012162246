import React, { forwardRef } from 'react';

import { InfiniteScroll } from '../../InfiniteScroll';
import { CircularProgress } from '../../CircularProgress';
import { Box } from '../../Box';

import useStyles from './ListBox.styles';

/**
 * Компонент ListBox является контейнером для отображения списка элементов с бесконечной прокруткой.
 * Он оборачивает список в компонент `InfiniteScroll` и отображает индикатор загрузки,
 * когда загружаются дополнительные элементы.
 *
 * @param {Object} props - Свойства компонента ListBox.
 * @param {boolean} props.hasMore - Указывает, есть ли еще элементы для загрузки.
 * @param {number} props.dataLength - Текущее количество загруженных элементов.
 * @param {function} props.next - Функция обратного вызова для загрузки следующего набора элементов.
 * @param {boolean} props.loading - Указывает, загружает ли компонент в настоящее время дополнительные элементы.
 * @param {React.ReactNode | React.ReactNode[]} [props.children] - Дочерние элементы, которые будут отображаться в списке.
 * @param {Object} [rest] - Любые дополнительные свойства, которые будут переданы в базовый div.
 * @param {React.Ref<HTMLDivElement>} [ref] - Ссылка на базовый элемент div.
 */

export const ListBox = forwardRef<
  HTMLDivElement,
  {
    hasMore: boolean;
    dataLength: number;
    next: () => void;
    loading: boolean;
    children?: React.ReactNode | React.ReactNode[];
  }
>(({ hasMore, dataLength, next, loading, children, ...rest }, ref) => {
  const classes = useStyles();

  return (
    <div
      {...rest}
      id="scrollable"
      ref={ref}
      className={classes.scrollableWrapper}
    >
      <InfiniteScroll
        next={next}
        hasMore={hasMore}
        dataLength={dataLength}
        loader={
          loading && (
            <Box display="flex" justifyContent="center" p="16">
              <CircularProgress size={30} />
            </Box>
          )
        }
        endMessage={<div />}
        scrollableTarget="scrollable"
      >
        <ul className={classes.listBox}>{children}</ul>
      </InfiniteScroll>
    </div>
  );
});
