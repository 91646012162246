import React, { ReactNode, forwardRef } from 'react';

import classNames from 'classnames';
import { Button as MUIButton } from '@material-ui/core';

import { Box } from '../Box';
import { CircularProgress } from '../CircularProgress';

import useStyles from './Button.styles';

/**
 * Возможные варианты кнопки.
 * @typedef {('primary' | 'secondary' | 'tertiaryLight' | 'tertiaryDark' | 'tertiaryBlue' | 'quaternary' | 'quaternaryDark' | 'warning')} ButtonVariant
 */
export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'tertiaryLight'
  | 'tertiaryDark'
  | 'tertiaryBlue'
  | 'quaternary'
  | 'quaternaryDark'
  | 'warning';

/**
 * Возможные размеры кнопки.
 * @typedef {('large' | 'medium' | 'small' | 'extraSmall')} ButtonSize
 */
export type ButtonSize = 'large' | 'medium' | 'small' | 'extraSmall';

/**
 * Интерфейс свойств для компонента Button.
 * @interface ButtonProps
 * @property {string} [className] - Класс для кнопки.
 * @property {string} [id] - ID для кнопки.
 * @property {React.ReactNode | React.ReactNode[]} [children] - Содержимое, отображаемое внутри кнопки.
 * @property {'button' | 'submit'} [type='button'] - Тип кнопки.
 * @property {boolean} [disabled=false] - Определяет, должна ли кнопка быть отключена.
 * @property {boolean} [disableRipple=false] - Определяет, должна ли быть отключена волна (ripple) эффект.
 * @property {ReactNode} [icon] - Иконка, отображаемая в конце кнопки.
 * @property {ReactNode} [startIcon] - Иконка, отображаемая в начале кнопки.
 * @property {boolean} [fullWidth=false] - Определяет, должна ли кнопка занимать всю ширину своего контейнера.
 * @property {boolean} [loading=false] - Определяет, должна ли кнопка отображать индикатор загрузки.
 * @property {ButtonSize} [size='medium'] - Размер кнопки.
 * @property {string} [text] - Текст, отображаемый внутри кнопки.
 * @property {ButtonVariant} [variant='primary'] - Вариант кнопки.
 * @property {(e: React.MouseEvent<HTMLElement>) => void} [onClick] - Обработчик события клика.
 * @property {string} [testId] - Тестовый ID для кнопки.
 */
export interface ButtonProps {
  className?: string;
  id?: string;
  children?: React.ReactNode | React.ReactNode[];
  type?: 'button' | 'submit';
  disabled?: boolean;
  disableRipple?: boolean;
  icon?: ReactNode;
  startIcon?: ReactNode;
  fullWidth?: boolean;
  loading?: boolean;
  size?: ButtonSize;
  text?: string;
  variant?: ButtonVariant;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  testId?: string;
}

const muiVariants: Record<ButtonVariant, 'text' | 'outlined' | 'contained'> = {
  primary: 'contained',
  quaternary: 'contained',
  quaternaryDark: 'contained',
  warning: 'contained',
  secondary: 'outlined',
  tertiaryLight: 'text',
  tertiaryDark: 'text',
  tertiaryBlue: 'text',
};

const aqaClasses: Record<ButtonVariant, string> = {
  primary: 'aqa_button_primary',
  secondary: 'aqa_button_secondary',
  quaternary: 'aqa_button_quaternary',
  warning: 'aqa_button_warning',
  tertiaryDark: 'aqa_button_tertiaryDark',
  tertiaryBlue: 'aqa_button_tertiaryBlue',
  tertiaryLight: '',
  quaternaryDark: '',
};

/**
 * Настраиваемая кнопка, построенная с использованием Material-UI.
 *
 * @component
 * @param {ButtonProps} props - Свойства для компонента Button.
 * @param {string} [props.className] - Класс для кнопки.
 * @param {string} [props.id] - ID для кнопки.
 * @param {React.ReactNode | React.ReactNode[]} [props.children] - Содержимое, отображаемое внутри кнопки.
 * @param {'button' | 'submit'} [props.type='button'] - Тип кнопки.
 * @param {boolean} [props.disabled=false] - Определяет, должна ли кнопка быть отключена.
 * @param {boolean} [props.disableRipple=false] - Определяет, должна ли быть отключена волна (ripple) эффект.
 * @param {ReactNode} [props.icon] - Иконка, отображаемая в конце кнопки.
 * @param {ReactNode} [props.startIcon] - Иконка, отображаемая в начале кнопки.
 * @param {boolean} [props.fullWidth=false] - Определяет, должна ли кнопка занимать всю ширину своего контейнера.
 * @param {boolean} [props.loading=false] - Определяет, должна ли кнопка отображать индикатор загрузки.
 * @param {ButtonSize} [props.size='medium'] - Размер кнопки.
 * @param {string} [props.text] - Текст, отображаемый внутри кнопки.
 * @param {ButtonVariant} [props.variant='primary'] - Вариант кнопки.
 * @param {(e: React.MouseEvent<HTMLElement>) => void} [props.onClick] - Обработчик события клика.
 * @param {string} [props.testId] - Тестовый ID для кнопки.
 * @returns {JSX.Element} Компонент Button.
 */

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      children,
      type = 'button',
      disabled = false,
      disableRipple = false,
      icon,
      startIcon,
      fullWidth = false,
      loading = false,
      size = 'medium',
      variant = 'primary',
      onClick,
      testId,
      ...rest // we need this for tooltips
    },
    ref,
  ) => {
    const classes = useStyles();

    const buttonStyles = classNames(
      classes.button,
      classes[variant],
      icon &&
        !children &&
        `${classes.buttonIconOnly}${
          variant === 'primary' ? ` ${classes.buttonPrimaryIconOnly}` : ''
        }`,
      loading && `${classes.buttonLoading} buttonLoading`,
      className,
      'aqa_button',
      aqaClasses[variant],
    );

    const muiVariant: 'text' | 'outlined' | 'contained' =
      muiVariants[variant] || variant;

    return (
      <MUIButton
        data-testid={testId}
        color="primary"
        variant={muiVariant}
        className={buttonStyles}
        disabled={disabled}
        disableRipple={disableRipple}
        fullWidth={fullWidth}
        startIcon={startIcon}
        endIcon={icon}
        size={size === 'extraSmall' ? 'small' : size}
        type={type}
        onClick={onClick}
        ref={ref}
        {...rest}
      >
        <Box visibility={loading ? 'hidden' : 'visible'}>{children}</Box>
        {loading && (
          <CircularProgress
            size={size === 'extraSmall' ? 12.6 : 18.8}
            className={classes.loading}
            color="inherit"
          />
        )}
      </MUIButton>
    );
  },
);
