import React from 'react';

import { Box } from '../../../Box';
import { ColoredChip } from '../../../ColoredChip';
import { Tooltip } from '../../../Tooltip';
import { useIsDesktop } from '../../../hooks';

interface BadgeProps {
  title?: string;
  description?: string;
  color?: 'gray' | 'green' | 'orange' | 'red' | 'blue' | 'velvet';
  size?: 'extraSmall' | 'small';
}

/**
 * Badge - компонент для отображения значка с опциональным описанием.
 *
 * @param {BadgeProps} props - Свойства компонента.
 * @param {string} [props.title=''] - Заголовок значка.
 * @param {string} [props.description=''] - Описание значка, отображаемое при наведении.
 * @param {'gray' | 'green' | 'orange' | 'red' | 'blue' | 'velvet'} [props.color] - Цвет значка.
 * @param {'extraSmall' | 'small'} [props.size='small'] - Размер значка.
 * @returns {JSX.Element} Возвращает JSX-элемент представляющий компонент Badge.
 */

export const Badge = ({
  title = '',
  description = '',
  color,
  size = 'small',
}: BadgeProps) => {
  const isDesktop = useIsDesktop();

  return (
    <Box>
      <Tooltip disabled={!isDesktop} title={description} placement="left">
        <span>
          <ColoredChip
            fullWidth
            size={size}
            color={color === 'velvet' ? 'purple' : color || 'blue'}
            label={title}
          />
        </span>
      </Tooltip>
    </Box>
  );
};
