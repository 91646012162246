import { useTheme } from '@material-ui/core';

import { useMatchQuery } from './useMatchQuery';

/**
 * Пользовательский хук, который определяет, является ли текущий размер экрана мобильным устройством.
 *
 * @returns {boolean} - Возвращает true, если размер экрана 'sm' или меньше, иначе false.
 */

export const useIsMobile = () => {
  const theme = useTheme();

  return useMatchQuery(theme.breakpoints.down('sm'));
};
