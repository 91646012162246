import React from 'react';

import { Box } from '../../Box';
interface FormKnownValuesProps {
  children: React.ReactNode | React.ReactNode[];
}

/**
 * Компонент для отображения известных значений формы.
 * @param {FormKnownValuesProps} props - Свойства компонента.
 * @param {React.ReactNode | React.ReactNode[]} props.children - Дочерние элементы, которые будут отображаться внутри компонента.
 * @returns {React.ReactElement} - Элемент Box с заданными стилями и дочерними элементами.
 */

export const FormKnownValues = ({ children }: FormKnownValuesProps) => (
  <Box
    display="flex"
    flexDirection="column"
    gap="20"
    p="16"
    bgcolor="bg.greyscale.primary"
    radius="l"
  >
    {children}
  </Box>
);
