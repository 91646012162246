import { createSlice } from '@reduxjs/toolkit';

import { initialState, filtersPresetAdapter } from './filtersPreset.state';
import { filtersPresetsReducers } from './actions';

export const filtersPreset = createSlice({
  name: 'filtersPreset',
  initialState: filtersPresetAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: (builder) => {
    filtersPresetsReducers(builder);
  },
});

export const filtersPresetReducer = filtersPreset.reducer;
