import React, { ReactNode } from 'react';

import { useIsDesktop } from '../hooks';
import { Box } from '../Box';
import { Dialog } from '../Dialog';
import { FilePreview } from '../FilePreview';
import { Snackbar } from '../Snackbar';

import { SpacingParams } from './FilePreviewWithSnackbar.types';
import useStyles from './FilePreviewWithSnackbar.styles';

interface FilePreviewWithSnackbarProps {
  open: boolean;
  onClose: () => void;
  fullScreen?: boolean;
  loadDocument: () => Promise<Blob>;
  snackbarMessage: ReactNode;
  action: ReactNode;
  closeIcon?: ReactNode;
  mt?: SpacingParams;
  mb?: SpacingParams;
}

/**
 * Компонент для предварительного просмотра файла с использованием диалогового окна и всплывающего уведомления (snackbar).
 *
 * @param {Object} props - Свойства, которые можно передать в компонент FilePreviewWithSnackbar.
 * @param {boolean} props.open - Если true, диалоговое окно открыто.
 * @param {() => void} props.onClose - Функция обратного вызова для обработки закрытия диалогового окна.
 * @param {boolean} [props.fullScreen=false] - Если true, диалоговое окно отображается в полноэкранном режиме.
 * @param {() => Promise<Blob>} props.loadDocument - Функция, возвращающая промис, который разрешается в Blob при загрузке файла.
 * @param {ReactNode} props.snackbarMessage - Сообщение для отображения в всплывающем уведомлении.
 * @param {ReactNode} props.action - Действие для отображения в всплывающем уведомлении.
 * @param {ReactNode} [props.closeIcon=null] - Пользовательская иконка закрытия для диалогового окна.
 * @param {SpacingParams} [props.mt={}] - Параметры отступов сверху.
 * @param {SpacingParams} [props.mb={}] - Параметры отступов снизу.
 * @returns {JSX.Element} JSX-элемент, представляющий компонент предварительного просмотра файла с диалоговым окном и всплывающим уведомлением.
 */

export const FilePreviewWithSnackbar = ({
  open,
  onClose,
  fullScreen = false,
  loadDocument,
  snackbarMessage,
  action,
  closeIcon = null,
  mt = {} as SpacingParams,
  mb = {} as SpacingParams,
}: FilePreviewWithSnackbarProps) => {
  const isDesktop = useIsDesktop();

  const classes = useStyles({ mt, mb });

  return (
    <Box>
      <Dialog
        scroll="body"
        onClose={onClose}
        open={open}
        fullScreen={fullScreen}
        className="aqa_file_preview_dialog"
        classes={{
          root: classes.root,
          paper: classes.paper,
          paperFullScreen: classes.paperFullScreen,
          paperScrollBody: classes.paperScrollBody,
        }}
      >
        <FilePreview onLoad={loadDocument} />
        {closeIcon}
      </Dialog>
      <Snackbar
        open={open}
        message={snackbarMessage}
        action={action}
        className="aqa_file_preview_snackbar"
        variant={!isDesktop ? 'static' : 'sticky'}
      />
    </Box>
  );
};
