import React, { useCallback } from 'react';

import classNames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';

import { Box } from '../Box';
import { Typography } from '../Typography';

import { LinkSize, LinkColor, LinkStroke } from './Link.types';
import { useStyles } from './Link.styles';

export interface LinkBaseProps {
  size?: keyof typeof LinkSize;
  variant?: keyof typeof LinkColor;
  stroke?: LinkStroke;
  disabled?: boolean;
  children?: React.ReactNode | React.ReactNode[];
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  toPageTitle?: string;
  onLogAction?: (pageTitle?: string) => void;
}

type RouterLinkProps = LinkBaseProps & {
  to: string;
};

type HrefLinkProps = LinkBaseProps & {
  href: string;
  target?: string;
};

type ButtonLinkProps = LinkBaseProps & {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  className?: string;
};

type LinkProps = RouterLinkProps | HrefLinkProps | ButtonLinkProps;

const isRouterProps = (props: LinkProps): props is RouterLinkProps =>
  Object.prototype.hasOwnProperty.call(props, 'to');
const isHrefProps = (props: LinkProps): props is HrefLinkProps =>
  Object.prototype.hasOwnProperty.call(props, 'href');
const isButtonProps = (props: LinkProps): props is ButtonLinkProps =>
  Object.prototype.hasOwnProperty.call(props, 'onClick');

const typographyMapping: Record<
  'large' | 'medium' | 'small' | 'extraSmall',
  'subtitle1' | 'subtitle2' | 'body3' | 'caption'
> = {
  large: 'subtitle1',
  medium: 'subtitle2',
  small: 'body3',
  extraSmall: 'caption',
};

/**
 * Вериатильный компонент Link, который может рендериться как ссылка роутера, обычный якорный тег с href или как ссылка в виде кнопки.
 *
 * @param {LinkProps} props - Пропсы для компонента Link.
 * @param {keyof typeof LinkSize} [props.size='medium'] - Размер ссылки. Может быть 'large', 'medium', 'small' или 'extraSmall'.
 * @param {keyof typeof LinkColor} [props.variant='primary'] - Цветовая вариация ссылки. Может быть 'primary', 'secondary' и т.д.
 * @param {LinkStroke} [props.stroke=true] - Определяет, должна ли ссылка иметь подчёркивание. Устанавливается в false, если variant равен 'simple'.
 * @param {boolean} [props.disabled=false] - Если true, ссылка будет отключена и некликабельна.
 * @param {React.ReactNode | React.ReactNode[]} [props.children] - Содержимое ссылки.
 * @param {React.ReactNode} [props.startIcon] - Иконка, отображаемая перед текстом ссылки.
 * @param {React.ReactNode} [props.endIcon] - Иконка, отображаемая после текста ссылки.
 * @param {string} [props.to] - Путь для ссылки при использовании react-router-dom.
 * @param {string} [props.href] - URL для ссылки при использовании обычного якорного тега.
 * @param {string} [props.target] - Атрибут target для якорного тега.
 * @param {(event: React.MouseEvent<HTMLElement>) => void} [props.onClick] - Обработчик клика для ссылки при рендеринге её как кнопки.
 * @param {string} [props.className] - Дополнительные классы для ссылки при рендеринге её как кнопки.
 *
 * @returns {JSX.Element} - React-компонент, рендерящий ссылку на основе предоставленных пропсов.
 */

export const Link = (props: LinkProps) => {
  const {
    size = 'medium',
    variant = 'primary',
    disabled = false,
    stroke = true,
    startIcon,
    endIcon,
    children,
    onLogAction,
    toPageTitle,
  } = props;

  const classes = useStyles({
    size: LinkSize[size],
    color: LinkColor[variant],
    stroke: variant === 'simple' ? false : stroke,
    disabled,
  });

  const handleLogAction = useCallback(() => {
    if (onLogAction) {
      onLogAction(toPageTitle);
    }
  }, [onLogAction, toPageTitle]);

  const buttonOnClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      if (isButtonProps(props)) {
        props.onClick(e);
        handleLogAction();
      }
    },
    [props, handleLogAction],
  );

  const content = (
    <Box
      {...(variant === 'simple'
        ? { display: 'inline' }
        : { display: 'flex', alignItems: 'center' })}
    >
      {startIcon && (
        <Box
          className={classNames(classes.icon, children && classes.startIcon)}
        >
          {startIcon}
        </Box>
      )}
      {children && (
        <Typography
          color="inherit"
          component="span"
          variant={typographyMapping[size]}
          data-testid="link-typography"
        >
          {children}
        </Typography>
      )}
      {endIcon && (
        <Box className={classNames(classes.icon, children && classes.endIcon)}>
          {endIcon}
        </Box>
      )}
    </Box>
  );

  const styles = classNames(
    classes.link,
    isButtonProps(props) && props.className,
    // isButtonProps(props) && classes.linkLikeButton,
  );

  if (isRouterProps(props)) {
    return (
      <RouterLink
        onClick={handleLogAction}
        to={props.to}
        className={styles}
        data-testid="router-link"
      >
        {content}
      </RouterLink>
    );
  }

  if (isHrefProps(props)) {
    return (
      <a
        href={props.href}
        target={props.target}
        className={styles}
        data-testid="link-a"
      >
        {content}
      </a>
    );
  }

  return (
    <a
      href="#"
      className={styles}
      onClick={buttonOnClick}
      data-testid="link-btn"
    >
      {content}
    </a>
  );
};
