import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import { useAbility } from '@casl/react';
import classNames from 'classnames';

import { Box } from '@vk-hr-tek/ui/Box';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { Divider } from '@vk-hr-tek/ui/Divider';
import { Tooltip } from '@vk-hr-tek/ui/Tooltip';
import { SidebarMoreIcon } from '@vk-hr-tek/ui/icons';
import { UserRoleEnum } from '@vk-hr-tek/app/app/types';
import { t } from '@vk-hr-tek/core/translations/t';

import { navigationItems } from '@app/router';
import { AppAbilities } from '@app/ability/ability';
import { AbilityContext } from '@app/ability/Can';
import { useSelector } from '@app/hooks';

import { selectUser } from '../../../../user';
import { SidebarItemsWithSubmenu } from '../SidebarItemsWithSubmenu';
import { SidebarItem } from '../SidebarItems';
import { ITEM_HEIGHT } from '../Sidebar.constants';

import { useStyles } from './SidebarMenu.styles';

interface SidebarMenuProps {
  sidebarOpened: boolean;
  skeletonLoading: boolean;
  userRole: UserRoleEnum;
  menuLabel: string;
  maxItemsInMenu?: number;
}

export const SidebarMenu = ({
  sidebarOpened,
  skeletonLoading,
  userRole,
  menuLabel,
  maxItemsInMenu = 5,
}: SidebarMenuProps) => {
  const { pathname } = useLocation();
  const ability = useAbility(AbilityContext);
  const user = useSelector(selectUser);
  const userId = user?.id || '';

  const handleIsActive = (path: string, href: string): boolean => {
    if (href.startsWith('/personal')) {
      const currentUserId = pathname.split('/personal/')[1] || '';

      return path.startsWith('/personal') && userId === currentUserId;
    }
    return path.startsWith(href) || path === href;
  };

  const createLinkHref = (role: string, href: string): string => {
    if (href === '/personal') {
      return `/personal/${userId}`;
    }

    return `/${role}${href}`;
  };

  const filteredLinks = navigationItems
    .filter((item) => ability.can('read', item.resource as AppAbilities[1]))
    .filter((item) => item.basename !== 'organization')
    .map((item) => {
      const newHref = createLinkHref(userRole, item.href);

      return {
        ...item,
        href: newHref,
        isActive: handleIsActive(pathname, newHref),
      };
    });

  const [allElementsVisible, setAllElementsVisible] = useState<boolean>(false);
  const [submenusVisibleItemsCount, setSubmenusVisibleItemsCount] =
    useState<number>(0);

  const handleSetSubmenuItemsCount = (count: number) =>
    setSubmenusVisibleItemsCount((prev: number) => prev + count);

  // eslint-disable-next-line no-magic-numbers
  const roleTitleFontSize = sidebarOpened ? 14 : 1;

  const itemsInMenu =
    filteredLinks.length > maxItemsInMenu
      ? maxItemsInMenu
      : filteredLinks.length;

  const menuHeight =
    ((allElementsVisible ? filteredLinks.length : itemsInMenu) +
      submenusVisibleItemsCount) *
    ITEM_HEIGHT;

  const styles = useStyles({
    menuHeight,
    sidebarOpened,
    roleTitleFontSize,
    allElementsVisible,
  });

  const handleAllElementsVisibleState = () => {
    setAllElementsVisible((prev) => !prev);
  };

  const toggleButtonText = allElementsVisible
    ? 'Показать меньше'
    : `Показать еще ${filteredLinks.length - maxItemsInMenu}`;

  useEffect(() => {
    if (!sidebarOpened) {
      setSubmenusVisibleItemsCount(0);
    }
  }, [sidebarOpened]);

  return (
    <Box display="flex" flexDirection="column" gap="4">
      <Box className={styles.sidebarRoleTitle}>
        {sidebarOpened ? (
          <Typography variant="inherit" noWrap color="text.light.tertirary">
            {skeletonLoading ? <Skeleton width={200} /> : menuLabel}
          </Typography>
        ) : (
          <Box width="100%">
            <Divider />
          </Box>
        )}
      </Box>

      <Box className={styles.sidebarLinks}>
        {filteredLinks.map(
          ({
            href,
            icon,
            submenu,
            aqaClass,
            isActive,
            basename,
            resource: resourseName,
          }) => {
            const filteredSubmenu = submenu
              ?.filter(({ resource }) =>
                ability.can('read', resource as AppAbilities[1]),
              )
              .map((item) => {
                const newHref = createLinkHref(userRole, item.href);

                return {
                  ...item,
                  title: t(
                    `modules.${resourseName}.${
                      item.basename === 'sign-types'
                        ? 'unep-types'
                        : item.basename
                    }.${userRole}Title`,
                  ),
                  href: newHref,
                  isActive: handleIsActive(pathname, newHref),
                };
              });

            if (filteredSubmenu && filteredSubmenu.length > 1) {
              return (
                <SidebarItemsWithSubmenu
                  key={t(
                    `modules.${resourseName}.${basename}.${userRole}Title`,
                  )}
                  icon={icon}
                  title={t(
                    `modules.${resourseName}.${basename}.${userRole}Title`,
                  )}
                  aqaClass={aqaClass}
                  sidebarOpened={sidebarOpened}
                  submenu={filteredSubmenu}
                  isActive={isActive}
                  skeletonLoading={skeletonLoading}
                  setSubmenusVisibleItemsCount={handleSetSubmenuItemsCount}
                  role={userRole}
                />
              );
            } else {
              return (
                <SidebarItem
                  key={t(
                    `modules.${resourseName}.${basename}.${userRole}Title`,
                  )}
                  href={href}
                  icon={icon}
                  aqaClass={aqaClass}
                  title={t(
                    `modules.${resourseName}.${basename}.${userRole}Title`,
                  )}
                  sidebarOpened={sidebarOpened}
                  isActive={isActive}
                  skeletonLoading={skeletonLoading}
                />
              );
            }
          },
        )}
      </Box>

      {filteredLinks.length > maxItemsInMenu && (
        <Box
          className={classNames(styles.showAllButton, 'aqa_showAllButton')}
          onClick={handleAllElementsVisibleState}
        >
          <Tooltip
            title={toggleButtonText}
            placement="right"
            disabled={sidebarOpened}
          >
            <Box
              p="8"
              display="flex"
              minWidth={40}
              minHeight={40}
              alignItems="center"
              justifyContent="center"
              fontSize="20px"
              className={styles.expandMoreIcon}
            >
              {skeletonLoading ? (
                <Skeleton width="100%" />
              ) : (
                <SidebarMoreIcon color="primary" />
              )}
            </Box>
          </Tooltip>

          <Box className={styles.showAllButtonText}>
            <Typography variant="body3" color="text.light.tertirary" noWrap>
              {toggleButtonText}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};
