import React, { useState, useCallback, useEffect } from 'react';

import { Document, Page, pdfjs } from 'react-pdf';
import {
  ArrowBackRounded as ArrowBackRoundedIcon,
  ArrowForwardRounded as ArrowForwardRoundedIcon,
} from '@material-ui/icons';
import classNames from 'classnames';
import { SizeMe } from 'react-sizeme';

import { useInject } from '@vk-hr-tek/core/ioc';
import { Logger } from '@vk-hr-tek/core/logger';

import { Box } from '../../Box';
import { CircularProgress } from '../../CircularProgress';
import { Button } from '../../Button';
import { ButtonGroup } from '../../ButtonGroup';

import { PreviewButtons } from './Buttons';
import { useStyles } from './Preview.styles';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

/**
 * Интерфейс пропсов компонента PreviewPdf.
 * @interface PreviewPdfProps
 * @property {string} url - URL файла PDF для предварительного просмотра.
 * @property {'auto' | 'square'} shape - Форма предварительного просмотра ('auto' или 'square').
 * @property {() => void} [onClickPrint] - Функция, вызываемая при клике на кнопку печати.
 * @property {() => void} [onOpen] - Функция, вызываемая при открытии предварительного просмотра.
 * @property {() => Promise<Blob | { file: Promise<Blob>; filename?: string }>} [onClickDownload] - Асинхронная функция, вызываемая при клике на кнопку загрузки.
 * @property {() => void} [onLoadSuccess] - Функция, вызываемая при успешной загрузке PDF.
 * @property {boolean} [withCollapsableFooter] - Флаг, указывающий, должен ли футер быть схлапываемым.
 */
interface PreviewPdfProps {
  url: string;
  shape: 'auto' | 'square';
  onClickPrint?: () => void;
  onOpen?: () => void;
  onClickDownload?: () => Promise<
    Blob | { file: Promise<Blob>; filename?: string }
  >;
  onLoadSuccess?: () => void;
  withCollapsableFooter?: boolean;
}

const options = {
  isEvalSupported: false,
};

/**
 * Компонент для предварительного просмотра PDF файлов.
 * @component PreviewPdf
 * @param {PreviewPdfProps} props - Пропсы компонента.
 * @param {string} props.url - URL файла PDF для предварительного просмотра.
 * @param {'auto' | 'square'} props.shape - Форма предварительного просмотра ('auto' или 'square').
 * @param {() => void} [props.onClickPrint] - Функция, вызываемая при клике на кнопку печати.
 * @param {() => void} [props.onOpen] - Функция, вызываемая при открытии предварительного просмотра.
 * @param {() => Promise<Blob | { file: Promise<Blob>; filename?: string }>} [props.onClickDownload] - Асинхронная функция, вызываемая при клике на кнопку загрузки.
 * @param {() => void} [props.onLoadSuccess] - Функция, вызываемая при успешной загрузке PDF.
 * @param {boolean} [props.withCollapsableFooter] - Флаг, указывающий, должен ли футер быть схлапываемым.
 * @returns {JSX.Element} Компонент предварительного просмотра PDF.
 */
export const PreviewPdf = ({
  url,
  shape,
  onClickPrint,
  onOpen,
  onClickDownload,
  onLoadSuccess,
  withCollapsableFooter,
}: PreviewPdfProps) => {
  const classes = useStyles();

  const [pagesCount, setPagesCount] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [nextPageNumber, setNextPageNumber] = useState<number>(1); // Предотвращение скачков при загрузке страницы
  const [isLoaded, setIsLoaded] = useState(false);
  const logger = useInject(Logger);

  useEffect(() => {
    setPageNumber(1);
    setNextPageNumber(1);
  }, [url]);

  /**
   * Обработчик открытия предварительного просмотра.
   * @function handleOpen
   * @returns {void}
   */
  const handleOpen = useCallback(() => onOpen && onOpen(), [onOpen]);

  /**
   * Обработчик успешной загрузки PDF.
   * @function handleLoadSuccess
   * @param {{ numPages: number }} param - Объект, содержащий количество страниц в PDF.
   * @returns {void}
   */
  const handleLoadSuccess = useCallback(
    ({ numPages }) => {
      setPagesCount(numPages);
      setIsLoaded(true);
      onLoadSuccess?.();
    },
    [setPagesCount, setIsLoaded, onLoadSuccess],
  );

  /**
   * Обработчик установки номера страницы.
   * @function handleSetPage
   * @returns {void}
   */
  const handleSetPage = useCallback(
    () => setPageNumber(nextPageNumber),
    [nextPageNumber, setPageNumber],
  );

  /**
   * Обработчик нажатия кнопки "Назад".
   * @function handlePrev
   * @param {React.MouseEvent} event - Событие нажатия мыши.
   * @returns {void}
   */
  const handlePrev = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      setNextPageNumber((x) => Math.max(1, x - 1));
    },
    [setNextPageNumber],
  );

  /**
   * Обработчик нажатия кнопки "Вперед".
   * @function handleNext
   * @param {React.MouseEvent} event - Событие нажатия мыши.
   * @returns {void}
   */
  const handleNext = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      setNextPageNumber((x) => Math.min(pagesCount ?? 0, x + 1));
    },
    [setNextPageNumber, pagesCount],
  );

  /**
   * Обработчик ошибки загрузки PDF.
   * @function handleLoadError
   * @param {Error} error - Объект ошибки.
   * @returns {void}
   */
  const handleLoadError = (error: Error) => {
    logger.error(error, {
      tags: {
        vkdoc_error_type: 'pdf',
      },
    });
  };

  return (
    <SizeMe refreshMode="debounce" refreshRate={100}>
      {({ size }) => (
        <div
          className={classNames(
            classes.root,
            classes.collapseWrapper,
            'aqa_file_preview_pdf',
          )}
        >
          {shape === 'square' && <div className={classes.square} />}
          <Box onClick={handleOpen}>
            <Document
              options={options}
              onLoadError={handleLoadError}
              onSourceError={handleLoadError}
              className={classNames(
                classes.document,
                shape === 'square' && classes.document_square,
              )}
              file={url}
              error={
                <Box p="32" color="text.light.secondary" textAlign="center">
                  Ошибка при загрузке файла
                </Box>
              }
              loading={
                <Box display="flex" justifyContent="center" p="32">
                  <CircularProgress size={50} />
                </Box>
              }
              onLoadSuccess={handleLoadSuccess}
            >
              {[
                <Page
                  key={pageNumber}
                  width={size.width === null ? 1 : size.width}
                  pageNumber={pageNumber}
                  renderAnnotationLayer
                />,
                nextPageNumber !== pageNumber && (
                  <Page
                    className={classes.hiddenPage}
                    key={nextPageNumber}
                    width={size.width === null ? 1 : size.width}
                    pageNumber={nextPageNumber}
                    onLoadSuccess={handleSetPage}
                    renderAnnotationLayer
                  />
                ),
              ]}
            </Document>
          </Box>
          {pagesCount !== null && pagesCount > 1 && (size.width ?? 0) > 240 && (
            <div className={classes.pagination}>
              <ButtonGroup
                variant="contained"
                size="small"
                onClick={(event) => event.stopPropagation()}
              >
                <Button
                  disabled={nextPageNumber <= 1}
                  onClick={handlePrev}
                  icon={<ArrowBackRoundedIcon />}
                />
                <Button disabled>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={38}
                  >
                    {pageNumber}/{pagesCount}
                  </Box>
                </Button>
                <Button
                  disabled={nextPageNumber >= pagesCount}
                  onClick={handleNext}
                  icon={<ArrowForwardRoundedIcon />}
                />
              </ButtonGroup>
            </div>
          )}
          {(onClickDownload || onClickPrint) && isLoaded && (
            <PreviewButtons
              pdf
              onClickDownload={onClickDownload}
              withCollapsableFooter={withCollapsableFooter}
              onClickPrint={onClickPrint}
            />
          )}
        </div>
      )}
    </SizeMe>
  );
};
