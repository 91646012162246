import React, { useCallback } from 'react';

import { useField } from 'react-final-form';

import { compose, ValidateType } from '@vk-hr-tek/core/validation';

import { TreeViewAutocomplete as Input } from '../../input';

/**
 * @typedef {Object} TreeNode
 * @template T
 * @property {Option<T>[]} [children] - Дочерние узлы.
 */

type TreeNode<T> = {
  children?: Option<T>[];
};

/**
 * @typedef {Object} Filter
 * @property {number} limit - Ограничение количества результатов.
 * @property {number} offset - Смещение результатов.
 * @property {string} query - Поисковый запрос.
 */

interface Filter {
  limit: number;
  offset: number;
  query: string;
}

/**
 * @typedef {Object} Option
 * @template T
 * @property {string} id - Уникальный идентификатор опции.
 * @property {string} label - Метка опции.
 * @property {Option<T>[]} [children] - Дочерние узлы.
 */

type Option<T> = Omit<T, 'children'> & TreeNode<T>;

/**
 * @typedef {Object} TreeViewAutocompleteProps
 * @template T
 * @property {boolean} [disabled] - Флаг, указывающий, отключено ли поле.
 * @property {string} name - Имя поля.
 * @property {string} label - Метка поля.
 * @property {React.ReactNode} [tooltip] - Всплывающая подсказка.
 * @property {boolean} [required] - Флаг, указывающий, является ли поле обязательным.
 * @property {Option<T>[]} options - Опции для автодополнения.
 * @property {string} [noOptionsText] - Текст, отображаемый, когда нет доступных опций.
 * @property {string} [placeholder] - Текст-заполнитель поля.
 * @property {boolean} [clearable] - Флаг, указывающий, можно ли очистить поле.
 * @property {(option: T) => string} [getOptionLabel] - Функция для получения метки опции.
 * @property {ValidateType[] | ValidateType} [validate] - Валидаторы поля.
 * @property {(value: string | undefined) => void} [onChange] - Коллбэк на изменение значения поля.
 * @property {(value: string) => Promise<T[]>} [onChildrenLoad] - Коллбэк на загрузку дочерних узлов.
 * @property {(filter: Filter) => Promise<T[]>} [onSearch] - Коллбэк на поиск опций.
 * @property {(unitId: string) => Promise<Option<T>[]>} [onLoadTree] - Коллбэк на загрузку дерева опций.
 * @property {string} [testId] - Идентификатор для тестирования.
 */

interface TreeViewAutocompleteProps<T> {
  disabled?: boolean;
  name: string;
  label: string;
  tooltip?: React.ReactNode;
  required?: boolean;
  options: Option<T>[];
  noOptionsText?: string;
  placeholder?: string;
  clearable?: boolean;
  getOptionLabel?: (option: T) => string;
  validate?: ValidateType[] | ValidateType;
  onChange?: (value: string | undefined) => void;
  onChildrenLoad?: (value: string) => Promise<T[]>;
  onSearch?: (filter: Filter) => Promise<T[]>;
  onLoadTree?: (unitId: string) => Promise<Option<T>[]>;
  testId?: string;
}

/**
 * Компонент для автодополнения с поддержкой дерева опций.
 * @template T
 * @param {TreeViewAutocompleteProps<T>} props - Свойства компонента.
 * @returns {React.ReactElement} Элемент React.
 */
export const TreeViewAutocomplete = <T extends { id: string; label: string }>({
  disabled,
  name,
  label,
  tooltip,
  options,
  placeholder,
  clearable,
  required,
  getOptionLabel,
  noOptionsText,
  validate = [],
  onChange,
  onChildrenLoad,
  onSearch,
  onLoadTree,
  testId,
}: TreeViewAutocompleteProps<T>) => {
  const { input, meta } = useField(name, {
    validate: compose(Array.isArray(validate) ? validate : [validate]),
  });

  /**
   * Обработчик изменения значения поля.
   * @param {string | undefined} value - Новое значение поля.
   */
  const handleChange = useCallback(
    (value) => {
      input.onChange(value);

      if (onChange) {
        onChange(value);
      }
    },
    [input, onChange],
  );

  return (
    <Input
      {...input}
      value={input.value}
      testId={testId}
      onChange={handleChange}
      label={label}
      options={options}
      disabled={disabled}
      tooltip={tooltip}
      placeholder={placeholder}
      clearable={clearable}
      required={required}
      getOptionLabel={getOptionLabel}
      noOptionsText={noOptionsText}
      error={meta.touched && !!meta.error}
      helperText={meta.error}
      onChildrenLoad={onChildrenLoad}
      onSearch={onSearch}
      onLoadTree={onLoadTree}
    />
  );
};
