import { Type, Transform } from 'class-transformer';
import {
  IsInt,
  IsNumber,
  IsIn,
  IsArray,
  IsString,
  IsDateString,
  IsUUID,
  IsNotEmpty,
  ValidateNested,
  IsOptional,
  IsBoolean,
} from 'class-validator';

export class EmployeeItem {
  @IsString()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsOptional()
  personnel_number?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  name: string;

  @IsString()
  @IsOptional()
  position?: string;

  @IsString()
  @IsOptional()
  unit_name?: string;
}

export class GetEventStaffPositionEmployeesResponse {
  @ValidateNested({ each: true })
  @Type(() => EmployeeItem)
  @IsArray()
  @IsNotEmpty()
  employees: EmployeeItem[];
}

export class CompetencyScaleValue {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsInt()
  @IsNotEmpty()
  value: number;

  @IsString({ each: true })
  @IsArray()
  @IsOptional()
  indicators?: string[];
}

export class CompetencyItem {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsString()
  @IsOptional()
  description?: string;

  @IsString()
  @IsOptional()
  group_name?: string;
}

export class EventCompetencyOption {
  @ValidateNested()
  @Type(() => CompetencyItem)
  @IsNotEmpty()
  competency: CompetencyItem;

  @ValidateNested({ each: true })
  @Type(() => CompetencyScaleValue)
  @IsArray()
  @IsNotEmpty()
  scale_values: CompetencyScaleValue[];

  @ValidateNested()
  @Type(() => CompetencyScaleValue)
  @IsOptional()
  current_value?: CompetencyScaleValue;
}

export class GetEventCompetencyOptionsResponse {
  @ValidateNested({ each: true })
  @Type(() => EventCompetencyOption)
  @IsArray()
  @IsNotEmpty()
  competencies: EventCompetencyOption[];
}

export class EventCatalogAttributesItem {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  value: string;

  @IsString()
  @IsOptional()
  subtext?: string;
}

export class EventCatalogAttributes {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsInt()
  @IsNotEmpty()
  quantity: number;

  @ValidateNested({ each: true })
  @Type(() => EventCatalogAttributesItem)
  @IsArray()
  @IsNotEmpty()
  items: EventCatalogAttributesItem[];
}

export class EventCompetencyValue {
  @ValidateNested()
  @Type(() => CompetencyScaleValue)
  @IsNotEmpty()
  value: CompetencyScaleValue;

  @IsBoolean()
  @IsNotEmpty()
  is_employee: boolean;
}

export class EventCompetency {
  @ValidateNested()
  @Type(() => CompetencyItem)
  @IsNotEmpty()
  competency: CompetencyItem;

  @ValidateNested({ each: true })
  @Type(() => CompetencyScaleValue)
  @IsArray()
  @IsNotEmpty()
  scale_values: CompetencyScaleValue[];

  @ValidateNested({ each: true })
  @Type(() => EventCompetencyValue)
  @IsArray()
  @IsOptional()
  values?: EventCompetencyValue[];

  @ValidateNested()
  @Type(() => CompetencyScaleValue)
  @IsOptional()
  required_level?: CompetencyScaleValue;

  @ValidateNested()
  @Type(() => CompetencyScaleValue)
  @IsOptional()
  current_value?: CompetencyScaleValue;

  @ValidateNested()
  @Type(() => CompetencyScaleValue)
  @IsOptional()
  new_value?: CompetencyScaleValue;
}

export class EventCompetencyGroup {
  @IsString()
  @IsNotEmpty()
  name: string;

  @ValidateNested({ each: true })
  @Type(() => EventCompetency)
  @IsArray()
  @IsNotEmpty()
  competencies: EventCompetency[];
}

export class EventOptions {
  @IsInt()
  @IsOptional()
  available_vacation_days?: number;

  @IsString()
  @IsOptional()
  create_child_label?: string;
}

export class UserShortInfo {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  first_name: string;

  @IsString()
  @IsNotEmpty()
  last_name: string;

  @IsString()
  @IsOptional()
  middle_name?: string;
}

export class EventComment {
  @IsDateString()
  @IsNotEmpty()
  created_at: string;

  @ValidateNested()
  @Type(() => UserShortInfo)
  @IsOptional()
  user?: UserShortInfo;

  @IsString()
  @IsOptional()
  info?: string;

  @IsString()
  @IsNotEmpty()
  comment: string;

  @IsString()
  @IsOptional()
  role?: string;

  @IsIn(['system'])
  @IsString()
  @IsOptional()
  type?: 'system';
}

export class EventPermissions {
  @IsBoolean()
  @IsNotEmpty()
  to_paper: boolean;

  @IsBoolean()
  @IsNotEmpty()
  cancel: boolean;

  @IsBoolean()
  @IsNotEmpty()
  create_linked: boolean;
}

export class RoleItem {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;
}

export class EventParticipant {
  @ValidateNested()
  @Type(() => RoleItem)
  @IsNotEmpty()
  role: RoleItem;

  @ValidateNested({ each: true })
  @Type(() => UserShortInfo)
  @IsArray()
  @IsNotEmpty()
  user: UserShortInfo[];
}

export class AttributeValueGoal {
  @IsString()
  @IsNotEmpty()
  name: string;

  @IsInt()
  @IsNotEmpty()
  share: number;

  @IsBoolean()
  @IsNotEmpty()
  mandatory: boolean;
}

export class AttributeValueGoalsFinalReview {
  @ValidateNested({ each: true })
  @Type(() => AttributeValueGoal)
  @IsArray()
  @IsNotEmpty()
  goals: AttributeValueGoal[];

  @IsInt()
  @IsOptional()
  percentage_manager?: number;

  @IsString()
  @IsOptional()
  justification?: string;
}

export class AttributeValueGoalReview {
  @ValidateNested()
  @Type(() => AttributeValueGoal)
  @IsNotEmpty()
  goal: AttributeValueGoal;

  @IsInt()
  @IsOptional()
  percentage_employee?: number;

  @IsInt()
  @IsOptional()
  percentage_manager?: number;

  @IsString()
  @IsOptional()
  actions?: string;

  @IsString()
  @IsOptional()
  supporting_docs?: string;

  @IsString()
  @IsOptional()
  justification?: string;
}

export class AttributeValueGoalChange {
  @ValidateNested()
  @Type(() => AttributeValueGoal)
  @IsNotEmpty()
  goal: AttributeValueGoal;

  @IsString()
  @IsOptional()
  new_name?: string;

  @IsInt()
  @IsOptional()
  new_share?: number;

  @IsString()
  @IsOptional()
  change_reason?: string;
}

export class AttributeEntity {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsOptional()
  name?: string;
}

export class AttributeFile {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  url: string;
}

export type AttributeType = string;

export class Attribute {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsIn([
    'text',
    'textarea',
    'date',
    'file',
    'file_multiple',
    'choice',
    'year',
    'text_catalog',
    'url',
    'text_fixed_catalog',
    'staff_position',
    'goals',
    'goals_change',
    'goals_review',
    'goals_final_review',
  ])
  @IsString()
  @IsNotEmpty()
  type:
    | 'text'
    | 'textarea'
    | 'date'
    | 'file'
    | 'file_multiple'
    | 'choice'
    | 'year'
    | 'text_catalog'
    | 'url'
    | 'text_fixed_catalog'
    | 'staff_position'
    | 'goals'
    | 'goals_change'
    | 'goals_review'
    | 'goals_final_review';

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsString()
  @IsOptional()
  description?: string;

  @IsOptional()
  value?:
    | string
    | string[]
    | AttributeFile
    | AttributeFile[]
    | AttributeEntity
    | AttributeValueGoal[]
    | AttributeValueGoalChange[]
    | AttributeValueGoalReview[]
    | AttributeValueGoalsFinalReview;
}

export class DocumentSignature {
  @IsString()
  @IsOptional()
  signed_by?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  signed_by_name?: string;

  @IsIn(['not_signed', 'signed', 'on_signing', 'can_sign', 'declined_sign'])
  @IsString()
  @IsNotEmpty()
  status: 'not_signed' | 'signed' | 'on_signing' | 'can_sign' | 'declined_sign';
}

export class Document {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  document_type_name: string;

  @IsString()
  @IsNotEmpty()
  url: string;

  @IsString()
  @IsNotEmpty()
  url_with_stamp: string;

  @IsString()
  @IsNotEmpty()
  url_zip: string;

  @ValidateNested({ each: true })
  @Type(() => DocumentSignature)
  @IsArray()
  @IsNotEmpty()
  signatures: DocumentSignature[];

  @IsDateString()
  @IsOptional()
  read_at?: string;

  @IsBoolean()
  @IsNotEmpty()
  show_read_at: boolean;
}

export class CustomState {
  @IsString()
  @IsNotEmpty()
  name: string;

  @IsString()
  @IsOptional()
  description?: string;

  @IsIn(['gray', 'darkgray', 'green', 'orange', 'purple', 'blue', 'red'])
  @IsString()
  @IsOptional()
  color?: 'gray' | 'darkgray' | 'green' | 'orange' | 'purple' | 'blue' | 'red';
}

export class NodeActionConfirmation {
  @IsString()
  @IsNotEmpty()
  title: string;

  @IsString()
  @IsNotEmpty()
  body: string;
}

export class NodeActionResponsible {
  @IsString()
  @IsNotEmpty()
  role: string;

  @IsString()
  @IsNotEmpty()
  name: string;
}

export class ActionExtra {
  @IsString()
  @IsOptional()
  document_type_name?: string;

  @IsIn(['candidate_notify'])
  @IsString()
  @IsOptional()
  marker?: 'candidate_notify';
}

export class FormMetaObjectAttribute {
  @IsIn(['attribute'])
  @IsString()
  @IsNotEmpty()
  type: 'attribute';

  @IsUUID()
  @IsNotEmpty()
  id: string;
}

export class FormMetaObjectRow {
  @IsIn(['row'])
  @IsString()
  @IsNotEmpty()
  type: 'row';

  @ValidateNested({ each: true })
  @Type(() => FormMetaObjectAttribute)
  @IsArray()
  @IsNotEmpty()
  objects: FormMetaObjectAttribute[];
}

export class FormMetaObjectSubBlock {
  @IsIn(['subblock'])
  @IsString()
  @IsNotEmpty()
  type: 'subblock';

  @IsBoolean()
  @IsNotEmpty()
  is_multiple: boolean;

  @IsArray()
  @IsNotEmpty()
  objects: (FormMetaObjectRow | FormMetaObjectAttribute)[];
}

export class FormMetaObjectBlock {
  @IsIn(['block'])
  @IsString()
  @IsNotEmpty()
  type: 'block';

  @IsString()
  @IsOptional()
  title?: string;

  @IsString()
  @IsOptional()
  description?: string;

  @IsArray()
  @IsNotEmpty()
  objects: (
    | FormMetaObjectSubBlock
    | FormMetaObjectRow
    | FormMetaObjectAttribute
  )[];
}

export class FormMetaObjectDocument {
  @IsIn(['document'])
  @IsString()
  @IsNotEmpty()
  type: 'document';

  @IsString()
  @IsOptional()
  title?: string;

  @IsString()
  @IsOptional()
  description?: string;

  @IsBoolean()
  @IsNotEmpty()
  is_multiple: boolean;

  @IsArray()
  @IsNotEmpty()
  objects: (
    | FormMetaObjectBlock
    | FormMetaObjectRow
    | FormMetaObjectAttribute
  )[];
}

export class FormMetaObjectStep {
  @IsIn(['step'])
  @IsString()
  @IsNotEmpty()
  type: 'step';

  @IsString()
  @IsOptional()
  title?: string;

  @IsString()
  @IsOptional()
  description?: string;

  @IsArray()
  @IsNotEmpty()
  objects: (FormMetaObjectDocument | FormMetaObjectBlock)[];
}

export class FormAttributeTooltip {
  @IsString()
  @IsOptional()
  title?: string;

  @IsString()
  @IsNotEmpty()
  description: string;

  @IsIn(['inline', 'disclaimer'])
  @IsString()
  @IsNotEmpty()
  style: 'inline' | 'disclaimer';

  @IsBoolean()
  @IsNotEmpty()
  visible: boolean;
}

export class FormAttributeInfoVacationDays {
  @IsIn(['vacation_days'])
  @IsString()
  @IsNotEmpty()
  type: 'vacation_days';

  @IsInt()
  @IsOptional()
  vacation_days?: number;

  @IsBoolean()
  @IsNotEmpty()
  import_vacation_days: boolean;
}

export class FormAttributeHideWhen {
  @IsUUID()
  @IsNotEmpty()
  attribute_id: string;

  @IsString({ each: true })
  @IsArray()
  @IsOptional()
  equal?: string[];

  @IsString({ each: true })
  @IsArray()
  @IsOptional()
  unequal?: string[];
}

export class FormAttributeChoice {
  @IsString()
  @IsNotEmpty()
  label: string;

  @IsString()
  @IsNotEmpty()
  value: string;

  @IsBoolean()
  @IsOptional()
  default?: boolean;
}

export class FormAttributeOptions {
  @ValidateNested({ each: true })
  @Type(() => FormAttributeChoice)
  @IsArray()
  @IsOptional()
  choices?: FormAttributeChoice[];

  @IsBoolean()
  @IsOptional()
  expanded?: boolean;

  @ValidateNested()
  @Type(() => FormAttributeHideWhen)
  @IsOptional()
  hide_when?: FormAttributeHideWhen;

  @ValidateNested()
  @Type(() => FormAttributeInfoVacationDays)
  @IsOptional()
  info?: FormAttributeInfoVacationDays;

  @ValidateNested()
  @Type(() => FormAttributeTooltip)
  @IsOptional()
  tooltip?: FormAttributeTooltip;

  @IsString()
  @IsOptional()
  placeholder?: string;

  @IsString()
  @IsOptional()
  format?: string;

  @IsIn(['S', 'M', 'L'])
  @IsString()
  @IsOptional()
  size?: 'S' | 'M' | 'L';

  @IsBoolean()
  @IsOptional()
  ocr?: boolean;

  @IsBoolean()
  @IsOptional()
  qrcode?: boolean;

  @IsBoolean()
  @IsOptional()
  is_multiple?: boolean;

  @IsString()
  @IsOptional()
  expression?: string;

  @IsOptional()
  expression_aliases?: object;

  @IsString()
  @IsOptional()
  alias?: string;

  @IsInt()
  @IsOptional()
  decimals?: number;

  @IsString({ each: true })
  @IsArray()
  @IsOptional()
  editable_fields?: (
    | 'percentage_employee'
    | 'percentage_manager'
    | 'actions'
    | 'obstacles'
    | 'supporting_docs'
    | 'justification'
  )[];
}

export class FormAttribute {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsIn([
    'text',
    'textarea',
    'date',
    'file',
    'file_multiple',
    'choice',
    'year',
    'text_catalog',
    'url',
    'text_fixed_catalog',
    'staff_position',
    'goals',
    'goals_change',
    'goals_review',
    'goals_final_review',
  ])
  @IsString()
  @IsNotEmpty()
  type:
    | 'text'
    | 'textarea'
    | 'date'
    | 'file'
    | 'file_multiple'
    | 'choice'
    | 'year'
    | 'text_catalog'
    | 'url'
    | 'text_fixed_catalog'
    | 'staff_position'
    | 'goals'
    | 'goals_change'
    | 'goals_review'
    | 'goals_final_review';

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsString()
  @IsOptional()
  description?: string;

  @IsBoolean()
  @IsNotEmpty()
  required: boolean;

  @IsBoolean()
  @IsOptional()
  read_only?: boolean;

  @ValidateNested()
  @Type(() => FormAttributeOptions)
  @IsOptional()
  options?: FormAttributeOptions;
}

export type ActionType = string;

export type UnepType = string;

export class NodeAction {
  @IsIn([
    'kontur',
    'goskey',
    'cryptopro_simple',
    'cryptopro_local',
    'disabled',
    'ukep',
  ])
  @IsString()
  @IsNotEmpty()
  unep_type:
    | 'kontur'
    | 'goskey'
    | 'cryptopro_simple'
    | 'cryptopro_local'
    | 'disabled'
    | 'ukep';

  @IsIn(['sms', 'no_sms'])
  @IsString()
  @IsOptional()
  two_factor_auth?: 'sms' | 'no_sms';

  @IsIn(['new', 'processing', 'canceled', 'error'])
  @IsString()
  @IsOptional()
  signature_status?: 'new' | 'processing' | 'canceled' | 'error';

  @IsIn(['snils_not_found'])
  @IsString()
  @IsOptional()
  signature_detailed_error?: 'snils_not_found';

  @IsString()
  @IsOptional()
  name?: string;

  @IsBoolean()
  @IsNotEmpty()
  is_primary: boolean;

  @IsBoolean()
  @IsOptional()
  is_employee?: boolean;

  @IsIn([
    'upload',
    'accept',
    'ukep_sign',
    'ukep_sign_batch',
    'unep_sign',
    'unep_sign_batch',
    'pep_sign',
    'pep_sign_batch',
    'generate_document_from_template',
    'decline',
    'decline_sign',
    'return',
    'competency_eval',
    'competency_profile',
    'system',
    'system_validate_sign',
    'system_set_vacation_days',
    'system_sync_scheduled_vacations',
    'system_booking_hook',
    'system_booking_approve',
    'system_booking_limits_exceeded_approve',
    'system_booking_trip_create',
    'system_booking_trip_change',
    'system_booking_trip_ordering',
    'system_booking_trip_limit',
    'system_booking_trip_limit_approved',
    'system_deadline',
    'system_condition_positive',
    'system_condition_negative',
    'system_condition_0',
    'system_condition_1',
    'system_condition_2',
    'system_condition_3',
    'system_condition_4',
    'system_condition_5',
    'system_condition_6',
    'system_condition_7',
    'system_condition_8',
    'system_condition_9',
  ])
  @IsString()
  @IsNotEmpty()
  type:
    | 'upload'
    | 'accept'
    | 'ukep_sign'
    | 'ukep_sign_batch'
    | 'unep_sign'
    | 'unep_sign_batch'
    | 'pep_sign'
    | 'pep_sign_batch'
    | 'generate_document_from_template'
    | 'decline'
    | 'decline_sign'
    | 'return'
    | 'competency_eval'
    | 'competency_profile'
    | 'system'
    | 'system_validate_sign'
    | 'system_set_vacation_days'
    | 'system_sync_scheduled_vacations'
    | 'system_booking_hook'
    | 'system_booking_approve'
    | 'system_booking_limits_exceeded_approve'
    | 'system_booking_trip_create'
    | 'system_booking_trip_change'
    | 'system_booking_trip_ordering'
    | 'system_booking_trip_limit'
    | 'system_booking_trip_limit_approved'
    | 'system_deadline'
    | 'system_condition_positive'
    | 'system_condition_negative'
    | 'system_condition_0'
    | 'system_condition_1'
    | 'system_condition_2'
    | 'system_condition_3'
    | 'system_condition_4'
    | 'system_condition_5'
    | 'system_condition_6'
    | 'system_condition_7'
    | 'system_condition_8'
    | 'system_condition_9';

  @ValidateNested()
  @Type(() => Document)
  @IsOptional()
  document?: Document;

  @IsBoolean()
  @IsOptional()
  document_info_required?: boolean;

  @ValidateNested({ each: true })
  @Type(() => Document)
  @IsArray()
  @IsOptional()
  documents?: Document[];

  @ValidateNested({ each: true })
  @Type(() => FormAttribute)
  @IsArray()
  @IsNotEmpty()
  form_attributes: FormAttribute[];

  @IsArray()
  @IsOptional()
  form_meta?: (
    | FormMetaObjectStep
    | FormMetaObjectDocument
    | FormMetaObjectBlock
  )[];

  @ValidateNested()
  @Type(() => ActionExtra)
  @IsOptional()
  extra?: ActionExtra;

  @ValidateNested({ each: true })
  @Type(() => NodeActionResponsible)
  @IsArray()
  @IsNotEmpty()
  responsible: NodeActionResponsible[];

  @ValidateNested()
  @Type(() => NodeActionConfirmation)
  @IsOptional()
  confirmation?: NodeActionConfirmation;
}

export class EventNode {
  @IsUUID()
  @IsNotEmpty()
  node_id: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsString()
  @IsOptional()
  description?: string;

  @ValidateNested({ each: true })
  @Type(() => NodeAction)
  @IsArray()
  @IsNotEmpty()
  actions: NodeAction[];

  @IsDateString()
  @IsOptional()
  deadline?: string;

  @ValidateNested()
  @Type(() => CustomState)
  @IsOptional()
  custom_state?: CustomState;
}

export class CanceledDetails {
  @IsDateString()
  @IsNotEmpty()
  canceled_at: string;

  @IsString()
  @IsNotEmpty()
  reason: string;

  @IsIn([
    'node_deadline',
    'event_deadline',
    'manual_employee',
    'manual_company',
    'dismiss',
    'workflow_change',
  ])
  @IsString()
  @IsNotEmpty()
  reason_type:
    | 'node_deadline'
    | 'event_deadline'
    | 'manual_employee'
    | 'manual_company'
    | 'dismiss'
    | 'workflow_change';

  @IsString()
  @IsOptional()
  document_type_name?: string;

  @IsString()
  @IsNotEmpty()
  node_name: string;

  @IsString()
  @IsNotEmpty()
  responsible: string;

  @IsBoolean()
  @IsNotEmpty()
  can_restore: boolean;

  @IsString()
  @IsOptional()
  comment?: string;
}

export class Action {
  @IsString()
  @IsNotEmpty()
  name: string;

  @IsIn([
    'upload',
    'accept',
    'ukep_sign',
    'ukep_sign_batch',
    'unep_sign',
    'unep_sign_batch',
    'pep_sign',
    'pep_sign_batch',
    'system',
    'system_validate_sign',
    'system_delete_attributes',
    'generate_document_from_template',
    'decline',
    'decline_sign',
    'in_paper',
    'completed',
    'canceled',
    'declined',
    'declined_sign',
    'competency_eval',
    'competency_profile',
    'system_complete_candidate',
    'system_booking_hook',
    'system_booking_approve',
    'system_booking_limits_exceeded_approve',
    'system_booking_trip_create',
    'system_booking_trip_change',
    'system_booking_trip_cancel',
    'system_booking_trip_ordering',
    'system_booking_trip_limit',
    'system_booking_trip_limit_approved',
    'system_set_vacation_days',
    'system_sync_scheduled_vacations',
    'system_deadline',
  ])
  @IsString()
  @IsNotEmpty()
  type:
    | 'upload'
    | 'accept'
    | 'ukep_sign'
    | 'ukep_sign_batch'
    | 'unep_sign'
    | 'unep_sign_batch'
    | 'pep_sign'
    | 'pep_sign_batch'
    | 'system'
    | 'system_validate_sign'
    | 'system_delete_attributes'
    | 'generate_document_from_template'
    | 'decline'
    | 'decline_sign'
    | 'in_paper'
    | 'completed'
    | 'canceled'
    | 'declined'
    | 'declined_sign'
    | 'competency_eval'
    | 'competency_profile'
    | 'system_complete_candidate'
    | 'system_booking_hook'
    | 'system_booking_approve'
    | 'system_booking_limits_exceeded_approve'
    | 'system_booking_trip_create'
    | 'system_booking_trip_change'
    | 'system_booking_trip_cancel'
    | 'system_booking_trip_ordering'
    | 'system_booking_trip_limit'
    | 'system_booking_trip_limit_approved'
    | 'system_set_vacation_days'
    | 'system_sync_scheduled_vacations'
    | 'system_deadline';
}

export class ActiveNode {
  @ValidateNested()
  @Type(() => Action)
  @IsNotEmpty()
  action: Action;

  @ValidateNested()
  @Type(() => CustomState)
  @IsOptional()
  custom_state?: CustomState;

  @IsString()
  @IsOptional()
  responsible?: string;

  @IsString({ each: true })
  @IsArray()
  @IsOptional()
  document_type_names?: string[];
}

export class CompanyItem {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsString()
  @IsOptional()
  tsp_url?: string;

  @IsIn(['api', 'browser_plugin'])
  @IsString()
  @IsOptional()
  hash_source?: 'api' | 'browser_plugin';
}

export type Email = string;

export type Phone = string;

export class EventEmployee {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  personnel_number: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsDateString()
  @IsNotEmpty()
  birthday: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  first_name: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  second_name: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  middle_name: string;

  @IsString()
  @IsOptional()
  unit?: string;

  @IsString()
  @IsOptional()
  legal_unit?: string;

  @IsString()
  @IsOptional()
  position?: string;

  @IsDateString()
  @IsOptional()
  dismissed_at?: string;

  @IsString()
  @IsOptional()
  phone?: string;

  @IsString()
  @IsOptional()
  email?: string;
}

export type EventCategory = string;

export class EventTypeItem {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsIn(['item'])
  @IsString()
  @IsNotEmpty()
  type: 'item';

  @IsString()
  @IsOptional()
  custom_type?: string;

  @IsIn(['employee', 'candidate', 'vacation'])
  @IsString()
  @IsOptional()
  category?: 'employee' | 'candidate' | 'vacation';

  @IsBoolean()
  @IsOptional()
  document_info_required?: boolean;
}

export class Event {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @ValidateNested()
  @Type(() => EventTypeItem)
  @IsNotEmpty()
  event_type: EventTypeItem;

  @ValidateNested()
  @Type(() => EventEmployee)
  @IsOptional()
  employee?: EventEmployee;

  @ValidateNested()
  @Type(() => CompanyItem)
  @IsNotEmpty()
  company: CompanyItem;

  @IsDateString()
  @IsNotEmpty()
  created_at: string;

  @ValidateNested({ each: true })
  @Type(() => ActiveNode)
  @IsArray()
  @IsNotEmpty()
  active_nodes: ActiveNode[];

  @IsDateString()
  @IsOptional()
  deadline?: string;

  @ValidateNested()
  @Type(() => CanceledDetails)
  @IsOptional()
  canceled?: CanceledDetails;

  @IsBoolean()
  @IsNotEmpty()
  signed_after_cancel: boolean;

  @IsBoolean()
  @IsNotEmpty()
  signed_after_skip: boolean;

  @IsUUID()
  @IsOptional()
  parent_event_id?: string;

  @IsInt()
  @IsOptional()
  children_events_count?: number;

  @ValidateNested({ each: true })
  @Type(() => EventNode)
  @IsArray()
  @IsNotEmpty()
  nodes: EventNode[];

  @ValidateNested({ each: true })
  @Type(() => EventNode)
  @IsArray()
  @IsNotEmpty()
  current_nodes: EventNode[];

  @ValidateNested({ each: true })
  @Type(() => Document)
  @IsArray()
  @IsNotEmpty()
  documents: Document[];

  @ValidateNested({ each: true })
  @Type(() => Attribute)
  @IsArray()
  @IsNotEmpty()
  attributes: Attribute[];

  @IsArray()
  @IsOptional()
  attributes_meta?: (FormMetaObjectDocument | FormMetaObjectBlock)[];

  @ValidateNested({ each: true })
  @Type(() => EventParticipant)
  @IsArray()
  @IsOptional()
  participants?: EventParticipant[];

  @ValidateNested()
  @Type(() => EventPermissions)
  @IsNotEmpty()
  permissions: EventPermissions;

  @ValidateNested({ each: true })
  @Type(() => EventComment)
  @IsArray()
  @IsOptional()
  comments?: EventComment[];

  @ValidateNested()
  @Type(() => EventOptions)
  @IsOptional()
  options?: EventOptions;

  @ValidateNested({ each: true })
  @Type(() => EventCompetencyGroup)
  @IsArray()
  @IsOptional()
  competency_groups?: EventCompetencyGroup[];
}

export class ScheduleEvent {
  @ValidateNested()
  @Type(() => Event)
  @IsNotEmpty()
  event: Event;

  @IsInt()
  @IsNotEmpty()
  schedule_year: number;

  @IsUUID()
  @IsNotEmpty()
  schedule_id: string;

  @IsBoolean()
  @IsNotEmpty()
  favorites_allowed: boolean;
}

export class FilterSelectBadge {
  @IsString()
  @IsNotEmpty()
  title: string;

  @IsString()
  @IsOptional()
  description?: string;

  @IsIn(['blue', 'velvet', 'red'])
  @IsString()
  @IsOptional()
  color?: 'blue' | 'velvet' | 'red';

  @IsIn(['left', 'bottom'])
  @IsString()
  @IsOptional()
  position?: 'left' | 'bottom';
}

export class FilterSelectOption {
  @IsString()
  @IsNotEmpty()
  value: string;

  @IsString()
  @IsNotEmpty()
  label: string;

  @ValidateNested()
  @Type(() => FilterSelectBadge)
  @IsOptional()
  badge?: FilterSelectBadge;
}

export type VacationScheduleAvailableFiltersEmployeeOptionsResponse =
  FilterSelectOption[];

export class FilterTimeRange {
  @IsString()
  @IsNotEmpty()
  key: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsIn(['timerange'])
  @IsString()
  @IsNotEmpty()
  type: 'timerange';

  @IsString()
  @IsNotEmpty()
  placeholder: string;

  @IsIn(['till_now', 'any', 'from_now'])
  @IsString()
  @IsNotEmpty()
  allow_dates: 'till_now' | 'any' | 'from_now';
}

export class FilterBool {
  @IsString()
  @IsNotEmpty()
  key: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsIn(['bool'])
  @IsString()
  @IsNotEmpty()
  type: 'bool';
}

export class FilterMultipleSearch {
  @IsString()
  @IsNotEmpty()
  key: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsIn(['multiple-search'])
  @IsString()
  @IsNotEmpty()
  type: 'multiple-search';

  @IsString()
  @IsNotEmpty()
  url: string;

  @IsString()
  @IsNotEmpty()
  placeholder: string;
}

export class FilterSearch {
  @IsString()
  @IsNotEmpty()
  key: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsIn(['search'])
  @IsString()
  @IsNotEmpty()
  type: 'search';

  @IsString()
  @IsNotEmpty()
  url: string;

  @IsString()
  @IsNotEmpty()
  placeholder: string;

  @IsBoolean()
  @IsOptional()
  disable_if_one_option?: boolean;
}

export class FilterText {
  @IsString()
  @IsNotEmpty()
  key: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsIn(['text'])
  @IsString()
  @IsNotEmpty()
  type: 'text';

  @IsString()
  @IsNotEmpty()
  placeholder: string;
}

export class FilterMultiple {
  @IsString()
  @IsNotEmpty()
  key: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsIn(['multiple'])
  @IsString()
  @IsNotEmpty()
  type: 'multiple';

  @ValidateNested({ each: true })
  @Type(() => FilterSelectOption)
  @IsArray()
  @IsNotEmpty()
  options: FilterSelectOption[];

  @IsString()
  @IsNotEmpty()
  placeholder: string;

  @IsBoolean()
  @IsOptional()
  disable_if_one_option?: boolean;

  @IsBoolean()
  @IsOptional()
  hidden?: boolean;
}

export class FilterSelect {
  @IsString()
  @IsNotEmpty()
  key: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsIn(['select'])
  @IsString()
  @IsNotEmpty()
  type: 'select';

  @ValidateNested({ each: true })
  @Type(() => FilterSelectOption)
  @IsArray()
  @IsNotEmpty()
  options: FilterSelectOption[];

  @IsString()
  @IsNotEmpty()
  placeholder: string;

  @IsBoolean()
  @IsOptional()
  autocomplete?: boolean;

  @IsBoolean()
  @IsOptional()
  disable_if_one_option?: boolean;
}

export class ScheduleEventAvailableFilters {
  @IsArray()
  @IsNotEmpty()
  filters: (
    | FilterSelect
    | FilterMultiple
    | FilterText
    | FilterSearch
    | FilterMultipleSearch
    | FilterBool
    | FilterTimeRange
  )[];
}

export class ScheduleEmployee {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  personnel_number: string;

  @IsString()
  @IsNotEmpty()
  position: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  first_name: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  second_name: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  middle_name: string;
}

export class ScheduleManager {
  @ValidateNested()
  @Type(() => ScheduleEmployee)
  @IsNotEmpty()
  employee: ScheduleEmployee;

  @IsString()
  @IsNotEmpty()
  type_name: string;
}

export class UnitScheduleItem {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsUUID()
  @IsOptional()
  parent_id?: string;

  @ValidateNested()
  @Type(() => ScheduleManager)
  @IsOptional()
  manager?: ScheduleManager;
}

export type DateType = string;

export class WorkCalendarDate {
  @IsDateString()
  @IsNotEmpty()
  date: string;

  @IsIn(['workday', 'weekend', 'holiday'])
  @IsString()
  @IsNotEmpty()
  type: 'workday' | 'weekend' | 'holiday';
}

export class VacationItem {
  @IsString()
  @IsNotEmpty()
  type_name: string;

  @IsDateString()
  @IsNotEmpty()
  from_date: string;

  @IsDateString()
  @IsNotEmpty()
  to_date: string;
}

export class ScheduleEventParticipant {
  @IsString()
  @IsNotEmpty()
  type: string;

  @IsString()
  @IsOptional()
  data?: string;
}

export class ReturnEventAction {
  @IsUUID()
  @IsNotEmpty()
  node_id: string;

  @IsUUID()
  @IsOptional()
  message_attr_id?: string;
}

export class AcceptEventAction {
  @IsUUID()
  @IsNotEmpty()
  node_id: string;

  @IsUUID()
  @IsOptional()
  message_attr_id?: string;
}

export class EventActions {
  @ValidateNested()
  @Type(() => AcceptEventAction)
  @IsOptional()
  accept_action?: AcceptEventAction;

  @ValidateNested()
  @Type(() => ReturnEventAction)
  @IsOptional()
  return_action?: ReturnEventAction;
}

export class ScheduleEventListItem {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @ValidateNested()
  @Type(() => ScheduleEmployee)
  @IsNotEmpty()
  employee: ScheduleEmployee;

  @IsDateString()
  @IsOptional()
  deadline?: string;

  @ValidateNested()
  @Type(() => EventActions)
  @IsOptional()
  actions?: EventActions;

  @ValidateNested()
  @Type(() => ScheduleEventParticipant)
  @IsOptional()
  active_participant?: ScheduleEventParticipant;

  @IsBoolean()
  @IsNotEmpty()
  up_to_me: boolean;

  @IsBoolean()
  @IsNotEmpty()
  my_event: boolean;

  @IsBoolean()
  @IsNotEmpty()
  favorite: boolean;

  @IsUUID()
  @IsOptional()
  unit_id?: string;

  @ValidateNested({ each: true })
  @Type(() => VacationItem)
  @IsArray()
  @IsNotEmpty()
  vacations: VacationItem[];

  @ValidateNested({ each: true })
  @Type(() => WorkCalendarDate)
  @IsArray()
  @IsOptional()
  calendar_diff?: WorkCalendarDate[];

  @IsIn(['new', 'accept', 'finished', 'canceled', 'onec_sync'])
  @IsString()
  @IsNotEmpty()
  status: 'new' | 'accept' | 'finished' | 'canceled' | 'onec_sync';
}

export class ScheduleFavoriteEventsListResponse {
  @ValidateNested({ each: true })
  @Type(() => ScheduleEventListItem)
  @IsArray()
  @IsNotEmpty()
  events: ScheduleEventListItem[];

  @ValidateNested({ each: true })
  @Type(() => UnitScheduleItem)
  @IsArray()
  @IsOptional()
  units?: UnitScheduleItem[];

  @IsBoolean()
  @IsNotEmpty()
  show_units: boolean;
}

export class ScheduleEventsXlsxResponse {
  @IsUUID()
  @IsNotEmpty()
  download_id: string;
}

export class ScheduleEventsListResponse {
  @ValidateNested({ each: true })
  @Type(() => ScheduleEventListItem)
  @IsArray()
  @IsNotEmpty()
  events: ScheduleEventListItem[];

  @ValidateNested({ each: true })
  @Type(() => UnitScheduleItem)
  @IsArray()
  @IsOptional()
  units?: UnitScheduleItem[];

  @IsBoolean()
  @IsNotEmpty()
  show_units: boolean;

  @IsInt()
  @IsNotEmpty()
  total_count: number;

  @IsInt()
  @IsOptional()
  total_count_my?: number;

  @IsInt()
  @IsOptional()
  total_count_subordinates?: number;

  @IsInt()
  @IsOptional()
  total_count_all?: number;

  @IsInt()
  @IsOptional()
  total_count_favorites?: number;
}

export class EventAttributeCacheFileResponse {
  @IsUUID()
  @IsNotEmpty()
  file_id: string;

  @IsDateString()
  @IsNotEmpty()
  expired_at: string;
}

export class EventFileHash {
  @IsUUID()
  @IsNotEmpty()
  event_id: string;

  @IsUUID()
  @IsNotEmpty()
  document_id: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  hash: string;
}

export class GetEventFileHashesResponse {
  @ValidateNested({ each: true })
  @Type(() => EventFileHash)
  @IsArray()
  @IsNotEmpty()
  hashes: EventFileHash[];
}

export class GetEventDocumentFileHashResponse {
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  hash: string;
}

export class CreateEventBatchDocumentZipResponse {
  @IsUUID()
  @IsNotEmpty()
  download_id: string;
}

export class BatchEventOperationResponse {
  @IsInt()
  @IsNotEmpty()
  success_count: number;

  @IsInt()
  @IsNotEmpty()
  error_count: number;
}

export class BatchEventAcceptResponse {
  @IsUUID(undefined, { each: true })
  @IsArray()
  @IsNotEmpty()
  successes: string[];

  @IsUUID(undefined, { each: true })
  @IsArray()
  @IsNotEmpty()
  errors: string[];
}

export class NonWorkingDaysIncludedCheckValidator {
  @IsIn(['non_working_days_included_check'])
  @IsString()
  @IsNotEmpty()
  type: 'non_working_days_included_check';

  @IsString()
  @IsNotEmpty()
  from_attribute_id: string;

  @IsString()
  @IsNotEmpty()
  to_attribute_id: string;
}

export class VacationPeriodsValidator {
  @IsIn(['vacation_periods'])
  @IsString()
  @IsOptional()
  type?: 'vacation_periods';

  @IsString()
  @IsOptional()
  from_attribute_id?: string;

  @IsString()
  @IsOptional()
  to_attribute_id?: string;

  @IsString()
  @IsOptional()
  type_attribute_id?: string;

  @IsOptional()
  vacations?: object;
}

export class VacationMin5daysBetweenNotWorkdaysValidator {
  @IsIn(['vacation_min_5_days_between_not_workdays'])
  @IsString()
  @IsOptional()
  type?: 'vacation_min_5_days_between_not_workdays';

  @IsString()
  @IsOptional()
  from_attribute_id?: string;

  @IsString()
  @IsOptional()
  to_attribute_id?: string;

  @IsString({ each: true })
  @IsArray()
  @IsOptional()
  vacation_types?: string[];
}

export class VacationLimitToCalculatedDaysValidator {
  @IsIn(['vacation_limit_to_calculated_days'])
  @IsString()
  @IsOptional()
  type?: 'vacation_limit_to_calculated_days';

  @IsString()
  @IsOptional()
  from_attribute_id?: string;

  @IsString()
  @IsOptional()
  to_attribute_id?: string;
}

export class VacationSchedule14DaysValidator {
  @IsIn(['vacation_schedule_14_days'])
  @IsString()
  @IsOptional()
  type?: 'vacation_schedule_14_days';

  @IsString({ each: true })
  @IsArray()
  @IsOptional()
  vacation_types?: string[];

  @IsString()
  @IsOptional()
  from_attribute_id?: string;

  @IsString()
  @IsOptional()
  to_attribute_id?: string;
}

export class VacationScheduleAllDaysValidator {
  @IsIn(['vacation_schedule_all_days'])
  @IsString()
  @IsOptional()
  type?: 'vacation_schedule_all_days';

  @IsString()
  @IsOptional()
  from_attribute_id?: string;

  @IsString()
  @IsOptional()
  to_attribute_id?: string;
}

export class MinHiringDayValidator {
  @IsIn(['min_hiring_day'])
  @IsString()
  @IsNotEmpty()
  type: 'min_hiring_day';

  @IsDateString()
  @IsOptional()
  hired_at?: string;

  @IsIn(['eq', 'ge', 'gt', 'le', 'lt'])
  @IsString()
  @IsNotEmpty()
  operation: 'eq' | 'ge' | 'gt' | 'le' | 'lt';

  @IsString()
  @IsNotEmpty()
  attribute_id: string;
}

export class MinSpecificWeekendDaysValidator {
  @IsIn(['min_specific_weekend_days'])
  @IsString()
  @IsNotEmpty()
  type: 'min_specific_weekend_days';

  @IsString()
  @IsNotEmpty()
  from_attribute_id: string;

  @IsString()
  @IsNotEmpty()
  to_attribute_id: string;

  @IsString({ each: true })
  @IsArray()
  @IsOptional()
  vacation_types?: string[];
}

export class MinWeekendDaysValidator {
  @IsIn(['min_weekend_days'])
  @IsString()
  @IsNotEmpty()
  type: 'min_weekend_days';

  @IsInt()
  @IsNotEmpty()
  working_days: number;

  @IsInt()
  @IsNotEmpty()
  min_weekend_days: number;

  @IsString()
  @IsNotEmpty()
  from_attribute_id: string;

  @IsString()
  @IsNotEmpty()
  to_attribute_id: string;

  @IsNotEmpty()
  vacation_type: object;
}

export class MaxYearValidator {
  @IsIn(['max_year'])
  @IsString()
  @IsNotEmpty()
  type: 'max_year';

  @IsInt()
  @IsNotEmpty()
  max_year: number;

  @IsInt()
  @IsNotEmpty()
  min_year: number;

  @IsString()
  @IsNotEmpty()
  attribute_id: string;
}

export class DivisibleByValidator {
  @IsIn(['divisible_by'])
  @IsString()
  @IsNotEmpty()
  type: 'divisible_by';

  @IsInt()
  @IsNotEmpty()
  divisible_by: number;

  @IsString()
  @IsNotEmpty()
  from_attribute_id: string;

  @IsString()
  @IsNotEmpty()
  to_attribute_id: string;
}

export class VacationAvailableValidator {
  @IsIn(['vacation_available'])
  @IsString()
  @IsNotEmpty()
  type: 'vacation_available';

  @IsInt()
  @IsNotEmpty()
  vacation_available_days: number;

  @IsString()
  @IsNotEmpty()
  from_attribute_id: string;

  @IsString()
  @IsNotEmpty()
  to_attribute_id: string;
}

export class SameMonthValidator {
  @IsIn(['same_month'])
  @IsString()
  @IsNotEmpty()
  type: 'same_month';

  @IsString()
  @IsNotEmpty()
  from_attribute_id: string;

  @IsString()
  @IsNotEmpty()
  to_attribute_id: string;
}

export class DateRange {
  @IsDateString()
  @IsNotEmpty()
  from_date: string;

  @IsDateString()
  @IsNotEmpty()
  to_date: string;
}

export class ForbiddenPeriodsValidator {
  @IsIn(['forbidden_periods'])
  @IsString()
  @IsNotEmpty()
  type: 'forbidden_periods';

  @ValidateNested({ each: true })
  @Type(() => DateRange)
  @IsArray()
  @IsNotEmpty()
  existing_dates: DateRange[];

  @IsString()
  @IsNotEmpty()
  from_attribute_id: string;

  @IsString()
  @IsNotEmpty()
  to_attribute_id: string;
}

export class NoOverlapValidator {
  @IsIn(['no_overlap'])
  @IsString()
  @IsNotEmpty()
  type: 'no_overlap';

  @ValidateNested({ each: true })
  @Type(() => DateRange)
  @IsArray()
  @IsNotEmpty()
  existing_dates: DateRange[];

  @IsString()
  @IsNotEmpty()
  from_attribute_id: string;

  @IsString()
  @IsNotEmpty()
  to_attribute_id: string;
}

export class MaximumDurationValidator {
  @IsIn(['max_duration'])
  @IsString()
  @IsNotEmpty()
  type: 'max_duration';

  @IsInt()
  @IsNotEmpty()
  days_count: number;

  @IsBoolean()
  @IsNotEmpty()
  working_days_only: boolean;

  @IsOptional()
  by_vacation_type?: object;

  @IsString()
  @IsNotEmpty()
  from_attribute_id: string;

  @IsString()
  @IsNotEmpty()
  to_attribute_id: string;
}

export class MinimumDurationValidator {
  @IsIn(['min_duration'])
  @IsString()
  @IsNotEmpty()
  type: 'min_duration';

  @IsInt()
  @IsNotEmpty()
  days_count: number;

  @IsBoolean()
  @IsNotEmpty()
  working_days_only: boolean;

  @IsOptional()
  by_vacation_type?: object;

  @IsString()
  @IsNotEmpty()
  from_attribute_id: string;

  @IsString()
  @IsNotEmpty()
  to_attribute_id: string;
}

export class HolydaysExclusionCheckValidator {
  @IsIn(['holydays_exclusion'])
  @IsString()
  @IsNotEmpty()
  type: 'holydays_exclusion';

  @IsString()
  @IsNotEmpty()
  from_attribute_id: string;

  @IsString()
  @IsNotEmpty()
  to_attribute_id: string;
}

export class WeekendExclusionCheckValidator {
  @IsIn(['weekend_exclusion'])
  @IsString()
  @IsNotEmpty()
  type: 'weekend_exclusion';

  @IsDateString(undefined, { each: true })
  @IsArray()
  @IsNotEmpty()
  existing_vacations: string[];

  @IsString()
  @IsNotEmpty()
  from_attribute_id: string;

  @IsString()
  @IsNotEmpty()
  to_attribute_id: string;
}

export class WorkingDayCheckValidator {
  @IsIn(['working_day'])
  @IsString()
  @IsNotEmpty()
  type: 'working_day';

  @IsString()
  @IsNotEmpty()
  from_attribute_id: string;

  @IsString()
  @IsNotEmpty()
  to_attribute_id: string;
}

export class MaxDateValidator {
  @IsIn(['max_date'])
  @IsString()
  @IsNotEmpty()
  type: 'max_date';

  @IsDateString()
  @IsNotEmpty()
  max_date: string;

  @IsInt()
  @IsNotEmpty()
  days_count: number;

  @IsBoolean()
  @IsNotEmpty()
  working_days_only: boolean;

  @IsString()
  @IsNotEmpty()
  attribute_id: string;
}

export class MinDateValidator {
  @IsIn(['min_date'])
  @IsString()
  @IsNotEmpty()
  type: 'min_date';

  @IsDateString()
  @IsNotEmpty()
  min_date: string;

  @IsInt()
  @IsNotEmpty()
  days_count: number;

  @IsBoolean()
  @IsNotEmpty()
  working_days_only: boolean;

  @IsString()
  @IsNotEmpty()
  attribute_id: string;
}

export class NumberValidator {
  @IsIn(['number'])
  @IsString()
  @IsNotEmpty()
  type: 'number';

  @IsNumber()
  @IsOptional()
  less_than?: number;

  @IsNumber()
  @IsOptional()
  less_than_or_equal?: number;

  @IsNumber()
  @IsOptional()
  greater_than?: number;

  @IsNumber()
  @IsOptional()
  greater_than_or_equal?: number;

  @IsUUID()
  @IsNotEmpty()
  attribute_id: string;
}

export class ValidatorList {}

export class Transition {
  @IsIn([
    'upload',
    'accept',
    'ukep_sign',
    'ukep_sign_batch',
    'unep_sign',
    'unep_sign_batch',
    'pep_sign',
    'pep_sign_batch',
    'generate_document_from_template',
    'decline',
    'decline_sign',
    'return',
    'competency_eval',
    'competency_profile',
    'system',
    'system_validate_sign',
    'system_set_vacation_days',
    'system_sync_scheduled_vacations',
    'system_booking_hook',
    'system_booking_approve',
    'system_booking_limits_exceeded_approve',
    'system_booking_trip_create',
    'system_booking_trip_change',
    'system_booking_trip_ordering',
    'system_booking_trip_limit',
    'system_booking_trip_limit_approved',
    'system_deadline',
    'system_condition_positive',
    'system_condition_negative',
    'system_condition_0',
    'system_condition_1',
    'system_condition_2',
    'system_condition_3',
    'system_condition_4',
    'system_condition_5',
    'system_condition_6',
    'system_condition_7',
    'system_condition_8',
    'system_condition_9',
  ])
  @IsString()
  @IsNotEmpty()
  action_type:
    | 'upload'
    | 'accept'
    | 'ukep_sign'
    | 'ukep_sign_batch'
    | 'unep_sign'
    | 'unep_sign_batch'
    | 'pep_sign'
    | 'pep_sign_batch'
    | 'generate_document_from_template'
    | 'decline'
    | 'decline_sign'
    | 'return'
    | 'competency_eval'
    | 'competency_profile'
    | 'system'
    | 'system_validate_sign'
    | 'system_set_vacation_days'
    | 'system_sync_scheduled_vacations'
    | 'system_booking_hook'
    | 'system_booking_approve'
    | 'system_booking_limits_exceeded_approve'
    | 'system_booking_trip_create'
    | 'system_booking_trip_change'
    | 'system_booking_trip_ordering'
    | 'system_booking_trip_limit'
    | 'system_booking_trip_limit_approved'
    | 'system_deadline'
    | 'system_condition_positive'
    | 'system_condition_negative'
    | 'system_condition_0'
    | 'system_condition_1'
    | 'system_condition_2'
    | 'system_condition_3'
    | 'system_condition_4'
    | 'system_condition_5'
    | 'system_condition_6'
    | 'system_condition_7'
    | 'system_condition_8'
    | 'system_condition_9';

  @IsUUID()
  @IsNotEmpty()
  to_node_id: string;
}

export class EventNodeFlowActor {
  @IsString()
  @IsOptional()
  role?: string;

  @IsString()
  @IsOptional()
  fio?: string;
}

export class EventNodeFlow {
  @IsUUID()
  @IsNotEmpty()
  node_id: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsIn(['finished', 'skipped', 'active', 'canceled', 'inactive'])
  @IsString()
  @IsNotEmpty()
  status: 'finished' | 'skipped' | 'active' | 'canceled' | 'inactive';

  @IsIn([
    'undefined',
    'node_deadline',
    'event_deadline',
    'manual_employee',
    'manual_company',
    'dismiss',
    'workflow_change',
  ])
  @IsString()
  @IsOptional()
  cancel_reason?:
    | 'undefined'
    | 'node_deadline'
    | 'event_deadline'
    | 'manual_employee'
    | 'manual_company'
    | 'dismiss'
    | 'workflow_change';

  @IsDateString()
  @IsOptional()
  activity_date?: string;

  @IsDateString()
  @IsOptional()
  deadline?: string;

  @ValidateNested({ each: true })
  @Type(() => NodeAction)
  @IsArray()
  @IsNotEmpty()
  actions: NodeAction[];

  @ValidateNested()
  @Type(() => CustomState)
  @IsOptional()
  custom_state?: CustomState;

  @ValidateNested()
  @Type(() => EventNodeFlowActor)
  @IsOptional()
  actor?: EventNodeFlowActor;

  @ValidateNested({ each: true })
  @Type(() => Transition)
  @IsArray()
  @IsNotEmpty()
  transitions: Transition[];

  @IsString({ each: true })
  @IsArray()
  @IsNotEmpty()
  documents: string[];

  @IsBoolean()
  @IsNotEmpty()
  is_optional: boolean;
}

export class EventFlow {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsUUID()
  @IsNotEmpty()
  start_node_id: string;

  @IsBoolean()
  @IsNotEmpty()
  in_paper: boolean;

  @ValidateNested({ each: true })
  @Type(() => EventNodeFlow)
  @IsArray()
  @IsNotEmpty()
  nodes: EventNodeFlow[];

  @IsIn(['employee', 'candidate', 'vacation'])
  @IsString()
  @IsNotEmpty()
  category: 'employee' | 'candidate' | 'vacation';
}

export class CreateSimpleEventTypeResponse {
  @IsUUID()
  @IsNotEmpty()
  event_type_id: string;
}

export class CustomTypes {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  custom_type: string;
}

export class GroupItem {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;
}

export class CreateSimpleEventTypeOptionsResponse {
  @ValidateNested({ each: true })
  @Type(() => EventTypeItem)
  @IsArray()
  @IsNotEmpty()
  event_types: EventTypeItem[];

  @ValidateNested({ each: true })
  @Type(() => GroupItem)
  @IsArray()
  @IsNotEmpty()
  signature_groups: GroupItem[];

  @ValidateNested({ each: true })
  @Type(() => CustomTypes)
  @IsArray()
  @IsOptional()
  custom_types?: CustomTypes[];
}

export class CreateSimpleEventTypeCompany {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsBoolean()
  @IsNotEmpty()
  workflow_change_cancels_events: boolean;
}

export class CreateSimpleEventTypeCompaniesResponse {
  @ValidateNested({ each: true })
  @Type(() => CreateSimpleEventTypeCompany)
  @IsArray()
  @IsNotEmpty()
  companies: CreateSimpleEventTypeCompany[];
}

export type EventAvailableFiltersDocumentTypeOptionsResponse =
  FilterSelectOption[];

export type EventAvailableFiltersParentEventOptionsResponse =
  FilterSelectOption[];

export type EventAvailableFiltersEventBatchOptionsResponse =
  FilterSelectOption[];

export type EventAvailableFiltersEmployeeOptionsResponse = FilterSelectOption[];

export type EventAvailableFiltersAttrOptionsResponse = FilterSelectOption[];

export class AvailableFilters {
  @IsArray()
  @IsNotEmpty()
  filters: (
    | FilterSelect
    | FilterMultiple
    | FilterText
    | FilterSearch
    | FilterMultipleSearch
    | FilterBool
    | FilterTimeRange
  )[];
}

export class EventTypeGroup {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsIn(['group'])
  @IsString()
  @IsNotEmpty()
  type: 'group';

  @IsString()
  @IsNotEmpty()
  label: string;

  @ValidateNested({ each: true })
  @Type(() => EventTypeItem)
  @IsArray()
  @IsNotEmpty()
  items: EventTypeItem[];
}

export class CreateEventOptionsEventTypes {
  @IsArray()
  @IsNotEmpty()
  event_types: (EventTypeGroup | EventTypeItem)[];
}

export class CreateEventTypeOptionsCopyAttribute {
  @IsString()
  @IsNotEmpty()
  name: string;

  @IsBoolean()
  @IsNotEmpty()
  missing: boolean;
}

export class CreateEventTypeOptionsCopyDocument {
  @IsString()
  @IsNotEmpty()
  name: string;

  @IsBoolean()
  @IsNotEmpty()
  missing: boolean;
}

export class ParticipantItem {
  @IsUUID()
  @IsNotEmpty()
  group_id: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @ValidateNested()
  @Type(() => CompanyItem)
  @IsOptional()
  company?: CompanyItem;

  @IsString()
  @IsOptional()
  personnel_number?: string;

  @IsString()
  @IsOptional()
  position?: string;
}

export type ParticipantScope = string;

export class AssignableRole {
  @ValidateNested()
  @Type(() => RoleItem)
  @IsNotEmpty()
  role: RoleItem;

  @IsIn(['group', 'company', 'account'])
  @IsString()
  @IsNotEmpty()
  scope: 'group' | 'company' | 'account';

  @ValidateNested()
  @Type(() => ParticipantItem)
  @IsOptional()
  default_group?: ParticipantItem;
}

export class AssignableRoles {
  @ValidateNested({ each: true })
  @Type(() => AssignableRole)
  @IsArray()
  @IsNotEmpty()
  required: AssignableRole[];

  @ValidateNested({ each: true })
  @Type(() => AssignableRole)
  @IsArray()
  @IsNotEmpty()
  optional: AssignableRole[];
}

export class CreateEventTypeOptions {
  @ValidateNested()
  @Type(() => AssignableRoles)
  @IsOptional()
  assignable_roles?: AssignableRoles;

  @ValidateNested({ each: true })
  @Type(() => CreateEventTypeOptionsCopyDocument)
  @IsArray()
  @IsOptional()
  copy_documents?: CreateEventTypeOptionsCopyDocument[];

  @ValidateNested({ each: true })
  @Type(() => CreateEventTypeOptionsCopyAttribute)
  @IsArray()
  @IsOptional()
  copy_attributes?: CreateEventTypeOptionsCopyAttribute[];
}

export class EventListItemBatch_noticesAccept {
  @IsBoolean()
  @IsNotEmpty()
  optional_attrs_exist: boolean;
}

export class EventListItemBatch_notices {
  @ValidateNested()
  @Type(() => EventListItemBatch_noticesAccept)
  @IsNotEmpty()
  accept: EventListItemBatch_noticesAccept;
}

export class EventListItemBatch_operations {
  @IsBoolean()
  @IsNotEmpty()
  accept: boolean;
}

export class CompanySignPermission {
  @IsUUID()
  @IsNotEmpty()
  node_id: string;

  @IsUUID()
  @IsNotEmpty()
  document_id: string;
}

export class Permissions {
  @ValidateNested({ each: true })
  @Type(() => CompanySignPermission)
  @IsArray()
  @IsNotEmpty()
  company_sign: CompanySignPermission[];
}

export class DocumentItem {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  document_type_name: string;
}

export class EventListItem {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @ValidateNested({ each: true })
  @Type(() => ActiveNode)
  @IsArray()
  @IsNotEmpty()
  active_nodes: ActiveNode[];

  @IsInt()
  @IsOptional()
  days_on_node?: number;

  @ValidateNested()
  @Type(() => EventTypeItem)
  @IsNotEmpty()
  event_type: EventTypeItem;

  @ValidateNested()
  @Type(() => EventEmployee)
  @IsOptional()
  employee?: EventEmployee;

  @ValidateNested()
  @Type(() => CompanyItem)
  @IsNotEmpty()
  company: CompanyItem;

  @IsDateString()
  @IsNotEmpty()
  created_at: string;

  @ValidateNested({ each: true })
  @Type(() => DocumentItem)
  @IsArray()
  @IsNotEmpty()
  documents: DocumentItem[];

  @IsDateString()
  @IsOptional()
  deadline?: string;

  @ValidateNested()
  @Type(() => Permissions)
  @IsNotEmpty()
  permissions: Permissions;

  @ValidateNested({ each: true })
  @Type(() => Attribute)
  @IsArray()
  @IsNotEmpty()
  attributes: Attribute[];

  @IsString()
  @IsOptional()
  report_status?: string;

  @ValidateNested()
  @Type(() => EventListItemBatch_operations)
  @IsNotEmpty()
  batch_operations: EventListItemBatch_operations;

  @ValidateNested()
  @Type(() => EventListItemBatch_notices)
  @IsNotEmpty()
  batch_notices: EventListItemBatch_notices;
}

export class EmployeeCompetencyEventsResponse {
  @ValidateNested({ each: true })
  @Type(() => EventListItem)
  @IsArray()
  @IsNotEmpty()
  competency_events: EventListItem[];

  @IsInt()
  @IsNotEmpty()
  total_count: number;
}

export class EmployeeCompetency {
  @ValidateNested()
  @Type(() => CompetencyItem)
  @IsNotEmpty()
  competency: CompetencyItem;

  @ValidateNested({ each: true })
  @Type(() => CompetencyScaleValue)
  @IsArray()
  @IsNotEmpty()
  scale_values: CompetencyScaleValue[];

  @ValidateNested()
  @Type(() => CompetencyScaleValue)
  @IsOptional()
  required_level?: CompetencyScaleValue;

  @ValidateNested()
  @Type(() => CompetencyScaleValue)
  @IsOptional()
  actual_level?: CompetencyScaleValue;
}

export class EmployeeCompetencyGroup {
  @IsString()
  @IsNotEmpty()
  name: string;

  @ValidateNested({ each: true })
  @Type(() => EmployeeCompetency)
  @IsArray()
  @IsNotEmpty()
  competencies: EmployeeCompetency[];
}

export class EmployeeCompetencyResponse {
  @ValidateNested({ each: true })
  @Type(() => EventListItem)
  @IsArray()
  @IsNotEmpty()
  competency_events: EventListItem[];

  @ValidateNested({ each: true })
  @Type(() => EmployeeCompetencyGroup)
  @IsArray()
  @IsNotEmpty()
  competency_groups: EmployeeCompetencyGroup[];
}

export class EmployeeCreatableEventTypes {
  @IsArray()
  @IsNotEmpty()
  event_types: (EventTypeGroup | EventTypeItem)[];
}

export class EmployeesList {
  @ValidateNested({ each: true })
  @Type(() => EmployeeItem)
  @IsArray()
  @IsNotEmpty()
  employees: EmployeeItem[];
}

export class CreateEventTypeParticipantOptions {
  @ValidateNested({ each: true })
  @Type(() => ParticipantItem)
  @IsArray()
  @IsNotEmpty()
  options: ParticipantItem[];
}

export class CreateEventCompanyItem {
  @IsUUID()
  @IsNotEmpty()
  company_id: string;

  @IsString()
  @IsNotEmpty()
  company_name: string;
}

export class CreateEventCompanyOptionsList {
  @ValidateNested({ each: true })
  @Type(() => CreateEventCompanyItem)
  @IsArray()
  @IsNotEmpty()
  items: CreateEventCompanyItem[];
}

export class CreateEventCommonOptionsResponse {
  @IsBoolean()
  @IsNotEmpty()
  has_batch: boolean;

  @IsBoolean()
  @IsNotEmpty()
  creator_for_others: boolean;
}

export class EventListToPaperResponse {
  @ValidateNested({ each: true })
  @Type(() => EventListItem)
  @IsArray()
  @IsNotEmpty()
  events: EventListItem[];

  @IsInt()
  @IsNotEmpty()
  total_count: number;
}

export class EventDocumentCountResponse {
  @IsInt()
  @IsNotEmpty()
  total_count: number;
}

export class EventPdfResponse {
  @IsUUID()
  @IsNotEmpty()
  download_id: string;
}

export class EventXlsxResponse {
  @IsUUID()
  @IsNotEmpty()
  download_id: string;
}

export class EventListResponse {
  @ValidateNested({ each: true })
  @Type(() => EventListItem)
  @IsArray()
  @IsNotEmpty()
  events: EventListItem[];

  @IsInt()
  @IsNotEmpty()
  total_count: number;

  @IsInt()
  @IsOptional()
  total_count_my?: number;

  @IsInt()
  @IsOptional()
  total_count_my_group?: number;

  @IsInt()
  @IsOptional()
  total_count_all?: number;

  @IsInt()
  @IsOptional()
  finished_count?: number;
}

export class CandidateEventListResponse {
  @ValidateNested({ each: true })
  @Type(() => EventListItem)
  @IsArray()
  @IsNotEmpty()
  events: EventListItem[];

  @IsInt()
  @IsNotEmpty()
  total_count: number;
}

export class EventBatchEmployee {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  personnel_number: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  name: string;

  @IsBoolean()
  @IsNotEmpty()
  enabled: boolean;

  @IsString()
  @IsOptional()
  position?: string;

  @IsString()
  @IsOptional()
  unit_name?: string;
}

export class EventBatchEmployeesResponse {
  @ValidateNested({ each: true })
  @Type(() => EventBatchEmployee)
  @IsArray()
  @IsNotEmpty()
  employees: EventBatchEmployee[];
}

export class CreateEventBatchResponse {
  @IsUUID()
  @IsNotEmpty()
  event_batch_id: string;
}

export class EventBatchItem {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;
}

export class EventBatchListResponse {
  @ValidateNested({ each: true })
  @Type(() => EventBatchItem)
  @IsArray()
  @IsNotEmpty()
  event_batches: EventBatchItem[];
}

export class CreateEventResponse {
  @IsUUID()
  @IsNotEmpty()
  event_id: string;
}

export class CancelReasonItem {
  @IsInt()
  @IsNotEmpty()
  id: number;

  @IsString()
  @IsNotEmpty()
  description: string;
}

export class CancelReasonListResponse {
  @ValidateNested({ each: true })
  @Type(() => CancelReasonItem)
  @IsArray()
  @IsNotEmpty()
  reasons: CancelReasonItem[];
}
