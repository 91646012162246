import React from 'react';

import { Help as HelpIcon } from '@material-ui/icons';

import { useIsDesktop } from '@vk-hr-tek/ui/hooks';
import { Tooltip } from '@vk-hr-tek/ui/Tooltip';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { Box } from '@vk-hr-tek/ui/Box';
import { useSelector } from '@vk-hr-tek/app/app/hooks';
import { Accordion } from '@vk-hr-tek/ui/Accordion';
import { Divider } from '@vk-hr-tek/ui/Divider';

import { selectUser } from '../../../slice';
import { AttorneySettingsNotification } from '../AttorneySettingsNotification';

import { AttorneysRolesList } from './AttorneysRolesList';

export const Roles = () => {
  const user = useSelector(selectUser);
  const isDesktop = useIsDesktop();

  return (
    <Box
      radius={isDesktop ? 'l' : '0'}
      border={isDesktop ? '1px solid' : 0}
      borderColor="stroke.primary"
      bgcolor="bg.light.primary"
      overflow="hidden"
    >
      <Accordion
        title={
          <Box gap="8" display="flex">
            <Typography variant={isDesktop ? 'h6' : 'subtitle1'}>
              Роль и МЧД
            </Typography>
            <Tooltip
              title={'МЧД - машиночитаемая доверенность'}
              placement={'bottom'}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                cursor="pointer"
              >
                <HelpIcon color="disabled" fontSize="small" />
              </Box>
            </Tooltip>
          </Box>
        }
        defaultExpanded
        detailsWithoutPaddings
      >
        {user?.groups?.map((group, index) => (
          <>
            <Box p="24" display="flex" flexDirection="column" gap="16">
              <Box mb="8">
                <Typography
                  variant={isDesktop ? 'h6' : 'subtitle1'}
                  color="text.light.primary"
                >
                  {group.name}
                </Typography>
              </Box>

              <AttorneysRolesList group={group} />
              {!!group?.attorneys.filter(
                (attorney) =>
                  attorney.status === 'signed' ||
                  attorney.status === 'revoking',
              ).length && <AttorneySettingsNotification companyId={group.id} />}
            </Box>
            {user.groups.length - 1 !== index && <Divider />}
          </>
        ))}
      </Accordion>
    </Box>
  );
};
