import { Type } from 'class-transformer';
import {
  IsNotEmpty,
  IsOptional,
  IsString,
  IsBoolean,
  IsArray,
} from 'class-validator';

export class GetCreateOptionsCompanyDto {
  @IsBoolean()
  @IsNotEmpty()
  withEventTypes: boolean;

  @IsString()
  @IsOptional()
  eventTypeGroup?: string;

  @Type(() => String)
  @IsArray()
  @IsOptional()
  tag?: string[];
}
