import { injectable } from 'inversify';

import { Logger } from '../logger';

export enum GoalsEnum {
  useFilter = 'useFilter',
  openLink = 'openLink',
  copyToClipboard = 'copyToClipboard',
  selectItems = 'selectItems',
  downloadItems = 'downloadItems',
  eventCancel = 'eventCancel',
  eventCancelReason = 'eventCancelReason',
  eventDocOriginalPrint = 'eventDocOriginalPrint',
  eventDocDownload = 'eventDocDownload',
  eventFlowDownload = 'eventFlowDownload',
  eventFlowOpen = 'eventFlowOpen',
  eventFlowPrint = 'eventFlowPrint',
  vacationEventFlowOpen = 'vacationEventFlowOpen',
  vacationEventFlowPrint = 'vacationEventFlowPrint',
  createEvent = 'createEvent',
}

interface ParamsType {
  page: string;
  block: string;
  element: string;
  actionType: string;
  side?: 'company' | 'employee';
  screenSize?: 'desktop' | 'mobile';
  reason?: string;
}

interface MyTrackerParamsType {
  id?: string;
  type: 'setUserID' | 'reachGoal' | 'pageView';
  userid?: string;
  goal?: GoalsEnum;
  params?: ParamsType;
  start?: number;
}

type Window = typeof window;
type MyTrackerWindow = Window & {
  _tmr: {
    push: (params: MyTrackerParamsType) => void;
  };
};

@injectable()
export class AnalyticService {
  constructor(private logger: Logger) {}

  private readonly tmr = (window as MyTrackerWindow)._tmr;

  setUser(id: string) {
    if (window.REACT_APP_VKHRTEK_MY_TRACKER_ID) {
      this.tmr?.push({ type: 'setUserID', userid: id });
    } else {
      this.logger.debug(
        `Аналитика не включена. Цель: 'setUserID', userId: ${id}`,
      );
    }
  }

  pageView(userId: string) {
    if (window.REACT_APP_VKHRTEK_MY_TRACKER_ID) {
      this.tmr?.push({
        id: window.REACT_APP_VKHRTEK_MY_TRACKER_ID,
        type: 'pageView',
        start: new Date().getTime(),
        userid: userId,
      });
    } else {
      this.logger.debug(
        `Аналитика не включена. Цель: 'pageView', userId: ${userId}`,
      );
    }
  }

  registerAction(goal: GoalsEnum, params: ParamsType) {
    if (window.REACT_APP_VKHRTEK_MY_TRACKER_ID) {
      this.tmr?.push({
        id: window.REACT_APP_VKHRTEK_MY_TRACKER_ID,
        type: 'reachGoal',
        goal,
        params,
      });
    } else {
      this.logger.debug(
        `Аналитика не включена. Цель: ${goal}, Параметры: ${JSON.stringify(
          params,
        )}`,
      );
    }
  }
}
