import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './creation.state';
import { creationActionReducers } from './actions';

export const creations = createSlice({
  name: 'creationEvent',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    creationActionReducers(builder);
  },
});

export const creationEventReducer = creations.reducer;
