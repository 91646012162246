import React from 'react';

import MUICircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';

/**
 * Интерфейс, представляющий свойства для компонента CircularProgress.
 *
 * @interface CircularProgressProps
 * @property {number | string} [size] - Размер кругового индикатора прогресса.
 * @property {'primary' | 'secondary' | 'inherit'} [color='primary'] - Цвет кругового индикатора прогресса.
 * @property {string} [className] - Дополнительные CSS классы для применения к круговому индикатору прогресса.
 */

interface CircularProgressProps {
  size?: number | string;
  color?: 'primary' | 'secondary' | 'inherit';
  className?: string;
}

/**
 * Компонент, отображающий круговой индикатор прогресса с настраиваемым размером и цветом.
 *
 * @component
 * @param {CircularProgressProps} props - Свойства для компонента CircularProgress.
 * @param {number | string} [props.size] - Размер кругового индикатора прогресса.
 * @param {'primary' | 'secondary' | 'inherit'} [props.color='primary'] - Цвет кругового индикатора прогресса.
 * @param {string} [props.className] - Дополнительные CSS классы для применения к круговому индикатору прогресса.
 * @returns {JSX.Element} Компонент CircularProgress.
 */

export const CircularProgress = ({
  size,
  color,
  className,
  ...rest
}: CircularProgressProps) => {
  return (
    <MUICircularProgress
      {...rest}
      data-testid="circular-progress"
      color={color}
      size={size}
      className={classNames(className, 'aqa_circular_progress')}
    />
  );
};
