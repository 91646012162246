import React, { ReactElement, useCallback } from 'react';

import { FieldProps, FieldRenderProps, useField } from 'react-final-form';

import { ScaleBarInput as Input } from '../../input';

/**
 * Интерфейс для свойств компонента ScaleBarInput
 * @typedef {Object} ScaleBarInputProps
 * @property {number} target - Целевое значение шкалы
 * @property {boolean} [showLabel] - Флаг для отображения метки
 * @property {(index: number) => ReactElement | undefined} [renderTooltip] - Функция для рендера подсказки
 * @property {boolean} [disabled] - Флаг для отключения компонента
 * @property {boolean} [showEmptyIfPristine] - Флаг для отображения пустого значения, если поле не изменялось
 */

interface ScaleBarInputProps {
  target: number;
  showLabel?: boolean;
  renderTooltip?: (index: number) => ReactElement | undefined;
  disabled?: boolean;
  showEmptyIfPristine?: boolean;
}

/**
 * Компонент ScaleBarInput для рендеринга поля ввода с шкалой
 * @param {ScaleBarInputProps & FieldProps<number | undefined, FieldRenderProps<number>>} props - Свойства компонента
 * @param {string} props.name - Имя поля ввода
 * @returns {ReactElement} - Элемент React с шкалой для ввода
 */

export const ScaleBarInput = ({
  renderTooltip,
  target,
  showLabel,
  disabled,
  showEmptyIfPristine,
  name,
  ...rest
}: ScaleBarInputProps &
  FieldProps<number | undefined, FieldRenderProps<number>>) => {
  const { input, meta } = useField(name, rest);
  const handleChange = useCallback(
    (onChange: (newValue: number) => void) => (newValue: number) => {
      onChange(newValue);
      input.onBlur();
    },
    [input],
  );
  return (
    <Input
      {...input}
      target={target}
      showLabel={showLabel}
      renderTooltip={renderTooltip}
      disabled={disabled}
      value={meta.touched || !showEmptyIfPristine ? input.value : 0}
      onChange={handleChange(input.onChange)}
    />
  );
};
