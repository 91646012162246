import { useContext } from 'react';

import { RootWidthContext } from '../rootWidth';

/** Определить, что приложение встроено в сторонний сервис как виджет
 * @returns {boolean}
 *  */
export const useIsInsideContainer = () => {
  const rootWidth = useContext(RootWidthContext);

  return typeof rootWidth === 'number';
};
