import React from 'react';

import { AppError } from '@vk-hr-tek/core/error';

import { Box } from '../../Box';
import { SubmitError } from '../SubmitError';
import { SUBMIT_ERROR_TEXT } from '../form.constants';

/**
 * Интерфейс для передачи пропсов компоненту FormError
 * @property {Object | null} error - Объект ошибки или null
 * @property {string} error.description - Описание ошибки
 * @property {string | null} [error.traceId] - Идентификатор трассировки ошибки (опционально)
 */
interface FormErrorProps {
  error: { description: string; traceId?: string | null } | null;
}

/**
 * Компонент для отображения ошибки формы
 * @param {FormErrorProps} props - Пропсы компонента
 * @returns {JSX.Element} Элемент JSX с отображением ошибки
 */
export const FormError = ({ error }: FormErrorProps) => (
  <Box pt="20">
    <SubmitError
      submitError={
        {
          title:
            error && error.description ? error.description : SUBMIT_ERROR_TEXT,
          traceId: error && error.traceId,
        } as AppError
      }
    />
  </Box>
);
