import React, { ReactNode } from 'react';

import classNames from 'classnames';
import { Chip } from '@material-ui/core';

import { Box } from '../Box';
import { Counter } from '../Counter';
import { Typography } from '../Typography';

import { ChipColor, ChipColorUnion, ChipSize } from './ColoredChip.types';
import { useStyles } from './ColoredChip.styles';

/**
 * Интерфейс, представляющий свойства для компонента ColoredChip.
 *
 * @interface ColoredChipProps
 * @property {string | ReactNode} label - Метка или содержимое чипа.
 * @property {ChipColorUnion} [color='gray'] - Цвет чипа.
 * @property {keyof typeof ChipSize} [size='small'] - Размер чипа.
 * @property {boolean} [fullWidth=false] - Определяет, должен ли чип занимать всю ширину своего контейнера.
 * @property {boolean} [disabled=false] - Определяет, должен ли чип быть отключён.
 * @property {ReactNode} [startIcon] - Иконка для отображения в начале чипа.
 * @property {ReactNode} [endIcon] - Иконка для отображения в конце чипа.
 * @property {number | string} [counter] - Счётчик для отображения на чипе.
 * @property {boolean} [counterSticked=false] - Определяет, должен ли счётчик быть прилипшим к концу метки чипа.
 * @property {boolean} [signed=false] - Определяет, должен ли счётчик быть подписан.
 * @property {() => void} [onClick] - Функция для обработки события клика на чип.
 */

export type ColoredChipProps = {
  label: string | ReactNode;
  color?: ChipColorUnion;
  size?: keyof typeof ChipSize;
  fullWidth?: boolean;
  disabled?: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  counter?: number | string;
  counterSticked?: boolean;
  signed?: boolean;
  onClick?: () => void;
};

/**
 * Функция проверяет, является ли переданное значение допустимым цветом чипа.
 *
 * @function isChipColor
 * @param {unknown} str - Значение, которое необходимо проверить.
 * @returns {boolean} Возвращает true, если значение является допустимым цветом чипа, иначе false.
 */

export const isChipColor = (str: unknown): str is ChipColor =>
  typeof str === 'string' && Object.values<string>(ChipColor).includes(str);

/**
 * Компонент, отображающий цветной чип с необязательными иконками, счётчиком и настраиваемыми размером и цветом.
 *
 * @component
 * @param {ColoredChipProps} props - Свойства для компонента ColoredChip.
 * @param {string | ReactNode} props.label - Метка или содержимое чипа.
 * @param {ChipColorUnion} [props.color='gray'] - Цвет чипа.
 * @param {keyof typeof ChipSize} [props.size='small'] - Размер чипа.
 * @param {boolean} [props.fullWidth=false] - Определяет, должен ли чип занимать всю ширину своего контейнера.
 * @param {boolean} [props.disabled=false] - Определяет, должен ли чип быть отключён.
 * @param {ReactNode} [props.startIcon] - Иконка для отображения в начале чипа.
 * @param {ReactNode} [props.endIcon] - Иконка для отображения в конце чипа.
 * @param {number | string} [props.counter] - Счётчик для отображения на чипе.
 * @param {boolean} [props.counterSticked=false] - Определяет, должен ли счётчик быть прилипшим к концу метки чипа.
 * @param {boolean} [props.signed=false] - Определяет, должен ли счётчик быть подписан.
 * @param {() => void} [props.onClick] - Функция для обработки события клика на чип.
 * @returns {JSX.Element} Компонент ColoredChip.
 */

export const ColoredChip = ({
  label,
  fullWidth = false,
  startIcon,
  endIcon,
  counter,
  size = 'small',
  color = ChipColor.gray,
  disabled = false,
  counterSticked = false,
  signed = false,
  onClick,
  ...rest
}: ColoredChipProps) => {
  const classes = useStyles({
    size: ChipSize[size],
    fullWidth,
    color: isChipColor(color) ? ChipColor[color] : ChipColor.gray,
    disabled,
  });

  const resultLabel = (
    <div className={classNames(classes.chipLabel, 'aqa_chip_label')}>
      {startIcon && (
        <Box
          className={classNames(
            classes.icon,
            classes.startIcon,
            'aqa_chip_start_icon',
          )}
        >
          {startIcon}
        </Box>
      )}
      <Typography
        className={classes.chipLabelTypography}
        variant={size === 'extraSmall' ? 'caption' : 'body3'}
      >
        {label}
      </Typography>
      {endIcon && (
        <Box
          className={classNames(
            classes.icon,
            classes.endIcon,
            'aqa_chip_end_icon',
          )}
        >
          {endIcon}
        </Box>
      )}
      {counter !== undefined && !counterSticked && (
        <Counter
          className={classNames('aqa_chip_counter', classes.counter)}
          counter={counter}
          size={size}
          signed={signed}
        />
      )}
    </div>
  );

  return (
    <div className={classes.container} data-testid="aqa_chip">
      <Chip
        {...rest}
        className={classNames(
          classes.chip,
          'aqa_chip',
          ...(onClick ? [classes.linkChip] : []),
        )}
        label={resultLabel}
        onClick={onClick}
      />
      {counter != undefined && counterSticked && (
        <Counter
          className={classNames('aqa_chip_counter', classes.counterSticked)}
          counter={counter}
          size={size}
          signed={signed}
        />
      )}
    </div>
  );
};
