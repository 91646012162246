import React from 'react';

import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';

import { useInject } from '@vk-hr-tek/core/ioc';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { Box } from '@vk-hr-tek/ui/Box';
import {
  FormError,
  FormKnownValue,
  FormKnownValues,
  FormValidationMessage,
  TextInput,
  Form,
} from '@vk-hr-tek/ui/form';
import { Link } from '@vk-hr-tek/ui/Link';

import { useDispatch, useSelector } from '@app/hooks';

import { ChangePasswordDto } from '../../../dto';
import {
  changePassword,
  redirectToSendInviteForPhone,
  selectNotification,
  selectPhone,
  selectSnils,
  selectStatus,
  setStage,
} from '../../../slice';
import { Button } from '../../../ui/Button';
import { useGetAuthInstance } from '../../../hooks';
import { PASSWORD_MIN_LENGTH } from '../../../auth.constants';

export const ChangePasswordFinish = () => {
  const dispatch = useDispatch();
  const validator = useInject(ValidationService);

  const phone = useSelector(selectPhone);
  const snils = useSelector(selectSnils);
  const error = useSelector(selectNotification);
  const status = useSelector(selectStatus);
  const authInstance = useGetAuthInstance();

  const { button_color: buttonColor } = authInstance;

  const onSubmit = (values: ChangePasswordDto) =>
    new Promise((resolve) => {
      dispatch(
        changePassword({
          values,
          actions: { resolve },
        }),
      );
    });

  return (
    <Form
      validate={(values) =>
        validator.validate(values, ChangePasswordDto, ['form'])
      }
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        submitting,
        pristine,
        hasValidationErrors,
        dirtySinceLastSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <FormKnownValues>
            <FormKnownValue label="СНИЛС" value={snils} />
            <FormKnownValue label="Номер телефона" value={phone} />
          </FormKnownValues>
          <Box mt="24">
            <TextInput
              name="password"
              label="Пароль"
              placeholder="Придумайте пароль"
              type="password"
              showError={false}
              id="qaAuthChangePasswordFinishPasswordInput"
              after={(value: string | undefined) => {
                const validators = [
                  {
                    check: /^[A-Za-z0-9!#{}@()%^\[\]~$\-_]+$/,
                    message:
                      'Латинские буквы, цифры или символы !#{}@$()%^[]~-_',
                  },
                  {
                    check: /[0-9\^\[\]~!#{}\/@\$()`%^\[\]~$\-_]/,
                    message: 'Хотя бы одну цифру или символ !#{}@$()%^[]~-_',
                  },
                  {
                    check: /[A-Z]/,
                    message: 'Хотя бы одну заглавную букву',
                  },
                  {
                    check: /[a-z]/,
                    message: 'Хотя бы одну строчную букву',
                  },
                ];
                return (
                  <Box mt="8">
                    <FormValidationMessage title="Пароль должен содержать:">
                      {validators.map(({ check, message }) => (
                        <Box
                          color={
                            check.test(value || '')
                              ? 'accent.text.success'
                              : 'text.light.tertirary'
                          }
                          mb="4"
                          key={message}
                        >
                          {message}
                        </Box>
                      ))}
                      <Box
                        color={
                          value && value.length >= PASSWORD_MIN_LENGTH
                            ? 'accent.text.success'
                            : 'text.light.tertirary'
                        }
                      >
                        Минимум восемь символов
                      </Box>
                    </FormValidationMessage>
                  </Box>
                );
              }}
            />
          </Box>
          <Box mt="8">
            <TextInput
              name="passwordRepeat"
              label="Повторите пароль"
              placeholder="Повторите пароль"
              type="password"
              id="qaAuthChangePasswordFinishPasswordRepeatInput"
            />
          </Box>
          <Box mt="32">
            <Button
              color={buttonColor && `#${buttonColor}`}
              disabled={pristine || hasValidationErrors}
              loading={submitting}
            >
              Готово!
            </Button>
          </Box>
          {!dirtySinceLastSubmit && status && status === 'failed' && (
            <FormError error={error} />
          )}
          <Box
            mt="8"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Link
              onClick={() => {
                dispatch(setStage('verification'));
              }}
              size="small"
              variant="tertiary"
              startIcon={<ArrowBackIcon />}
            >
              Вернуться назад
            </Link>
            <Link
              onClick={() => dispatch(redirectToSendInviteForPhone())}
              size="small"
              variant="tertiary"
            >
              Я сменил телефон
            </Link>
          </Box>
        </form>
      )}
    />
  );
};
