import { injectable } from 'inversify';

import { HttpService } from '@vk-hr-tek/core/http';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { UnmarshallerService } from '@vk-hr-tek/core/unmarshaller';
import { FiltersResponse } from '@vk-hr-tek/core/filter';

import { GetEventFiltersDto, GetScheduleFiltersDto } from '../dto';

@injectable()
export class AvailableFiltersService {
  constructor(
    private validator: ValidationService,
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
  ) {}

  async getPolicyFilters() {
    const result = await this.unmarshaller.unmarshall(
      await this.http.get(
        `/policy/list/available_filters`,
        {},
        { withSide: true, reloadIfOldVersion: true },
      ),
      FiltersResponse,
    );

    return result.filters;
  }

  async getEventsFilters(getFiltersDto?: GetEventFiltersDto) {
    if (getFiltersDto) {
      await this.validator.validateOrReject(getFiltersDto, GetEventFiltersDto);
    }

    const result = await this.unmarshaller.unmarshall(
      await this.http.get(
        '/event/available_filters',
        {
          ...(getFiltersDto?.companyId
            ? { company_id: getFiltersDto.companyId }
            : {}),
          ...(getFiltersDto?.tags ? { tags: getFiltersDto.tags } : {}),
        },
        { withSide: true, reloadIfOldVersion: true },
      ),
      FiltersResponse,
    );

    return result.filters;
  }

  async getAbsenceFilters(getFiltersDto?: GetEventFiltersDto) {
    if (getFiltersDto) {
      await this.validator.validateOrReject(getFiltersDto, GetEventFiltersDto);
    }

    const result = await this.unmarshaller.unmarshall(
      await this.http.get(
        '/event/absence_available_filters',
        {
          ...(getFiltersDto?.companyId
            ? { company_id: getFiltersDto.companyId }
            : {}),
        },
        { withSide: true, reloadIfOldVersion: true },
      ),
      FiltersResponse,
    );

    return result.filters;
  }

  async getSheduleFilters(getFiltersDto: GetScheduleFiltersDto) {
    const { scheduleId } = getFiltersDto;

    const result = await this.unmarshaller.unmarshall(
      await this.http.get(
        `/vacation/schedule/${scheduleId}/available_filters`,
        {},
        { withSide: true, reloadIfOldVersion: true },
      ),
      FiltersResponse,
    );

    return result.filters;
  }
}
