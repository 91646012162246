import React from 'react';

import Checkbox from '@material-ui/core/Checkbox';

import { Box } from '../Box';
import { Typography } from '../Typography';
import { Content } from '../Content';

import { useStyles } from './CheckboxList.style';

/**
 * Интерфейс, представляющий свойства для компонента CheckboxList.
 *
 * @interface CheckboxListProps
 * @property {string} title - Заголовок списка флажков.
 * @property {string[]} selected - Массив значений выбранных элементов.
 * @property {(id: string[]) => void} onSelect - Функция для обработки изменения выбора.
 * @property {{ label: string; value: string }[]} items - Массив элементов для отображения в списке флажков.
 */

interface CheckboxListProps {
  title: string;
  selected: string[];
  onSelect: (id: string[]) => void;
  items: {
    label: string;
    value: string;
  }[];
}

/**
 * Компонент, отображающий список флажков с заголовком и обработкой выбора.
 * Включает опцию "Выбрать все" и может обрабатывать отдельный выбор элементов.
 *
 * @component
 * @param {CheckboxListProps} props - Свойства для компонента CheckboxList.
 * @param {string} props.title - Заголовок списка флажков.
 * @param {string[]} props.selected - Массив значений выбранных элементов.
 * @param {(id: string[]) => void} props.onSelect - Функция для обработки изменения выбора.
 * @param {{ label: string; value: string }[]} props.items - Массив элементов для отображения в списке флажков.
 * @returns {JSX.Element} Компонент CheckboxList.
 */

export const CheckboxList = ({
  title,
  selected,
  onSelect,
  items,
}: CheckboxListProps) => {
  const classes = useStyles();
  const isRowSelectable = () => true;

  return (
    <Box className="aqa_checkbox_list" testId="checkbox-list">
      <Box mb="16">
        <Typography variant="h6">{title}</Typography>
      </Box>
      <Content>
        <Box
          height={72}
          py="24"
          pr="24"
          pl="16"
          bgcolor="bg.greyscale.primary"
          display="flex"
          alignItems="center"
        >
          <Checkbox
            color="primary"
            indeterminate={
              selected.length > 0 &&
              selected.length < items.filter(isRowSelectable).length
            }
            checked={
              selected.length > 0 &&
              selected.length === items.filter(isRowSelectable).length
            }
            onChange={(e) =>
              onSelect(
                e.target.checked
                  ? items.filter(isRowSelectable).map((item) => item.value)
                  : [],
              )
            }
          />
          <Box pl="8">
            <Typography variant="body2">Выбрать все</Typography>
          </Box>
        </Box>
        {items.length === 0 ? (
          <Box
            height={56}
            py="24"
            pr="24"
            pl="16"
            display="flex"
            alignItems="center"
            justifyContent="center"
            className={classes.border}
          >
            <Typography variant="body2">Ничего не найдено</Typography>
          </Box>
        ) : (
          items.map((item) => {
            const isItemSelected = selected.some((id) => id === item.value);

            return (
              <Box
                key={item.value}
                height={56}
                py="24"
                pr="24"
                pl="16"
                display="flex"
                alignItems="center"
                className={classes.border}
              >
                <Checkbox
                  color="primary"
                  checked={isItemSelected}
                  onChange={() => {
                    onSelect(
                      isItemSelected
                        ? selected.filter((id) => id !== item.value)
                        : [...selected, item.value],
                    );
                  }}
                />
                <Box pl="8">
                  <Typography variant="body2">{item.label}</Typography>
                </Box>
              </Box>
            );
          })
        )}
      </Content>
    </Box>
  );
};
