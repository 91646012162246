import React, { ReactNode } from 'react';

import { Box } from '../../Box';
interface InputDisclaimerProps {
  children: ReactNode;
  top?: number;
  left?: number;
}

/**
 * Компонент InputDisclaimer используется для отображения дополнительной информации
 * или предупреждений, связанных с полем ввода. Он размещает свое содержимое относительно
 * родительского контейнера с возможностью указать смещение по осям X и Y.
 *
 * @param {ReactNode} children - Содержимое, которое будет отображено в компоненте.
 * @param {number} [top=28] - Смещение содержимого по вертикали от верхнего края родительского контейнера.
 * @param {number} [left=20] - Смещение содержимого по горизонтали от левого края родительского контейнера.
 *
 */

export const InputDisclaimer = ({
  children,
  top = 28,
  left = 20,
}: InputDisclaimerProps) => (
  <Box width="100%" position="relative">
    <Box position="absolute" left={left} top={top}>
      {children}
    </Box>
  </Box>
);
