import React, { forwardRef } from 'react';

import classNames from 'classnames';

import { InfiniteScroll } from '../../InfiniteScroll';
import { CircularProgress } from '../../CircularProgress';
import { Box } from '../../Box';

import useStyles from './ListBox.styles';

/**
 * ListBox — компонент, который предоставляет прокручиваемый список с возможностями бесконечной прокрутки.
 * Может отображать индикатор загрузки, когда загружаются новые данные.
 *
 * @param {Object} props - Свойства компонента.
 * @param {boolean} props.hasMore - Указывает, есть ли ещё данные для загрузки.
 * @param {number} props.dataLength - Текущее количество данных.
 * @param {() => void} props.next - Функция для загрузки следующего набора данных.
 * @param {boolean} props.loading - Указывает, загружаются ли данные в данный момент.
 * @param {boolean} [props.long=false] - Если true, применяются дополнительные стили для более длинных списков.
 * @param {React.ReactNode | React.ReactNode[]} [props.children] - Дочерние элементы, которые будут отображены внутри списка.
 * @param {React.RefAttributes<HTMLDivElement>} ref - Ссылка, которая будет прикреплена к корневому элементу div.
 * @returns {JSX.Element} - JSX компонента.
 */

export const ListBox = forwardRef<
  HTMLDivElement,
  {
    hasMore: boolean;
    dataLength: number;
    next: () => void;
    loading: boolean;
    long?: boolean;
    children?: React.ReactNode | React.ReactNode[];
  }
>(
  (
    { hasMore, dataLength, next, loading, long = false, children, ...rest },
    ref,
  ) => {
    const classes = useStyles();

    return (
      <div
        {...rest}
        id="scrollable"
        ref={ref}
        className={classNames(classes.scrollableWrapper, {
          [classes.longScrollableWrapper]: long,
        })}
      >
        <InfiniteScroll
          next={next}
          hasMore={hasMore}
          dataLength={dataLength}
          loader={
            loading && (
              <Box display="flex" justifyContent="center" p="16">
                <CircularProgress size={30} />
              </Box>
            )
          }
          endMessage={<div />}
          scrollableTarget="scrollable"
        >
          <ul className={classes.listBox}>{children}</ul>
        </InfiniteScroll>
      </div>
    );
  },
);
