import { Type, Transform } from 'class-transformer';
import {
  IsInt,
  IsNumber,
  IsIn,
  IsArray,
  IsString,
  IsDateString,
  IsUUID,
  IsNotEmpty,
  ValidateNested,
  IsOptional,
  IsBoolean,
} from 'class-validator';
import { injectable } from 'inversify';

import { HttpService } from '@vk-hr-tek/core/http';
import {
  FiltersResponse,
  Filter,
  FilterService,
  FilterType,
} from '@vk-hr-tek/core/filter';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { UnmarshallerService } from '@vk-hr-tek/core/unmarshaller';
import { QueryService } from '@vk-hr-tek/core/query';

export class DocsParams {
  @IsString()
  @IsNotEmpty()
  service_doc_type: string;
}

export class AttorneyGetParams {
  @IsUUID()
  @IsNotEmpty()
  attorney_id: string;
}

export class PhotoParams {
  @IsUUID()
  @IsNotEmpty()
  user_id: string;
}

export class ProfileParams {
  @IsUUID()
  @IsNotEmpty()
  user_id: string;
}

export class UserAttorneyExportQuery {}

export class AttorneyExportQuery {
  @IsString()
  @IsOptional()
  attorney_ids?: string;

  @IsUUID()
  @IsOptional()
  user_id?: string;
}

export class GetAttorneyEmployeeFilterOptionsQuery {
  @IsString()
  @IsOptional()
  id?: string;

  @IsString()
  @IsOptional()
  company_id?: string;

  @IsString()
  @IsOptional()
  query?: string;

  @IsNumber()
  @IsOptional()
  limit?: number;

  @IsNumber()
  @IsOptional()
  offset?: number;
}

export class AttorneyUserListAvailableFiltersQuery {
  @IsUUID()
  @IsOptional()
  company_id?: string;
}

export class Oauth2AuthFormCallbackQuery {
  @IsString()
  @IsNotEmpty()
  state: string;

  @IsString()
  @IsOptional()
  code?: string;

  @IsString()
  @IsOptional()
  error?: string;

  @IsString()
  @IsOptional()
  error_description?: string;
}

export class ProfileQuery {
  @IsUUID()
  @IsOptional()
  employee_id?: string;
}

export class GetUserManagerSubstitutesCreateOptionsQuery {
  @IsUUID()
  @IsNotEmpty()
  employee_id: string;

  @IsUUID()
  @IsOptional()
  company_id?: string;
}

export class GetUserManagerSubstitutesQuery {
  @IsUUID()
  @IsNotEmpty()
  employee_id: string;
}

export class GetEmployeeFilterOptionsQuery {
  @IsUUID(undefined, { each: true })
  @IsArray()
  @IsOptional()
  ids?: string[];

  @IsString()
  @IsOptional()
  query?: string;

  @IsNumber()
  @IsOptional()
  limit?: number;

  @IsNumber()
  @IsOptional()
  offset?: number;
}

export class AttorneyXMLHash {
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  attorney_hash: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  revocation_hash?: string;

  @IsUUID()
  @IsNotEmpty()
  attorney_id: string;
}

export class GetAttorneyXMLHashesResponse {
  @ValidateNested({ each: true })
  @Type(() => AttorneyXMLHash)
  @IsArray()
  @IsNotEmpty()
  hashes: AttorneyXMLHash[];
}

export class Company {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;
}

export type AttorneyStatus = string;

export class Attorney {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsIn(['new', 'signed', 'expired', 'revoking', 'revoked', 'canceled'])
  @IsString()
  @IsNotEmpty()
  status: 'new' | 'signed' | 'expired' | 'revoking' | 'revoked' | 'canceled';

  @IsString()
  @IsNotEmpty()
  author_full_name: string;

  @IsUUID()
  @IsNotEmpty()
  author_user_id: string;

  @ValidateNested()
  @Type(() => Company)
  @IsNotEmpty()
  company: Company;

  @IsBoolean()
  @IsNotEmpty()
  is_default: boolean;

  @IsDateString()
  @IsNotEmpty()
  creation_date: string;

  @IsDateString()
  @IsNotEmpty()
  valid_from: string;

  @IsDateString()
  @IsNotEmpty()
  valid_to: string;

  @IsString()
  @IsNotEmpty()
  powers: string;

  @IsUUID()
  @IsNotEmpty()
  user_id: string;

  @IsString()
  @IsNotEmpty()
  full_name: string;

  @IsString()
  @IsNotEmpty()
  snils: string;

  @IsString()
  @IsNotEmpty()
  issuer: string;

  @IsBoolean()
  @IsNotEmpty()
  imported: boolean;

  @IsString()
  @IsOptional()
  revocation_reason?: string;
}

export class AttorneysResponse {
  @ValidateNested({ each: true })
  @Type(() => Attorney)
  @IsArray()
  @IsNotEmpty()
  attorneys: Attorney[];
}

export type CompanyEIO = string;

export type CompanyType = string;

export class AttorneyCompany {
  @IsIn(['legal', 'foreign', 'individual'])
  @IsString()
  @IsNotEmpty()
  type: 'legal' | 'foreign' | 'individual';

  @IsIn(['management_company', 'person', 'individual'])
  @IsString()
  @IsOptional()
  eio?: 'management_company' | 'person' | 'individual';

  @ValidateNested()
  @Type(() => Company)
  @IsNotEmpty()
  company: Company;

  @IsBoolean()
  @IsNotEmpty()
  can_sign: boolean;

  @IsBoolean()
  @IsNotEmpty()
  can_edit: boolean;
}

export class AttorneyCompanyListResponse {
  @ValidateNested({ each: true })
  @Type(() => AttorneyCompany)
  @IsArray()
  @IsNotEmpty()
  companies: AttorneyCompany[];
}

export class FilterSelectBadge {
  @IsString()
  @IsNotEmpty()
  title: string;

  @IsString()
  @IsOptional()
  description?: string;

  @IsIn(['blue', 'velvet', 'red'])
  @IsString()
  @IsOptional()
  color?: 'blue' | 'velvet' | 'red';

  @IsIn(['left', 'bottom'])
  @IsString()
  @IsOptional()
  position?: 'left' | 'bottom';
}

export class FilterSelectOption {
  @IsString()
  @IsNotEmpty()
  value: string;

  @IsString()
  @IsNotEmpty()
  label: string;

  @ValidateNested()
  @Type(() => FilterSelectBadge)
  @IsOptional()
  badge?: FilterSelectBadge;
}

export type AttorneyListAvailableFiltersEmployeeOptionsResponse =
  FilterSelectOption[];

export class FilterTimeRange {
  @IsString()
  @IsNotEmpty()
  key: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsIn(['timerange'])
  @IsString()
  @IsNotEmpty()
  type: 'timerange';

  @IsString()
  @IsNotEmpty()
  placeholder: string;

  @IsIn(['till_now', 'any', 'from_now'])
  @IsString()
  @IsNotEmpty()
  allow_dates: 'till_now' | 'any' | 'from_now';
}

export class FilterBool {
  @IsString()
  @IsNotEmpty()
  key: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsIn(['bool'])
  @IsString()
  @IsNotEmpty()
  type: 'bool';
}

export class FilterMultipleSearch {
  @IsString()
  @IsNotEmpty()
  key: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsIn(['multiple-search'])
  @IsString()
  @IsNotEmpty()
  type: 'multiple-search';

  @IsString()
  @IsNotEmpty()
  url: string;

  @IsString()
  @IsNotEmpty()
  placeholder: string;
}

export class FilterSearch {
  @IsString()
  @IsNotEmpty()
  key: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsIn(['search'])
  @IsString()
  @IsNotEmpty()
  type: 'search';

  @IsString()
  @IsNotEmpty()
  url: string;

  @IsString()
  @IsNotEmpty()
  placeholder: string;

  @IsBoolean()
  @IsOptional()
  disable_if_one_option?: boolean;
}

export class FilterText {
  @IsString()
  @IsNotEmpty()
  key: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsIn(['text'])
  @IsString()
  @IsNotEmpty()
  type: 'text';

  @IsString()
  @IsNotEmpty()
  placeholder: string;
}

export class FilterMultiple {
  @IsString()
  @IsNotEmpty()
  key: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsIn(['multiple'])
  @IsString()
  @IsNotEmpty()
  type: 'multiple';

  @ValidateNested({ each: true })
  @Type(() => FilterSelectOption)
  @IsArray()
  @IsNotEmpty()
  options: FilterSelectOption[];

  @IsString()
  @IsNotEmpty()
  placeholder: string;

  @IsBoolean()
  @IsOptional()
  disable_if_one_option?: boolean;

  @IsBoolean()
  @IsOptional()
  hidden?: boolean;
}

export class FilterSelect {
  @IsString()
  @IsNotEmpty()
  key: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsIn(['select'])
  @IsString()
  @IsNotEmpty()
  type: 'select';

  @ValidateNested({ each: true })
  @Type(() => FilterSelectOption)
  @IsArray()
  @IsNotEmpty()
  options: FilterSelectOption[];

  @IsString()
  @IsNotEmpty()
  placeholder: string;

  @IsBoolean()
  @IsOptional()
  autocomplete?: boolean;

  @IsBoolean()
  @IsOptional()
  disable_if_one_option?: boolean;
}

export class AttorneyUserAvailableFilters {
  @IsArray()
  @IsNotEmpty()
  filters: (
    | FilterSelect
    | FilterMultiple
    | FilterText
    | FilterSearch
    | FilterMultipleSearch
    | FilterBool
    | FilterTimeRange
  )[];
}

export class AttorneyUser {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  full_name: string;

  @ValidateNested()
  @Type(() => Company)
  @IsNotEmpty()
  company: Company;

  @IsString()
  @IsNotEmpty()
  snils: string;

  @IsString({ each: true })
  @IsArray()
  @IsNotEmpty()
  groups: string[];

  @ValidateNested({ each: true })
  @Type(() => Attorney)
  @IsArray()
  @IsNotEmpty()
  attorneys: Attorney[];
}

export class AttorneyUserListResponse {
  @ValidateNested({ each: true })
  @Type(() => AttorneyUser)
  @IsArray()
  @IsNotEmpty()
  attorney_users: AttorneyUser[];

  @IsInt()
  @IsNotEmpty()
  total: number;
}

export class AttorneyAvailableFilters {
  @IsArray()
  @IsNotEmpty()
  filters: (
    | FilterSelect
    | FilterMultiple
    | FilterText
    | FilterSearch
    | FilterMultipleSearch
    | FilterBool
    | FilterTimeRange
  )[];
}

export class AttorneyListResponse {
  @ValidateNested({ each: true })
  @Type(() => Attorney)
  @IsArray()
  @IsNotEmpty()
  attorneys: Attorney[];

  @IsInt()
  @IsNotEmpty()
  total: number;
}

export class AwardItem {
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  name: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsDateString()
  @IsOptional()
  date?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  document_number?: string;
}

export class EmployeeProfileResponseAwards {
  @IsString()
  @IsNotEmpty()
  name: string;

  @ValidateNested({ each: true })
  @Type(() => AwardItem)
  @IsArray()
  @IsNotEmpty()
  awards: AwardItem[];
}

export class WorkHistoryItem {
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  name?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsInt()
  @IsOptional()
  years?: number;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsInt()
  @IsOptional()
  months?: number;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsInt()
  @IsOptional()
  days?: number;
}

export class EmployeeProfileResponseWork_history {
  @IsString()
  @IsNotEmpty()
  name: string;

  @ValidateNested({ each: true })
  @Type(() => WorkHistoryItem)
  @IsArray()
  @IsOptional()
  work_history?: WorkHistoryItem[];
}

export class WorkBookItem {
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsDateString()
  @IsOptional()
  start_of_work?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsDateString()
  @IsOptional()
  end_of_work?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  organization?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  position?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  organization_address?: string;
}

export class EmployeeProfileResponseWork_book {
  @IsString()
  @IsNotEmpty()
  name: string;

  @ValidateNested({ each: true })
  @Type(() => WorkBookItem)
  @IsArray()
  @IsOptional()
  work_book?: WorkBookItem[];
}

export class EducationDocument {
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  document_type?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  number?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  series?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsDateString()
  @IsOptional()
  issue_date?: string;
}

export class Education {
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  type?: string;

  @IsIn(['academic_cap', 'brain'])
  @IsString()
  @IsNotEmpty()
  icon_type: 'academic_cap' | 'brain';

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  institution?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsDateString()
  @IsOptional()
  start_date?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsDateString()
  @IsOptional()
  end_date?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  speciality?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  qualification?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  course?: string;

  @ValidateNested()
  @Type(() => EducationDocument)
  @IsOptional()
  document?: EducationDocument;
}

export class EmployeeProfileResponseEducations {
  @IsString()
  @IsNotEmpty()
  name: string;

  @ValidateNested({ each: true })
  @Type(() => Education)
  @IsArray()
  @IsOptional()
  educations?: Education[];
}

export class AdditionalAgreement {
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  number?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsDateString()
  @IsOptional()
  signature_date?: string;
}

export class EmploymentContract {
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  number?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsDateString()
  @IsOptional()
  signature_date?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  type?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsDateString()
  @IsOptional()
  expiration_date?: string;
}

export class EmploymentContractAndAdditionalAgreements {
  @ValidateNested()
  @Type(() => EmploymentContract)
  @IsOptional()
  employment_contract?: EmploymentContract;

  @ValidateNested({ each: true })
  @Type(() => AdditionalAgreement)
  @IsArray()
  @IsOptional()
  additional_agreements?: AdditionalAgreement[];
}

export class EmployeeProfileResponseEmployment_contract_and_additional_agreements {
  @IsString()
  @IsNotEmpty()
  name: string;

  @ValidateNested()
  @Type(() => EmploymentContractAndAdditionalAgreements)
  @IsOptional()
  employment_contract_and_additional_agreements?: EmploymentContractAndAdditionalAgreements;
}

export class Visa {
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  number?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  country?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsDateString()
  @IsOptional()
  start_date?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsDateString()
  @IsOptional()
  end_date?: string;
}

export class MilitaryCard {
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  number?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  series?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsDateString()
  @IsOptional()
  issue_date?: string;
}

export class InternationalPassport {
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  fio?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  series?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  number?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsDateString()
  @IsOptional()
  issue_date?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsDateString()
  @IsOptional()
  validity_period?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  issue_country?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  issue_place?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  issue_organization_id?: string;
}

export type Snils = string;

export class DocumentsPassport {
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  series?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  number?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsDateString()
  @IsOptional()
  issue_date?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  issue_organization?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  issue_organization_code?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsDateString()
  @IsNotEmpty()
  birth_date: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  birth_place?: string;
}

export class Documents {
  @ValidateNested()
  @Type(() => DocumentsPassport)
  @IsOptional()
  passport?: DocumentsPassport;

  @IsString()
  @IsNotEmpty()
  snils: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  inn: string;

  @ValidateNested({ each: true })
  @Type(() => InternationalPassport)
  @IsArray()
  @IsOptional()
  international_passports?: InternationalPassport[];

  @ValidateNested()
  @Type(() => MilitaryCard)
  @IsOptional()
  military_card?: MilitaryCard;

  @ValidateNested({ each: true })
  @Type(() => Visa)
  @IsArray()
  @IsOptional()
  visas?: Visa[];
}

export class EmployeeProfileResponseDocuments {
  @IsString()
  @IsNotEmpty()
  name: string;

  @ValidateNested()
  @Type(() => Documents)
  @IsOptional()
  documents?: Documents;
}

export class Contacts {
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  email?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  mobile_phone?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  personal_mobile_phone?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  home_phone?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  personal_email?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  skype?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  work_mobile_phone?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  work_phone?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  work_email?: string;
}

export class EmployeeProfileResponseContacts {
  @IsString()
  @IsNotEmpty()
  name: string;

  @ValidateNested()
  @Type(() => Contacts)
  @IsOptional()
  contacts?: Contacts;
}

export class GeneralInfo {
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsDateString()
  @IsOptional()
  birth_date?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  organization: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  unit: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  legal_unit: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  position: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsDateString()
  @IsOptional()
  start_of_work?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsDateString()
  @IsOptional()
  end_of_work?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  workplace_address?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  residential_address?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  registration_address?: string;
}

export class EmployeeProfileResponseGeneral_info {
  @IsString()
  @IsNotEmpty()
  name: string;

  @ValidateNested()
  @Type(() => GeneralInfo)
  @IsOptional()
  general_info?: GeneralInfo;
}

export class ProfileEmployeeItem {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsUUID()
  @IsNotEmpty()
  company_id: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  company_name: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  position: string;

  @IsUUID()
  @IsOptional()
  unit_id?: string;

  @IsDateString()
  @IsOptional()
  dismissed_at?: string;
}

export class EmployeeProfileResponseUser {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  first_name: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  last_name: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  middle_name?: string;

  @ValidateNested({ each: true })
  @Type(() => ProfileEmployeeItem)
  @IsArray()
  @IsNotEmpty()
  employees: ProfileEmployeeItem[];
}

export class EmployeeProfileResponse {
  @ValidateNested()
  @Type(() => EmployeeProfileResponseUser)
  @IsNotEmpty()
  user: EmployeeProfileResponseUser;

  @ValidateNested()
  @Type(() => EmployeeProfileResponseGeneral_info)
  @IsOptional()
  general_info?: EmployeeProfileResponseGeneral_info;

  @ValidateNested()
  @Type(() => EmployeeProfileResponseContacts)
  @IsOptional()
  contacts?: EmployeeProfileResponseContacts;

  @ValidateNested()
  @Type(() => EmployeeProfileResponseDocuments)
  @IsOptional()
  documents?: EmployeeProfileResponseDocuments;

  @ValidateNested()
  @Type(
    () => EmployeeProfileResponseEmployment_contract_and_additional_agreements,
  )
  @IsOptional()
  employment_contract_and_additional_agreements?: EmployeeProfileResponseEmployment_contract_and_additional_agreements;

  @ValidateNested()
  @Type(() => EmployeeProfileResponseEducations)
  @IsOptional()
  educations?: EmployeeProfileResponseEducations;

  @ValidateNested()
  @Type(() => EmployeeProfileResponseWork_book)
  @IsOptional()
  work_book?: EmployeeProfileResponseWork_book;

  @ValidateNested()
  @Type(() => EmployeeProfileResponseWork_history)
  @IsOptional()
  work_history?: EmployeeProfileResponseWork_history;

  @ValidateNested()
  @Type(() => EmployeeProfileResponseAwards)
  @IsOptional()
  awards?: EmployeeProfileResponseAwards;
}

export class UserTelegramInitResponse {
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  redirect_url: string;
}

export type OneCVKAccessTokenStatus = string;

export class OneCVKAccessTokenStatusResponse {
  @IsIn(['not_issued', 'active', 'expired'])
  @IsString()
  @IsNotEmpty()
  status: 'not_issued' | 'active' | 'expired';

  @IsDateString()
  @IsOptional()
  expires_at?: string;
}

export type AccessToken = string;

export class GenerateOneCVKAccessTokenResponse {
  @IsString()
  @IsNotEmpty()
  access_token: string;

  @IsDateString()
  @IsNotEmpty()
  expires_at: string;
}

export class UserLoginResponse {
  @IsString()
  @IsNotEmpty()
  access_token: string;
}

export class UserRegisterPhoneVerifiedResponse {
  @IsString()
  @IsNotEmpty()
  access_token: string;
}

export class UserRegisterResponse {
  @IsString()
  @IsNotEmpty()
  access_token: string;
}

export class AccessTokenResponse {
  @IsString()
  @IsNotEmpty()
  access_token: string;
}

export class OpenIdTokenVerifyResponse {
  @IsIn(['register', 'login'])
  @IsString()
  @IsNotEmpty()
  status: 'register' | 'login';

  @IsString()
  @IsOptional()
  access_token?: string;
}

export class CompanyEventTypeItem {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;
}

export class GetUserManagerSubstitutesCreateOptionsResponse {
  @ValidateNested({ each: true })
  @Type(() => CompanyEventTypeItem)
  @IsArray()
  @IsNotEmpty()
  event_types: CompanyEventTypeItem[];
}

export type SignType = string;

export class CompanyItem {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsString()
  @IsOptional()
  tsp_url?: string;

  @IsIn(['api', 'browser_plugin'])
  @IsString()
  @IsOptional()
  hash_source?: 'api' | 'browser_plugin';
}

export class CompanyEmployee {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  first_name: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  middle_name: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  last_name: string;

  @ValidateNested()
  @Type(() => CompanyItem)
  @IsNotEmpty()
  company: CompanyItem;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  email?: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  phone?: string;

  @IsString()
  @IsNotEmpty()
  personnel_number: string;

  @IsString()
  @IsOptional()
  position?: string;

  @IsIn([
    'kontur',
    'goskey',
    'cryptopro_simple',
    'cryptopro_local',
    'disabled',
    'ukep',
  ])
  @IsString()
  @IsOptional()
  sign_type?:
    | 'kontur'
    | 'goskey'
    | 'cryptopro_simple'
    | 'cryptopro_local'
    | 'disabled'
    | 'ukep';

  @IsUUID()
  @IsOptional()
  unit_id?: string;

  @IsString()
  @IsOptional()
  unit_breadcrumbs?: string;

  @IsUUID()
  @IsNotEmpty()
  user_id: string;
}

export class ManagerSubstitutesEventType {
  @ValidateNested()
  @Type(() => CompanyEventTypeItem)
  @IsNotEmpty()
  event_type: CompanyEventTypeItem;

  @ValidateNested({ each: true })
  @Type(() => CompanyEmployee)
  @IsArray()
  @IsNotEmpty()
  substitutes: CompanyEmployee[];
}

export class GetUserManagerSubstitutesResponse {
  @ValidateNested({ each: true })
  @Type(() => ManagerSubstitutesEventType)
  @IsArray()
  @IsNotEmpty()
  substitutes: ManagerSubstitutesEventType[];
}

export type UnitManagerSettingApprove = string;

export class EventTypeSettings {
  @ValidateNested()
  @Type(() => CompanyEventTypeItem)
  @IsNotEmpty()
  event_type: CompanyEventTypeItem;

  @IsIn(['always', 'direct_only', 'never'])
  @IsString()
  @IsNotEmpty()
  approve: 'always' | 'direct_only' | 'never';
}

export class UnitManagerSetting {
  @ValidateNested()
  @Type(() => CompanyEventTypeItem)
  @IsNotEmpty()
  event_type: CompanyEventTypeItem;

  @IsIn(['always', 'direct_only', 'never'])
  @IsString()
  @IsNotEmpty()
  approve: 'always' | 'direct_only' | 'never';
}

export class Unit {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsUUID()
  @IsOptional()
  parent_id?: string;

  @IsBoolean()
  @IsNotEmpty()
  limited_access: boolean;

  @ValidateNested()
  @Type(() => CompanyEmployee)
  @IsOptional()
  manager?: CompanyEmployee;

  @ValidateNested({ each: true })
  @Type(() => UnitManagerSetting)
  @IsArray()
  @IsOptional()
  manager_settings?: UnitManagerSetting[];

  @ValidateNested({ each: true })
  @Type(() => CompanyEmployee)
  @IsArray()
  @IsOptional()
  assistants?: CompanyEmployee[];
}

export class ManageableCompanyUnit {
  @ValidateNested()
  @Type(() => Unit)
  @IsNotEmpty()
  unit: Unit;

  @ValidateNested({ each: true })
  @Type(() => EventTypeSettings)
  @IsArray()
  @IsOptional()
  event_type_settings?: EventTypeSettings[];

  @IsIn(['manager', 'assistant'])
  @IsString()
  @IsNotEmpty()
  role_in_unit: 'manager' | 'assistant';
}

export class ManageableCompanyItem {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @ValidateNested()
  @Type(() => CompanyEmployee)
  @IsNotEmpty()
  employee: CompanyEmployee;

  @ValidateNested({ each: true })
  @Type(() => ManagerSubstitutesEventType)
  @IsArray()
  @IsNotEmpty()
  substitutes: ManagerSubstitutesEventType[];

  @ValidateNested({ each: true })
  @Type(() => ManageableCompanyUnit)
  @IsArray()
  @IsOptional()
  functional_manageable_units?: ManageableCompanyUnit[];
}

export class GetManageableCompaniesResponse {
  @ValidateNested({ each: true })
  @Type(() => ManageableCompanyItem)
  @IsArray()
  @IsNotEmpty()
  companies: ManageableCompanyItem[];
}

export class UserPermissions {
  @IsBoolean()
  @IsNotEmpty()
  view_events: boolean;

  @IsBoolean()
  @IsNotEmpty()
  view_event_to_paper: boolean;

  @IsBoolean()
  @IsNotEmpty()
  view_employees: boolean;

  @IsBoolean()
  @IsNotEmpty()
  view_policies: boolean;

  @IsBoolean()
  @IsNotEmpty()
  view_company: boolean;

  @IsBoolean()
  @IsNotEmpty()
  view_company_profiles: boolean;

  @IsBoolean()
  @IsNotEmpty()
  view_settings: boolean;

  @IsBoolean()
  @IsNotEmpty()
  view_attorneys: boolean;

  @IsBoolean()
  @IsNotEmpty()
  view_candidates: boolean;

  @IsBoolean()
  @IsNotEmpty()
  view_vacations: boolean;

  @IsBoolean()
  @IsNotEmpty()
  view_vacations_settings: boolean;

  @IsBoolean()
  @IsNotEmpty()
  view_payslips: boolean;

  @IsBoolean()
  @IsNotEmpty()
  view_smev_errors: boolean;

  @IsBoolean()
  @IsNotEmpty()
  view_absences: boolean;

  @IsBoolean()
  @IsNotEmpty()
  view_personal_profile: boolean;

  @IsBoolean()
  @IsNotEmpty()
  view_competencies: boolean;

  @IsBoolean()
  @IsNotEmpty()
  view_units: boolean;
}

export class UserPermissionsResponse {
  @ValidateNested()
  @Type(() => UserPermissions)
  @IsNotEmpty()
  general_permissions: UserPermissions;

  @ValidateNested()
  @Type(() => UserPermissions)
  @IsNotEmpty()
  employee_permissions: UserPermissions;

  @ValidateNested()
  @Type(() => UserPermissions)
  @IsNotEmpty()
  company_permissions: UserPermissions;
}

export class UkepCertificate {
  @IsDateString()
  @IsNotEmpty()
  valid_from: string;

  @IsDateString()
  @IsNotEmpty()
  valid_to: string;

  @IsString()
  @IsNotEmpty()
  issued_by: string;

  @IsString()
  @IsNotEmpty()
  owner: string;

  @IsString()
  @IsNotEmpty()
  serial_number: string;

  @IsUUID()
  @IsNotEmpty()
  company_id: string;

  @IsString()
  @IsNotEmpty()
  company_name: string;
}

export class UserResponseNon_representative {
  @IsString()
  @IsNotEmpty()
  text: string;

  @IsString()
  @IsOptional()
  linktext?: string;

  @IsString()
  @IsOptional()
  link?: string;
}

export type CertificateReleaseMethod = string;

export type CertificateStatus = string;

export class UserResponseCertificate {
  @IsIn([
    'kontur',
    'goskey',
    'cryptopro_simple',
    'cryptopro_local',
    'disabled',
    'ukep',
  ])
  @IsString()
  @IsNotEmpty()
  sign_type:
    | 'kontur'
    | 'goskey'
    | 'cryptopro_simple'
    | 'cryptopro_local'
    | 'disabled'
    | 'ukep';

  @IsBoolean()
  @IsNotEmpty()
  exists: boolean;

  @IsIn([
    'waiting_to_create',
    'need_verify',
    'waiting_confirmation',
    'verifying',
    'released',
    'expired',
    'error',
    'release_paused',
  ])
  @IsString()
  @IsNotEmpty()
  status:
    | 'waiting_to_create'
    | 'need_verify'
    | 'waiting_confirmation'
    | 'verifying'
    | 'released'
    | 'expired'
    | 'error'
    | 'release_paused';

  @IsIn(['sms', 'esia'])
  @IsString()
  @IsOptional()
  release_method?: 'sms' | 'esia';

  @IsDateString()
  @IsOptional()
  valid_from?: string;

  @IsDateString()
  @IsOptional()
  valid_to?: string;
}

export class ProfileItem {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;
}

export class UserGroup {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsBoolean()
  @IsNotEmpty()
  is_admin: boolean;

  @IsBoolean()
  @IsNotEmpty()
  is_recruiter: boolean;

  @ValidateNested()
  @Type(() => Company)
  @IsNotEmpty()
  company: Company;

  @ValidateNested({ each: true })
  @Type(() => ProfileItem)
  @IsArray()
  @IsNotEmpty()
  profiles: ProfileItem[];
}

export class EmployeePermissions {
  @IsBoolean()
  @IsNotEmpty()
  edit_substitutes: boolean;
}

export class AuthEmployeeManager {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  name: string;
}

export class AuthEmployee {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  email?: string;

  @ValidateNested()
  @Type(() => Company)
  @IsNotEmpty()
  company: Company;

  @IsDateString()
  @IsOptional()
  dismissed_at?: string;

  @IsString()
  @IsNotEmpty()
  personnel_number: string;

  @ValidateNested()
  @Type(() => AuthEmployeeManager)
  @IsOptional()
  manager?: AuthEmployeeManager;

  @IsString()
  @IsNotEmpty()
  position: string;

  @IsString()
  @IsNotEmpty()
  unit: string;

  @ValidateNested()
  @Type(() => EmployeePermissions)
  @IsNotEmpty()
  permissions: EmployeePermissions;

  @IsInt()
  @IsOptional()
  vacation_days?: number;

  @IsBoolean()
  @IsNotEmpty()
  import_vacation_days: boolean;

  @IsIn([
    'kontur',
    'goskey',
    'cryptopro_simple',
    'cryptopro_local',
    'disabled',
    'ukep',
  ])
  @IsString()
  @IsNotEmpty()
  sign_type:
    | 'kontur'
    | 'goskey'
    | 'cryptopro_simple'
    | 'cryptopro_local'
    | 'disabled'
    | 'ukep';
}

export class UserResponse {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  first_name: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  last_name: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  middle_name: string;

  @IsBoolean()
  @IsNotEmpty()
  use_full_greeting: boolean;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  phone: string;

  @IsBoolean()
  @IsNotEmpty()
  has_company_side: boolean;

  @ValidateNested({ each: true })
  @Type(() => AuthEmployee)
  @IsArray()
  @IsNotEmpty()
  employees: AuthEmployee[];

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsDateString()
  @IsNotEmpty()
  birth_date: string;

  @IsString()
  @IsNotEmpty()
  snils: string;

  @ValidateNested({ each: true })
  @Type(() => UserGroup)
  @IsArray()
  @IsNotEmpty()
  groups: UserGroup[];

  @ValidateNested({ each: true })
  @Type(() => Attorney)
  @IsArray()
  @IsNotEmpty()
  attorneys: Attorney[];

  @ValidateNested()
  @Type(() => UserResponseCertificate)
  @IsNotEmpty()
  certificate: UserResponseCertificate;

  @IsBoolean()
  @IsNotEmpty()
  telegram_enabled: boolean;

  @IsBoolean()
  @IsNotEmpty()
  representative: boolean;

  @ValidateNested()
  @Type(() => UserResponseNon_representative)
  @IsOptional()
  non_representative?: UserResponseNon_representative;

  @ValidateNested({ each: true })
  @Type(() => UkepCertificate)
  @IsArray()
  @IsOptional()
  ukep_certificates?: UkepCertificate[];
}

export type EmployeeStatus = string;

export class UserEmployeeListItem {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @ValidateNested()
  @Type(() => CompanyItem)
  @IsNotEmpty()
  company: CompanyItem;

  @IsString()
  @IsNotEmpty()
  personnel_number: string;

  @IsDateString()
  @IsOptional()
  dismissed_at?: string;

  @IsString()
  @IsNotEmpty()
  position: string;

  @IsString()
  @IsNotEmpty()
  unit: string;

  @IsIn([
    'data_received',
    'documents_verification',
    'unep_application_signing',
    'unep_releasing',
    'unep_release_failed',
    'unep_release_paused',
    'invite_sent',
    'invite_expired',
    'active',
    'dismissed',
    'wait_registration',
  ])
  @IsString()
  @IsNotEmpty()
  status:
    | 'data_received'
    | 'documents_verification'
    | 'unep_application_signing'
    | 'unep_releasing'
    | 'unep_release_failed'
    | 'unep_release_paused'
    | 'invite_sent'
    | 'invite_expired'
    | 'active'
    | 'dismissed'
    | 'wait_registration';

  @IsDateString()
  @IsNotEmpty()
  status_changed_at: string;

  @IsDateString()
  @IsNotEmpty()
  employee_created_at: string;
}

export class UserListItem {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  first_name: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  middle_name: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  last_name: string;

  @ValidateNested({ each: true })
  @Type(() => UserEmployeeListItem)
  @IsArray()
  @IsNotEmpty()
  employees: UserEmployeeListItem[];
}

export class UserListResponse {
  @ValidateNested({ each: true })
  @Type(() => UserListItem)
  @IsArray()
  @IsNotEmpty()
  users: UserListItem[];
}

export type EmployeeAvailableFiltersEmployeeOptionsResponse =
  FilterSelectOption[];

export class AvailableFilters {
  @IsArray()
  @IsNotEmpty()
  filters: (
    | FilterSelect
    | FilterMultiple
    | FilterText
    | FilterSearch
    | FilterMultipleSearch
    | FilterBool
    | FilterTimeRange
  )[];
}

export class EmployeeXlsxResponse {
  @IsUUID()
  @IsNotEmpty()
  download_id: string;
}

export class SmevError {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  code: string;

  @IsIn(['fatal', 'warning'])
  @IsString()
  @IsNotEmpty()
  level: 'fatal' | 'warning';

  @IsString()
  @IsNotEmpty()
  description: string;

  @IsBoolean()
  @IsNotEmpty()
  ignore: boolean;
}

export class EmployeeListItem {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  email?: string;

  @ValidateNested()
  @Type(() => Company)
  @IsNotEmpty()
  company: Company;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  first_name: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  middle_name: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  last_name: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsDateString()
  @IsNotEmpty()
  birth_date: string;

  @IsString()
  @IsNotEmpty()
  personnel_number: string;

  @IsDateString()
  @IsNotEmpty()
  created_at: string;

  @IsBoolean()
  @IsNotEmpty()
  has_invite: boolean;

  @IsDateString()
  @IsOptional()
  dismissed_at?: string;

  @IsString()
  @IsNotEmpty()
  position: string;

  @IsString()
  @IsNotEmpty()
  unit: string;

  @IsIn([
    'data_received',
    'documents_verification',
    'unep_application_signing',
    'unep_releasing',
    'unep_release_failed',
    'unep_release_paused',
    'invite_sent',
    'invite_expired',
    'active',
    'dismissed',
    'wait_registration',
  ])
  @IsString()
  @IsNotEmpty()
  status:
    | 'data_received'
    | 'documents_verification'
    | 'unep_application_signing'
    | 'unep_releasing'
    | 'unep_release_failed'
    | 'unep_release_paused'
    | 'invite_sent'
    | 'invite_expired'
    | 'active'
    | 'dismissed'
    | 'wait_registration';

  @IsDateString()
  @IsNotEmpty()
  status_changed_at: string;

  @IsIn([
    'kontur',
    'goskey',
    'cryptopro_simple',
    'cryptopro_local',
    'disabled',
    'ukep',
  ])
  @IsString()
  @IsOptional()
  sign_type?:
    | 'kontur'
    | 'goskey'
    | 'cryptopro_simple'
    | 'cryptopro_local'
    | 'disabled'
    | 'ukep';

  @ValidateNested()
  @Type(() => SmevError)
  @IsOptional()
  smev_error?: SmevError;
}

export class EmployeeListResponse {
  @ValidateNested({ each: true })
  @Type(() => EmployeeListItem)
  @IsArray()
  @IsNotEmpty()
  employees: EmployeeListItem[];

  @IsInt()
  @IsNotEmpty()
  total_count: number;
}

export class EmployeeInviteVerifyResponse {
  @IsIn(['active', 'registration', 'login', 'phone_change'])
  @IsString()
  @IsNotEmpty()
  status: 'active' | 'registration' | 'login' | 'phone_change';

  @IsString()
  @IsOptional()
  phone?: string;
}

export class OAuth2VerifyResponse {
  @IsString()
  @IsOptional()
  access_token?: string;

  @IsIn(['registration', 'login', 'phone_change'])
  @IsString()
  @IsNotEmpty()
  status: 'registration' | 'login' | 'phone_change';
}

export class CheckEmployeeInviteByFiltersResponse {
  @IsNumber()
  @IsNotEmpty()
  total_invitations_available: number;
}

export type AuthInstanceId = string;

export class AuthInstance {
  @IsString()
  @IsNotEmpty()
  id: string;

  @IsIn(['default', 'pass', 'oauth2', 'openid'])
  @IsString()
  @IsNotEmpty()
  auth_type: 'default' | 'pass' | 'oauth2' | 'openid';

  @IsIn(['vkteam', 'keycloak', 'esia'])
  @IsString()
  @IsOptional()
  oauth2_source?: 'vkteam' | 'keycloak' | 'esia';

  @IsString()
  @IsNotEmpty()
  button_text: string;

  @IsString()
  @IsNotEmpty()
  button_color: string;
}

export class AuthInstanceResponse {
  @ValidateNested({ each: true })
  @Type(() => AuthInstance)
  @IsArray()
  @IsNotEmpty()
  auth_instances: AuthInstance[];
}

export class AttorneyImportRequest {
  @IsUUID()
  @IsNotEmpty()
  user_id: string;

  @IsUUID()
  @IsNotEmpty()
  company_id: string;

  @Type(() => String)
  @IsNotEmpty()
  xml: File;

  @Type(() => String)
  @IsNotEmpty()
  sig: File;
}

export class AttorneysIdsRequest {
  @IsUUID(undefined, { each: true })
  @IsArray()
  @IsNotEmpty()
  attorney_ids: string[];
}

export type AttorneyXMLEntity = string;

export class AttorneyUkepSign {
  @IsUUID()
  @IsNotEmpty()
  attorney_id: string;

  @IsString()
  @IsNotEmpty()
  signature: string;

  @IsIn(['attorney', 'revocation'])
  @IsString()
  @IsNotEmpty()
  entity: 'attorney' | 'revocation';
}

export class AttorneyUkepSignRequest {
  @ValidateNested({ each: true })
  @Type(() => AttorneyUkepSign)
  @IsArray()
  @IsNotEmpty()
  attorneys: AttorneyUkepSign[];
}

export class AttorneysRevokeRequest {
  @IsUUID(undefined, { each: true })
  @IsArray()
  @IsNotEmpty()
  attorney_ids: string[];

  @IsString()
  @IsNotEmpty()
  reason: string;
}

export class SetDefaultAttorneyRequest {
  @IsUUID()
  @IsNotEmpty()
  attorney_id: string;
}

export type AttorneyPowersCooperation = string;

export class AttorneysCreateRequest {
  @IsUUID(undefined, { each: true })
  @IsArray()
  @IsNotEmpty()
  user_ids: string[];

  @IsUUID()
  @IsNotEmpty()
  company_id: string;

  @IsString()
  @IsOptional()
  principal_snils?: string;

  @IsIn(['cooperative', 'individual'])
  @IsString()
  @IsOptional()
  principal_powers_cooperation?: 'cooperative' | 'individual';

  @IsIn(['cooperative', 'individual'])
  @IsString()
  @IsNotEmpty()
  confidant_powers_cooperation: 'cooperative' | 'individual';

  @IsString()
  @IsOptional()
  powers?: string;

  @IsDateString()
  @IsNotEmpty()
  valid_to: string;
}

export class AttorneyUserListRequestFilter {
  @IsUUID()
  @IsNotEmpty()
  company_id: string;

  @IsUUID()
  @IsOptional()
  employee_id?: string;

  @IsUUID(undefined, { each: true })
  @IsArray()
  @IsOptional()
  group?: string[];
}

export class AttorneyUserListRequest {
  @ValidateNested()
  @Type(() => AttorneyUserListRequestFilter)
  @IsNotEmpty()
  filter: AttorneyUserListRequestFilter;

  @IsInt()
  @IsOptional()
  offset?: number;

  @IsInt()
  @IsOptional()
  limit?: number;
}

export class TimeRange {
  @IsDateString()
  @IsOptional()
  start?: string;

  @IsDateString()
  @IsOptional()
  end?: string;
}

export class AttorneyListRequestFilter {
  @IsUUID()
  @IsOptional()
  company_id?: string;

  @IsUUID()
  @IsOptional()
  employee_id?: string;

  @IsIn(['new', 'signed', 'expired', 'revoking', 'revoked', 'canceled'], {
    each: true,
  })
  @IsArray()
  @IsOptional()
  status?: (
    | 'new'
    | 'signed'
    | 'expired'
    | 'revoking'
    | 'revoked'
    | 'canceled'
  )[];

  @ValidateNested()
  @Type(() => TimeRange)
  @IsOptional()
  valid_from_range?: TimeRange;

  @ValidateNested()
  @Type(() => TimeRange)
  @IsOptional()
  valid_to_range?: TimeRange;

  @IsBoolean()
  @IsOptional()
  is_default?: boolean;
}

export class AttorneyListRequest {
  @ValidateNested()
  @Type(() => AttorneyListRequestFilter)
  @IsNotEmpty()
  filter: AttorneyListRequestFilter;

  @IsInt()
  @IsOptional()
  offset?: number;

  @IsInt()
  @IsOptional()
  limit?: number;
}

export class ActivityLogChangeSide {
  @IsIn(['employee', 'company'])
  @IsString()
  @IsNotEmpty()
  to_side: 'employee' | 'company';
}

export class ActivityLogEventView {
  @IsUUID()
  @IsNotEmpty()
  event_id: string;
}

export class CertificateVerifyRequest {
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  code: string;
}

export class CertificateInitRequest {}

export type Password = string;

export type InviteTarget = string;

export class UserInviteSendRequest {
  @IsString()
  @IsNotEmpty()
  snils: string;

  @IsIn(['phone_change'])
  @IsString()
  @IsNotEmpty()
  target: 'phone_change';

  @IsString()
  @IsOptional()
  password?: string;
}

export type PhoneCode = string;

export type Phone = string;

export class PhoneVerification {
  @IsString()
  @IsNotEmpty()
  phone: string;

  @IsString()
  @IsNotEmpty()
  code: string;
}

export class Credentials {
  @ValidateNested()
  @Type(() => PhoneVerification)
  @IsNotEmpty()
  phone_verification: PhoneVerification;

  @IsString()
  @IsNotEmpty()
  password: string;
}

export class UserChangePasswordRequest {
  @ValidateNested()
  @Type(() => Credentials)
  @IsNotEmpty()
  credentials: Credentials;

  @IsString()
  @IsNotEmpty()
  snils: string;
}

export class UserPasswordInitChangeRequest {
  @IsString()
  @IsNotEmpty()
  phone: string;

  @IsString()
  @IsNotEmpty()
  snils: string;
}

export type InviteCode = string;

export class UserLoginRequest {
  @ValidateNested()
  @Type(() => Credentials)
  @IsNotEmpty()
  credentials: Credentials;

  @IsString()
  @IsOptional()
  invite_code?: string;

  @IsString()
  @IsNotEmpty()
  auth_instance_id: string;
}

export class UserLoginInitRequest {
  @IsString()
  @IsNotEmpty()
  phone: string;

  @IsString()
  @IsNotEmpty()
  password: string;

  @IsString()
  @IsNotEmpty()
  auth_instance_id: string;
}

export class UserLogoutRequest {}

export type OAuth2Code = string;

export class OAuth2RegisterUserRequest {
  @IsString()
  @IsNotEmpty()
  phone_code: string;

  @IsString()
  @IsNotEmpty()
  auth_code: string;
}

export class RegisterUserPhoneVerifiedRequest {
  @IsString()
  @IsNotEmpty()
  invite_code: string;

  @IsString()
  @IsNotEmpty()
  snils: string;

  @IsString()
  @IsNotEmpty()
  password: string;
}

export class RegisterUserRequest {
  @IsString()
  @IsNotEmpty()
  invite_code: string;

  @ValidateNested()
  @Type(() => Credentials)
  @IsNotEmpty()
  credentials: Credentials;
}

export type OpenIdToken = string;

export class OpenIdUserRegisterRequest {
  @IsString()
  @IsNotEmpty()
  phone: string;

  @IsString()
  @IsNotEmpty()
  phone_code: string;

  @IsString()
  @IsNotEmpty()
  token: string;
}

export class OpenIdUserRegisterInitRequest {
  @IsString()
  @IsNotEmpty()
  phone: string;

  @IsString()
  @IsNotEmpty()
  token: string;
}

export class OpenIdTokenVerifyRequest {
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  token: string;

  @IsString()
  @IsNotEmpty()
  auth_instance_id: string;
}

export class OAuth2UserRegisterInitRequest {
  @IsString()
  @IsNotEmpty()
  phone: string;

  @IsString()
  @IsNotEmpty()
  auth_code: string;
}

export class UserRegisterInitRequest {
  @IsString()
  @IsNotEmpty()
  phone: string;

  @IsString()
  @IsNotEmpty()
  invite_code: string;

  @IsString()
  @IsNotEmpty()
  snils: string;
}

export class DeleteUserManagerSubstituteRequest {
  @IsUUID()
  @IsNotEmpty()
  employee_id: string;

  @IsUUID()
  @IsNotEmpty()
  event_type_id: string;

  @IsUUID()
  @IsNotEmpty()
  substitute_id: string;
}

export class CreateUserManagerSubstituteRequest {
  @IsUUID()
  @IsNotEmpty()
  employee_id: string;

  @IsUUID(undefined, { each: true })
  @IsArray()
  @IsNotEmpty()
  event_types: string[];

  @IsUUID()
  @IsNotEmpty()
  substitute_id: string;
}

export class UserListRequestFilter {
  @IsUUID()
  @IsNotEmpty()
  company_id: string;

  @IsString()
  @IsOptional()
  query?: string;

  @IsUUID()
  @IsOptional()
  exclude_group_id?: string;
}

export class UserListRequest {
  @ValidateNested()
  @Type(() => UserListRequestFilter)
  @IsNotEmpty()
  filter: UserListRequestFilter;

  @IsInt()
  @IsOptional()
  limit?: number;

  @IsInt()
  @IsOptional()
  offset?: number;
}

export class EmployeeUnepResetRequest {
  @IsUUID()
  @IsNotEmpty()
  employee_id: string;
}

export class EmployeeFilter {
  @IsString()
  @IsOptional()
  personnel_number_fio_query?: string;

  @IsString({ each: true })
  @IsArray()
  @IsOptional()
  company_ids?: string[];

  @IsUUID()
  @IsOptional()
  employee_id?: string;

  @IsUUID(undefined, { each: true })
  @IsArray()
  @IsOptional()
  employee_ids?: string[];

  @IsIn(
    [
      'data_received',
      'documents_verification',
      'unep_application_signing',
      'unep_releasing',
      'unep_release_failed',
      'unep_release_paused',
      'invite_sent',
      'invite_expired',
      'active',
      'dismissed',
      'wait_registration',
    ],
    { each: true },
  )
  @IsArray()
  @IsOptional()
  statuses?: (
    | 'data_received'
    | 'documents_verification'
    | 'unep_application_signing'
    | 'unep_releasing'
    | 'unep_release_failed'
    | 'unep_release_paused'
    | 'invite_sent'
    | 'invite_expired'
    | 'active'
    | 'dismissed'
    | 'wait_registration'
  )[];

  @ValidateNested()
  @Type(() => TimeRange)
  @IsOptional()
  status_changed_at?: TimeRange;

  @ValidateNested()
  @Type(() => TimeRange)
  @IsOptional()
  employee_created_at?: TimeRange;

  @IsUUID(undefined, { each: true })
  @IsArray()
  @IsOptional()
  unit_ids?: string[];

  @IsUUID(undefined, { each: true })
  @IsArray()
  @IsOptional()
  segments?: string[];

  @IsIn([
    'kontur',
    'goskey',
    'cryptopro_simple',
    'cryptopro_local',
    'disabled',
    'ukep',
  ])
  @IsString()
  @IsOptional()
  sign_type?:
    | 'kontur'
    | 'goskey'
    | 'cryptopro_simple'
    | 'cryptopro_local'
    | 'disabled'
    | 'ukep';

  @IsBoolean()
  @IsOptional()
  only_representative?: boolean;
}

export class EmployeeXlsxRequest {
  @ValidateNested()
  @Type(() => EmployeeFilter)
  @IsOptional()
  filter?: EmployeeFilter;
}

export class EmployeeListRequest {
  @IsInt()
  @IsOptional()
  offset?: number;

  @IsInt()
  @IsOptional()
  limit?: number;

  @ValidateNested()
  @Type(() => EmployeeFilter)
  @IsOptional()
  filter?: EmployeeFilter;
}

export class EmployeeActivateRequest {
  @IsString()
  @IsNotEmpty()
  invite_code: string;
}

export class EmployeeInviteVerifyRequest {
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsOptional()
  snils?: string;

  @IsString()
  @IsNotEmpty()
  invite_code: string;
}

export class OAuth2Verify {
  @IsString()
  @IsNotEmpty()
  auth_code: string;
}

export class CheckEmployeeInviteByFiltersRequest {
  @ValidateNested()
  @Type(() => EmployeeFilter)
  @IsNotEmpty()
  filter: EmployeeFilter;
}

export class EmployeeInviteByFiltersRequest {
  @ValidateNested()
  @Type(() => EmployeeFilter)
  @IsNotEmpty()
  filter: EmployeeFilter;
}

export class EmployeeInviteByIdsRequest {
  @IsUUID(undefined, { each: true })
  @IsArray()
  @IsNotEmpty()
  employee_ids: string[];
}

@injectable()
export class UsersService {
  constructor(
    private validator: ValidationService,
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
    private query: QueryService,
  ) {}

  async docs({ params }: { params: DocsParams }) {
    await Promise.all([this.validator.validateOrReject(params, DocsParams)]);

    let requestPath = '/docs/{service_doc_type}';

    const paramsKeys = Object.keys(params) as (keyof DocsParams)[];
    if (paramsKeys.length) {
      paramsKeys.forEach((param) => {
        requestPath = requestPath.replace(
          `{${param}}`,
          encodeURIComponent(String(params[param])),
        );
      });
    }

    const result = await this.http.getFile(requestPath, {
      withAuth: false,
      withSide: false,
      isJson: true,
    });

    return result;
  }

  async userAttorneyExport({
    query = {},
  }: {
    query?: UserAttorneyExportQuery;
  } = {}) {
    await Promise.all([
      this.validator.validateOrReject(query, UserAttorneyExportQuery),
    ]);

    let requestPath = '/user/attorney/export';

    const result = await this.http.getFile(
      requestPath,
      {
        withAuth: true,
        withSide: false,
        isJson: true,
      },
      query,
    );

    return result;
  }

  async attorneyExport({
    query = {},
  }: {
    query?: AttorneyExportQuery;
  } = {}) {
    await Promise.all([
      this.validator.validateOrReject(query, AttorneyExportQuery),
    ]);

    let requestPath = '/attorney/export';

    const result = await this.http.getFile(
      requestPath,
      {
        withAuth: true,
        withSide: false,
        isJson: true,
      },
      query,
    );

    return result;
  }

  async attorneyImport({ body }: { body: AttorneyImportRequest }) {
    await Promise.all([
      this.validator.validateOrReject(body, AttorneyImportRequest),
    ]);

    let requestPath = '/attorney/import';

    const result = await this.http.post(requestPath, body, { isJson: false });

    return result;
  }

  async getAttorneyXMLHashes({ body }: { body: AttorneysIdsRequest }) {
    await Promise.all([
      this.validator.validateOrReject(body, AttorneysIdsRequest),
    ]);

    let requestPath = '/attorney/xml/hashes';

    const result = await this.http.post(requestPath, body, {});

    const response = await this.unmarshaller.unmarshall(
      result,
      GetAttorneyXMLHashesResponse,
    );

    return response;
  }

  async attorneyUkepSign({ body }: { body: AttorneyUkepSignRequest }) {
    await Promise.all([
      this.validator.validateOrReject(body, AttorneyUkepSignRequest),
    ]);

    let requestPath = '/attorney/ukep_sign';

    const result = await this.http.post(requestPath, body, { withSide: true });

    return result;
  }

  async attorneysUnrevoke({ body }: { body: AttorneysIdsRequest }) {
    await Promise.all([
      this.validator.validateOrReject(body, AttorneysIdsRequest),
    ]);

    let requestPath = '/attorney/unrevoke';

    const result = await this.http.post(requestPath, body, { withSide: true });

    const response = await this.unmarshaller.unmarshall(
      result,
      AttorneysResponse,
    );

    return response;
  }

  async attorneysRevokeImported({ body }: { body: AttorneysIdsRequest }) {
    await Promise.all([
      this.validator.validateOrReject(body, AttorneysIdsRequest),
    ]);

    let requestPath = '/attorney/revoke/imported';

    const result = await this.http.post(requestPath, body, { withSide: true });

    const response = await this.unmarshaller.unmarshall(
      result,
      AttorneysResponse,
    );

    return response;
  }

  async attorneysRevoke({ body }: { body: AttorneysRevokeRequest }) {
    await Promise.all([
      this.validator.validateOrReject(body, AttorneysRevokeRequest),
    ]);

    let requestPath = '/attorney/revoke';

    const result = await this.http.post(requestPath, body, { withSide: true });

    const response = await this.unmarshaller.unmarshall(
      result,
      AttorneysResponse,
    );

    return response;
  }

  async attorneyCompanyList() {
    let requestPath = '/attorney/company/list';

    const result = await this.http.get(requestPath, {}, { withSide: true });

    const response = await this.unmarshaller.unmarshall(
      result,
      AttorneyCompanyListResponse,
    );

    return response;
  }

  async setDefaultAttorney({ body }: { body: SetDefaultAttorneyRequest }) {
    await Promise.all([
      this.validator.validateOrReject(body, SetDefaultAttorneyRequest),
    ]);

    let requestPath = '/user/attorney/set_default';

    const result = await this.http.post(requestPath, body, { withSide: true });

    return result;
  }

  async attorneysDelete({ body }: { body: AttorneysIdsRequest }) {
    await Promise.all([
      this.validator.validateOrReject(body, AttorneysIdsRequest),
    ]);

    let requestPath = '/attorney/delete';

    const result = await this.http.post(requestPath, body, { withSide: true });

    return result;
  }

  async attorneysCreate({ body }: { body: AttorneysCreateRequest }) {
    await Promise.all([
      this.validator.validateOrReject(body, AttorneysCreateRequest),
    ]);

    let requestPath = '/attorney/create';

    const result = await this.http.post(requestPath, body, { withSide: true });

    const response = await this.unmarshaller.unmarshall(
      result,
      AttorneysResponse,
    );

    return response;
  }

  async attorneyGet({ params }: { params: AttorneyGetParams }) {
    await Promise.all([
      this.validator.validateOrReject(params, AttorneyGetParams),
    ]);

    let requestPath = '/attorney/{attorney_id}';

    const paramsKeys = Object.keys(params) as (keyof AttorneyGetParams)[];
    if (paramsKeys.length) {
      paramsKeys.forEach((param) => {
        requestPath = requestPath.replace(
          `{${param}}`,
          encodeURIComponent(String(params[param])),
        );
      });
    }

    const result = await this.http.get(requestPath, {}, { withSide: true });

    const response = await this.unmarshaller.unmarshall(result, Attorney);

    return response;
  }

  async getAttorneyEmployeeFilterOptions({
    query = {},
  }: {
    query?: GetAttorneyEmployeeFilterOptionsQuery;
  } = {}) {
    await Promise.all([
      this.validator.validateOrReject(
        query,
        GetAttorneyEmployeeFilterOptionsQuery,
      ),
    ]);

    let requestPath = '/attorney/list/available_filters/employee_options';

    const result = await this.http.get(requestPath, query, { withSide: true });

    class Response {
      @ValidateNested()
      @Type(() => FilterSelectOption)
      response: FilterSelectOption[];
    }
    const { response } = await this.unmarshaller.unmarshall(
      { response: result },
      Response,
    );

    return response;
  }

  async attorneyUserListAvailableFilters({
    query = {},
  }: {
    query?: AttorneyUserListAvailableFiltersQuery;
  } = {}) {
    await Promise.all([
      this.validator.validateOrReject(
        query,
        AttorneyUserListAvailableFiltersQuery,
      ),
    ]);

    let requestPath = '/attorney/user/list/available_filters';

    const result = await this.http.get(requestPath, query, {});

    const response = await this.unmarshaller.unmarshall(
      result,
      FiltersResponse,
    );

    return response;
  }

  async attorneyUserList({ body }: { body: AttorneyUserListRequest }) {
    await Promise.all([
      this.validator.validateOrReject(body, AttorneyUserListRequest),
    ]);

    let requestPath = '/attorney/user/list';

    const result = await this.http.post(requestPath, body, { withSide: true });

    const response = await this.unmarshaller.unmarshall(
      result,
      AttorneyUserListResponse,
    );

    return response;
  }

  async attorneyListAvailableFilters() {
    let requestPath = '/attorney/list/available_filters';

    const result = await this.http.get(requestPath, {}, {});

    const response = await this.unmarshaller.unmarshall(
      result,
      FiltersResponse,
    );

    return response;
  }

  async attorneyList({ body }: { body: AttorneyListRequest }) {
    await Promise.all([
      this.validator.validateOrReject(body, AttorneyListRequest),
    ]);

    let requestPath = '/attorney/list';

    const result = await this.http.post(requestPath, body, { withSide: true });

    const response = await this.unmarshaller.unmarshall(
      result,
      AttorneyListResponse,
    );

    return response;
  }

  async oauth2AuthFormCallback({
    query,
  }: {
    query: Oauth2AuthFormCallbackQuery;
  }) {
    await Promise.all([
      this.validator.validateOrReject(query, Oauth2AuthFormCallbackQuery),
    ]);

    let requestPath = '/oauth2/auth_form/callback';

    const result = await this.http.get(requestPath, query, {});

    return result;
  }

  async oauth2AuthFormInit() {
    let requestPath = '/oauth2/auth_form/init';

    const result = await this.http.post(requestPath, {}, {});

    return result;
  }

  async photo({ params }: { params: PhotoParams }) {
    await Promise.all([this.validator.validateOrReject(params, PhotoParams)]);

    let requestPath = '/photo/{user_id}';

    const paramsKeys = Object.keys(params) as (keyof PhotoParams)[];
    if (paramsKeys.length) {
      paramsKeys.forEach((param) => {
        requestPath = requestPath.replace(
          `{${param}}`,
          encodeURIComponent(String(params[param])),
        );
      });
    }

    const result = await this.http.getFile(requestPath, {
      withAuth: true,
      withSide: false,
      isJson: true,
    });

    return result;
  }

  async profile({
    query = {},
    params,
  }: {
    query?: ProfileQuery;
    params: ProfileParams;
  }) {
    await Promise.all([
      this.validator.validateOrReject(query, ProfileQuery),
      this.validator.validateOrReject(params, ProfileParams),
    ]);

    let requestPath = '/profile/{user_id}';

    const paramsKeys = Object.keys(params) as (keyof ProfileParams)[];
    if (paramsKeys.length) {
      paramsKeys.forEach((param) => {
        requestPath = requestPath.replace(
          `{${param}}`,
          encodeURIComponent(String(params[param])),
        );
      });
    }

    const result = await this.http.get(requestPath, query, {});

    const response = await this.unmarshaller.unmarshall(
      result,
      EmployeeProfileResponse,
    );

    return response;
  }

  async userActivityLogChangeSide({ body }: { body: ActivityLogChangeSide }) {
    await Promise.all([
      this.validator.validateOrReject(body, ActivityLogChangeSide),
    ]);

    let requestPath = '/user/activity_log/change_side';

    const result = await this.http.post(requestPath, body, { withSide: true });

    return result;
  }

  async userActivityLogProfileView() {
    let requestPath = '/user/activity_log/profile_view';

    const result = await this.http.post(requestPath, {}, { withSide: true });

    return result;
  }

  async userActivityLogEventView({ body }: { body: ActivityLogEventView }) {
    await Promise.all([
      this.validator.validateOrReject(body, ActivityLogEventView),
    ]);

    let requestPath = '/user/activity_log/event_view';

    const result = await this.http.post(requestPath, body, { withSide: true });

    return result;
  }

  async userTelegramClear() {
    let requestPath = '/user/telegram/clear';

    const result = await this.http.post(requestPath, {}, {});

    return result;
  }

  async userTelegramInit() {
    let requestPath = '/user/telegram/init';

    const result = await this.http.post(requestPath, {}, {});

    const response = await this.unmarshaller.unmarshall(
      result,
      UserTelegramInitResponse,
    );

    return response;
  }

  async userCertificateUnep() {
    let requestPath = '/user/certificate/unep';

    const result = await this.http.getFile(requestPath, {
      withAuth: true,
      withSide: false,
      isJson: true,
    });

    return result;
  }

  async userCertificateVerify({ body }: { body: CertificateVerifyRequest }) {
    await Promise.all([
      this.validator.validateOrReject(body, CertificateVerifyRequest),
    ]);

    let requestPath = '/user/certificate/_verify';

    const result = await this.http.post(requestPath, body, {});

    return result;
  }

  async userCertificateInit({
    body = {},
  }: {
    body?: CertificateInitRequest;
  } = {}) {
    await Promise.all([
      this.validator.validateOrReject(body, CertificateInitRequest),
    ]);

    let requestPath = '/user/certificate/_init';

    const result = await this.http.post(requestPath, body, {});

    return result;
  }

  async userOnecvkAccessTokenStatus() {
    let requestPath = '/user/onecvk/access_token/status';

    const result = await this.http.get(requestPath, {}, {});

    const response = await this.unmarshaller.unmarshall(
      result,
      OneCVKAccessTokenStatusResponse,
    );

    return response;
  }

  async userOnecvkAccessTokenGenerate() {
    let requestPath = '/user/onecvk/access_token/generate';

    const result = await this.http.post(requestPath, {}, {});

    const response = await this.unmarshaller.unmarshall(
      result,
      GenerateOneCVKAccessTokenResponse,
    );

    return response;
  }

  async userInviteSend({ body }: { body: UserInviteSendRequest }) {
    await Promise.all([
      this.validator.validateOrReject(body, UserInviteSendRequest),
    ]);

    let requestPath = '/user/invite/send';

    const result = await this.http.post(requestPath, body, { withAuth: false });

    return result;
  }

  async userPassword({ body }: { body: UserChangePasswordRequest }) {
    await Promise.all([
      this.validator.validateOrReject(body, UserChangePasswordRequest),
    ]);

    let requestPath = '/user/password';

    const result = await this.http.put(requestPath, body, { withAuth: false });

    return result;
  }

  async userPasswordVerifyCode({ body }: { body: PhoneVerification }) {
    await Promise.all([
      this.validator.validateOrReject(body, PhoneVerification),
    ]);

    let requestPath = '/user/password/verify_code';

    const result = await this.http.post(requestPath, body, { withAuth: false });

    return result;
  }

  async userPasswordInitChange({
    body,
  }: {
    body: UserPasswordInitChangeRequest;
  }) {
    await Promise.all([
      this.validator.validateOrReject(body, UserPasswordInitChangeRequest),
    ]);

    let requestPath = '/user/password/init_change';

    const result = await this.http.post(requestPath, body, { withAuth: false });

    return result;
  }

  async userLogin({ body }: { body: UserLoginRequest }) {
    await Promise.all([
      this.validator.validateOrReject(body, UserLoginRequest),
    ]);

    let requestPath = '/user/login';

    const result = await this.http.post(requestPath, body, { withAuth: false });

    const response = await this.unmarshaller.unmarshall(
      result,
      UserLoginResponse,
    );

    return response;
  }

  async userLoginInit({ body }: { body: UserLoginInitRequest }) {
    await Promise.all([
      this.validator.validateOrReject(body, UserLoginInitRequest),
    ]);

    let requestPath = '/user/login/init';

    const result = await this.http.post(requestPath, body, { withAuth: false });

    return result;
  }

  async userLogout({
    body = {},
  }: {
    body?: UserLogoutRequest;
  } = {}) {
    await Promise.all([
      this.validator.validateOrReject(body, UserLogoutRequest),
    ]);

    let requestPath = '/user/logout';

    const result = await this.http.post(requestPath, body, {});

    return result;
  }

  async oauth2UserRegister({ body }: { body: OAuth2RegisterUserRequest }) {
    await Promise.all([
      this.validator.validateOrReject(body, OAuth2RegisterUserRequest),
    ]);

    let requestPath = '/oauth2/user/register';

    const result = await this.http.post(requestPath, body, { withAuth: false });

    const response = await this.unmarshaller.unmarshall(
      result,
      UserLoginResponse,
    );

    return response;
  }

  async userPhoneVerifiedRegister({
    body,
  }: {
    body: RegisterUserPhoneVerifiedRequest;
  }) {
    await Promise.all([
      this.validator.validateOrReject(body, RegisterUserPhoneVerifiedRequest),
    ]);

    let requestPath = '/user/phone_verified/register';

    const result = await this.http.post(requestPath, body, { withAuth: false });

    const response = await this.unmarshaller.unmarshall(
      result,
      UserRegisterPhoneVerifiedResponse,
    );

    return response;
  }

  async userRegister({ body }: { body: RegisterUserRequest }) {
    await Promise.all([
      this.validator.validateOrReject(body, RegisterUserRequest),
    ]);

    let requestPath = '/user/register';

    const result = await this.http.post(requestPath, body, { withAuth: false });

    const response = await this.unmarshaller.unmarshall(
      result,
      UserRegisterResponse,
    );

    return response;
  }

  async openidUserRegister({ body }: { body: OpenIdUserRegisterRequest }) {
    await Promise.all([
      this.validator.validateOrReject(body, OpenIdUserRegisterRequest),
    ]);

    let requestPath = '/openid/user/register';

    const result = await this.http.post(requestPath, body, { withAuth: false });

    const response = await this.unmarshaller.unmarshall(
      result,
      AccessTokenResponse,
    );

    return response;
  }

  async openidUserRegisterInit({
    body,
  }: {
    body: OpenIdUserRegisterInitRequest;
  }) {
    await Promise.all([
      this.validator.validateOrReject(body, OpenIdUserRegisterInitRequest),
    ]);

    let requestPath = '/openid/user/register/init';

    const result = await this.http.post(requestPath, body, { withAuth: false });

    return result;
  }

  async openidTokenVerify({ body }: { body: OpenIdTokenVerifyRequest }) {
    await Promise.all([
      this.validator.validateOrReject(body, OpenIdTokenVerifyRequest),
    ]);

    let requestPath = '/openid/token/verify';

    const result = await this.http.post(requestPath, body, { withAuth: false });

    const response = await this.unmarshaller.unmarshall(
      result,
      OpenIdTokenVerifyResponse,
    );

    return response;
  }

  async oauth2UserRegisterInit({
    body,
  }: {
    body: OAuth2UserRegisterInitRequest;
  }) {
    await Promise.all([
      this.validator.validateOrReject(body, OAuth2UserRegisterInitRequest),
    ]);

    let requestPath = '/oauth2/user/register/init';

    const result = await this.http.post(requestPath, body, { withAuth: false });

    return result;
  }

  async userRegisterInit({ body }: { body: UserRegisterInitRequest }) {
    await Promise.all([
      this.validator.validateOrReject(body, UserRegisterInitRequest),
    ]);

    let requestPath = '/user/register/init';

    const result = await this.http.post(requestPath, body, { withAuth: false });

    return result;
  }

  async getUserManagerSubstitutesCreateOptions({
    query,
  }: {
    query: GetUserManagerSubstitutesCreateOptionsQuery;
  }) {
    await Promise.all([
      this.validator.validateOrReject(
        query,
        GetUserManagerSubstitutesCreateOptionsQuery,
      ),
    ]);

    let requestPath = '/user/manager_substitute/create_options';

    const result = await this.http.get(requestPath, query, {});

    const response = await this.unmarshaller.unmarshall(
      result,
      GetUserManagerSubstitutesCreateOptionsResponse,
    );

    return response;
  }

  async deleteUserManagerSubstitute({
    body,
  }: {
    body: DeleteUserManagerSubstituteRequest;
  }) {
    await Promise.all([
      this.validator.validateOrReject(body, DeleteUserManagerSubstituteRequest),
    ]);

    let requestPath = '/user/manager_substitute';

    const result = await this.http.delete(requestPath, body, {});

    return result;
  }

  async createUserManagerSubstitute({
    body,
  }: {
    body: CreateUserManagerSubstituteRequest;
  }) {
    await Promise.all([
      this.validator.validateOrReject(body, CreateUserManagerSubstituteRequest),
    ]);

    let requestPath = '/user/manager_substitute';

    const result = await this.http.post(requestPath, body, {});

    return result;
  }

  async getUserManagerSubstitutes({
    query,
  }: {
    query: GetUserManagerSubstitutesQuery;
  }) {
    await Promise.all([
      this.validator.validateOrReject(query, GetUserManagerSubstitutesQuery),
    ]);

    let requestPath = '/user/manager_substitute';

    const result = await this.http.get(requestPath, query, {});

    const response = await this.unmarshaller.unmarshall(
      result,
      GetUserManagerSubstitutesResponse,
    );

    return response;
  }

  async getManageableCompanies() {
    let requestPath = '/user/get_manageable_companies';

    const result = await this.http.get(requestPath, {}, {});

    const response = await this.unmarshaller.unmarshall(
      result,
      GetManageableCompaniesResponse,
    );

    return response;
  }

  async getUserPermissions() {
    let requestPath = '/user/permissions';

    const result = await this.http.get(requestPath, {}, {});

    const response = await this.unmarshaller.unmarshall(
      result,
      UserPermissionsResponse,
    );

    return response;
  }

  async getUser() {
    let requestPath = '/user';

    const result = await this.http.get(requestPath, {}, {});

    const response = await this.unmarshaller.unmarshall(result, UserResponse);

    return response;
  }

  async userList({ body }: { body: UserListRequest }) {
    await Promise.all([this.validator.validateOrReject(body, UserListRequest)]);

    let requestPath = '/user/list';

    const result = await this.http.post(requestPath, body, {});

    const response = await this.unmarshaller.unmarshall(
      result,
      UserListResponse,
    );

    return response;
  }

  async employeeUnepForceRelease({ body }: { body: EmployeeUnepResetRequest }) {
    await Promise.all([
      this.validator.validateOrReject(body, EmployeeUnepResetRequest),
    ]);

    let requestPath = '/employee/unep_force_release';

    const result = await this.http.post(requestPath, body, {});

    return result;
  }

  async employeeUnepReissue({ body }: { body: EmployeeUnepResetRequest }) {
    await Promise.all([
      this.validator.validateOrReject(body, EmployeeUnepResetRequest),
    ]);

    let requestPath = '/employee/unep_reissue';

    const result = await this.http.post(requestPath, body, {});

    return result;
  }

  async getEmployeeFilterOptions({
    query = {},
  }: {
    query?: GetEmployeeFilterOptionsQuery;
  } = {}) {
    await Promise.all([
      this.validator.validateOrReject(query, GetEmployeeFilterOptionsQuery),
    ]);

    let requestPath = '/employee/available_filters/employee_options';

    const result = await this.http.get(requestPath, query, { withSide: true });

    class Response {
      @ValidateNested()
      @Type(() => FilterSelectOption)
      response: FilterSelectOption[];
    }
    const { response } = await this.unmarshaller.unmarshall(
      { response: result },
      Response,
    );

    return response;
  }

  async getAvailableEmployeeFilters() {
    let requestPath = '/employee/available_filters';

    const result = await this.http.get(requestPath, {}, {});

    const response = await this.unmarshaller.unmarshall(
      result,
      FiltersResponse,
    );

    return response;
  }

  async employeeXlsx({
    body = {},
  }: {
    body?: EmployeeXlsxRequest;
  } = {}) {
    await Promise.all([
      this.validator.validateOrReject(body, EmployeeXlsxRequest),
    ]);

    let requestPath = '/employee/xlsx';

    const result = await this.http.post(requestPath, body, {});

    const response = await this.unmarshaller.unmarshall(
      result,
      EmployeeXlsxResponse,
    );

    return response;
  }

  async employeeList({
    body = {},
  }: {
    body?: EmployeeListRequest;
  } = {}) {
    await Promise.all([
      this.validator.validateOrReject(body, EmployeeListRequest),
    ]);

    let requestPath = '/employee/list';

    const result = await this.http.post(requestPath, body, {});

    const response = await this.unmarshaller.unmarshall(
      result,
      EmployeeListResponse,
    );

    return response;
  }

  async employeeActivate({ body }: { body: EmployeeActivateRequest }) {
    await Promise.all([
      this.validator.validateOrReject(body, EmployeeActivateRequest),
    ]);

    let requestPath = '/employee/activate';

    const result = await this.http.post(requestPath, body, {});

    return result;
  }

  async employeeInviteVerify({ body }: { body: EmployeeInviteVerifyRequest }) {
    await Promise.all([
      this.validator.validateOrReject(body, EmployeeInviteVerifyRequest),
    ]);

    let requestPath = '/employee/invite/verify';

    const result = await this.http.post(requestPath, body, { withAuth: false });

    const response = await this.unmarshaller.unmarshall(
      result,
      EmployeeInviteVerifyResponse,
    );

    return response;
  }

  async oauth2AuthCodeVerify({ body }: { body: OAuth2Verify }) {
    await Promise.all([this.validator.validateOrReject(body, OAuth2Verify)]);

    let requestPath = '/oauth2/auth_code/verify';

    const result = await this.http.post(requestPath, body, {});

    const response = await this.unmarshaller.unmarshall(
      result,
      OAuth2VerifyResponse,
    );

    return response;
  }

  async employeeCheckInviteByFilters({
    body,
  }: {
    body: CheckEmployeeInviteByFiltersRequest;
  }) {
    await Promise.all([
      this.validator.validateOrReject(
        body,
        CheckEmployeeInviteByFiltersRequest,
      ),
    ]);

    let requestPath = '/employee/check_invite_by_filters';

    const result = await this.http.post(requestPath, body, {});

    const response = await this.unmarshaller.unmarshall(
      result,
      CheckEmployeeInviteByFiltersResponse,
    );

    return response;
  }

  async employeeInviteByFilters({
    body,
  }: {
    body: EmployeeInviteByFiltersRequest;
  }) {
    await Promise.all([
      this.validator.validateOrReject(body, EmployeeInviteByFiltersRequest),
    ]);

    let requestPath = '/employee/invite_by_filters';

    const result = await this.http.post(requestPath, body, {});

    return result;
  }

  async employeeInviteByIds({ body }: { body: EmployeeInviteByIdsRequest }) {
    await Promise.all([
      this.validator.validateOrReject(body, EmployeeInviteByIdsRequest),
    ]);

    let requestPath = '/employee/invite_by_ids';

    const result = await this.http.post(requestPath, body, { withSide: true });

    return result;
  }

  async domainQr() {
    let requestPath = '/domain_qr';

    const result = await this.http.getFile(requestPath, {
      withAuth: true,
      withSide: false,
      isJson: true,
    });

    return result;
  }

  async authInstance() {
    let requestPath = '/auth_instance';

    const result = await this.http.get(requestPath, {}, { withAuth: false });

    const response = await this.unmarshaller.unmarshall(
      result,
      AuthInstanceResponse,
    );

    return response;
  }
}
