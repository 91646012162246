import React, { ReactElement, useState, useEffect } from 'react';

import { useIntersectionObserver } from '@vk-hr-tek/core/hooks';

import { Box } from '../Box';
import { CircularProgress } from '../CircularProgress';

import { FilePreviewContent } from './FilePreviewContent';

interface FilePreviewProps {
  onLoad: () => Promise<Blob>;
  shape?: 'auto' | 'square';
  onClickPrint?: () => void;
  onClickDownload?: () => Promise<
    Blob | { file: Promise<Blob>; filename?: string }
  >;
  onError?: (e: Error | undefined) => void;
  onClickOpen?: () => void;
  onLoadSuccess?: () => void;
  isClickable?: boolean;
  withCollapsableFooter?: boolean;
  view?: 'file' | 'fileLarge' | 'link';
  showSnackbarOnDesktop?: boolean;
  snackbarMessage?: string;
  snackbarAction?: string;
  linkText?: string | ReactElement;
  hideLoader?: boolean;
  withFlex?: boolean;
  centerImage?: boolean;
}

/**
 * Компонент для предварительного просмотра файла с ленивой загрузкой и дополнительными действиями.
 *
 * @param {Object} props - Свойства, которые можно передать в компонент FilePreview.
 * @param {() => Promise<Blob>} props.onLoad - Функция, возвращающая промис, который разрешается в Blob при загрузке файла.
 * @param {('auto' | 'square')} [props.shape='auto'] - Форма предварительного просмотра файла.
 * @param {() => void} [props.onClickPrint] - Коллбэк-функция для обработки действия печати.
 * @param {() => Promise<Blob | { file: Promise<Blob>; filename?: string }>} [props.onClickDownload] - Коллбэк-функция для обработки действия загрузки.
 * @param {(e: Error | undefined) => void} [props.onError] - Коллбэк-функция для обработки ошибок при загрузке файла.
 * @param {() => void} [props.onClickOpen] - Коллбэк-функция для обработки открытия файла.
 * @param {() => void} [props.onLoadSuccess] - Коллбэк-функция для обработки успешной загрузки файла.
 * @param {boolean} [props.isClickable=false] - Если true, предварительный просмотр файла кликабелен.
 * @param {boolean} [props.withCollapsableFooter=false] - Если true, нижний колонтитул может сворачиваться.
 * @param {('file' | 'fileLarge' | 'link')} [props.view='file'] - Тип представления предварительного просмотра файла.
 * @param {boolean} [props.showSnackbarOnDesktop=false] - Если true, уведомление отображается на настольных устройствах.
 * @param {string} [props.snackbarMessage] - Сообщение для отображения в уведомлении.
 * @param {string} [props.snackbarAction] - Текст действия для уведомления.
 * @param {string | ReactElement} [props.linkText] - Текст или React-элемент для отображения в виде ссылки.
 * @param {boolean} [props.hideLoader=false] - Если true, индикатор загрузки скрыт.
 * @param {boolean} [props.withFlex=false] - Если true, предварительный просмотр файла использует flexbox.
 * @param {boolean} [props.centerImage=false] - Если true, изображение центрируется.
 * @returns {JSX.Element} JSX-элемент, представляющий компонент предварительного просмотра файла.
 */

export const FilePreview = (props: FilePreviewProps) => {
  const [isIntersected, setIsIntersected] = useState(false);

  const [ref, entry] = useIntersectionObserver({
    threshold: 0,
    root: null,
    rootMargin: '100px',
  });

  useEffect(() => {
    if (entry?.isIntersecting) {
      setIsIntersected(true);
    }
  }, [entry?.isIntersecting]);

  return (
    <>
      <Box ref={ref} component="span" position="absolute" />
      {isIntersected ? (
        <FilePreviewContent {...props} />
      ) : (
        !props.hideLoader && (
          <Box display="flex" justifyContent="center" p="32">
            <CircularProgress size={50} />
          </Box>
        )
      )}
    </>
  );
};
