import { injectable } from 'inversify';

import {
  AssignableRole,
  EventTypeGroup,
  CreateEventCompanyItem,
} from '@app/gen/events';
import { CreateEventOption, CreateEventEmployee } from '@app/types';

import { EventTypeItemWithGroup } from '../types/EventTypeItemWithGroup';

@injectable()
export class EventsCreateMapper {
  processCreateEventOption(options: CreateEventOption[]) {
    return options.map((option) => ({
      optionName: option.option_name,
      employeeId: option.employee_id,
      eventTypeId: option.event_type_id,
      eventTypeName: option.event_type_name,
      employeeName: option.employee_name,
      personnelNumber: option.personnel_number,
    }));
  }

  processCreateEventItem(items: CreateEventCompanyItem[]) {
    return items.map((item) => ({
      companyId: item.company_id,
      companyName: item.company_name,
    }));
  }

  processEmployees(employees: CreateEventEmployee[]) {
    return employees
      .map((employee) => {
        const eventTypes = employee.event_types;

        const eventTypeItems = eventTypes
          .filter(
            (event): event is EventTypeItemWithGroup => event.type === 'item',
          )
          .sort((a, b) => {
            return (a.name || '').toLowerCase() > (b.name || '').toLowerCase()
              ? 1
              : -1;
          });

        const eventTypeGroups = eventTypes.filter(
          (event): event is EventTypeGroup => event.type === 'group',
        );

        eventTypeGroups.forEach((event) => {
          if (event.items) {
            event.items.forEach((item) =>
              eventTypeItems.unshift({
                ...item,
                group: event.label,
              }),
            );
          }
        });

        return {
          ...employee,
          personnelNumber: employee.personnel_number,
          eventTypes: eventTypeItems,
        };
      })
      .sort((a, b) =>
        (a.name || '').toLowerCase() > (b.name || '').toLowerCase() ? 1 : -1,
      );
  }

  processCreateEventOptionCompany(options: { company_id: string }[]) {
    return options.map((option) => ({
      companyId: option.company_id,
    }));
  }

  processCreateEventItemCompany(
    items: {
      company_id: string;
      company_name: string;
      event_types: EventTypeItemWithGroup[];
    }[],
  ) {
    return items.map((item) => ({
      companyId: item.company_id,
      companyName: item.company_name,
      eventTypes: item.event_types,
    }));
  }

  processAssignableRoles(
    roles: AssignableRole[] | undefined,
    id: string,
    required: boolean,
  ) {
    return (
      roles?.reduce((acc, role) => {
        return {
          ...acc,
          [role.role.id]: {
            roleName: role.role.name,
            defaultValue: role.default_group?.group_id,
            defaultName: role.default_group?.name,
            position: role.default_group?.position,
            personalNumber: role.default_group?.personnel_number,
            companyName: role.default_group?.company?.name,
            scope: role.scope,
            eventTypeId: id,
            required,
          },
        };
      }, {}) || null
    );
  }
}
