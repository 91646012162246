export interface EventCreationRouter {
  goToDetail(id: string): void;
  goToList(query?: string): void;
  goToCreate(search: string): void;
  goToCreateBatch(params: {
    companyId: string;
    eventTypeId: string;
    employeeId?: string;
    parentEventId?: string;
  }): void;
  goBack(prevState: { pathname: string; search: string }): void;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EventCreationRouter = Symbol('EventCreationRouter');
