import { IsNotEmpty, IsString, IsIn } from 'class-validator';

export class GetFiltersPresetDto {
  @IsIn([
    'events',
    'schedule_events',
    'absence_events',
    'employees',
    'company_policies',
  ])
  @IsString()
  @IsNotEmpty()
  listCode:
    | 'events'
    | 'schedule_events'
    | 'absence_events'
    | 'employees'
    | 'company_policies';
}
