export enum PersonalLinksIcons {
  globe = 'globe',
  document = 'document',
  bookmark = 'bookmark',
  picture = 'picture',
  cup = 'cup',
  briefcase = 'briefcase',
  advertising = 'advertising',
  user = 'user',
}
