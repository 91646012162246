import React, { ReactNode } from 'react';

import { Box } from '../../../Box';

import useStyles from './FillBanner.styles';

interface FillBannerProps {
  children: ReactNode;
}

export default ({ children }: FillBannerProps) => {
  const classes = useStyles();

  return (
    <Box
      position="absolute"
      left={0}
      top={0}
      right={0}
      px="16"
      py={{ xs: '12', md: '16' }}
    >
      <Box className={classes.autoFillBanner}>{children}</Box>
    </Box>
  );
};
