import React from 'react';

import classNames from 'classnames';

import useStyles from './FormLinkButton.styles';

interface FormLinkButtonProps {
  children: React.ReactNode;
  variant?: 'primary' | 'tertiary';
}

/**
 * Компонент кнопки-ссылки для форм.
 * @param {Object} props - Свойства компонента.
 * @param {React.ReactNode} props.children - Дочерние элементы, которые будут отображены внутри кнопки.
 * @param {'primary'|'tertiary'} [props.variant='primary'] - Вариант стиля кнопки. Может быть 'primary' или 'tertiary'.
 * @returns {JSX.Element} Кнопка-ссылка с указанными свойствами.
 */

export const FormLinkButton = ({
  children,
  variant = 'primary',
}: FormLinkButtonProps) => {
  const classes = useStyles();

  return (
    <button
      type="submit"
      className={classNames(
        classes.linkButton,
        variant === 'tertiary' && classes.linkButton_tertiary,
      )}
    >
      {children}
    </button>
  );
};
