import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { classToPlain } from 'class-transformer';

import { AppError } from '../../error';
import { NotificationDownloadService } from '../services';
import { DownloadDto } from '../dto';
import { DownloadNotificationEventEmitter, NotificationText } from '../types';
import { GetFileRequestStateResponse } from '../gen/tools';
import { ThunkExtra } from '../../utils';
import { DownloadService } from '../../download';

import { DownloadNotificationState } from './downloadNotification.state';

const delay = (time: number) =>
  new Promise((resolve) => {
    setTimeout(() => resolve(null), time);
  });

interface DownloadNotificationInterface {
  downloadNotification: DownloadNotificationState;
}

export const setDownloadWaiting = createAsyncThunk<
  { id: string; count: number; text?: NotificationText },
  { id: string; count: number; text?: NotificationText },
  ThunkExtra<DownloadNotificationInterface>
>(
  'core/downloadNotification/setDownloadWaiting',
  (args, { extra: { inject } }) => {
    const eventEmitter = inject<DownloadNotificationEventEmitter>(
      DownloadNotificationEventEmitter,
    );
    eventEmitter.onShow({
      id: args.id,
      status: 'waiting',
      text: args.text?.loading,
    });

    return args;
  },
);
export const setDownloadComplete = createAsyncThunk<
  { id: string },
  { id: string },
  ThunkExtra<DownloadNotificationInterface>
>(
  'core/downloadNotification/setDownloadComplete',
  async (args, { getState, extra: { inject } }) => {
    const eventEmitter = inject<DownloadNotificationEventEmitter>(
      DownloadNotificationEventEmitter,
    );
    const downloads = getState().downloadNotification.downloads;
    const download = downloads.find(({ id }) => id === args.id);

    eventEmitter.onShow({
      id: args.id,
      status: 'complete',
      text: download?.text?.complete,
    });

    return args;
  },
);
export const setDownloadFailed = createAsyncThunk<
  { id: string },
  { id: string; errorMessage?: string },
  ThunkExtra<DownloadNotificationInterface>
>(
  'core/downloadNotification/setDownloadFailed',
  async (args, { getState, extra: { inject } }) => {
    const eventEmitter = inject<DownloadNotificationEventEmitter>(
      DownloadNotificationEventEmitter,
    );
    const downloads = getState().downloadNotification.downloads;
    const download = downloads.find(({ id }) => id === args.id);

    eventEmitter.onShow({
      id: args.id,
      status: 'failed',
      text: download?.text?.failed,
    });

    return args;
  },
);
export const cancelDownloads = createAsyncThunk<
  void,
  void,
  ThunkExtra<DownloadNotificationInterface>
>(
  'core/downloadNotification/cancelDownloads',
  async (_, { extra: { inject } }) => {
    const eventEmitter = inject<DownloadNotificationEventEmitter>(
      DownloadNotificationEventEmitter,
    );
    eventEmitter.onCancel();

    return;
  },
);

export const pollDownloadStatus = createAsyncThunk<
  GetFileRequestStateResponse,
  DownloadDto,
  ThunkExtra<DownloadNotificationInterface>
>(
  'core/downloadNotification/pollDownloadStatus',
  async (
    batchDownloadDto,
    { getState, rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      const second = 1000;
      const service = inject(NotificationDownloadService);

      await delay(second);
      let downloadEntity = await service.getStatus(batchDownloadDto);

      while (downloadEntity.download.status === 'in_progress') {
        const state = getState().downloadNotification.downloads;
        const fiveSeconds = 5000;

        if (!state.length) {
          break;
        }

        await delay(fiveSeconds);
        downloadEntity = await service.getStatus(batchDownloadDto);
      }

      if (
        downloadEntity.download.status === 'error' ||
        downloadEntity.download.status === 'expired'
      ) {
        dispatch(
          setDownloadFailed({
            id: batchDownloadDto.download_id,
            errorMessage:
              downloadEntity.download.error_message &&
              `Ошибка: ${downloadEntity.download.error_message}`,
          }),
        );

        throw new AppError('server', {
          code: 500,
          message: `Status ${downloadEntity.download.status}`,
        });
      }

      if (
        downloadEntity.download.status === 'done' &&
        getState().downloadNotification.downloads.length
      ) {
        dispatch(setDownloadComplete({ id: batchDownloadDto.download_id }));

        service
          .getArchive(batchDownloadDto)
          .then(async ({ file, filename }) => {
            await inject(DownloadService).doDownload(
              file,
              filename || 'documents.zip',
            );
          });
      }

      return downloadEntity;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const downloadNotificationReducers = (
  builder: ActionReducerMapBuilder<DownloadNotificationState>,
) => {
  builder.addCase(setDownloadWaiting.fulfilled, (state, action) => {
    const { id, count, text } = action.payload;
    state.downloads = [
      ...state.downloads,
      { id, status: 'waiting', counter: count, text },
    ];
  });
  builder.addCase(setDownloadComplete.fulfilled, (state, action) => {
    state.downloads = state.downloads.map((item) => {
      if (item.id === action.payload.id) {
        return {
          ...item,
          status: 'complete',
        };
      }

      return item;
    });
  });
  builder.addCase(setDownloadFailed.fulfilled, (state, action) => {
    state.downloads = state.downloads.map((item) => {
      if (item.id === action.payload.id) {
        return {
          ...item,
          ...(item.text && {
            text: {
              ...item.text,
              failed: action.meta.arg.errorMessage || item.text.failed,
            },
          }),
          status: 'failed',
        };
      }

      return item;
    });
  });
  builder.addCase(cancelDownloads.fulfilled, (state) => {
    state.downloads = [];
  });
};
